import React, { useState, useEffect } from 'react';

import styled from 'styled-components';
import { useHistory } from 'react-router';
import EjectIcon from 'mdi-react/EjectIcon';
import RayStartArrowIcon from 'mdi-react/RayStartArrowIcon';
import { Divider, IconButton, Tooltip } from '@material-ui/core';

import Span from '../../Common/Span';
import color from '../../../utils/color';
import { Col, Row } from '../../../styles/snowm_styled';
import { getYearMonthDay } from '../../../helpers/date';
import ListTitleItem from '../../Generics/list_title_item';
import {
  getServicePointById,
  getEndUserByUid,
} from '../../../controllers/snowm_firebase';

const ServiceRequestItem = ({
  serviceRequest,
  inWorkOrdersPage,
  handleStartButton,
  handleRejectButton,
}) => {
  const [marker, setMarker] = useState();
  const [customer, setCustomer] = useState();

  const history = useHistory();

  useEffect(() => {
    let isCancel = false;
    if (serviceRequest) {
      getServicePointById(serviceRequest.markerKey).then((res) => {
        if (!isCancel) {
          setMarker(res);
        }
      });
      getEndUserByUid(serviceRequest.customerUid).then((res) => {
        if (!isCancel) {
          setCustomer(res);
        }
      });
    }

    return () => {
      isCancel = true;
    };
  }, [serviceRequest]);

  function handleCardAction(e) {
    if (inWorkOrdersPage) {
      e.stopPropagation();
      history.push(
        `/home/work-orders/${serviceRequest.markerKey}/order/${serviceRequest.id}`
      );
    }
  }

  function getColor() {
    switch (serviceRequest.status) {
      case 'REQUESTED':
        return color.snomwBlue;
      case 'WORKING':
        return color.gradientEndColor;
      case 'REJECTED':
        return color.red;
      case 'DISPATCHED':
        return color.amber;
      case 'COMPLETED':
        return color.green;
      default:
        return color.black;
    }
  }

  const requestNote = () => {
    if (serviceRequest?.requestNote?.length < 40) {
      return (
        <ListTitleItem
          title="Request Note"
          content={serviceRequest?.requestNote}
        />
      );
    }
    return (
      <Col>
        <Span size="16px">Request Note</Span>
        <Tooltip title={serviceRequest?.requestNote}>
          <LongText size="16px" weight="bold">
            {serviceRequest?.requestNote}
          </LongText>
        </Tooltip>
      </Col>
    );
  };

  function handleRejectButtonClick(event) {
    event.stopPropagation();
    handleRejectButton();
  }

  function handleStartOrderButton(event) {
    event.stopPropagation();
    handleStartButton();
  }

  const workOrderActions = () => {
    if (serviceRequest?.status !== 'REQUESTED' || !inWorkOrdersPage) {
      return null;
    }

    return (
      <>
        <Divider />
        <Row marginbottom="0" justify="space-around">
          <Tooltip title="Reject the order" aria-label="reject">
            <IconButton onClick={handleRejectButtonClick}>
              <EjectIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Start the order" aria-label="start">
            <IconButton onClick={handleStartOrderButton}>
              <RayStartArrowIcon />
            </IconButton>
          </Tooltip>
        </Row>
      </>
    );
  };

  return (
    <Card
      onClick={handleCardAction}
      onKeyDown={handleCardAction}
      tabIndex={0}
      role="article"
    >
      <Row justify="space-between">
        <Span size="16px" weight="bold">
          {marker?.name}
        </Span>
        <Status color={getColor()} width="auto">
          <Span size="12px" color={color.white}>
            {serviceRequest?.status}
          </Span>
        </Status>
      </Row>
      <Divider />
      <ListTitleItem content={customer?.name} title="Customer" />
      <ListTitleItem
        content={getYearMonthDay(serviceRequest?.requestedDate)}
        title="Requested Date"
      />
      {requestNote()}
      {workOrderActions()}
    </Card>
  );
};

export default ServiceRequestItem;

const Card = styled.article`
  display: flex;
  flex-direction: column;
  background-color: ${color.white};
  padding: 12px;
  box-shadow: 0px 0px 4px 0px ${color.lightGrey};
  border-radius: 8px;
  cursor: pointer;
`;

const Status = styled(Row)`
  background-color: ${(props) => props.color};
  padding: 2px 8px;
  border-radius: 4px;
`;

const LongText = styled(Span)`
  word-wrap: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
