/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: Jobs/index.jsx
 *
 *  Description: Index file that contains routes for jobs
 *
 *  Author: Roshan Gautam (roshan@brainants.com)
 *
 *  Date created: 12-july-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */

import React, { useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Jobs from './snowm_jobs';
import { JobLogs } from './JobsLogs';
import JobDetail from './job_detail';
import LogDetailOfAMarker from './log_detail_of_marker';
import IndoorJobRouteDetail from './indoor_route_job_detail';
import OutdoorJobRouteDetail from './outdoor_route_job_detail';
import { ServiceTypesContext } from '../../../contexts/service_types';

export default function () {
  const { companyServices } = useContext(ServiceTypesContext);

  if (companyServices?.length <= 0) {
    return <Redirect to="/" />;
  }

  return (
    <Switch>
      <Route path="/home/jobs/shift/details/:jobId" component={JobDetail} />
      <Route path="/home/jobs/details/:jobKey/logs" exact component={JobLogs} />
      <Route path="/home/jobs/details/:jobId" exact component={JobDetail} />
      <Route
        path="/home/jobs/details/:jobKey/indoor/route/:routeKey"
        exact
        component={IndoorJobRouteDetail}
      />
      <Route
        path="/home/jobs/details/:jobKey/outdoor/route/:routeKey"
        exact
        component={OutdoorJobRouteDetail}
      />
      <Route
        path="/home/jobs/details/:jobKey/marker/:markerKey"
        exact
        component={LogDetailOfAMarker}
      />
      <Route path="/home/jobs/" exact component={Jobs} />

      <Redirect to="/home/jobs" />
    </Switch>
  );
}
