/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: snowm_jobs.jsx
 *
 *  Description: Show the list of jobs
 *
 *  Author: Roshan Gautam (roshan@brainants.com)
 *
 *  Date created: 12-july-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */

import React, { useEffect, useState, useContext } from 'react';

import moment from 'moment';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import CloseIcon from 'mdi-react/CloseIcon';
import MenuDownIcon from 'mdi-react/MenuDownIcon';
import FilterOutlineIcon from 'mdi-react/FilterOutlineIcon';
import SortBoolAscendingVariantIcon from 'mdi-react/SortBoolAscendingVariantIcon';
import SortBoolDescendingVariantIcon from 'mdi-react/SortBoolDescendingVariantIcon';
import {
  CircularProgress,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
} from '@material-ui/core';

import JobItem from './job_item';
import JobFilter from './JobFilter';
import Span from '../../Common/Span';
import JobModal from './snowm_add_job';
import Header from '../../Common/header';
import AlertBox from '../../Common/alert_box';
import AddButton from '../../Common/add_button';
import TabsToggle from '../../Common/TabsToggle';
import PrimaryButton from '../../Common/primary_button';
import { JobsContext } from '../../../contexts/service_jobs';
import Loader, { Progress } from '../../Generics/snowm_loader';
import { ServiceTypesContext } from '../../../contexts/service_types';
import { Col, Row, StyledTitle, Title } from '../../../styles/snowm_styled';
import { LocalizationContext } from '../../../contexts/localization_context';
import {
  getFilteredItems as getFilteredJobs,
  getFilteredItems,
  filterDataByName,
} from '../../../helpers/misc';
import {
  deleteJob,
  deleteShiftJob,
  getServices,
  completeJob,
  getProviderByUid,
  getServicePointById,
  getPropertyById,
  getShiftById,
  searchJobs,
  getOlderJobs,
  getFilteredJobs as getJobsByFilters,
} from '../../../controllers/snowm_firebase';
import JobsTable from './JobsTable';
import color from '../../../utils/color';
import IssueLink from '../Profile/IssueLink';
import ADialog, { Align } from '../../Common/styled';
import { getDayMonthYear } from '../../../helpers/date';

export default function (jobsProps) {
  return (
    <JobsContext.Consumer>
      {({ jobs, periodicJobs, fetchJobs, fetchPeriodicJobs }) => {
        return (
          <Jobs
            assignedJobs={jobs}
            periodicJobs={periodicJobs}
            fetchJobs={fetchJobs}
            fetchPeriodicJobs={fetchPeriodicJobs}
            {...jobsProps}
          />
        );
      }}
    </JobsContext.Consumer>
  );
}

function Jobs({ fetchJobs, fetchPeriodicJobs, periodicJobs, assignedJobs }) {
  const { strings } = useContext(LocalizationContext);
  const services = useContext(ServiceTypesContext);

  const subscribedServices = services?.subscribedServices;

  const { ASSIGNED_JOBS, SHIFT_JOBS } = strings?.jobType;

  const defaultFilterTypes = ['ALL', 'By Crew', 'By Marker'];

  const [jobs, setJobs] = useState(null);
  const [edit, setEdit] = useState(false);
  const [jobTabs, setJobTabs] = useState(0);
  const [jobTypes, setJobTypes] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isSearch, setIsSearch] = useState(false);
  const [sortType, setSortType] = useState('desc');
  const [olderJobs, setOlderJobs] = useState(null);
  const [selectedJob, setSelectedJob] = useState([]);
  const [filterTypes, setFilterTypes] = useState({});
  const [filterType, setFilterType] = useState('ALL');
  const [searchResult, setSearchResult] = useState([]);
  const [sortByNames, setSortByNames] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [noOlderJobs, setNoOlderJobs] = useState(false);
  const [filteredJobs, setFilteredJobs] = useState(null);
  const [individualJob, setIndividualJob] = useState({});
  const [selectedJobKey, setSelectedJobKey] = useState();
  const [selectedName, setSelectedName] = useState(null);
  const [errorOccured, setErrorOccured] = useState(false);
  const [showJobModal, setShowJobModal] = useState(false);
  const [openJobDialog, setOpenJobDialog] = useState(false);
  const [anotherEdit, setAnotherEdit] = useState(false);
  const [customerServices, setCustomerServices] = useState();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedServiceKey, setSelectedServiceKey] = useState('all');
  const [isLoadingOlderJobs, setIsLoadingOlderJobs] = useState(false);
  const [selectedJobType, setSelectedJobType] = useState(SHIFT_JOBS);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  const open = Boolean(anchorEl);

  function handleJobTypeChange(jobType) {
    setIsSearch(false);
    setSelectedJobType(jobType);
  }

  useEffect(() => {
    if (jobTypes) {
      const jobType = jobTypes?.[jobTabs];
      setSelectedJobType(jobType);
    }
  }, [jobTabs, jobTypes]);

  const handleChangeJobTabs = (event, value) => {
    setJobTabs(value);
    const jobType = jobTypes[value];
    handleJobTypeChange(jobType);
  };

  function closeJobDialog() {
    setOpenJobDialog(false);
    setIndividualJob({});
  }

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  function sortByCreatedDate(firstJob, secondJob) {
    const firstJobDate = firstJob?.job?.createdDate ?? firstJob.createdDate;
    const secondJobDate = secondJob.job?.createdDate ?? secondJob.createdDate;

    let integer = 1;
    if (sortType === 'desc') {
      integer = -1;
    }

    if (firstJobDate > secondJobDate) {
      return integer;
    }
    if (firstJobDate < secondJobDate) {
      return -integer;
    }
    return 0;
  }

  function sortJobs(jobsArray) {
    return jobsArray.sort(sortByCreatedDate);
  }

  const handleFilteredJobs = (jobsFiltered, showAll, filteringAttrs) => {
    if (showAll) {
      setIsSearch(false);
    } else {
      setIsSearch(true);
    }
    setFilterTypes(filteringAttrs);
    const jobsThatAreFiltered = sortJobs(jobsFiltered);
    setFilteredJobs(jobsThatAreFiltered);
    handleMenuClose();
  };

  useEffect(() => {
    let isSubscribe = true;
    getServices().then((res) => {
      const response = res?.map((d) => d.data() ?? []);
      if (isSubscribe) setCustomerServices(response);
    });

    return () => {
      isSubscribe = false;
    };
  }, []);

  useEffect(() => {
    if (subscribedServices?.shiftManagement) {
      setJobTypes([SHIFT_JOBS, ASSIGNED_JOBS]);
    } else setJobTypes([ASSIGNED_JOBS]);
  }, [subscribedServices]);

  useEffect(() => {
    if (selectedJobType === SHIFT_JOBS) {
      setJobs(periodicJobs);
    }
  }, [periodicJobs]);

  const fetchUntilAny = (jobRes, count) => {
    const res = Object.values(jobRes ?? {});
    if (res?.length === 0 && count < 100) {
      fetchMore(count);
    } else {
      setJobs([...(res ?? []), ...(olderJobs ?? [])]);
    }
  };

  const fetchMore = (count) => {
    const newCount = count + 1;

    getOlderJobs().then((res) => {
      fetchUntilAny(res, newCount);
    });
  };

  useEffect(() => {
    if (selectedJobType === ASSIGNED_JOBS) {
      fetchUntilAny(assignedJobs, 0);
    }
  }, [assignedJobs]);

  useEffect(() => {
    setSearchResult([]);
    setFilterType('ALL');
    setOlderJobs(null);
    setFilteredJobs(null);
    if (selectedJobType === ASSIGNED_JOBS) {
      fetchJobs();
    } else if (selectedJobType === SHIFT_JOBS) {
      fetchPeriodicJobs();
    }
  }, [selectedJobType]);

  function getUniqueIds(fieldName) {
    const uniquefieldKeys = Object.values(jobs).reduce((acc, currentJob) => {
      const job = currentJob?.job ?? currentJob;
      let fieldKeys = [];
      const keys = job[fieldName] ?? [];
      if (typeof keys === 'string') {
        fieldKeys = [...new Set([...acc, keys])];
      } else {
        fieldKeys = [...new Set([...acc, ...keys])];
      }
      return fieldKeys;
    }, []);

    return uniquefieldKeys;
  }

  function getKeyValuePair(data, nameString = 'name', keyString = 'key') {
    const keyValueOfNameAndKey = data?.reduce((acc, datum) => {
      const name = datum[nameString];
      const key = datum[keyString];
      if (!name || !key) {
        return acc;
      }
      return { ...acc, [name]: key };
    }, {});
    return keyValueOfNameAndKey;
  }

  function getDataFromFirestore(key, collection) {
    switch (collection) {
      case 'crews':
        return getProviderByUid(key);
      case 'properties':
        return getPropertyById(key);
      case 'shifts':
        return getShiftById(key);
      case 'markers':
        return getServicePointById(key);
      default:
        throw new Error();
    }
  }

  async function filterByJobField(
    fieldName,
    collectionName,
    nameInDoc,
    docKey
  ) {
    const uniquePropertyKeys = getUniqueIds(fieldName);
    const propertiesPromises = uniquePropertyKeys?.map((key) => {
      return getDataFromFirestore(key, collectionName);
    });

    const properties = await Promise.all(propertiesPromises);

    const propertiesKeyValue = getKeyValuePair(properties, nameInDoc, docKey);
    return propertiesKeyValue;
  }

  function getFilteredJobsByType() {
    switch (filterType) {
      case 'By Crew':
        return filterByJobField('providerUids', 'crews', 'name', 'uid');

      case 'By Marker':
        return filterByJobField('allMarkers', 'markers', 'name', 'key');

      case 'By Property':
        return filterByJobField('propertyKey', 'properties', 'name', 'id');
      case 'By Shift':
        return filterByJobField('shiftKey', 'shifts', 'name', 'id');
      default:
        throw new Error();
    }
  }

  useEffect(() => {
    if (filterType === 'ALL' && selectedJobType === ASSIGNED_JOBS) {
      fetchJobs();
    } else if (filterType !== 'ALL') {
      setSortByNames(null);
      getFilteredJobsByType().then((res) => {
        setSortByNames(res);
      });
    }
  }, [filterType]);

  useEffect(() => {
    const jobsArray = Object.values(filteredJobs ?? {});
    const jobsFromDatabase = Object.values(jobs ?? {});
    let arrayOfJobs = jobsArray;

    if (!isSearch) {
      arrayOfJobs = jobsFromDatabase;
    }

    const sortedJobs = sortJobs(arrayOfJobs);
    let allJobs = [];
    if (searchResult.length > 0) {
      allJobs = getFilteredItems(searchResult, selectedServiceKey);
    } else if (jobs) {
      allJobs = getFilteredJobs(sortedJobs, selectedServiceKey);
    }

    setFilteredJobs([...allJobs, ...(olderJobs ?? [])]);
  }, [jobs, selectedServiceKey]);

  useEffect(() => {
    if (olderJobs) {
      setFilteredJobs([...filteredJobs, ...olderJobs]);
    }
  }, [olderJobs]);

  useEffect(() => {
    if (filteredJobs) {
      const arrayOfJobs = Object.values(filteredJobs);
      const sortedJobs = sortJobs(arrayOfJobs);
      const allJobs = getFilteredJobs(sortedJobs, selectedServiceKey);
      setFilteredJobs(allJobs);
    }
  }, [sortType]);

  function handleServiceChange(event) {
    setSelectedServiceKey(event.target.value);
  }

  const handleEditIconClick = (job) => {
    setIndividualJob(job);
    setOpenJobDialog(true);
    setEdit(true);
    setAnotherEdit(false);
  };
  const handleStartEndDateAndStatusEdit = (job) => {
    setIndividualJob(job);
    setOpenJobDialog(true);
    setAnotherEdit(true);
    setEdit(false);
  };
  const handleDeleteIconPress = (job) => {
    setIndividualJob(job);
    setOpenDeleteDialog(true);
  };

  const handleDetailsIconClick = (job) => {
    setSelectedJob(job);
    setShowJobModal(true);
  };

  const handleAddButton = () => {
    setEdit(false);
    setAnotherEdit(false);
    setOpenJobDialog(true);
    setIndividualJob({});
  };

  const handlePositiveAction = async () => {
    if (selectedJobType === SHIFT_JOBS) {
      deleteShiftJob(individualJob.key);
    } else {
      const jobCreatedDay = getDayMonthYear(individualJob.createdDate);

      const currentDay = getDayMonthYear(moment().valueOf());
      await deleteJob(individualJob.key);
      if (jobCreatedDay !== currentDay) {
        const updatedJobs = filteredJobs?.filter(
          (job) => job.key !== individualJob.key
        );

        setFilteredJobs(updatedJobs);
      }
    }
    setOpenDeleteDialog(false);
  };

  const handleNegativeAction = () => {
    setOpenDeleteDialog(false);
    setOpenConfirmationDialog(false);
    setErrorOccured();
  };

  const handleSearchOnClick = async (textOrResponse) => {
    setIsSearching(true);
    let jobsAfterSearch;
    if (typeof textOrResponse === 'string') {
      if (selectedJobType === ASSIGNED_JOBS) {
        jobsAfterSearch = await searchJobs(textOrResponse, 'jobs');
      } else {
        jobsAfterSearch = filterDataByName(textOrResponse, filteredJobs);
      }
    } else {
      jobsAfterSearch = getFilteredItems(textOrResponse, selectedServiceKey);
    }

    setFilteredJobs(jobsAfterSearch);
    setIsSearch(true);
    setIsSearching(false);
  };

  const handleShowAllButton = () => {
    setNoOlderJobs(false);
    setFilterTypes({});
    setSelectedServiceKey('all');
    const jobsArrray = Object.values(jobs);
    setFilteredJobs(jobsArrray);
    setIsSearch(false);
  };

  const handleJobItemClick = () => {
    if (selectedJobType === SHIFT_JOBS) {
      return `/home/jobs/shift/details/${selectedJob.key}`;
    }
    return `/home/jobs/details/${selectedJob.key}`;
  };

  async function openCompleteJobConfirmationDialog(jobKey) {
    setOpenConfirmationDialog(true);
    setSelectedJobKey(jobKey);
  }

  async function handleCompleteJob() {
    if (selectedJobKey) {
      try {
        await completeJob(selectedJobKey);
        setOpenConfirmationDialog(false);
      } catch (error) {
        setErrorOccured(true);
      }
    }
  }

  function handleFilteredJobsByJobFields(name, fieldToBeFiltered) {
    const key = sortByNames[name];
    const sortedJobs = Object.values(jobs).reduce((acc, job) => {
      const currentJob = job?.job ?? job;
      const keys = currentJob[fieldToBeFiltered];
      if (typeof keys !== 'string') {
        const setOfUids = new Set(keys);
        if (!setOfUids.has(key)) {
          return acc;
        }
        return {
          ...acc,
          [job.key]: job,
        };
      }
      if (currentJob[fieldToBeFiltered] !== key) {
        return acc;
      }
      return { ...acc, [job.key]: job };
    }, {});
    setFilteredJobs(sortedJobs);
  }

  function handleSortingNameChange(event) {
    const name = event.target.value;
    setSelectedName(name);
    switch (filterType) {
      case 'By Crew':
        handleFilteredJobsByJobFields(name, 'providerUids');
        break;

      case 'By Marker':
        handleFilteredJobsByJobFields(name, 'allMarkers');
        break;
      case 'By Property':
        handleFilteredJobsByJobFields(name, 'propertyKey');
        break;
      case 'By Shift':
        handleFilteredJobsByJobFields(name, 'shiftKey');
        return;
      default:
        throw new Error();
    }
  }

  function renderFilterByName() {
    if (filterType === 'ALL') {
      return null;
    }

    if (!sortByNames) {
      return <CircularProgress />;
    }

    return (
      <Text
        select
        onChange={handleSortingNameChange}
        value={selectedName}
        label="Filter by name"
      >
        {Object.keys(sortByNames ?? {})?.map((item) => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </Text>
    );
  }

  if (!subscribedServices) {
    return <Loader />;
  }

  function handleFilterClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleSortingChange(sortingType) {
    setSortType(sortingType);
  }

  const handleShowMoreButton = async () => {
    setIsLoadingOlderJobs(true);
    setOlderJobs(null);
    const lastJob = filteredJobs[filteredJobs.length - 1];

    const olderJobsFromDb = await getJobsByFilters({
      ...filterTypes,
      lastData: lastJob,
    });
    if (!olderJobsFromDb?.jobs?.length) {
      setNoOlderJobs(true);
    }

    setOlderJobs([...(olderJobsFromDb?.jobs ?? [])]);
    setIsLoadingOlderJobs(false);
  };

  const renderFilterOption = () => {
    if (jobTypes[jobTabs] === SHIFT_JOBS) {
      return null;
    }

    return (
      <Row justify="flex-end">
        <Tooltip title="Filter">
          <IconButton onClick={handleFilterClick}>
            <FilterOutlineIcon cursor="pointer" size={30} />
            <MenuDownIcon />
          </IconButton>
        </Tooltip>
      </Row>
    );
  };

  const getJobs = () => {
    return Object.values(filteredJobs ?? {});
  };

  const getHeaderLabel = () => {
    return selectedJobType === ASSIGNED_JOBS ? 'Assigned' : 'Search Shift';
  };

  const getAddLabel = () => {
    if (selectedJobType === ASSIGNED_JOBS) {
      return 'Create New Assigned Job';
    }
    return 'Create New Shift Job';
  };

  if (!jobs || !jobTypes) {
    return <Loader />;
  }

  return (
    <div>
      <Row>
        <StyledTitle>
          {selectedJobType === ASSIGNED_JOBS ? 'Assigned Jobs' : 'Shift Jobs'}
        </StyledTitle>
      </Row>
      <Header
        heading={`${getHeaderLabel()} Jobs`}
        placeholder="Job Name"
        isSearching={isSearching}
        handleSearch={handleSearchOnClick}
        selectedServiceKey={selectedServiceKey}
        handleServiceChange={handleServiceChange}
        paddingbottom="1em"
        indexName={selectedJobType === ASSIGNED_JOBS ? 'jobs' : 'periodicJobs'}
        reset={!isSearch}
        localSearch
        hideSearch={selectedJobType === ASSIGNED_JOBS}
        subheading={
          selectedJobType === SHIFT_JOBS && 'Search by shift job name'
        }
      >
        <Row justify="flex-end" width="auto" gap="16px">
          <Tooltip title="Sort">
            <IconButton>
              {sortType === 'desc' ? (
                <SortBoolAscendingVariantIcon
                  size={30}
                  cursor="pointer"
                  onClick={() => handleSortingChange('asc')}
                />
              ) : (
                <SortBoolDescendingVariantIcon
                  size={30}
                  cursor="pointer"
                  onClick={() => handleSortingChange('desc')}
                />
              )}
            </IconButton>
          </Tooltip>

          {renderFilterOption()}
        </Row>
        <MenuStyled
          anchorEl={anchorEl}
          open={open}
          onClose={handleMenuClose}
          keepMounted
        >
          <JobFilter
            allJobs={jobs}
            handleJobs={handleFilteredJobs}
            handleMenuClose={handleMenuClose}
            reset={!isSearch}
          />
        </MenuStyled>

        {renderFilterByName()}
      </Header>
      <AddButton onPress={handleAddButton} title={getAddLabel()} />

      <TabsToggle
        currentTab={jobTabs}
        arrayOfLabels={jobTypes}
        handleChange={handleChangeJobTabs}
      />

      {isSearch ? (
        <ShowAllButton>
          <PrimaryButton onClick={() => handleShowAllButton()}>
            Show all
            {` ${selectedJobType}`}
          </PrimaryButton>
        </ShowAllButton>
      ) : (
        <div />
      )}
      {isEmpty(jobs) || isEmpty(filteredJobs) ? (
        <>
          {filteredJobs === null ? (
            <Row justify="center">
              <Progress />
            </Row>
          ) : (
            <StyledContainer justifycontent="center">
              <Span weight="bold" size="16px">
                No Jobs
              </Span>
            </StyledContainer>
          )}
        </>
      ) : (
        <Col gap="32px" style={{ margin: 16 }}>
          <JobsTable
            onStartEndDateAndStatusEdit={handleStartEndDateAndStatusEdit}
            onEditClick={(job) => handleEditIconClick(job)}
            onDeleteClick={(job) => handleDeleteIconPress(job)}
            onCheckClick={(job) => openCompleteJobConfirmationDialog(job?.key)}
            jobs={getJobs()}
            onDetailsClick={(job) => handleDetailsIconClick(job)}
            isPeriodic={selectedJobType === SHIFT_JOBS}
          />
          {/* ) : ( */}
          {/* <>
            <JobItemsContainer container spacing={4}>
              {Object.values(filteredJobs ?? {}).map((job) => {
                return (
                  <JobItem
                    key={job.key}
                    job={job}
                    onEditIconPress={() => {
                      handleEditIconClick(job);
                    }}
                    onDeleteIconPress={() => handleDeleteIconPress(job)}
                    isPeriodic={selectedJobType === SHIFT_JOBS}
                    onPress={() => handleJobItemClick(job)}
                    services={customerServices}
                    onCheckIconPress={() =>
                      openCompleteJobConfirmationDialog(job?.key)
                    }
                  />
                );
              })}
            </JobItemsContainer>
          </> */}
          {/* )} */}
          <Row justify="center">
            {selectedJobType === ASSIGNED_JOBS && !noOlderJobs && (
              <PrimaryButton
                onClick={handleShowMoreButton}
                loading={isLoadingOlderJobs}
              >
                <Span color={color.white}>Show more</Span>
              </PrimaryButton>
            )}
            {noOlderJobs && <Span>No older jobs.</Span>}
          </Row>
        </Col>
      )}

      <JobModal
        individualJob={edit || anotherEdit ? individualJob : null}
        open={openJobDialog}
        onClose={closeJobDialog}
        selectedJobType={selectedJobType}
        edit={edit}
        anotherEdit={anotherEdit}
        setindividualJob={setIndividualJob}
      />
      <AlertBox
        open={openDeleteDialog}
        handlePositiveAction={handlePositiveAction}
        handleNegativeAction={handleNegativeAction}
        subtitle="Are you sure you want to delete the job?"
      />
      <AlertBox
        open={openConfirmationDialog}
        handlePositiveAction={handleCompleteJob}
        handleNegativeAction={handleNegativeAction}
        subtitle="Are you sure you want to complete the job?"
        positiveText="Complete Job"
        error={errorOccured ? 'Cannot complete the job' : ''}
      />
      <ADialog open={showJobModal} padding="0" width="100%" $minWidth="600px">
        <Align align="right">
          <IconButton onClick={() => setShowJobModal(false)}>
            <CloseIcon />
          </IconButton>
        </Align>
        <Align>
          <Title>Job details</Title>
        </Align>
        <IssueLink link={handleJobItemClick()}>
          <JobItem
            smGrow="auto"
            mdGrow="auto"
            lgGrow="auto"
            boxShadow="none"
            key={selectedJob?.key}
            job={selectedJob}
            isPeriodic={selectedJobType === SHIFT_JOBS}
          />
        </IssueLink>
      </ADialog>
    </div>
  );
}

const StyledContainer = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifycontent || 'space-between'};
  align-items: center;
`;

const Text = styled(TextField)`
  && {
    margin: 16px;
    flex-grow: 3;
    min-width: 300px;
  }
`;

const ShowAllButton = styled.div`
  && {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 10px 0;
  }
`;

const JobItemsContainer = styled(Grid)`
  margin: 16px;
`;

const MenuStyled = styled(Menu)`
  .MuiMenu-paper {
    padding: 16px;
    left: 1172px;
    width: 15%;
  }
`;
