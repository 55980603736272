import React from 'react';

import EyeIcon from 'mdi-react/EyeIcon';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Tooltip,
} from '@material-ui/core';

import Span from '../../Common/Span';
import color from '../../../utils/color';
import { StyledTableRow } from '../../Common/styled';
import { getBgColorOfTableRow } from '../../../helpers/misc';

const propertiesHeader = ['Name', 'Address', 'Actions'];

// eslint-disable-next-line import/prefer-default-export
export const PropertiesTable = ({
  properties,
  openPropertiesDetail,
  onEditIconPress,
  onDeleteIconPress,
}) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <StyledTableRow bgcolor={color.snomwBlue}>
            {propertiesHeader.map((header) => (
              <TableCell key={header}>
                <Span color={color.white} weight="bold">
                  {header}
                </Span>
              </TableCell>
            ))}
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {properties.map((property, index) => (
            <PropertyBody
              key={property.id}
              property={property}
              index={index}
              openPropertiesDetail={() => openPropertiesDetail(property)}
              onEditIconPress={() => onEditIconPress(property)}
              onDeleteIconPress={() => onDeleteIconPress(property)}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const PropertyBody = ({
  property,
  index,
  openPropertiesDetail,
  onEditIconPress,
  onDeleteIconPress,
}) => {
  const getCustomerDetail = () => {
    if (!property.customer) {
      return '-';
    }
    return property.customer.email;
  };

  return (
    <StyledTableRow bgcolor={getBgColorOfTableRow(index)}>
      <TableCell>
        <Span>{property.name}</Span>
      </TableCell>
      <TableCell>
        <Span>{property.address}</Span>
      </TableCell>
      <TableCell>
        <Tooltip title="View">
          <IconButton onClick={openPropertiesDetail} size="small">
            <EyeIcon size="24px" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Edit">
          <IconButton onClick={onEditIconPress} size="small">
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton onClick={onDeleteIconPress} size="small">
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </TableCell>
    </StyledTableRow>
  );
};
