import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import React, { useEffect } from 'react';

import { decodeHash } from '../../../../controllers/map_helper';

export default function IndoorMap({
  hash,
  image,
  beaconPoints,
  onTapHandler,
  crews,
  imageWidth,
  imageHeight,
  selectedMarker
}) {
  let CANVAS_WIDTH;
  let CANVAS_HEIGHT;
  if (imageWidth > imageHeight) {
    CANVAS_WIDTH = 900;
    CANVAS_HEIGHT = (CANVAS_WIDTH / imageWidth) * imageHeight;
  } else {
    CANVAS_HEIGHT = 700;
    CANVAS_WIDTH = (CANVAS_HEIGHT / imageHeight) * imageWidth;
  }

  const canvasStyle = {
    backgroundImage: `url(${image})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    border: '1.5px solid black',
    cursor: 'pointer',
    overflow: 'hidden'
  };

  const canvasRef = React.useRef(null);
  const crewCanvasRef = React.useRef(null);

  function drawPolyline(ctx) {
    ctx.beginPath();

    if (hash) {
      const data = decodeHash(hash);

      ctx.strokeStyle = 'red';
      ctx.lineWidth = 2;

      ctx.moveTo(CANVAS_WIDTH * data[0].kx, CANVAS_HEIGHT * (1 - data[0].ky));
      // eslint-disable-next-line no-plusplus
      for (let i = 1; i < data.length; i++) {
        ctx.lineTo(CANVAS_WIDTH * data[i].kx, CANVAS_HEIGHT * (1 - data[i].ky));
        ctx.stroke();
      }
    }
  }

  function drawBeaconPoint(ctx) {
    ctx.beginPath();
    beaconPoints.forEach(point => {
      ctx.moveTo(CANVAS_WIDTH * point.kx, CANVAS_HEIGHT * (1 - point.ky));
      ctx.arc(
        CANVAS_WIDTH * point.kx,
        CANVAS_HEIGHT * (1 - point.ky),
        6.5,
        0,
        2 * Math.PI
      );
    });
    ctx.fillStyle = '#29abe2';
    ctx.fill();
  }

  const drawCrews = ctx => {
    ctx.beginPath();
    Object.values(crews).forEach(crew => {
      const img = new Image();
      img.src = crew.imageUrl;

      img.addEventListener('load', () => {
        const photoHeight = img.naturalHeight;
        const photoWidth = img.naturalWidth;

        // ctx.save();
        // ctx.beginPath();
        // ctx.arc(
        //   CANVAS_WIDTH * crew.x,
        //   CANVAS_HEIGHT * (1 - crew.y),
        //   10,
        //   0,
        //   2 * Math.PI,
        //   true
        // );
        // ctx.clip();
        // ctx.closePath();
        // ctx.restore();

        ctx.drawImage(
          img,
          0.5 * photoWidth,
          0.5 * photoHeight,
          900,
          900,
          CANVAS_WIDTH * crew.x - 10,
          CANVAS_HEIGHT * (1 - crew.y) - 10,
          25,
          25
        );
      });
    });
  };

  const drawText = (ctx, markerPointX, markerPointY, markerName) => {
    ctx.font = '15px Montserrat';
    ctx.fillStyle = 'black';
    ctx.fillText(markerName, markerPointX + 50, markerPointY + 25, 150);
    ctx.fillStyle = '#000';
  };

  const drawRectangle = (ctx, point) => {
    let distanceX = -100;
    let distanceY = -55;
    const markerPointX = CANVAS_WIDTH * point.kx;
    const markerPointY = CANVAS_HEIGHT * (1 - point.ky);

    ctx.beginPath();
    ctx.fillStyle = '#ccecf9';

    if (markerPointX <= 50) {
      distanceX = -10;
    } else if (markerPointX > CANVAS_WIDTH - 50) {
      distanceX = -230;
    }

    if (markerPointY <= 50) {
      distanceY = 5;
    } else if (markerPointY > CANVAS_HEIGHT - 50) {
      distanceY = -55;
    }

    const drawingPointX = markerPointX + distanceX;
    const drawingPointY = markerPointY + distanceY;

    ctx.fillRect(drawingPointX, drawingPointY, 235, 50);

    drawText(ctx, drawingPointX, drawingPointY, selectedMarker.name);
  };

  useEffect(() => {
    const canvas = canvasRef.current;

    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);

    drawPolyline(ctx);
    drawBeaconPoint(ctx);

    const beaconDetail = beaconPoints?.find(
      beacon => beacon.uuid === selectedMarker?.beaconId
    );
    if (beaconDetail) {
      drawRectangle(ctx, beaconDetail, selectedMarker.name);
    }
  }, [hash, beaconPoints, selectedMarker]);

  useEffect(() => {
    const canvas = crewCanvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);

    if (crews) drawCrews(ctx);
  }, [crews]);

  const checkPoints = (pointX, pointY, newKX, newKY) => {
    const diffX = Math.abs(pointX - newKX);
    const diffY = Math.abs(pointY - newKY);

    return diffX <= 0.01 && diffY <= 0.01;
  };

  function handleMouseClick(e) {
    const rect = canvasRef.current.getBoundingClientRect();
    const offsetX = rect.left;
    const offsetY = rect.top;
    const pointX = e.clientX - offsetX;
    const pointY = e.clientY - offsetY;
    const newKX = pointX / CANVAS_WIDTH;
    const newKY = 1 - pointY / CANVAS_HEIGHT;
    let crewPoint = [];

    if (crews) {
      crewPoint = Object.values(crews).filter(crew => {
        return checkPoints(crew.x, crew.y, newKX, newKY);
      });
    }

    const points = beaconPoints.filter(beaconPoint => {
      return checkPoints(beaconPoint.kx, beaconPoint.ky, newKX, newKY);
    });

    if (points.length > 0 || crewPoint.length > 0) {
      onTapHandler(points[0], true, crewPoint[0]);
    } else {
      onTapHandler(
        {
          kx: newKX,
          ky: newKY
        },
        false
      );
    }
  }

  return (
    <Grid item xs>
      <div style={{ padding: 16 }}>
        <div
          style={{
            height: CANVAS_HEIGHT,
            width: CANVAS_WIDTH,
            overflow: 'hidden',
            border: '0.5px solid black',
            position: 'relative'
          }}
        >
          <StyledCanvas
            zindex={3}
            style={{ border: canvasStyle.border, cursor: canvasStyle.cursor }}
            ref={canvasRef}
            height={CANVAS_HEIGHT}
            width={CANVAS_WIDTH}
            onClick={e => {
              handleMouseClick(e);
            }}
          />
          <StyledCanvas
            zindex={2}
            ref={crewCanvasRef}
            height={CANVAS_HEIGHT}
            width={CANVAS_WIDTH}
          />
          <StyledCanvas
            zindex={1}
            height={CANVAS_HEIGHT}
            width={CANVAS_WIDTH}
            style={canvasStyle}
          />
        </div>
      </div>
    </Grid>
  );
}

const StyledCanvas = styled.canvas`
  position: absolute;
  z-index: ${props => props.zindex};
`;
