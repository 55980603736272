import React from 'react';

import { Switch, Route } from 'react-router';

import OrderDetails from './OrderDetails';
import ServiceRequests from './ServiceRequests';

export default function () {
  return (
    <Switch>
      <Route
        path="/home/work-orders/:markerKey/order/:orderKey/"
        component={OrderDetails}
        exact
      />
      <Route path="/home/work-orders/" component={ServiceRequests} />
    </Switch>
  );
}
