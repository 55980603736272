import React from 'react';

import ADialog from '../../../Common/styled';

import ChangePassword from '../../../Public/ChangePassword';

const ChangePasswordModal = ({ open, handleCancelButton, setSnackBarOpen }) => {
  return (
    <ADialog open={open}>
      <ChangePassword
        fromProfile
        handleCancelButton={handleCancelButton}
        setSnackBarOpen={setSnackBarOpen}
      />
    </ADialog>
  );
};

export default ChangePasswordModal;
