import React, { useEffect, useState, useContext } from 'react';

import moment from 'moment';
import cronstrue from 'cronstrue';
import styled from 'styled-components';
import { useHistory, useLocation, useParams } from 'react-router';
import { Typography, Grid, Card, Divider } from '@material-ui/core';

import Span from '../../Common/Span';
import Map from '../Maps/map_polyline';
import IssueReports from './IssueReports';
import ADialog from '../../Common/styled';
import Loader from '../../Generics/snowm_loader';
import MarkerDetailOfAJob from './marker_detail_job';
import { getDateForJob } from '../../../helpers/date';
import { Col, Row } from '../../../styles/snowm_styled';
import RouteDetailOfAJob from './route_detail_of_a_job';
import PrimaryButton from '../../Common/primary_button';
import ListTitleItem from '../../Generics/list_title_item';
import { JobsContext } from '../../../contexts/service_jobs';
import { getRouteForPoints } from '../../../helpers/polyline';
import { routesIncludingServiceTypes } from '../reports/selectType';
import JobHistoryDetails from '../attendance/jobHistory/JobHistoryDetails';
import { LocalizationContext } from '../../../contexts/localization_context';
import {
  getLocationOfProvider,
  getIssueReports,
  getProviderByUid,
  getRouteByKey,
  getServicePointById,
  getServiceInfo,
  getPropertyById,
  getShiftById,
  getMarkerLogsForAJob,
  fetchJobAuditHistory,
} from '../../../controllers/snowm_firebase';

export default function (props) {
  return (
    <JobsContext.Consumer>
      {({ getJobDetail }) => {
        return (
          <JobDetail
            getJobDetail={getJobDetail}
            history={props.history}
            match={props.match}
          />
        );
      }}
    </JobsContext.Consumer>
  );
}

function JobDetail({ getJobDetail }) {
  const history = useHistory();
  const { jobId } = useParams();
  const { pathname } = useLocation();
  const { strings } = useContext(LocalizationContext);

  const [job, setJob] = useState(null);
  const [service, setService] = useState();
  const [latLong, setLatLong] = useState({});
  const [polyline, setPolyline] = useState('');
  const [shiftData, setShiftData] = useState();
  const [markerLogs, setMarkerLogs] = useState([]);
  const [markerLogsByKey, setMarkerLogsByKey] = useState({});
  const [jobReports, setJobReports] = useState([]);
  const [markerKeys, setMarkerKeys] = useState([]);
  const [remainingKeys, setRemainingKeys] = useState();
  const [propertiesData, setPropertiesData] = useState();
  const [crewsOfReports, setCrewsOfReports] = useState({});
  const [routeDetailsOfJob, setRouteDetailsOfJob] = useState([]);
  const [openHistoriesOfJob, setOpenHistoriesOfJob] = useState(false);
  const [jobHistories, setJobHistories] = useState(null);
  const [jobInfo, setJobInfo] = useState({
    keys: [],
    isPeriodic: false,
  });

  const path = pathname.split('/');

  const setOfPaths = new Set(path ?? []);

  const isPeriodic = setOfPaths.has('shift');

  const { MAP_VIEW } = strings?.misc;
  const { ROUTES } = strings?.sidemenu;
  const { ISSUE_REPORTS } = strings?.detail;

  useEffect(() => {
    let isSubscribe = true;

    getJobDetail(jobId, isPeriodic).then((jobDetail) => {
      let data = {};
      if (jobDetail) {
        const actualJob = jobDetail?.job ?? jobDetail;
        const key = jobDetail?.key ?? job.key;
        const { serviceType } = actualJob;
        if (serviceType === 'outdoor') {
          const allRoutes = actualJob?.allRoutes ?? [];
          const servedRoutes = actualJob?.servedRoutes ?? [];
          const filtredRoute = new Set([...allRoutes, ...servedRoutes]);
          const filtredRouteArray = Array.from(filtredRoute);
          data = {
            ...jobDetail,
            ...actualJob,
            allRoutes: filtredRouteArray,
            key,
          };
        } else {
          const allMarkers = actualJob?.allMarkers ?? [];
          const servedMarkrs = actualJob?.servedMarkers ?? [];
          const filteredMarker = new Set([...allMarkers, ...servedMarkrs]);
          const filtredMarkerArray = Array.from(filteredMarker);
          data = {
            ...jobDetail,
            ...actualJob,
            allMarkers: filtredMarkerArray,
            key,
          };
        }
      }

      if (isSubscribe) {
        setJob(data);
      }
    });

    const _updateReports = (messages) => {
      if (isSubscribe) setJobReports(messages);
    };

    getIssueReports(jobId, _updateReports).catch((error) => new Error(error));

    if (!isPeriodic) {
      fetchJobAuditHistory(jobId).then((res) => {
        if (isSubscribe) {
          setJobHistories(res);
        }
      });
    }

    return () => {
      isSubscribe = false;
    };
  }, [jobId, isPeriodic]);

  const _getMarkerLogs = (res) => {
    if (res) {
      const { key } = res;
      setMarkerLogsByKey((prev) => ({ ...(prev || {}), [key]: res }));
    } else {
      setMarkerLogsByKey((prev) => ({ ...prev }));
    }
  };

  useEffect(() => {
    setMarkerLogs(Object.values(markerLogsByKey));
  }, [markerLogsByKey]);

  useEffect(() => {
    let isSubscribe = true;
    if (job) {
      if (isSubscribe) getMarkerLogsForAJob(job, _getMarkerLogs);
    } else {
      setMarkerLogs(null);
    }
    return () => {
      isSubscribe = false;
    };
  }, [job]);

  useEffect(() => {
    let isSubscribe = true;

    function updateService(res) {
      if (isSubscribe) setService(res);
    }
    if (markerLogs?.length > 0) {
      if (job?.job?.serviceKey) {
        getServiceInfo(job?.job?.serviceKey).then((res) => updateService(res));
      } else if (job?.serviceKey) {
        getServiceInfo(job?.serviceKey).then((res) => updateService(res));
      }
    }
    return () => {
      isSubscribe = false;
    };
  }, [job, markerLogs]);

  useEffect(() => {
    if (markerLogs && jobInfo.keys) {
      const markerIds = markerLogs?.map((log) => log.servicePointId);
      const setOfMarkerKeys = new Set(markerIds);
      const remainingMarkerIds = jobInfo.keys?.filter(
        (key) => !setOfMarkerKeys.has(key)
      );
      setRemainingKeys(remainingMarkerIds);
    } else {
      setRemainingKeys([]);
    }
  }, [markerLogs, jobInfo]);

  useEffect(() => {
    let isSubscribe = true;
    jobReports.forEach((report) => {
      const eachReport = {};
      eachReport[report.key] = {
        latitude: report.lat,
        longitude: report.long,
        type: report.type,
        message: report.message,
        key: report.key,
        jobReport: true,
      };
      if (isSubscribe) {
        setLatLong((prevData) => {
          return {
            ...prevData,
            ...eachReport,
          };
        });
      }
    });
    return () => {
      isSubscribe = false;
    };
  }, [jobReports]);

  const getCrewInfo = async (crewId) => {
    const crew = await getProviderByUid(crewId);
    return crew.name;
  };

  useEffect(() => {
    let isSubscribe = true;
    jobReports.forEach((report) => {
      getCrewInfo(report.providerUid).then((crewName) => {
        if (isSubscribe) {
          setCrewsOfReports((prevData) => {
            return {
              ...prevData,
              [report.key]: crewName,
            };
          });
        }
      });
    });

    return () => {
      isSubscribe = false;
    };
  }, [jobReports]);

  useEffect(() => {
    let keys = [];
    let isSubscribe = true;

    const onChange = (crewId, crewData) => {
      const adata = {};
      adata[crewId] = { ...crewData, uid: crewId };
      if (isSubscribe) {
        setLatLong((prevData) => {
          return {
            ...prevData,
            ...adata,
          };
        });
      }
    };

    if (routesIncludingServiceTypes.includes(job?.serviceType)) {
      keys = job?.job?.allRoutes ?? job?.allRoutes;
    } else {
      keys = job?.job?.allMarkers ?? job?.allMarkers;
    }

    if (job?.job?.providerUids) {
      job.job.providerUids.forEach((crewId) => {
        getLocationOfProvider(crewId, onChange);
      });
    } else if (job?.providerUids) {
      job.providerUids.forEach((crewId) => {
        getLocationOfProvider(crewId, onChange);
      });
    }

    setJobInfo({ keys, isPeriodic });

    return () => {
      isSubscribe = false;
    };
  }, [job]);

  async function fetchProperties() {
    const properties = job?.propertyKeys?.map((property) => {
      return getPropertyById(property);
    });
    const data = await Promise.all(properties);
    setPropertiesData(data);
  }

  async function fetchShift() {
    if (job?.shift) {
      setShiftData(job.shift);
    } else {
      const shift = await getShiftById(job?.shiftKey);
      setShiftData(shift ?? {});
    }
  }

  useEffect(() => {
    let isSubscribe = true;
    const promise = job?.allRoutes?.map((routeKey) => {
      return getRouteByKey(routeKey);
    });

    if (promise) {
      Promise.all(promise).then((routesInfo) => {
        if (isSubscribe) setRouteDetailsOfJob(routesInfo);
      });
    }

    if (job?.propertyKeys?.length) {
      fetchProperties();
    } else {
      setPropertiesData([]);
    }
    if (job?.shiftKey) {
      fetchShift();
    } else {
      setShiftData({});
    }

    return () => {
      isSubscribe = false;
    };
  }, [job]);

  useEffect(() => {
    const acc = routeDetailsOfJob.reduce((accumulator, routeDetail) => {
      if (routeDetail) {
        return [...accumulator, ...routeDetail.servicePointsKeys];
      }
      return accumulator;
    }, []);

    setMarkerKeys([...acc]);
  }, [routeDetailsOfJob]);

  useEffect(() => {
    let isSubscribe = true;

    const getMarkerDetails = async () => {
      const promise = markerKeys?.map((markerKey) => {
        return getServicePointById(markerKey);
      });

      const markerResponse = await Promise.all(promise);

      markerResponse.sort((a, b) => {
        return a.latitude - b.latitude;
      });

      const responsePolyline = await getRouteForPoints(markerResponse);
      if (isSubscribe) setPolyline(responsePolyline?.routePath ?? '');
    };

    if (markerKeys.length > 0) getMarkerDetails();

    return () => {
      isSubscribe = false;
    };
  }, [markerKeys]);

  const handleJobHistoryClose = () => {
    setOpenHistoriesOfJob(false);
  };

  const handleHistoryButtonClick = () => {
    setOpenHistoriesOfJob(true);
  };

  const handleLogsButtonClick = () => {
    history.push(`/home/jobs/details/${jobId}/logs`);
  };

  if (!job || !markerLogs || !remainingKeys || !propertiesData || !shiftData) {
    return <Loader />;
  }

  if (!job.key) {
    return (
      <Row justify="center">
        <Span weight="bold">No job found.</Span>
      </Row>
    );
  }

  return (
    <>
      <div style={{ padding: 16, margin: 16, height: '100%' }}>
        {job && (
          <>
            <Row justify="space-between">
              <Typography variant="h4">{job.name}</Typography>
              <Row gap="12px" width="auto">
                {!!jobHistories?.length && (
                  <PrimaryButton onClick={handleHistoryButtonClick}>
                    History
                  </PrimaryButton>
                )}
              </Row>
            </Row>
            {job.serviceType === 'outdoor' && (
              <>
                <Typography>{MAP_VIEW}</Typography>
                <div
                  style={{
                    height: 400,
                  }}
                >
                  <Map points={latLong} polyline={polyline} />
                </div>
              </>
            )}
          </>
        )}

        {!routesIncludingServiceTypes.includes(job?.serviceType) ? (
          <>
            {jobInfo?.keys?.length > 0 ? (
              <>
                <JobDetailCard
                  job={job}
                  propertiesData={propertiesData}
                  shiftData={shiftData}
                  isPeriodic={isPeriodic}
                />
                <Divider />
                <Span weight="bold" size="32px">
                  Markers
                </Span>
                <Grid container spacing={3}>
                  {remainingKeys?.map((markerKey) => (
                    <MarkerDetailOfAJob
                      key={markerKey}
                      job={job}
                      markerKey={markerKey}
                    />
                  ))}
                  {markerLogs?.map((log) => (
                    <MarkerDetailOfAJob
                      key={log.key}
                      markerKey={log.servicePointId}
                      markerLog={log}
                      job={job}
                      service={service}
                    />
                  ))}
                </Grid>
                {job?.activities?.length ? <JobActivities job={job} /> : null}
              </>
            ) : (
              <Typography>No Markers added on this job.</Typography>
            )}
          </>
        ) : (
          <>
            <Typography>{ROUTES}</Typography>
            {jobInfo?.keys?.length > 0 ? (
              <>
                {jobInfo.keys.map((routeKey) => {
                  return (
                    <RouteDetailOfAJob
                      key={routeKey}
                      routeKey={routeKey}
                      jobKey={job?.key}
                      isPeriodic={jobInfo?.isPeriodic}
                      history={history}
                    />
                  );
                })}
              </>
            ) : (
              <Typography>No routes</Typography>
            )}
          </>
        )}

        {jobReports.length ? (
          <>
            <Typography>{ISSUE_REPORTS}</Typography>
            <Grid container spacing={3}>
              {jobReports.map((report) => (
                <IssueReports
                  key={report.key}
                  crew={crewsOfReports[report.key]}
                  report={report}
                />
              ))}
            </Grid>
          </>
        ) : null}
      </div>

      <ADialog open={openHistoriesOfJob} maxWidth="md" width="100%">
        <JobHistoryDetails job={job} handleCloseClick={handleJobHistoryClose} />
      </ADialog>
    </>
  );
}

const formatDate = (date) => {
  if (!date) {
    return '---';
  }
  const formattedDate = getDateForJob(date);
  return formattedDate;
};

function JobDetailCard({ job, shiftData, isPeriodic }) {
  let difference = null;
  let momentedStartedDate = null;
  let momentedEndDate = null;

  const getTimeSpent = () => {
    let timeSpent = null;

    if (difference < 3600000) {
      timeSpent = momentedEndDate.diff(momentedStartedDate, 'minutes');
      return `${timeSpent} minutes`;
    }
    if (difference < 86400000) {
      timeSpent = momentedEndDate.diff(momentedStartedDate, 'hours');
      return `${timeSpent} hours`;
    }

    timeSpent = momentedEndDate.diff(momentedStartedDate, 'days');
    return timeSpent === 1 ? `${timeSpent} day` : `${timeSpent} days`;
  };

  if (job.startedDate && job.endDate) {
    momentedStartedDate = moment(job.startedDate);
    momentedEndDate = moment(job.endDate);
    difference = momentedEndDate.diff(momentedStartedDate);
  }

  const getNonPeriodicJobDetails = () => {
    if (!isPeriodic) {
      return (
        <>
          <ListTitleItem
            title="Started Time"
            content={formatDate(job.startedDate)}
          />
          <ListTitleItem title="Ended Time" content={formatDate(job.endDate)} />
          <ListTitleItem
            title="Time Spent"
            content={difference ? getTimeSpent() : '---'}
          />
        </>
      );
    }
    return null;
  };

  return (
    <DetailedCard>
      <Col>
        {getNonPeriodicJobDetails()}

        {job.serviceType === 'markerbased' ? (
          <>
            <ListTitleItem
              title="No. of Markers"
              content={job.allMarkers.length ?? 0}
            />
            {job.cronExp && (
              <ListTitleItem
                title="Scheduled for"
                content={cronstrue.toString(job.cronExp)}
              />
            )}
            {job?.shift || job?.shiftKey ? (
              <>
                <Divider />
                <Shift shiftData={shiftData} />
              </>
            ) : null}
          </>
        ) : null}
      </Col>
    </DetailedCard>
  );
}

function Shift({ shiftData }) {
  return (
    <>
      <h3>Shift Detail:</h3>
      <ListTitleItem content={shiftData.name ?? '---'} title="Name" />
      <ListTitleItem
        content={shiftData.startTime ?? '---'}
        title="Start Time"
      />
      <ListTitleItem content={shiftData.endTime ?? '---'} title="End Time" />
    </>
  );
}

function JobActivities({ job }) {
  const completedActivites = job.activities.filter((a) => a.completed === true);
  return (
    <>
      <DetailedCard>
        <h3>Activities</h3>
        <Row>
          {completedActivites
            .map((c) => c.name)
            .join(', ')
            .toString()}
        </Row>
      </DetailedCard>
    </>
  );
}

const DetailedCard = styled(Card)`
  && {
    padding: 16px;
    margin: 20px auto;
    min-height: 200px;
    cursor: ${(props) => props.cursor};
  }
`;
