import React, { useEffect, useState } from 'react';

import { TableCell, TableRow } from '@material-ui/core';

import Span from '../../Common/Span';
import color from '../../../utils/color';
import { getServicePointById } from '../../../controllers/snowm_firebase';

export const BeaconMsItem = (props) => {
  const { beaconM } = props;

  const [servicePointName, setServicePointName] = useState('');

  useEffect(() => {
    let isCancel = false;
    if (beaconM.servicePointId) {
      getServicePointById(beaconM.servicePointId).then((res) => {
        if (!isCancel) {
          setServicePointName(res.name);
        }
      });
    } else {
      setServicePointName('-');
    }

    return () => {
      isCancel = true;
    };
  }, [beaconM]);

  const getColorForBeacon = () => {
    if (!beaconM?.assigned) return color.red;
    return color.green;
  };

  return (
    <TableRow>
      <TableCell align="center">
        <Span>{beaconM.serialNumber}</Span>
      </TableCell>
      <TableCell align="center">
        <Span weight="bold">{beaconM.name}</Span>
      </TableCell>
      <TableCell align="center">
        <Span color={getColorForBeacon()} weight="bold">
          {beaconM.assigned ? 'Assigned' : 'Unassigned'}
        </Span>
      </TableCell>
      <TableCell align="center">
        <Span weight="bold">{servicePointName || '-'}</Span>
      </TableCell>
      <TableCell align="center">
        <Span weight="bold">{beaconM.type ?? 'Sensor'}</Span>
      </TableCell>
    </TableRow>
  );
};

export default BeaconMsItem;
