import styled from 'styled-components';
import { TextField, Typography } from '@material-ui/core';

import Span from '../../Common/Span';

const StyledTextField = styled(TextField)`
  && {
    .MuiInputBase-input::placeholder {
      font-size: 20px;
    }
    margin-top: ${(props) => props.margintop};
    width: 100%;
  }
`;

export const Text = styled(Typography).attrs({ component: 'span' })`
  && {
    padding-left: 10px;
  }
`;

export const BoldTitle = styled(Span)`
  && {
    font-weight: bold;
  }
`;

export default StyledTextField;
