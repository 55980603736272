import React, { useState, useEffect } from 'react';

import styled from 'styled-components';
import CloseIcon from 'mdi-react/CloseIcon';
import {
  CircularProgress,
  Divider,
  IconButton,
  Tooltip,
} from '@material-ui/core';

import Span from '../../Common/Span';
import color from '../../../utils/color';
import { defaultAvatar } from '../../../helpers/misc';
import { Row, Col } from '../../../styles/snowm_styled';
import { getRelativeDate } from '../../../helpers/date';
import {
  getProviderByUid,
  isCrewWorking,
} from '../../../controllers/snowm_firebase';

const InfoWindow = ({ point, onClose }) => {
  const [crewInfo, setCrewInfo] = useState({});
  const [reportInfo, setReportInfo] = useState({});
  const [crewWorking, setCrewWorking] = useState(false);

  useEffect(() => {
    let isSubscribe = true;
    if (point.uid) {
      getProviderByUid(point.uid).then((response) => {
        if (isSubscribe) {
          setCrewInfo({
            ...response,
            lastUpdated: point.last_seen,
          });
        }
      });
    } else if (point.jobReport) {
      if (isSubscribe) {
        setReportInfo({
          ...point,
        });
      }
    }

    return () => {
      isSubscribe = false;
    };
  }, []);

  useEffect(() => {
    const onUpdate = (isWorking) => {
      setCrewWorking(isWorking);
    };
    isCrewWorking(point.uid, onUpdate);
  }, []);

  const handleInfoWindowClose = () => {
    onClose();
  };

  const statusInfo = () => {
    const isWorking = () => {
      return crewWorking;
    };
    const checkIfWorking = () => {
      if (isWorking()) {
        return color.black;
      }
      return color.white;
    };

    return (
      <>
        <Span size="10px">Status: </Span>
        <StyledSpan
          weight="bold"
          color={checkIfWorking()}
          $isWorking={crewWorking}
          size="10px"
        >
          {crewWorking ? 'Working' : 'Not working'}
        </StyledSpan>
      </>
    );
  };

  if (point.uid || point.jobReport) {
    return (
      <StyledWindowContainer>
        {Object.keys(crewInfo).length !== 0 ? (
          <Row justify="space-between">
            <Col $flexBasis="100%">
              <Row justify="center">
                {crewInfo.imageUrl && (
                  <img
                    height="30px"
                    width="30px"
                    src={crewInfo.imageUrl}
                    alt="crew"
                    style={{
                      borderRadius: '50%',
                    }}
                    onError={(e) => {
                      e.currentTarget.src = defaultAvatar;
                    }}
                  />
                )}
              </Row>
              <Row justify="center">
                <Tooltip title={crewInfo.name}>
                  <InfoWindowText>{crewInfo.name}</InfoWindowText>
                </Tooltip>
              </Row>
              <Row justify="center">
                <Tooltip title={crewInfo.email}>
                  <InfoWindowText weight="bold" size="12px">
                    {crewInfo.email}
                  </InfoWindowText>
                </Tooltip>
              </Row>
              <Row justify="center">{statusInfo()}</Row>
              <Row justify="center">
                <StyledTypography>Last updated:</StyledTypography>
                <Span weight="bold" style={{ fontSize: 12, marginLeft: 4 }}>
                  {getRelativeDate(crewInfo.lastUpdated)}
                </Span>
              </Row>
            </Col>
            <Col
              style={{
                marginBottom: '9em',
              }}
            >
              <IconButton onClick={handleInfoWindowClose} size="small">
                <CloseIcon />
              </IconButton>
            </Col>
          </Row>
        ) : (
          <Col
            style={{
              height: '100%',
            }}
          >
            {Object.keys(reportInfo).length !== 0 ? (
              <Row>
                <Col>
                  <Row justify="center">
                    <Span weight="bold">{reportInfo.type}</Span>
                  </Row>
                  <Row justify="center">
                    {reportInfo.message ? reportInfo.message : 'Not Available'}
                  </Row>
                </Col>
                <Col
                  style={{
                    marginBottom: '6em',
                  }}
                >
                  <IconButton onClick={handleInfoWindowClose}>
                    <CloseIcon />
                  </IconButton>
                </Col>
              </Row>
            ) : (
              <Row
                style={{
                  height: '100%',
                }}
                justify="center"
              >
                <CircularProgress />
              </Row>
            )}
          </Col>
        )}
      </StyledWindowContainer>
    );
  }
  if (point.beaconId) {
    return (
      <StyledWindowContainer>
        <Row>
          <Col>
            <Row justify="center">
              <StyledTypography>Marker Details</StyledTypography>
            </Row>
            <Row>
              <StyledRoutesMarker size="12px">{point.name}</StyledRoutesMarker>
            </Row>
            <Divider />
            <Row style={{ marginTop: 4 }}>
              <StyledRoutesMarker size="12px">
                {point.address}
              </StyledRoutesMarker>
            </Row>
          </Col>
          <Col
            style={{
              marginBottom: '8em',
            }}
          >
            <IconButton onClick={handleInfoWindowClose}>
              <CloseIcon />
            </IconButton>
          </Col>
        </Row>
      </StyledWindowContainer>
    );
  }
  return <div />;
};

export default InfoWindow;

const StyledTypography = styled(Span)`
  && {
font-size: 12px;
`;

const StyledWindowContainer = styled.div`
  background-color: white;
  position: absolute;
  border-radius: 12px;
  left: -100px;
  top: -206px;
  padding: 8px;
  height: 13em;
  min-width: 20em;
  z-index: 1;
  overflow: auto;
`;

const StyledRoutesMarker = styled(Span)`
  && {
    margin-left: 4px;
  }
`;

const StyledSpan = styled(Span)`
  background-color: ${(props) =>
    props.$isWorking ? color.greenAccent : color.black};
  padding: 2px 8px;
  border-radius: 8px;
  margin-left: 6px;
`;

const InfoWindowText = styled(StyledTypography)`
  && {
    width: 155px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
  }
`;
