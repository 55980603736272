import React from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';

import Providers from './snowm_providers';

export default function () {
  return (
    <Switch>
      <Route path="/home/crews" exact component={Providers} />
      <Redirect to="/home/crews" />
    </Switch>
  );
}
