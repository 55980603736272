import React from 'react';

import CloseIcon from 'mdi-react/CloseIcon';
import { Divider, IconButton } from '@material-ui/core';

import Span from '../../../Common/Span';
import JobsTableOfACrew from './JobsTableOfACrew';
import { Col, Row } from '../../../../styles/snowm_styled';

const SelectedCrewDetails = ({
  auditableCrew,
  jobs,
  closeCrewDetailModal,
  handleAudit,
}) => {
  const { arrayOfDates, response } = jobs ?? {};

  return (
    <Col gap="4px">
      <Row justify="space-between">
        <Row>
          <Span weight="bold">Single Crew Report</Span>
        </Row>

        <IconButton onClick={closeCrewDetailModal}>
          <CloseIcon />
        </IconButton>
      </Row>
      <Row gap="4px" justify="center">
        <Span>Name:</Span>
        <Span weight="bold">{auditableCrew?.label}</Span>
      </Row>
      <Divider />
      <JobsTableOfACrew
        arrayOfDates={arrayOfDates}
        jobs={response}
        auditableCrew={auditableCrew}
        handleAudit={handleAudit}
      />
    </Col>
  );
};

export default SelectedCrewDetails;
