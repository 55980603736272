/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: snowm_service_points.jsx
 *
 *  Description: Show the list of service points
 *
 *  Author: Roshan Gautam (roshan@brainants.com)
 *
 *  Date created: 8-july-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */
import React, { useEffect, useState } from 'react';

import { isEmpty } from 'lodash';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { Close } from '@material-ui/icons';
import {
  Grid,
  Dialog,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@material-ui/core';

import SortBoolAscendingVariantIcon from 'mdi-react/SortBoolAscendingVariantIcon';
import SortBoolDescendingVariantIcon from 'mdi-react/SortBoolDescendingVariantIcon';

import Span from '../../Common/Span';
import Header from '../../Common/header';
import ADialog from '../../Common/styled';
import MarkersTable from './MarkersTable';
import MarkerDetails from './marker_details';
import AlertBox from '../../Common/alert_box';
import AddButton from '../../Common/add_button';
import NotifyBox from '../../Common/notify_box';
import Loader from '../../Generics/snowm_loader';
import ServicePointItem from './service_point_item';
import { useModal } from '../../Generics/snowm_modal';
import PrimayButton from '../../Common/primary_button';
import AddServicePoint from './snowm_add_service_points';
import { Row, StyledTitle } from '../../../styles/snowm_styled';
import { ServicePointsContext } from '../../../contexts/service_points';
import {
  deleteServicePoint,
  getPendingJobsByMarkerKey,
} from '../../../controllers/snowm_firebase';
import {
  filterDataByName,
  getFilteredItems as getFilteredMarkers,
} from '../../../helpers/misc';

export default function (servicePointProps) {
  return (
    <ServicePointsContext.Consumer>
      {({ markers, fetchServicePoints }) => {
        return (
          <ServicePoints
            markers={markers}
            fetchServicePoints={fetchServicePoints}
            {...servicePointProps}
          />
        );
      }}
    </ServicePointsContext.Consumer>
  );
}

function ServicePoints({ fetchServicePoints = Function, markers }) {
  const [isEdit, setIsEdit] = useState(false);
  const [sortType, setSortType] = useState('asc');
  const [isSearch, setIsSearch] = useState(false);
  const [markerNames, setMarkerNames] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMarker, setSelectedMarker] = useState();
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [searchedMarkers, setSearchedMarkers] = useState([]);
  const [filteredMarkers, setFilteredMarkers] = useState();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedServiceKey, setSelectedServiceKey] = useState('all');
  const [selectedServicePoint, setSelectedServicePoint] = useState({});
  const [pendingJobsWarning, setPendingJobsWarning] = useState(0);

  const history = useHistory();

  const notifyBoxModal = useModal();

  const sortArrayOfObjectsByName = (array, type, field = 'name') => {
    array.sort((a, b) => {
      const first = a[field].toLowerCase();
      const second = b[field].toLowerCase();
      if (first < second) {
        return type === 'asc' ? -1 : 1;
      }
      if (second < first) {
        return type === 'asc' ? 1 : -1;
      }
      return 0;
    });
  };

  useEffect(() => {
    fetchServicePoints();
  }, []);

  useEffect(() => {
    let names = [];
    let result = [];
    if (searchedMarkers.length > 0) {
      result = getFilteredMarkers(searchedMarkers, selectedServiceKey);
    } else {
      result = getFilteredMarkers(markers, selectedServiceKey);
    }

    if (markers) {
      Object.values(markers).forEach((marker) => {
        names = [...names, marker.name.toLowerCase()];
      });

      setMarkerNames(names);
    }

    setFilteredMarkers(result);
  }, [markers, selectedServiceKey]);

  useEffect(() => {
    if (filteredMarkers) {
      const arrayOfMarkers = [...Object.values(filteredMarkers)];
      sortArrayOfObjectsByName(arrayOfMarkers, sortType);

      const sortedArrayOfMarkers = arrayOfMarkers.reduce((acc, marker) => {
        const { key } = marker;
        return { ...acc, [key]: marker };
      }, {});
      setFilteredMarkers(sortedArrayOfMarkers);
    }
  }, [sortType]);

  function onAddButtonPress() {
    let names = [];
    Object.values(markers).forEach((marker) => {
      names = [...names, marker.name.toLowerCase()];
    });

    setMarkerNames(names);
    setOpenAddDialog(true);
  }
  function handleCloseAddDialog() {
    setOpenAddDialog(false);
    setIsEdit(false);
  }

  function handleCloseDialog() {
    setOpenDeleteDialog(false);
    setPendingJobsWarning(0);
  }

  function onEditPress(marker) {
    setOpenAddDialog(true);
    setSelectedServicePoint(marker);
    setIsEdit(true);
  }

  async function onDeletePress(servicePoint) {
    const noOfJobs = await getPendingJobsByMarkerKey(servicePoint.key);
    if (noOfJobs > 0) {
      setPendingJobsWarning(noOfJobs);
    } else {
      setOpenDeleteDialog(true);
    }
    setSelectedServicePoint(servicePoint);
  }

  async function deleteServicePointData() {
    const { key: markerKey } = selectedServicePoint;

    await deleteServicePoint(markerKey);

    const { [markerKey]: marker, ...remainingMarkers } = filteredMarkers;

    setFilteredMarkers(remainingMarkers);

    setPendingJobsWarning(0);
    setOpenDeleteDialog(false);
  }

  function handleServiceTypeChange(event) {
    setSelectedServiceKey(event.target.value);
  }

  const handleSearchInput = (items) => {
    setIsSearching(true);
    setSearchedMarkers(items);
    const markersAfterFilter = filterDataByName(items, markers);
    setFilteredMarkers(markersAfterFilter);
    setIsSearching(false);
    setIsSearch(true);
  };

  const handleShowAllMarkers = () => {
    setIsSearch(false);
    setSearchedMarkers([]);
    setFilteredMarkers(markers);
    setSelectedServiceKey('all');
  };

  function handleMarkerItemPressed(marker) {
    history.push(`/home/markers/${marker.key}`);
    setSelectedMarker(marker);
  }

  function closeDetailsBox() {
    setIsModalOpen(false);
  }

  if (!markers) {
    return <Loader />;
  }

  const sortIconButton = () => {
    if (sortType === 'asc') {
      return <SortBoolAscendingVariantIcon size={30} />;
    }
    return <SortBoolDescendingVariantIcon size={30} />;
  };

  const handleChangeSort = () => {
    switch (sortType) {
      case 'asc': {
        setSortType('desc');
        break;
      }
      case 'desc': {
        setSortType('asc');
        break;
      }

      default:
        setSortType(sortType);
    }
  };

  const getActualMarkers = () => {
    return Object.values(filteredMarkers);
  };

  if (filteredMarkers) {
    return (
      <StyledContainer>
        <StyledTitle>Markers</StyledTitle>
        <Header
          heading="Search Markers"
          subheading="Search by Marker Name"
          placeholder="Marker name"
          paddingbottom="1em"
          handleSearch={handleSearchInput}
          selectedServiceKey={selectedServiceKey}
          handleServiceChange={handleServiceTypeChange}
          isSearching={isSearching}
          indexName="markers"
          localSearch
          reset={!isSearch}
        >
          <Tooltip title="Sort">
            <IconButton onClick={handleChangeSort}>
              {sortIconButton()}
            </IconButton>
          </Tooltip>
          <div />
          <div />
        </Header>

        {isSearch && (
          <Row justify="center">
            <PrimayButton onClick={handleShowAllMarkers}>
              Show all markers
            </PrimayButton>
          </Row>
        )}
        <MarkersTable
          markers={getActualMarkers()}
          onEditIconPress={onEditPress}
          onDeletePress={onDeletePress}
          onViewPress={handleMarkerItemPressed}
        />
        <StyledDialog open={openAddDialog}>
          <Row justify="space-between">
            <DialogTitle>{isEdit ? 'Edit Marker' : 'Add Marker'}</DialogTitle>
            <IconButton onClick={handleCloseAddDialog}>
              <Close />
            </IconButton>
          </Row>
          <AddServicePoint
            handleCloseDialog={handleCloseAddDialog}
            servicePoint={selectedServicePoint}
            edit={isEdit}
            marker={isEdit ? selectedServicePoint : ''}
            names={markerNames}
          />
        </StyledDialog>
        <AddButton onPress={onAddButtonPress} title="Create New Marker" />

        <NotifyBox notifyBoxModal={notifyBoxModal} message="Marker Deleted" />
        <AlertBox
          subtitle={`There ${
            pendingJobsWarning > 1 ? 'are' : 'is'
          } ${pendingJobsWarning} active jobs with this marker Once deleted markers will be removed from active jobs.`}
          title="Are you sure you want to delete?"
          handlePositiveAction={deleteServicePointData}
          handleNegativeAction={handleCloseDialog}
          open={pendingJobsWarning > 0}
        />
        <AlertBox
          title="Delete Marker"
          subtitle="Are you sure you want to delete this marker ?"
          handlePositiveAction={deleteServicePointData}
          handleNegativeAction={handleCloseDialog}
          open={openDeleteDialog}
        />
        <ADialog overflow="auto" open={isModalOpen}>
          <MarkerDetails
            closeDetailsBox={closeDetailsBox}
            details={selectedMarker}
            inModal
          />
        </ADialog>
        {isEmpty(markers) && (
          <Row justify="center">
            <Span weight="bold">No markers found.</Span>
          </Row>
        )}
      </StyledContainer>
    );
  }
  return <Loader />;
}

const StyledDialog = styled(Dialog)`
  .MuiPaper-rounded {
    min-width: 500px;
    border-radius: 1em;
    padding: 1em;
  }
`;

const StyledContainer = styled.div`
  padding: 16px;
`;

const StyledGrid = styled(Grid)`
  padding: 20px;
`;
