import React, { useContext, useEffect, useState } from 'react';

import cronstrue from 'cronstrue';
import styled from 'styled-components';
import EditIcon from '@material-ui/icons/Edit';
import EditAttributesIcon from '@material-ui/icons/EditAttributes';
import DeleteIcon from '@material-ui/icons/Delete';
import PlaylistCheckIcon from 'mdi-react/PlaylistCheckIcon';
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  IconButton,
  TableContainer,
  Tooltip,
} from '@material-ui/core';

import Span from '../../Common/Span';
import color from '../../../utils/color';
import { Col } from '../../../styles/snowm_styled';
import { StyledTableRow } from '../../Common/styled';
import { getDateForJob } from '../../../helpers/date';
import PrimaryButton from '../../Common/primary_button';
import { getBgColorOfTableRow } from '../../../helpers/misc';
import Loader, { Progress } from '../../Generics/snowm_loader';
import { getShiftById } from '../../../controllers/snowm_firebase';
import { ServicePContext } from '../../../contexts/service_provider';
import { ServicePointsContext } from '../../../contexts/service_points';
import {
  getColorForStatus,
  getJobStatus,
  possibleJobStatuses,
} from '../../../enums/jobStatus';
import { ServiceTypesContext } from '../../../contexts/service_types';

export default function JobsTable(props) {
  const {
    jobs,
    onEditClick,
    onDeleteClick,
    onCheckClick,
    onDetailsClick,
    isPeriodic,
    onStartEndDateAndStatusEdit,
  } = props;

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <StyledTableRow bgcolor={color.snomwBlue}>
            <TableCell>
              <Span color={color.white} weight="bold">
                Job
              </Span>
            </TableCell>
            {!isPeriodic && (
              <TableCell>
                <Span color={color.white} weight="bold">
                  Status
                </Span>
              </TableCell>
            )}
            {!isPeriodic && (
              <TableCell>
                <Span color={color.white} weight="bold">
                  Start Time
                  <br />
                  End Time
                </Span>
              </TableCell>
            )}
            <TableCell>
              <Span color={color.white} weight="bold">
                Crew
              </Span>
            </TableCell>
            <TableCell>
              <Span color={color.white} weight="bold">
                Markers
              </Span>
            </TableCell>

            <TableCell>
              <Span color={color.white} weight="bold">
                Actions
              </Span>
            </TableCell>
            <TableCell />
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {jobs?.map((job, index) => {
            return (
              <CrewsBody
                key={job.key}
                index={index}
                isPeriodic={isPeriodic}
                onEditClick={onEditClick}
                onStartEndDateAndStatusEdit={onStartEndDateAndStatusEdit}
                onDeleteClick={onDeleteClick}
                onCheckClick={onCheckClick}
                onDetailsClick={onDetailsClick}
                job={job}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const FlexContainer = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent ?? 'flex-end'};
  aligh-items: ${(props) => props.alignItems ?? 'right'};
`;

const CrewsBody = ({
  job,
  isPeriodic,
  index,
  onEditClick,
  onStartEndDateAndStatusEdit,
  onDeleteClick,
  onCheckClick,
  onDetailsClick,
}) => {
  const { subscribedServices } = useContext(ServiceTypesContext);
  const { allowMasterEditing } = subscribedServices ?? {};

  const getJob = () => {
    if (isPeriodic) {
      return job?.job;
    }
    return job;
  };

  const [shift, setShift] = useState(null);
  const [actualJob, setActualJob] = useState(getJob());

  useEffect(() => {
    const updatedJob = getJob();
    setActualJob(updatedJob);
  }, [job, isPeriodic]);

  useEffect(() => {
    let isCancel = false;

    if ((actualJob?.shift || actualJob?.shiftKey) && !isPeriodic) {
      if (actualJob?.shift) {
        setShift(actualJob.shift);
      } else {
        getShiftById(actualJob?.shiftKey).then((res) => {
          if (!isCancel) {
            setShift(res);
          }
        });
      }
    }
    return () => {
      isCancel = true;
    };
  }, [actualJob]);

  const statusColor = () => {
    return getColorForStatus(actualJob);
  };

  const renderTimeStampForJob = () => {
    const startDate = actualJob?.startedDate
      ? getDateForJob(actualJob.startedDate)
      : '-';
    const endedDate = actualJob?.endDate
      ? getDateForJob(actualJob.endDate)
      : '-';
    return (
      <>
        <FlexContainer justifyContent="space-between">
          <Span>{startDate}</Span>
        </FlexContainer>
        <FlexContainer justifyContent="space-between">
          <Span>{endedDate}</Span>
        </FlexContainer>
      </>
    );
  };

  const checkIfCompleteJobIsAllowed = () => {
    const { status: jobStatus } = job;
    return (
      jobStatus === possibleJobStatuses.STARTED ||
      jobStatus === possibleJobStatuses.NOT_COMPLETED
    );
  };

  const actionButtons = () => {
    const jobStatus = job?.status;
    return (
      <>
        {isPeriodic || jobStatus === 'assigned' ? (
          <Tooltip title="Edit">
            <IconButton onClick={() => onEditClick(job)} size="small">
              <EditIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        ) : null}

        {!isPeriodic && allowMasterEditing && (
          <Tooltip title="Edit">
            <IconButton
              onClick={() => onStartEndDateAndStatusEdit(job)}
              size="small"
            >
              <EditAttributesIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        )}

        {checkIfCompleteJobIsAllowed(job) && (
          <Tooltip title="Complete Job">
            <IconButton onClick={() => onCheckClick(job)} size="small">
              <PlaylistCheckIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title="Delete">
          <IconButton onClick={() => onDeleteClick(job)} size="small">
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </>
    );
  };

  const renderDetailsIcon = () => {
    return (
      <PrimaryButton onClick={() => onDetailsClick(job)}>Details</PrimaryButton>
    );
  };

  return (
    <StyledTableRow key={job?.key} bgcolor={getBgColorOfTableRow(index)}>
      <TableCell>
        <Col>
          <Span weight="bold">{actualJob?.name}</Span>
          {isPeriodic && job?.cronExp && (
            <Span>{cronstrue.toString(job?.cronExp)}</Span>
          )}
        </Col>
      </TableCell>
      {!isPeriodic && (
        <TableCell>
          <Span color={statusColor(job)} weight="bold">
            {getJobStatus(actualJob, shift)}
          </Span>
        </TableCell>
      )}
      {!isPeriodic && (
        <TableCell>
          <Span weight="bold">{renderTimeStampForJob()}</Span>
        </TableCell>
      )}

      <CrewsOfJob job={actualJob} />

      <MarkerOfAJob job={actualJob} />

      <TableCell>
        <Span weight="bold">{actionButtons()}</Span>
      </TableCell>

      <TableCell>
        <Span weight="bold">{renderDetailsIcon()}</Span>
      </TableCell>
    </StyledTableRow>
  );
};

const CrewsOfJob = ({ job }) => {
  const crewContext = useContext(ServicePContext);

  const { getCrewByUid } = crewContext ?? {};

  const [crewsName, setCrewsName] = useState(null);

  const getCrews = async (crewIds) => {
    const crewPromise = crewIds?.map((uid) => {
      const response = getCrewByUid(uid);
      return response;
    });

    const result = await Promise.all(crewPromise ?? []);

    return result?.map((prov) => prov.name).join() || 'Deleted';
  };

  useEffect(() => {
    if (job?.providerUids) {
      getCrews(job.providerUids).then((res) => {
        setCrewsName(res);
      });
    }
  }, [job]);

  if (!crewContext) {
    return <Loader />;
  }

  const renderCrewsName = () => {
    if (!crewsName) {
      return <Progress size={20} />;
    }
    return crewsName;
  };

  return (
    <TableCell>
      <Span weight="bold">{renderCrewsName()}</Span>
    </TableCell>
  );
};

const MarkerOfAJob = ({ job }) => {
  const markerContext = useContext(ServicePointsContext);

  const [markers, setMarkers] = useState(null);

  const { getMarkerByKey } = markerContext;

  const getMarkers = async () => {
    const promise = job?.allMarkers?.map((markerKey) => {
      return getMarkerByKey(markerKey);
    });

    const res = await Promise.all(promise ?? []);

    const validMarkers = res?.reduce((acc, marker) => {
      if (marker.name) {
        return [...acc, marker.name];
      }
      return acc;
    }, []);

    const markersSeparatedByComma = validMarkers?.join() ?? '-';

    setMarkers(markersSeparatedByComma || '-');
  };

  useEffect(() => {
    getMarkers();
  }, [job]);

  const renderMarkersName = () => {
    if (!markers) {
      return <Progress size={20} />;
    }
    return markers;
  };

  return (
    <TableCell>
      <Span>{renderMarkersName()}</Span>
    </TableCell>
  );
};
