/* eslint-disable react/jsx-wrap-multilines */
import React, { useState, useEffect } from 'react';

import styled from 'styled-components';
import { TextField, MenuItem, FormHelperText } from '@material-ui/core';

import color from '../../../utils/color';
import FormModal from '../../Common/form_modal';
import { getRealTimeServices } from '../../../controllers/snowm_firebase';

const initialRoute = {
  name: '',
  providerUid: null,
  serviceKey: null,
  serviceType: '',
  servicePointsKeys: [],
  polyline: null,
  key: null,
  distance: 0,
  companyKey: null,
  imageUrl: null,
  navigineLocationId: null,
  navigineLocationVersion: 0
};

export default function AddNewRoute({
  open,
  onCloseDialog,
  onChangeRoute,
  loading,
  setLoading
}) {
  const [services, setServices] = useState();
  const [selectedService, setSelectedService] = useState('none');
  const [routeInfo, setRouteInfo] = useState(initialRoute);
  const [image, setImage] = useState(null);
  const [imageHeight, setImageHeight] = useState();
  const [imageWidth, setImageWidth] = useState();
  const [serviceError, setServiceError] = useState(false);

  async function handleUpload(e) {
    e.preventDefault();
    if (e.target.files.length !== 0) {
      const file = e.target.files[e.target.files.length - 1];

      const reader = new FileReader();

      const url = URL.createObjectURL(file);
      const img = new Image();
      img.src = url;
      // eslint-disable-next-line func-names
      img.onload = function() {
        setImageHeight(img.height);
        setImageWidth(img.width);
      };
      reader.readAsDataURL(file);
      setImage(reader);
    }
  }

  function updateRouteName(e) {
    setRouteInfo({ ...routeInfo, name: e.target.value });
  }

  function handleServiceChange(event) {
    setSelectedService(event.target.value);
    setRouteInfo({ ...routeInfo, serviceType: event.target.value.type });
  }

  function _updateServices(response) {
    const routeBasedServices = Object.values(response).filter(
      service => service.type === 'indoor' || service.type === 'outdoor'
    );

    setServices(routeBasedServices);
  }

  useEffect(() => {
    const unsubscribe = getRealTimeServices(_updateServices);
    return () => unsubscribe();
  }, []);

  const handleSubmit = e => {
    e.preventDefault();
    if (selectedService !== 'none') {
      setLoading(true);
      onChangeRoute(selectedService, routeInfo, image, imageHeight, imageWidth);
      setRouteInfo({ ...routeInfo, service: '', name: '' });
      setSelectedService('none');
    } else {
      setLoading(false);
      setServiceError(true);
    }
  };

  return (
    <FormModal
      title="Add Route"
      handlePositiveAction={handleSubmit}
      handleNegativeAction={onCloseDialog}
      loading={loading}
      open={open}
    >
      <TextField
        required
        autoFocus
        margin="dense"
        id="name"
        placeholder="Route Name"
        type="text"
        variant="outlined"
        value={routeInfo.name}
        onChange={updateRouteName}
        fullWidth
      />

      <TextField
        fullWidth
        margin="dense"
        variant="outlined"
        select
        value={selectedService}
        onChange={handleServiceChange}
        required
      >
        <InvisibleMenuItem disabled value="none">
          Service
        </InvisibleMenuItem>
        {services?.map(service => (
          <MenuItem key={service.key} value={service}>
            {service.name}
          </MenuItem>
        ))}
      </TextField>
      {selectedService === 'none' && serviceError && (
        <WarningText>Please select the service</WarningText>
      )}
      {selectedService.type === 'indoor' && (
        <div>
          <label htmlFor="image">
            Upload Floorplan:
            <input required type="file" name="image" onChange={handleUpload} />
          </label>
        </div>
      )}
    </FormModal>
  );
}

const InvisibleMenuItem = styled(MenuItem)`
  && {
    display: none;
  }
`;

const WarningText = styled(FormHelperText)`
  && {
    color: ${color.red};
  }
`;
