/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: Private/index.js
 *
 *  Description: Entry point of private components.
 *
 *  Author: Nabin Kharal (nabin@brainants.com) and Roshan Gautam (roshan@brainants.com)
 *
 *  Date created: 4-july-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */
import React, { useState, useEffect, useContext } from 'react';

import styled from 'styled-components';
import WrenchIcon from 'mdi-react/WrenchIcon';
import RoutesIcon from 'mdi-react/RoutesIcon';
import ReceiptIcon from 'mdi-react/ReceiptIcon';
import FilePdfIcon from 'mdi-react/FilePdfIcon';
import HomeIcon from 'mdi-react/HomeOutlineIcon';
import ServiceIcon from 'mdi-react/BriefcaseIcon';
import { withTheme } from '@material-ui/core/styles';
import AccountClockIcon from 'mdi-react/AccountClockIcon';
import { Redirect, Route, Switch } from 'react-router-dom';
import PlaylistCheckIcon from 'mdi-react/PlaylistCheckIcon';
import HomeMapMarkerIcon from 'mdi-react/HomeMapMarkerIcon';
import AccountCircleIcon from 'mdi-react/AccountCircleIcon';
import CalendarCheckIcon from 'mdi-react/CalendarCheckIcon';
import FormatListTextIcon from 'mdi-react/FormatListTextIcon';
import AccountHardHatIcon from 'mdi-react/AccountHardHatIcon';
import OfficeBuildingIcon from 'mdi-react/OfficeBuildingIcon';
import HomeCityOutlineIcon from 'mdi-react/HomeCityOutlineIcon';
import OfficeBuildingMarkerIcon from 'mdi-react/OfficeBuildingMarkerIcon';
import OrderBoolAscendingVariantIcon from 'mdi-react/OrderBoolAscendingVariantIcon';

import Home from './Home';
import Jobs from './Jobs';
import Shifts from './shifts';
import Routes from './Routes';
import Vendors from './vendors';
import Services from './Services';
import Invoices from './invoices';
import BeaconMs from './BeaconMs';
import Providers from './Providers';
import ServicePoints from './markers';
import Activities from './activities';
import Properties from './properties';
import WorkOrders from './serviceRequests';
import Settings from './settings/Settings';
import AppBar from '../Generics/snowm_appbar';
import Drawer from '../Generics/snowm_drawer';
import Loader from '../Generics/snowm_loader';
import UserProfile from './Profile/UserProfile';
import ReportsTabs from './reports/ReportsTabsToggle';
import CompanyProfile from './Profile/CompanyProfile';
import { ServiceTypesContext } from '../../contexts/service_types';
import { LocalizationContext } from '../../contexts/localization_context';

// const { HOME, JOBS, CREW, SERVICES, MARKERS, ROUTES } = en.sidemenu;

/*
 ****************************************************************************
 * @brief  (getRoutes)      renders the list of routes
 * @param{type : Array,Function,Object } (routesToRender,setActive,theme)
 *
 * @return  returns a jsx element containing list of all routesToRender
 *
 ****************************************************************************
 */
function getRoutes(routesToRender, setActive, theme) {
  return (
    <>
      {routesToRender.map((route) => {
        return (
          <Switch key={route.name}>
            <Route
              path={route.route}
              component={(p) => {
                useEffect(() => {
                  setActive(route.route);
                }, []);
                const Component = route.component;
                return <Component {...p} theme={theme} />;
              }}
              exact={route.exact}
            />
          </Switch>
        );
      })}
    </>
  );
}

const drawerWidth = 240;

// Functional Component
const Dashboard = (props) => {
  const { strings } = useContext(LocalizationContext);
  const {
    HOME,
    JOBS,
    CREW,
    ROUTES,
    MARKERS,
    REPORTS,
    COMPANY_PROFILE,
    ATTENDANCE,
    USER_PROFILE,
  } = strings?.sidemenu;
  const [active, setActive] = useState('');
  const { theme } = props;

  // return content of the private component (with appropriate routes)

  const data = useContext(ServiceTypesContext);

  const { subscribedServices, companyServices } = data ?? {};

  const { activities, activitiesOnJob } = subscribedServices ?? {};

  const routes = [
    {
      route: '/home',
      component: Home,
      name: HOME,
      icon: <HomeIcon />,
      exact: true,
    },
    {
      route: '/home/jobs',
      component: Jobs,
      name: JOBS,
      icon: <PlaylistCheckIcon />,
      hide: companyServices?.length <= 0 ?? true,
    },

    {
      route: '/home/shifts',
      component: Shifts,
      hide: !subscribedServices?.shiftManagement,
      name: 'Shifts',
      icon: <AccountClockIcon />,
    },

    {
      route: '/home/crews',
      component: Providers,
      name: CREW,
      icon: <AccountHardHatIcon />,
    },
    {
      route: '/home/vendors',
      name: 'Vendors',
      hide: !subscribedServices?.enableOrganizationCrew,
      component: Vendors,
      icon: <OfficeBuildingMarkerIcon />,
    },
    // {
    //   route: '/home/services',
    //   component: Services,
    //   name: SERVICES,
    //   icon: <ServiceIcon />
    // },
    {
      route: '/home/routes',
      component: Routes,
      name: ROUTES,
      hide: !subscribedServices?.indoor && !subscribedServices?.outdoor,
      icon: <RoutesIcon />,
    },
    {
      route: '/home/work-orders',
      component: WorkOrders,
      name: 'Work Orders',
      icon: <FormatListTextIcon />,
      hide: !subscribedServices?.workOrders,
    },
    {
      route: '/home/markers',
      component: ServicePoints,
      name: MARKERS,
      icon: <HomeMapMarkerIcon />,
    },

    {
      route: '/home/invoices',
      component: Invoices,
      name: 'Invoices',
      icon: <ReceiptIcon />,
      hide: !subscribedServices?.invoicing,
    },

    {
      route: '/home/profile',
      component: UserProfile,
      name: USER_PROFILE,
      icon: <AccountCircleIcon />,
      menu: true,
    },
    {
      route: '/home/report',
      component: ReportsTabs,
      name: REPORTS,
      hide: !subscribedServices?.reports,
      icon: <FilePdfIcon />,
    },
    {
      route: '/home/properties',
      component: Properties,
      name: 'Properties',
      hide: !subscribedServices?.properties,
      icon: <HomeCityOutlineIcon />,
    },
    {
      route: '/home/activities',
      component: Activities,
      name: 'Activities',
      hide: !activities && !activitiesOnJob,
      icon: <OrderBoolAscendingVariantIcon />,
    },
    {
      route: '/home/devices',
      component: BeaconMs,
      name: 'DEVICES',
      hide: true,
      icon: <FilePdfIcon />,
    },
    {
      route: '/home/company-profile',
      component: CompanyProfile,
      name: COMPANY_PROFILE,
      icon: <OfficeBuildingIcon />,
    },
    {
      route: '/home/settings',
      component: Settings,
      name: 'Settings',
      icon: <WrenchIcon />,
    },
  ];
  useEffect(() => {
    data.getCompanyDetailAsync();
  }, []);

  if (!subscribedServices) {
    return <Loader />;
  }

  return (
    <Body>
      {/* <Row> */}
      <AppBar routes={routes} {...props} />
      {/* </Row> */}
      <Content>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            height: '100%',
          }}
        >
          <Drawer
            active={active}
            width={drawerWidth}
            routes={routes}
            {...props}
          />
          <Main theme={theme}>
            <div style={{ height: '100%', overflow: 'hidden' }}>
              <Switch>
                {getRoutes(routes, setActive, theme)}
                <Redirect to="/home" />
              </Switch>
            </div>
          </Main>
        </div>
      </Content>
    </Body>
  );
};

export default withTheme(Dashboard);

const Body = styled.div`
  display: flex;
  flex-flow: column;
  height: 100vh;
`;

const Row = styled.div`
  display: flex;
  flex: 0 1 auto;
`;

const Content = styled.div`
  flex: 1;
`;

const Main = styled.div`
  width: 100%;
  overflow: auto;
  background: #f2ecec;
`;
