import styled from 'styled-components';
import { TableCell } from '@material-ui/core';

import Span from '../../Common/Span';

export const StyledTableCell = styled(TableCell)`
  && {
    color: ${(props) => props.color};
    cursor: ${(props) => props.cursor};
  }
`;

export const UnderlinedText = styled(Span)`
  && {
    text-decoration: underline;
  }
`;
