/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: service_jobs.js
 *
 *  Description: Context API for Jobs
 *
 *  Author: Roshan Gautam (roshan@brainants.com)
 *
 *  Date created: 12-july-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */

import React, { createContext, useState } from 'react';
import {
  getRealTimeJobs,
  getJobInfo,
  getRealTimePeriodicJobs,
} from '../controllers/snowm_firebase';

export const JobsContext = createContext();

export function JobsProvider({ children }) {
  const initialFilters = {
    serviceKey: '',
    markerKey: '',
    propertyKey: '',
    providerKey: '',
    status: '',
  };
  const [jobs, setJobs] = useState(null);
  const [periodicJobs, setPeriodicJobs] = useState(null);
  const [filterTypes, setFilterTypes] = useState(initialFilters);

  function _updateJobs(jobsData) {
    setJobs(jobsData);
  }

  function fetchJobs() {
    getRealTimeJobs(_updateJobs);
  }

  const _updatePeriodicJobs = (res) => {
    setPeriodicJobs(res);
  };

  function fetchPeriodicJobs() {
    getRealTimePeriodicJobs(_updatePeriodicJobs);
  }

  async function getJobDetail(jobId, isPeriodic) {
    if (!jobs?.[jobId]) {
      const jobDetail = await getJobInfo(jobId, isPeriodic);
      return jobDetail;
    }
    return jobs[jobId];
  }

  function setFilterData(data) {
    setFilterTypes(data);
  }

  return (
    <JobsContext.Provider
      value={{
        jobs,
        fetchJobs,
        fetchPeriodicJobs,
        getJobDetail,
        setFilterData,
        filterTypes,
        periodicJobs,
      }}
    >
      {children}
    </JobsContext.Provider>
  );
}
