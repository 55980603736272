import moment from 'moment';
import humanizeDuration from 'humanize-duration';

export function getRelativeDate(date) {
  return date && moment(date).fromNow();
}

export function getCurrentDate() {
  return moment().format('YYYY-MM-DD h:mm A');
}

export function getDateInDashFormat(date) {
  return date ? moment(date).format('YYYY-MM-DD h:mm A') : '-';
}

export function getTimeInEpoch(date) {
  return moment(date).valueOf();
}

export function getFormattedDate(date) {
  return moment(date).format('MMM Do, YYYY hh:mm A');
}

export function getDayMonthYear(date) {
  return moment(date).format('MM/DD/YYYY');
}

export function getDayMonthYearTime(date) {
  return date ? moment(date).format('YYYY/MM/DD hh:mm A') : '-';
}

export function getLocaleDate(date) {
  return moment(date).format('lll');
}

export function getDateForJob(date) {
  return moment(date).format('YYYY/MM/DD hh:mm A');
}

export function getYearMonthDay(date) {
  return moment(date).format('YYYY/MM/DD');
}

export function getCurrentTimeInEpoch() {
  return moment().valueOf();
}

export function getStartTimeStampOfTheDay() {
  return moment().startOf('day').valueOf();
}

export function getTimestampOfAWeekBefore(date) {
  return moment(date).subtract(7, 'd').valueOf();
}

export const getMomentObjectFromTimestamp = (date) => {
  return moment(date);
};

export const getHourMinutes = (date) => {
  return moment(date).format('HH:mm A');
};

export const getHoursFromMill = (millSec) => {
  return humanizeDuration(millSec, {
    units: ['h', 'm', 's'],
    round: true,
    language: 'shortEn',
    languages: {
      shortEn: {
        y: () => 'y',
        mo: () => 'mo',
        w: () => 'w',
        d: () => 'd',
        h: () => 'h',
        m: () => 'm',
        s: () => 's',
        ms: () => 'ms',
      },
    },
  });
};

export const getTimeInStartOfMinute = (date) => {
  const startOfMinute = moment(date).startOf('minute');
  return getTimeInEpoch(startOfMinute);
};
