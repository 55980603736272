import React from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import Span from '../../../Common/Span';
import color from '../../../../utils/color';
import { StyledTableRow } from '../../../Common/styled';
import { StyledTableCell, UnderlinedText } from '../styled';
import { getBgColorOfTableRow } from '../../../../helpers/misc';
import { possibleJobStatuses } from '../../../../enums/jobStatus';
import { getDayMonthYear, getHoursFromMill } from '../../../../helpers/date';

export const getJobDate = (job) => {
  return job.startedDate ?? job.createdDate;
};

const JobsTableOfACrew = ({
  arrayOfDates,
  jobs,
  auditableCrew,
  handleAudit,
}) => {
  const getDetailsOfADate = ({ date }) => {
    const selectedDate = getDayMonthYear(date);

    const jobDetails = jobs?.reduce(
      (acc, curr) => {
        let {
          totalHours,
          completedJobs,
          notStartedJobs,
          notCompletedJobs,
          modified,
        } = acc;

        const { endDate, providerUids } = curr;
        const validDate = getJobDate(curr);
        const setOfProviderUids = new Set(providerUids ?? []);

        const startDateOfJob = getDayMonthYear(validDate);

        if (
          !setOfProviderUids.has(auditableCrew?.value) ||
          startDateOfJob !== selectedDate
        ) {
          return acc;
        }

        if (endDate) {
          const diffInMill = endDate - validDate;
          totalHours += diffInMill;
        }

        if (curr.status === possibleJobStatuses.COMPLETED) {
          completedJobs += 1;
          if (curr.modified) {
            modified.completedJobs = true;
          }
        }

        if (curr.status === possibleJobStatuses.NOT_STARTED) {
          notStartedJobs += 1;
          if (curr.modified) {
            modified.notStartedJobs = true;
          }
        }

        if (curr.status === possibleJobStatuses.NOT_COMPLETED) {
          notCompletedJobs += 1;
          if (curr.modified) {
            modified.notCompletedJobs = true;
          }
        }

        return {
          ...acc,
          totalHours,
          completedJobs,
          notStartedJobs,
          notCompletedJobs,
          modified,
        };
      },
      {
        totalHours: 0,
        completedJobs: 0,
        notStartedJobs: 0,
        notCompletedJobs: 0,
        modified: {
          completedJobs: false,
          notStartedJobs: false,
          notCompletedJobs: false,
        },
      }
    );

    return (
      <>
        <TableCell>
          <Span>{getHoursFromMill(jobDetails.totalHours)}</Span>
        </TableCell>
        {jobDetails.completedJobs ? (
          <StyledTableCell
            cursor="pointer"
            onClick={() =>
              handleAudit(auditableCrew, date, possibleJobStatuses.COMPLETED)
            }
          >
            <UnderlinedText>
              {jobDetails.completedJobs}
              {jobDetails?.modified?.completedJobs ? '*' : ''}
            </UnderlinedText>
          </StyledTableCell>
        ) : (
          <TableCell>{jobDetails.completedJobs}</TableCell>
        )}
        {jobDetails.notStartedJobs ? (
          <StyledTableCell
            cursor="pointer"
            onClick={() =>
              handleAudit(auditableCrew, date, possibleJobStatuses.NOT_STARTED)
            }
          >
            <UnderlinedText>
              {jobDetails.notStartedJobs}
              {jobDetails?.modified?.notStartedJobs ? '*' : ''}
            </UnderlinedText>
          </StyledTableCell>
        ) : (
          <TableCell>{jobDetails.notStartedJobs}</TableCell>
        )}
        {jobDetails.notCompletedJobs ? (
          <StyledTableCell
            cursor="pointer"
            onClick={() =>
              handleAudit(
                auditableCrew,
                date,
                possibleJobStatuses.NOT_COMPLETED
              )
            }
          >
            <UnderlinedText>
              {jobDetails.notCompletedJobs}
              {jobDetails?.modified?.notCompletedJobs ? '*' : ''}
            </UnderlinedText>
          </StyledTableCell>
        ) : (
          <TableCell>{jobDetails.notCompletedJobs}</TableCell>
        )}
      </>
    );
  };

  const renderTableCell = ({ tableData, key }) => {
    return (
      <TableCell key={key}>
        <Span weight="bold" color={color.white}>
          {tableData}
        </Span>
      </TableCell>
    );
  };

  const getTotalHours = () => {
    const totalDescriptions = jobs?.reduce(
      (total, curr) => {
        let {
          totalHours,
          completedJobs,
          notStartedJobs,
          notCompletedJobs,
        } = total;
        const { endDate, providerUids } = curr;
        const validDate = getJobDate(curr);
        const setOfProviderUids = new Set(providerUids ?? []);

        if (!setOfProviderUids.has(auditableCrew?.value)) {
          return total;
        }

        if (endDate) {
          const diffInMill = endDate - validDate;
          totalHours += diffInMill;
        }

        if (curr.status === possibleJobStatuses.COMPLETED) {
          completedJobs += 1;
        }

        if (curr.status === possibleJobStatuses.NOT_STARTED) {
          notStartedJobs += 1;
        }

        if (curr.status === possibleJobStatuses.NOT_COMPLETED) {
          notCompletedJobs += 1;
        }

        return {
          ...total,
          totalHours,
          completedJobs,
          notStartedJobs,
          notCompletedJobs,
        };
      },
      {
        totalHours: 0,
        completedJobs: 0,
        notStartedJobs: 0,
        notCompletedJobs: 0,
      }
    );

    return Object.keys(totalDescriptions).map((description) => {
      if (description === 'totalHours') {
        const totalWorkedHours = getHoursFromMill(
          totalDescriptions[description]
        );
        return renderTableCell({
          tableData: totalWorkedHours,
          key: description,
        });
      }

      return renderTableCell({
        tableData: totalDescriptions[description],
        key: description,
      });
    });
  };

  const detailsHeader = [
    'Date',
    'Total Hours',
    'Completed Jobs',
    'Not Started Jobs',
    'Not Completed Jobs',
  ];

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow bgcolor={color.snomwBlue}>
            {detailsHeader.map((header) => (
              <TableCell key={header}>
                <Span weight="bold" color={color.white}>
                  {header}
                </Span>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {arrayOfDates?.map((date, index) => {
            return (
              <StyledTableRow
                key={getDayMonthYear(date)}
                bgcolor={getBgColorOfTableRow(index)}
              >
                <TableCell>
                  <Span>{getDayMonthYear(date)}</Span>
                </TableCell>
                {getDetailsOfADate({ date })}
              </StyledTableRow>
            );
          })}
          <StyledTableRow bgcolor={color.green}>
            <TableCell>
              <Span weight="bold" color={color.white}>
                Total
              </Span>
            </TableCell>
            {getTotalHours()}
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default JobsTableOfACrew;
