/* eslint-disable react/jsx-wrap-multilines */

import React, { useState, useEffect, useContext, useRef } from 'react';

import moment from 'moment';
import cronstrue from 'cronstrue';
import styled from 'styled-components';
import MomentUtils from '@date-io/moment';
import { DateRangePicker, SingleDatePicker } from 'react-dates';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
  FormControlLabel,
  Divider,
  Switch,
  CircularProgress,
  Button,
  InputAdornment,
} from '@material-ui/core';

import './style.css';
import List from './List';
import Span from '../../Common/Span';
import StyledTextField from './styled';
import ADialog from '../../Common/styled';
import { CustomSelect } from './custom_select';
import FormModal from '../../Common/form_modal';
import PrimayButton from '../../Common/primary_button';
import { Row, Col } from '../../../styles/snowm_styled';
import { JobConfiguration } from '../settings/JobConfiguration';
import { generateCronExpression } from '../../../controllers/cron';
import ActivitiesSelection from '../../Common/ActivitiesSelection';
import { ServiceTypesContext } from '../../../contexts/service_types';
import {
  defaultPeriodicTimeSchedule,
  PeriodicJobTimeSelection,
} from './PeriodicJobTimeSelection';
import { ServicePointsContext } from '../../../contexts/service_points';
import {
  createEditJob,
  getCrewsForJobs,
  getCompanyDetail,
  getRealTimeServices,
  getRoutesForService,
  getServicePointById,
  getShiftsOfACompany,
  createEditShiftJob,
  getRoutesFromDatabase,
  getPropertiesOfCompany,
  getWorkOrderFromRoutes,
  getMarkersForServiceKey,
  getMarkersByPropertyKey,
} from '../../../controllers/snowm_firebase';
import { LocalizationContext } from '../../../contexts/localization_context';

const status = [
  { label: 'Assigned', value: 'assigned' },
  { label: 'Not Started', value: 'not_started' },
  { label: 'Completed', value: 'completed' },
  { label: 'Not Completed', value: 'not_completed' },
  { label: 'Started', value: 'started' },
];

export default function JobModal({
  open,
  onClose,
  individualJob,
  selectedJobType,
  edit,
  anotherEdit,
  serviceOfOrder,
  markerSelected,
  orderSelected,
  onJobCreation,
  setindividualJob,
}) {
  const defaultJob = {
    key: '',
    name: '',
    endDate: null,
    allRoutes: [],
    orderKeys: {},
    companyKey: '',
    serviceKey: '',
    allMarkers: [],
    noTouch: false,
    shiftKey: null,
    activities: {},
    serviceType: '',
    providerUids: [],
    servedRoutes: [],
    createdDate: null,
    startedDate: null,
    servedMarkers: [],
    dateToEndJob: null,
    status: 'assigned',
    propertyKeys: null,
    detectionTime: null,
    expectedEndTime: null,
    expectedStartTime: null,
    invoicingEnabled: false,
    enabledGeofencing: false,
    minimumServingDistance: 80,
    allowImageFromGallery: true,
    temperatureMonitoring: false,
  };

  const defaultPeriodicJob = {
    name: '',
    cronExp: '',
    createdDate: null,
    job: {},
    days: [],
    hours: [],
    minutes: [],
    key: '',
    companyKey: '',
    serviceType: '',
  };

  const markerRef = useRef(null);

  const serviceContext = useContext(ServiceTypesContext);

  const { markers: allMarkers, fetchServicePoints } = useContext(
    ServicePointsContext
  );
  const subscribedServices = serviceContext?.subscribedServices;

  const { strings } = useContext(LocalizationContext);

  const { SHIFT_JOBS } = strings?.jobType;

  const [job, setJob] = useState(defaultJob);

  const [isPeriodic, setIsPeriodic] = useState(false);
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [crew, setCrew] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [selectedService, setSelectedService] = useState();
  const [serviceOption, setServiceOption] = useState('');
  const [names, setNames] = useState([]);
  const [crewsNames, setCrewsNames] = useState([]);
  const [date, setDate] = useState(null);
  const [focus, setFocus] = useState(false);
  const [markers, setMarkers] = useState([]);
  const [propertyDetail, setPropertyDetail] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const [
    isMinimumServingTimeEnabled,
    setIsMinimumServingTimeEnabled,
  ] = useState(false);

  const [selectedProperties, setSelectedProperties] = useState(null);

  const [selectedProperty, setSelectedProperty] = useState();
  const [selectedCrew, setSelectedCrew] = useState(null);
  const [isConfigureJobOpen, setIsConfigureJobOpen] = useState(false);

  const [notes, setNotes] = useState(null);
  const [shifts, setShifts] = useState(null);
  const [cronExp, setCronExp] = useState('');
  const [noteInJob, setNoteInJob] = useState(false);
  const [noTouchJob, setNoTouchJob] = useState(true);
  const [selectedShift, setSelectedShift] = useState(null);
  const [selectedMarkers, setSelectedMarkers] = useState();
  const [companyDetail, setCompanyDetail] = useState(null);
  const [selectedLastMarker, setSelectedLastMarker] = useState();
  const [selectedActivities, setSelectedActivities] = useState([]);
  const [openActivitiesModal, setOpenActivitiesModal] = useState(false);
  const [isTimeSelectionOpen, setIsTimeSelectionOpen] = useState(false);
  const [periodicJobData, setPeriodicJobData] = useState(defaultPeriodicJob);
  const [periodicData, setPeriodicData] = useState(defaultPeriodicTimeSchedule);
  const [isPropertyChanged, setIsPropertyChanged] = useState(false);
  const [dateTimeErrorMessage, setDateTimeErrorMessage] = useState('');

  const getLabel = (jobStatus) => {
    switch (jobStatus) {
      case 'assigned':
        return 'Assigned';
      case 'not_started':
        return 'Not Started';
      case 'completed':
        return 'Completed';
      case 'started':
        return 'Started';
      case 'not_completed':
        return 'Not Completed';
      default:
        return '';
    }
  };

  const isJobAutoComplete = (finishJobAtEnd) => {
    if (!finishJobAtEnd) return '';

    return '(Job auto complete on shift end)';
  };

  const customLabel = (shift) => {
    const { name, startTime, endTime, finishJobAtEnd } = shift;
    const shiftName = name;
    const shiftDuration = `${startTime} - ${endTime}`;
    const jobAutoCompleteMessage = isJobAutoComplete(finishJobAtEnd);
    const shiftLabel = `${shiftName} ( ${shiftDuration} ) ${jobAutoCompleteMessage}`;

    return shiftLabel;
  };

  useEffect(() => {
    if (!allMarkers) {
      fetchServicePoints();
    }
  }, []);

  useEffect(() => {
    if (edit) {
      setIsMinimumServingTimeEnabled(
        !!individualJob.job?.minimumServingTime ||
          !!individualJob.minimumServingTime
      );
    }
  }, [edit, individualJob]);

  useEffect(() => {
    function _updateShifts(res) {
      const shiftOptions = res?.map((shift) => {
        return { label: customLabel(shift), value: shift };
      });
      setShifts(shiftOptions);
    }
    if (selectedService?.value?.serviceFeatures?.shiftManagement) {
      getShiftsOfACompany(_updateShifts);
    } else {
      setJob({ ...job, shiftKey: null });
    }
  }, [selectedService]);

  useEffect(() => {
    if (noteInJob) {
      setOpenActivitiesModal(true);
      setNotes({ [orderSelected.markerKey]: orderSelected.requestNote });
    } else {
      setOpenActivitiesModal(false);
      setNotes(null);
      setJob({ ...job, notes: null, activities: null });
    }
  }, [noteInJob]);

  useEffect(() => {
    let propertyKeys = [];
    let orderKeys = {};
    let allMarkers = [];
    let serviceKey = '';
    let serviceType = '';
    let expectedServiceTime = '';
    let notes = {};

    const service = serviceOfOrder?.value;
    if (serviceOfOrder) {
      setSelectedService(serviceOfOrder);
      serviceKey = service.key;
      serviceType = service.type;
    }

    if (markerSelected?.propertyKey) {
      propertyKeys = [markerSelected?.propertyKey];

      serviceKey = markerSelected.serviceKey;
    }

    if (orderSelected) {
      orderKeys = {
        [orderSelected.markerKey]: orderSelected.id,
      };

      if (
        orderSelected?.preferredTime &&
        orderSelected?.preferredTime?.length > 0
      ) {
        expectedServiceTime = orderSelected.preferredTime;
        notes[orderSelected.markerKey] = orderSelected.requestNote;
      }
      allMarkers = [orderSelected.markerKey];
    }

    setJob({
      ...job,
      orderKeys,
      allMarkers,
      propertyKeys,
      serviceKey,
      serviceType,
      expectedServiceTime,
      notes,
    });
  }, [markerSelected, orderSelected, serviceOfOrder]);

  useEffect(() => {
    getCompanyDetail().then((res) => {
      setCompanyDetail(res);
    });
  }, []);

  useEffect(() => {
    if (individualJob?.startedDate > individualJob?.endDate) {
      setDateTimeErrorMessage(
        'End date/time must be less than start date/time.'
      );
    } else {
      setDateTimeErrorMessage('');
    }
  }, [individualJob?.endDate]);

  const generateNumbers = (lastNum, diff) => {
    const numbers = [];
    for (let i = 0; i <= lastNum; i += diff) {
      numbers.push({ value: i, label: i });
    }
    return numbers;
  };

  const hoursData = generateNumbers(23, 1);
  const minutesData = generateNumbers(55, 5);
  const weekDaysData = [
    { value: 0, label: 'Sunday' },
    { value: 1, label: 'Monday' },
    { value: 2, label: 'Tuesday' },
    { value: 3, label: 'Wednesday' },
    { value: 4, label: 'Thursday' },
    { value: 5, label: 'Friday' },
    { value: 6, label: 'Saturday' },
  ];

  function sortNumber(a, b) {
    return a - b;
  }

  function getDataFromReducer(jobField, setOfAllMarkers) {
    const actualJobField = job?.[jobField] ?? job?.job?.[jobField] ?? {};
    const newData = Object.keys(actualJobField).reduce((acc, curr) => {
      if (!setOfAllMarkers.has(curr)) {
        return acc;
      }
      return { ...acc, [curr]: actualJobField?.[curr] ?? null };
    }, {});
    return newData;
  }

  function handleClose(res) {
    setPeriodicData(defaultPeriodicTimeSchedule);
    setCronExp('');
    if (onJobCreation && res) {
      onJobCreation(res);
    } else {
      onClose(res);
    }
  }

  const getSelectedDataFromArray = (oldData, newData) => {
    if (!newData?.length) {
      return oldData ?? [];
    }
    return newData ?? [];
  };

  const getJobForPeriodic = (data, jobMainDetails) => {
    return {
      key: jobMainDetails?.key || data.key,
      enabledGeofencing:
        data?.enabledGeofencing ?? jobMainDetails?.enabledGeofencing ?? false,
      closedBy: jobMainDetails?.closedBy ?? null,
      name: jobMainDetails?.name || data.name,
      expectedServiceTime: jobMainDetails?.expectedServiceTime ?? null,
      futureAssignmentData: jobMainDetails?.futureAssignmentData ?? null,
      notes: data.notes ?? jobMainDetails?.notes ?? null,
      noTouch: data.noTouch ?? jobMainDetails?.noTouch ?? null,
      crewAdminNote: jobMainDetails?.crewAdminNote ?? null,
      endDate: jobMainDetails?.endDate ?? null,
      reassignCrew: jobMainDetails?.reassignCrew ?? null,
      shiftKey: data.shiftKey ?? jobMainDetails?.shiftKey ?? null,
      allRoutes: getSelectedDataFromArray(
        jobMainDetails?.allRoutes,
        data.allRoutes
      ),
      serviceKey: data.serviceKey || jobMainDetails.serviceKey,
      companyKey: data?.companyKey || jobMainDetails?.companyKey,
      allMarkers: getSelectedDataFromArray(
        jobMainDetails?.allMarkers,
        data.allMarkers
      ),
      propertyKeys: getSelectedDataFromArray(
        jobMainDetails?.propertyKeys,
        data.propertyKeys
      ),
      invoicingEnabled:
        data?.invoicingEnabled ?? jobMainDetails?.invoicingEnabled ?? null,
      createdDate: (data?.createdDate || jobMainDetails?.createdDate) ?? null,
      startedDate: jobMainDetails?.startedDate ?? null,
      providerUids: getSelectedDataFromArray(
        jobMainDetails?.providerUids,
        data?.providerUids
      ),
      dateToEndJob: jobMainDetails?.dataToEndJob ?? null,
      servedRoutes: jobMainDetails?.servedRoutes ?? [],
      detectionTime: jobMainDetails?.detectionTime ?? null,
      servedMarkers: jobMainDetails?.servedMarkers ?? [],
      expectedEndTime: jobMainDetails?.expectedEndTime ?? null,
      status: data?.status ?? jobMainDetails?.status ?? null,
      expectedStartTime: jobMainDetails?.expectedStartTime ?? null,
      serviceType: data.serviceType ?? jobMainDetails?.serviceType,
      temperatureMonitoring: jobMainDetails?.temperatureMonitoring ?? null,
      allowImageFromGallery: jobMainDetails?.allowImageFromGallery ?? null,
      activities: data.activities ?? jobMainDetails?.activities ?? [],
      orderKeys: data.orderKeys ?? null,
      minimumServingTime:
        data.minimumServingTime ?? jobMainDetails?.minimumServingTime ?? null,
      minimumServingDistance:
        data?.minimumServingDistance ??
        jobMainDetails?.minimumServingDistance ??
        0,
    };
  };

  const isPeriodicTimeEmpty = (periodicSchedule) => {
    if (periodicSchedule) {
      return (
        !periodicSchedule?.hours?.length || !periodicSchedule?.minutes?.length
      );
    }
    return !periodicData?.hours?.length || !periodicData?.minutes?.length;
  };

  const getShiftByShiftKey = (shiftKey) => {
    const shiftSelected = shifts?.find((shift) => shift.value.id === shiftKey);
    return shiftSelected?.value ?? null;
  };
  async function addEditJob(e) {
    e.preventDefault();

    // const isEmpty = isPeriodicTimeEmpty();

    // console.log({ isPeriodic, isEmpty });

    // if (isEmpty && isPeriodic) {
    //   return;
    // }

    let jobRes;
    setLoading(true);
    const data = { ...job };
    const setOfAllMarkers = new Set(
      job?.allMarkers?.length ? job?.allMarkers : job?.job?.allMarkers ?? []
    );
    const newActivities = getDataFromReducer('activities', setOfAllMarkers);

    const newOrderKeys = getDataFromReducer('orderKeys', setOfAllMarkers);

    const newNotes = getDataFromReducer('notes', setOfAllMarkers);
    data.activities = newActivities;
    data.orderKeys = newOrderKeys;
    data.notes = newNotes;

    const jobMainDetails = data?.job;

    try {
      if (isPeriodic) {
        let periodicJob = {};
        // periodicData.minutes.sort(sortNumber);
        // periodicData.hours.sort(sortNumber);
        // if (periodicData?.weekDays?.length) {
        //   periodicData.weekDays.sort(sortNumber);
        // }

        // const cronExpression = generateCronExpression(
        //   periodicData.minutes,
        //   periodicData.hours,
        //   periodicData.weekDays
        // );

        if (!edit) {
          periodicJob = {
            // cronExp: cronExpression,
            job: getJobForPeriodic(data, jobMainDetails),
            companyKey: job.companyKey,

            shift: getShiftByShiftKey(
              data.shiftKey ?? jobMainDetails?.shiftKey ?? null
            ), // cronConfiguration: {
            //   days: periodicData.weekDays ?? [],
            //   hours: periodicData.hours,
            //   minutes: periodicData.minutes,
            // },
          };
        } else {
          periodicJob = {
            createdDate: data.createdDate ?? jobMainDetails.createdDate,
            key: data.key ?? jobMainDetails.key,
            companyKey: data.companyKey ?? jobMainDetails?.companyKey,
            shift: getShiftByShiftKey(
              data.shiftKey ?? jobMainDetails?.shiftKey ?? null
            ),

            job: getJobForPeriodic(data, jobMainDetails),
          };
        }

        setLoading(false);

        jobRes = await createEditShiftJob(periodicJob);
      } else {
        const jobReqBody = anotherEdit
          ? individualJob
          : getJobForPeriodic(data, jobMainDetails);

        jobRes = await createEditJob(jobReqBody);
      }
      handleClose(jobRes);
      setJob(defaultJob);
      setNotes(null);
      setPeriodicData(defaultPeriodicTimeSchedule);
      setCronExp('');
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }

  useEffect(() => {
    setSelectedProperties(null);
    if (!open) {
      setJob(defaultJob);
      setSelectedMarkers(null);
      setSelectedProperty();
      setSelectedCrew();
      setNoteInJob(false);
      setSelectedService(null);
      setSelectedShift(null);
    }
  }, [open]);

  function handleFormInputChange(event) {
    setJob({
      ...job,
      [event.target.name]: event.target.value,
    });
    setPeriodicJobData({
      ...periodicJobData,
      [event.target.name]: event.target.value,
    });
  }

  function getLabelValue(data = [], isMarkers) {
    if (!isMarkers) {
      return data?.map((detail) => {
        // TODO:
        const value = detail.key ?? detail.masterUid ?? detail.uid;
        return { value, label: detail.name };
      });
    }

    const result = data?.map((detail) => {
      return {
        value: detail.key,
        label: detail.name,
      };
    });

    return result;
  }

  const getJobKey = () => {
    if (edit) {
      return job.key ?? '';
    }
    return '';
  };

  const getCreatedDate = () => {
    if (edit) {
      return job.createdDate ?? '';
    }
    return null;
  };

  const getUidsOfSelectedCrews = (crewsSelected) => {
    let crewsUids = [];
    if (Array.isArray(crewsSelected)) {
      crewsUids = crewsSelected?.map((prov) => prov.value);
    } else {
      crewsUids = crewsSelected && [crewsSelected?.value];
    }

    return crewsUids;
  };

  async function handleServiceChange(service) {
    const serv = service.value;
    const { serviceFeatures } = serv;
    setSelectedService(service);
    setSelectedMarkers(null);
    setNames();
    setSelectedProperties(null);

    let updatedJob = {
      ...defaultJob,
      invoicingEnabled: serviceFeatures?.invoicing ?? false,
      serviceKey: serv.key,
      serviceType: serv.type,
      key: edit ? job.key : '',
      providerUids: getUidsOfSelectedCrews(selectedCrew),
      createdDate: getCreatedDate(),
    };
    if (!isPeriodic && !edit) {
      updatedJob = { ...updatedJob };
    } else if (isPeriodic) {
      updatedJob = {
        job: { ...updatedJob },
        name: job.name,
        companyKey: job.companyKey,
        key: getJobKey(),
        createdDate: getCreatedDate(),
      };
    }
    if (edit) {
      updatedJob = { ...updatedJob, name: job.name };
    }
    setJob({
      ...updatedJob,
      name: job.name,
    });
    if (isPeriodic) {
      setPeriodicJobData({
        ...defaultPeriodicJob,
        serviceKey: serv.key,
      });
    }
  }

  const handleStatus = (data) => {
    setindividualJob({ ...individualJob, status: data.value });
  };

  function handleChange(event) {
    setIsPeriodic(event.target.checked);
  }

  useEffect(() => {
    if (selectedJobType === SHIFT_JOBS) {
      setIsPeriodic(true);
    } else {
      setIsPeriodic(false);
    }
  }, [selectedJobType]);

  useEffect(() => {
    let isSubscribe = true;
    const updateServices = (response) => {
      const serviceOptions = Object.values(response).map((service) => {
        return { value: service, label: service.name };
      });

      if (isSubscribe) setServices(serviceOptions);
    };

    getRealTimeServices(updateServices);

    return () => {
      isSubscribe = false;
    };
  }, []);

  useEffect(() => {
    const serviceSelected = selectedService?.value;
    if (!edit) {
      setNoTouchJob(serviceSelected?.serviceFeatures?.noTouch ?? false);
    }
    setMarkers([]);
    setSelectedProperty();
    if (serviceSelected?.serviceFeatures?.properties) {
      getPropertiesOfCompany().then((response) => {
        const properties = response?.reduce((acc, property) => {
          if (!property.archive) {
            return [...acc, { label: property.name, value: property.id }];
          }
          return acc;
        }, []);

        setPropertyDetail(properties);
      });
    } else if (
      serviceSelected?.type === 'markerbased' &&
      serviceSelected?.key
    ) {
      getMarkersForServiceKey(serviceSelected?.key).then(async (response) => {
        const selectableMarkers = getLabelValue(response, true);
        setMarkers(selectableMarkers);
      });
    } else if (serviceSelected) {
      getRoutesForService(serviceSelected).then(async (response) => {
        const serviceRoutes = getLabelValue(response);
        setRoutes(serviceRoutes);
      });
    }

    setPropertyDetail(null);
    setSelectedProperties(null);
  }, [selectedService, individualJob]);

  useEffect(() => {
    const serviceFeatures = selectedService?.value?.serviceFeatures;
    if (!edit) {
      setJob({
        ...job,
        noTouch: serviceFeatures?.noTouch ?? false,
      });
    }
  }, [selectedService]);

  useEffect(() => {
    if (services?.length === 1 && open) {
      handleServiceChange(services[0]);
    }
  }, [services, open]);

  const getRoutesOfAJob = async (routesKeys) => {
    const response = routesKeys?.map((routeKey) => {
      const r = getRoutesFromDatabase(routeKey);
      return r;
    });

    const arrayOfNames = await Promise.all(response ?? []);
    return arrayOfNames;
  };

  const getCrewsOfAJob = (providerUids) => {
    const setOfProviderUids = new Set(providerUids);
    const selectedCrews = crew?.reduce((acc, crewDetails) => {
      if (!setOfProviderUids?.has(crewDetails.value)) {
        return acc;
      }
      return [...acc, crewDetails];
    }, []);

    return selectedCrews;
  };

  async function getMarkersOfAJob(markerKeys = []) {
    const response = markerKeys?.map((key) => {
      const marker = getServicePointById(key);
      return marker;
    });

    const arrayOfNames = await Promise.all(response);
    return arrayOfNames;
  }

  useEffect(() => {
    if (isPeriodic) {
      const isEmpty = isPeriodicTimeEmpty();
      if (isEmpty) {
        return;
      }
      const cronExpression = generateCronExpression(
        periodicData.minutes,
        periodicData.hours,
        periodicData.weekDays
      );
      const expression = cronstrue.toString(cronExpression);
      setCronExp(expression);
    }
  }, [periodicData]);

  useEffect(() => {
    let isSubscribe = true;
    const getServiceOfAJob = () => {
      let serviceKey = '';
      if (isPeriodic) serviceKey = individualJob?.job?.serviceKey;
      else serviceKey = individualJob?.serviceKey;

      const service = services?.filter(
        (serviceOpt) => serviceKey === serviceOpt?.value?.key
      );

      if (service?.length > 0) {
        setServiceOption(service[0]);
        setSelectedService(service[0]);
      }
    };

    if (edit && isPeriodic) {
      setDate(moment(individualJob?.job?.dateToEndJob));

      setJob(() => {
        return individualJob;
      });

      const hrs = individualJob?.cronConfiguration?.hours?.map((h) => {
        return {
          value: h,
          label: h,
        };
      });

      const mins = individualJob?.cronConfiguration?.minutes?.map((m) => {
        return {
          value: m,
          label: m,
        };
      });

      const jobDays = new Set(individualJob?.cronConfiguration?.days);

      const dys = weekDaysData?.filter((day) => {
        return jobDays?.has(day?.value);
      });

      setPeriodicData((prevData) => {
        return {
          ...prevData,
          hours: individualJob?.cronConfiguration?.hours,
          weekDays: individualJob?.cronConfiguration?.days,
          minutes: individualJob?.cronConfiguration?.minutes,
          jobhoursData: hrs,
          jobminutesData: mins,
          jobWeekDaysData: dys,
        };
      });

      if (individualJob?.job?.serviceType === 'markerbased') {
        getMarkersOfAJob(individualJob?.job?.allMarkers).then(
          (markersDetails) => {
            const markersNames = markersDetails?.map((marker) => marker.name);
            if (isSubscribe) {
              const markersSelected = getLabelValue(markersDetails);
              setSelectedMarkers(markersSelected);
              setNames(markersNames);
            }
          }
        );
      } else if (individualJob?.job?.serviceType === 'outdoor') {
        getRoutesOfAJob(individualJob?.job?.allRoutes).then((routesNames) => {
          if (isSubscribe) setNames(routesNames);
        });
      }

      const filteredSelectedCrews = getCrewsOfAJob(
        individualJob?.job?.providerUids
      );

      setSelectedCrew(filteredSelectedCrews);
    } else if (edit && !isPeriodic) {
      setDate(moment(individualJob.dateToEndJob));

      setJob(() => {
        return individualJob;
      });

      if (individualJob?.serviceType === 'markerbased') {
        getMarkersOfAJob(individualJob?.allMarkers).then((markersDetails) => {
          const markersNames = markersDetails?.map((marker) => marker.name);
          if (isSubscribe) {
            const labels = getLabelValue(markersDetails);
            setSelectedMarkers(labels);
            setNames(markersNames);
          }
        });
      } else if (individualJob?.serviceType === 'outdoor') {
        getRoutesOfAJob(individualJob?.allRoutes).then((routesNames) => {
          if (isSubscribe) setNames(routesNames);
        });
      }

      const filteredSelectedCrews = getCrewsOfAJob(individualJob.providerUids);

      setSelectedCrew(filteredSelectedCrews);
    } else {
      setJob({ ...job, name: '' });
      setNames([]);
    }

    getServiceOfAJob();

    return () => {
      isSubscribe = false;
    };
  }, [edit, individualJob]);

  useEffect(() => {
    const service = selectedService?.value;
    const selectedServiceKey = service?.key;
    const serviceKey =
      individualJob?.job?.serviceKey ?? individualJob?.serviceKey;

    if (edit && serviceKey === selectedServiceKey) {
      const propertyKeys =
        individualJob?.job?.propertyKeys ?? individualJob?.propertyKeys;
      const setOfPropertyKeys = new Set(propertyKeys ?? []);

      const propertiesThatsSelected = propertyDetail?.filter((property) => {
        return setOfPropertyKeys.has(property.value);
      });

      setSelectedProperties(propertiesThatsSelected);
      setSelectedProperty(propertyKeys);
    }
  }, [edit, propertyDetail]);

  useEffect(() => {
    if (edit) {
      const jobIndividual = isPeriodic ? individualJob?.job : individualJob;
      const shift = shifts?.find(
        (eachShift) => jobIndividual?.shiftKey === eachShift.value?.id
      );

      if (shift) {
        setSelectedShift(shift);
      } else {
        setSelectedShift(null);
      }
    }
  }, [edit, shifts, individualJob]);

  useEffect(() => {
    if (edit) {
      setNoTouchJob(
        individualJob?.job?.noTouch ?? individualJob?.noTouch ?? false
      );
    }
  }, [edit]);

  useEffect(() => {
    let isSubscribe = true;
    if (selectedService && selectedService?.value?.type !== 'markerbased') {
      getRoutesForService(selectedService.value).then((response) => {
        const serviceRoutes = Object.values(response).map((route) => {
          return { value: route.key, label: route.name };
        });
        if (isSubscribe) setRoutes(serviceRoutes);
      });
    }

    return () => {
      isSubscribe = false;
    };
  }, [serviceOption, selectedService]);

  useEffect(() => {
    let isSubscribe = true;

    getCrewsForJobs().then((crewMembers) => {
      if (isSubscribe) setCrew(crewMembers);
    });

    return () => {
      isSubscribe = false;
    };
  }, []);

  useEffect(() => {
    const getAllMarkersOfSelectedProperties = async (
      propertiesThatAreSelected,
      service
    ) => {
      const markersPromises = propertiesThatAreSelected?.map((propertyKey) => {
        return getMarkersByPropertyKey(propertyKey, service.type, service.key);
      });

      const res = await Promise.all(markersPromises ?? []);

      const markersRes = res?.flat();

      const markersListForDropDown = getLabelValue(markersRes, true);
      return markersListForDropDown;
    };
    if (selectedProperty?.length && selectedService) {
      const service = selectedService?.value;

      if (service?.serviceFeatures?.multipleProperties) {
        getAllMarkersOfSelectedProperties(selectedProperty, service).then(
          (res) => {
            setMarkers(res);
          }
        );
      } else {
        getMarkersByPropertyKey(
          selectedProperty,
          service.type,
          service.key
        ).then((response) => {
          const markersList = getLabelValue(response, true);
          setMarkers(markersList);
        });
      }
    } else if (
      !selectedProperty &&
      selectedService?.value.serviceFeatures.properties
    ) {
      setSelectedMarkers(null);
      setMarkers([]);
      setJob({ ...job, allMarkers: [] });
    } else if (!selectedProperty?.length) {
      setMarkers([]);
    }
  }, [selectedProperty]);

  useEffect(() => {
    if (
      !selectedService?.value?.serviceFeatures.multipleProperties &&
      markerRef.current
    ) {
      markerRef.current.focus();
    }
  }, [markerRef, isPropertyChanged]);

  const handleDateChange = (d) => {
    setDate(d);
    setJob({ ...job, dateToEndJob: parseInt(moment(d._d).format('x'), 10) });
  };

  async function setRoutesOrMarkers(data, selectedOptions, propertyKeys) {
    setIsPropertyChanged(false);
    const { serviceFeatures } = selectedService.value;
    let removedMarker = '';
    if (selectedService.value.type === 'markerbased') {
      const markersSelected = selectedOptions?.length ? selectedOptions : null;
      setSelectedMarkers(markersSelected);
      if (
        data?.length &&
        (!allMarkers?.[data[data.length - 1]]?.longitude ||
          !allMarkers?.[data[data.length - 1]]?.latitude) &&
        noTouchJob
      ) {
        setIsConfigureJobOpen(true);
      } else if (
        data.length > (job?.allMarkers?.length ?? 0) &&
        (serviceFeatures?.activitiesOnJob || serviceFeatures?.enableJobNotes)
      ) {
        setSelectedLastMarker(data[data.length - 1]);
        setOpenActivitiesModal(true);
      } else if (data.length < job?.allMarkers?.length) {
        const markerSet = new Set(data);
        const removedMarkers = job?.allMarkers?.filter(
          (markerKey) => !markerSet?.has(markerKey)
        );

        removedMarker = removedMarkers[0] ?? '';
      }

      let updatedJobData = {
        allMarkers: data,
      };
      if (removedMarker) {
        updatedJobData = {
          ...updatedJobData,
          activities: { ...job?.activities, [removedMarker]: [] },
          notes: { ...job?.notes, [removedMarker]: null },
        };
      }

      if (propertyKeys) {
        setJob({ ...job, ...updatedJobData, propertyKeys });
      } else {
        setJob({ ...job, ...updatedJobData });
      }
    } else {
      const orderKeys = await getWorkOrderFromRoutes(data);
      setJob({ ...job, allRoutes: data ?? [], orderKeys });
    }
  }

  async function handlePropertyChange(data) {
    const serv = selectedService?.value;
    const { serviceFeatures } = serv ?? {};
    setIsPropertyChanged(!isPropertyChanged);
    if (serviceFeatures.multipleProperties) {
      const propertyKeys =
        data?.map((propertyLabVal) => propertyLabVal.value) ?? null;

      if (propertyKeys?.length < selectedProperty?.length) {
        const setOfPropertyKeys = new Set(propertyKeys ?? []);

        const newMarkersPromises = selectedMarkers?.map((sm) => {
          return getServicePointById(sm.value);
        });

        const newMarkersValues = await Promise.all(newMarkersPromises ?? []);

        const markersPropertiesKeyValue = newMarkersValues?.reduce((a, c) => {
          return { ...a, [c.key]: c.propertyKey };
        }, {});

        const newMarkersSelected = selectedMarkers?.filter((sm) => {
          return setOfPropertyKeys.has(markersPropertiesKeyValue[sm.value]);
        });

        const markerKeys = newMarkersSelected?.map((m) => m.value) ?? [];
        await setRoutesOrMarkers(markerKeys, newMarkersSelected, propertyKeys);
      } else {
        setJob({ ...job, propertyKeys });
      }
      setSelectedProperty(propertyKeys);
    } else {
      if (selectedProperty !== data.value) {
        setSelectedMarkers(null);
      }
      const propertyKey = data.value ?? null;
      setSelectedProperty(propertyKey);
      setJob({ ...job, propertyKeys: [propertyKey], allMarkers: [] });
    }
    setSelectedProperties(data);
  }

  function checkPropertyEnabled() {
    return (
      selectedService?.value?.serviceFeatures?.properties && selectedProperty
    );
  }

  function checkIfPropertySelected() {
    return (
      !selectedService?.value?.serviceFeatures?.properties &&
      job?.providerUids?.length > 0
    );
  }

  useEffect(() => {
    if (!noTouchJob) setJob({ ...job, temperatureMonitoring: false });
  }, [noTouchJob]);

  function handleNoTouchChange(event) {
    event.stopPropagation();
    setJob({ ...job, noTouch: event.target?.checked });
    setNoTouchJob(event.target?.checked);
  }

  function handleNoteConfigChange(event) {
    event.stopPropagation();
    setNoteInJob(!noteInJob);
  }

  function handleImgConfigChange(event) {
    event.preventDefault();
    setJob({ ...job, allowImageFromGallery: event.target?.checked });
  }

  const ImageConfiguration = () => {
    if (selectedService?.value?.serviceFeatures?.imageConfiguration) {
      return (
        <SwitchContainer
          control={
            <Switch
              disabled={edit && job?.status !== 'assigned'}
              color="primary"
              checked={job?.allowImageFromGallery}
              onChange={handleImgConfigChange}
            />
          }
          label="Allow image from gallery"
          labelPlacement="start"
        />
      );
    }
    return null;
  };

  function handleTempConfigChange(event) {
    event.preventDefault();
    setJob({ ...job, temperatureMonitoring: event.target.checked });
  }

  function isNoTouchEnabled() {
    return selectedService?.value?.serviceFeatures?.noTouch;
  }

  function isTemperatureMonitoringEnabled() {
    return selectedService?.value?.serviceFeatures?.temperatureMonitoring;
  }

  const TemperatureConfiguration = () => {
    if (isTemperatureMonitoringEnabled() && isNoTouchEnabled() && noTouchJob) {
      return (
        <SwitchContainer
          control={
            <Switch
              disabled={edit && job?.status !== 'assigned'}
              color="primary"
              checked={job?.temperatureMonitoring}
              onChange={handleTempConfigChange}
            />
          }
          label="Monitor Temperature"
          labelPlacement="start"
        />
      );
    }
    return null;
  };

  function handleShiftChange(shiftSelected) {
    setSelectedShift(shiftSelected);
    const shiftKey = shiftSelected?.value?.id ?? null;
    setJob({ ...job, shiftKey });
  }

  function handleActivities() {
    setOpenActivitiesModal(false);
    let activities;
    if (!orderSelected) {
      activities = {
        [selectedLastMarker]: selectedActivities ?? [],
      };
    } else {
      activities = {
        [orderSelected.markerKey]: selectedActivities ?? [],
      };
    }
    setJob({
      ...job,
      activities: { ...(job?.activities ?? {}), ...activities },
      notes: notes ?? null,
    });
  }

  function handleActivityClose() {
    const activities = {
      [selectedLastMarker]: [],
    };
    setJob({
      ...job,
      activities: { ...(job?.activities ?? {}), ...activities },
    });
    setOpenActivitiesModal(false);
    setDateTimeErrorMessage('');
  }

  function handleActivitiesNotes(e) {
    e.stopPropagation();
    if (!orderSelected) {
      setNotes({
        ...notes,
        [selectedLastMarker]: e.target.value,
      });
    } else {
      setNotes({
        [orderSelected?.markerKey]: e.target.value,
      });
    }
  }

  function getEditOrReassign() {
    return 'Edit Job';
  }

  function handleCalendarDate(d, key) {
    const expectedTime = moment(d._d).valueOf();
    setJob({ ...job, [key]: expectedTime });
  }

  const selectJobStartAndEndTime = () => {
    if (!companyDetail) {
      return (
        <Row>
          <CircularProgress size={20} />
        </Row>
      );
    }

    function getMinimumDate() {
      if (!job.expectedStartTime) {
        return undefined;
      }
      return new Date(job.expectedStartTime);
    }

    if (!companyDetail?.markerFeatures?.calendarView) {
      return null;
    }
    return (
      <Col gap="8px">
        <Divider />
        <Span weight="bold">Select Time</Span>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Col gap="16px">
            <DateTimePicker
              size="small"
              label="Start Time"
              inputVariant="outlined"
              value={job.expectedStartTime}
              onChange={(d) => handleCalendarDate(d, 'expectedStartTime')}
              minutesStep={15}
              disabled={job?.status !== 'assigned'}
            />

            <DateTimePicker
              size="small"
              label="End Time"
              inputVariant="outlined"
              value={job.expectedEndTime}
              onChange={(d) => handleCalendarDate(d, 'expectedEndTime')}
              minDate={getMinimumDate()}
              minutesStep={15}
              minDateMessage="End date should not be more than expected start time"
              disabled={job?.status !== 'assigned'}
            />
          </Col>
        </MuiPickersUtilsProvider>
        <Divider />
      </Col>
    );
  };

  function areDatesEmpty() {
    return !individualJob?.startedDate || !individualJob?.endDate;
  }

  function isEndTimeLessThanStartTime() {
    return individualJob?.endDate < individualJob?.startedDate;
  }

  function checkForDisablity() {
    return areDatesEmpty() || isEndTimeLessThanStartTime();
  }

  function checkIfActivitiesEnabled() {
    const { serviceFeatures } = selectedService?.value ?? {};
    return serviceFeatures?.enableJobNotes || serviceFeatures?.activitiesOnJob;
  }

  const handleCrewChange = (crews) => {
    const crewsSelected = crews;
    const crewsUids = getUidsOfSelectedCrews(crewsSelected);
    setSelectedCrew(crewsSelected);
    setJob({ ...job, providerUids: crewsUids ?? [] });
  };

  const getSelectedMarkers = () => {
    if (!selectedMarkers?.length) return null;
    return selectedMarkers;
  };

  const handleConfigureJobCancel = (isCancel) => {
    if (!isCancel) {
      handleClose();
      setNotes(null);
      setJob(defaultJob);
    } else {
      const newMarkers = [...selectedMarkers];
      newMarkers.splice(newMarkers.length - 1, 1);
      setSelectedMarkers(newMarkers);
    }
    setIsConfigureJobOpen(false);
  };

  const handleScheduleClick = () => {
    setIsTimeSelectionOpen(true);
  };

  const handleSelection = (periodicSchedule) => {
    setPeriodicData(periodicSchedule);
    const isEmpty = isPeriodicTimeEmpty(periodicSchedule);
    if (isEmpty) {
      return;
    }
    setIsTimeSelectionOpen(false);
    const cronExpression = generateCronExpression(
      periodicSchedule.minutes,
      periodicSchedule.hours,
      periodicSchedule.weekDays
    );
    const expression = cronstrue.toString(cronExpression);
    setCronExp(expression);
  };

  const handleCancel = () => {
    setIsTimeSelectionOpen(false);
    if (!edit && isPeriodicTimeEmpty()) {
      setPeriodicData(defaultPeriodicTimeSchedule);
      setCronExp('');
    }
  };

  const getHeightOfTheScreenWindow = () => {
    return window.innerHeight;
  };

  const getWidthOfTheScreen = () => {
    return window.innerWidth;
  };

  const handleServiceDistanceChange = (event) => {
    const valueInNumber = parseInt(event.target.value, 10);
    if (selectedJobType === SHIFT_JOBS) {
      setJob({
        ...job,
        job: { ...job.job, minimumServingDistance: valueInNumber },
      });
    } else {
      setJob({ ...job, minimumServingDistance: valueInNumber });
    }
  };

  const handleMinimumServingTime = (event) => {
    const numberifiedValue = parseInt(event.target.value, 10);

    if (selectedJobType === SHIFT_JOBS) {
      setJob({
        ...job,
        job: { ...job.job, minimumServingTime: numberifiedValue },
      });
    } else {
      setJob({ ...job, minimumServingTime: numberifiedValue });
    }
  };

  const handleGeoFencingEnablingChange = (event) => {
    setJob({
      ...job,
      enabledGeofencing: event.target.checked,
    });
  };

  const toggleMinimumServingTime = (event) => {
    setIsMinimumServingTimeEnabled(event.target.checked);
    if (!event.target.checked) {
      if (selectedJobType === SHIFT_JOBS) {
        setJob({ ...job, job: { ...job.job, minimumServingTime: null } });
      } else {
        setJob({ ...job, minimumServingTime: null });
      }
    }
  };

  const enableMinimumServingTime = () => (
    <Col gap="4px">
      <SwitchContainer
        value="Minimum serving time"
        control={
          <Switch color="primary" checked={isMinimumServingTimeEnabled} />
        }
        labelPlacement="start"
        label="Enable minimum serving time"
        onChange={toggleMinimumServingTime}
      />
      {isMinimumServingTimeEnabled && (
        <StyledTextField
          variant="outlined"
          label="Minimum Serving Time"
          InputProps={{
            endAdornment: <InputAdornment>minutes</InputAdornment>,
          }}
          type="number"
          // eslint-disable-next-line react/jsx-no-duplicate-props
          inputProps={{ min: '1', max: '3600' }}
          value={job?.job?.minimumServingTime ?? job?.minimumServingTime}
          onChange={handleMinimumServingTime}
          required
        />
      )}
    </Col>
  );

  return (
    <FormModal
      open={open}
      edit={edit}
      loading={loading}
      anotherEdit={anotherEdit}
      disabled={anotherEdit && checkForDisablity()}
      handlePositiveAction={addEditJob}
      maxWidth={getWidthOfTheScreen() * 0.7}
      handleNegativeAction={() => handleClose()}
      width={`${getWidthOfTheScreen() * 0.6}px`}
      overflow={anotherEdit ? 'visible' : 'auto'}
      title={edit || anotherEdit ? getEditOrReassign() : 'Add Job'}
    >
      {/* {subscribedServices?.periodicJobs && !orderSelected && (
        <FormControlLabel
          control={
            <Checkbox
              disabled={edit}
              checked={isPeriodic}
              onChange={handleChange}
              value="checkedB"
              color="primary"
            />
          }
          label="Make Periodic"
        />
      )} */}
      {/* {isPeriodic ? (
        <>
          <div style={{ marginBottom: '1em' }}>
            <StyledTextField
              fullWidth
              required
              margin="dense"
              placeholder="Periodic Job Name"
              value={job.name}
              onChange={handleFormInputChange}
              name="name"
              variant="outlined"
            />
          </div>

          <Button
            onClick={handleScheduleClick}
            style={{ textTransform: 'none', border: '1px solid #c4c4c4 ' }}
          >
            <Col width="100%">
              <ScheduleTimeContainer>Schedule</ScheduleTimeContainer>
              <ScheduleTimeContainer color={color.grey}>
                {cronExp}
              </ScheduleTimeContainer>
            </Col>
          </Button>
          <input
            style={{
            opacity: 0,
              width: '100%',
              height: 0,
            }}
            required={isPeriodicTimeEmpty()}
            value={isPeriodicTimeEmpty() ? '' : 'value'}
          />
        </>
      ) : (
        <div />
      )} */}
      {anotherEdit ? (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <div>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <div
                style={{ display: 'flex', flexDirection: 'column', gap: 12 }}
              >
                <DateTimePicker
                  label="Start Date"
                  inputVariant="outlined"
                  value={
                    individualJob?.startedDate
                      ? moment(individualJob?.startedDate)
                      : null
                  }
                  onChange={(d) =>
                    setindividualJob({
                      ...individualJob,
                      startedDate: moment(d).valueOf(),
                    })
                  }
                  disableFuture
                />
                <DateTimePicker
                  minDate={individualJob?.startedDate}
                  label="End Date"
                  inputVariant="outlined"
                  value={
                    individualJob?.endDate
                      ? moment(individualJob?.endDate)
                      : null
                  }
                  onChange={(d) =>
                    setindividualJob({
                      ...individualJob,
                      endDate: moment(d).valueOf(),
                    })
                  }
                  disabled={!individualJob?.startedDate}
                  disableFuture
                  error={
                    individualJob?.endDate &&
                    individualJob?.startedDate > individualJob?.endDate
                  }
                />
                {!dateTimeErrorMessage ? null : (
                  <p style={{ color: 'red', marginTop: '-12px 0 -10px -6px' }}>
                    {dateTimeErrorMessage}
                  </p>
                )}
              </div>
            </MuiPickersUtilsProvider>
          </div>

          <CustomSelect
            title="Status"
            options={status}
            onChange={(data) => handleStatus(data)}
            required={!edit}
            value={{ label: getLabel(individualJob.status) } ?? null}
            placeholder="Select"
          />
        </div>
      ) : (
        <>
          <div style={{ marginTop: '1em', marginBottom: 10 }}>
            {services.length !== 1 && !serviceOfOrder && (
              <CustomSelect
                title="Services"
                options={services}
                onChange={handleServiceChange}
                required={!edit}
                value={selectedService ?? null}
                placeholder="Select"
              />
            )}
          </div>
          <StyledTextField
            fullWidth
            required
            margin="dense"
            placeholder="Job name"
            value={job.job?.name || job.name}
            onChange={handleFormInputChange}
            name="name"
            variant="outlined"
          />
          {selectJobStartAndEndTime()}
          {selectedService?.value &&
            selectedService?.value?.type === 'attendance' && (
              <SingleDatePicker
                date={date}
                onDateChange={(d) => handleDateChange(d)}
                focused={focus}
                onFocusChange={(f) => setFocus(f.focused)}
                required
              />
            )}
          {selectedService?.value?.serviceFeatures?.noTouch && (
            <SwitchContainer
              label="No Touch Job"
              labelPlacement="start"
              control={
                <Switch
                  disabled={edit && !isPeriodic}
                  color="primary"
                  checked={noTouchJob}
                  onChange={handleNoTouchChange}
                />
              }
            />
          )}
          {Object.keys(selectedService ?? {}).length ? (
            <>
              <div style={{ marginTop: '1em' }}>
                <CustomSelect
                  title="Crew"
                  autoFocus={edit && !isPeriodic && job?.status !== 'assigned'}
                  value={selectedCrew ?? null}
                  isMulti={
                    selectedService?.value?.serviceFeatures?.multipleCrew
                  }
                  name="crew"
                  options={crew}
                  onChange={handleCrewChange}
                  required={!job?.providerUids || job?.providerUids?.length < 1}
                  maxMenuHeight={getHeightOfTheScreenWindow() * 0.3}
                  placeholder={
                    edit
                      ? crewsNames?.map((name) => name).join()
                      : 'Select crew members'
                  }
                />
              </div>
              <div style={{ marginTop: 14 }}>
                <>
                  {selectedService?.value?.serviceFeatures?.properties &&
                    !markerSelected?.propertyKey && (
                      <>
                        {propertyDetail ? (
                          <CustomSelect
                            title="Property"
                            defaultValue={[]}
                            placeholder="Select Property"
                            required={!selectedProperties}
                            options={propertyDetail}
                            onChange={handlePropertyChange}
                            value={selectedProperties ?? null}
                            isMulti={
                              selectedService?.value?.serviceFeatures
                                ?.multipleProperties
                            }
                            maxMenuHeight={getHeightOfTheScreenWindow() * 0.3}
                          />
                        ) : (
                          <Row justify="center">
                            <CircularProgress />
                          </Row>
                        )}
                      </>
                    )}

                  <StyledContainer>
                    {(checkPropertyEnabled() || checkIfPropertySelected()) &&
                      !serviceOfOrder && (
                        <>
                          {selectedService.value?.type === 'markerbased' ? (
                            <List
                              placeholder="Select Markers"
                              selectedService={selectedService}
                              options={markers}
                              setRoutesOrMarkers={setRoutesOrMarkers}
                              edit={edit}
                              markersSelected
                              value={getSelectedMarkers()}
                              ref={markerRef}
                              openMenuOnFocus={
                                !selectedService?.value?.serviceFeatures
                                  .multipleProperties
                              }
                            />
                          ) : (
                            <List
                              selectedService={selectedService}
                              edit={edit}
                              names={names}
                              options={routes}
                              setRoutesOrMarkers={setRoutesOrMarkers}
                            />
                          )}
                        </>
                      )}
                  </StyledContainer>
                </>
              </div>

              {checkIfActivitiesEnabled() && markerSelected && (
                <SwitchContainer
                  label="Configure Activities"
                  labelPlacement="start"
                  control={
                    <Switch
                      color="primary"
                      checked={noteInJob}
                      onChange={handleNoteConfigChange}
                    />
                  }
                />
              )}
              {selectedService?.value?.serviceFeatures?.shiftManagement && (
                <>
                  {!shifts && (
                    <Row justify="center">
                      <CircularProgress />
                    </Row>
                  )}
                  {shifts?.length > 0 && selectedJobType === SHIFT_JOBS && (
                    <div style={{ marginTop: 8 }}>
                      <CustomSelect
                        title="Shifts"
                        isClearable
                        name="shifts"
                        options={shifts}
                        placeholder="Select Shift"
                        onChange={handleShiftChange}
                        value={selectedShift}
                        maxMenuHeight={getHeightOfTheScreenWindow() * 0.2}
                        required
                      />
                    </div>
                  )}
                </>
              )}

              <TemperatureConfiguration />
              <ImageConfiguration />

              {enableMinimumServingTime()}

              {selectedService?.value?.serviceFeatures?.properties &&
                selectedService?.value?.serviceFeatures?.jobLevelGeofence && (
                  <SwitchContainer
                    value="Enable Job Geofencing"
                    control={
                      <Switch
                        color="primary"
                        checked={
                          job?.enabledGeofencing ?? job?.job?.enabledGeofencing
                        }
                      />
                    }
                    labelPlacement="start"
                    label="Enable Job Geofencing"
                    onChange={handleGeoFencingEnablingChange}
                  />
                )}

              {(job?.job?.enabledGeofencing || job?.enabledGeofencing) && (
                <Col style={{ marginTop: 8 }}>
                  <StyledTextField
                    variant="outlined"
                    label="Allow Start Job At"
                    InputProps={{
                      endAdornment: <InputAdornment>m</InputAdornment>,
                    }}
                    type="number"
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    inputProps={{ min: '50' }}
                    value={
                      job?.job?.minimumServingDistance ??
                      job?.minimumServingDistance
                    }
                    onChange={handleServiceDistanceChange}
                  />
                  <Span color="grey" size="12px">
                    Minimum distance the crew should be to present near the
                    property to start the job. We recommend to have 80 meters
                    however if the property is larger in size, consider
                    increasing it to a higher value.
                  </Span>
                </Col>
              )}
            </>
          ) : (
            <div />
          )}
          <ADialog
            $minHeight="auto"
            $maxHeight="75%"
            justifycontent="space-evenly"
            padding="24px"
            open={openActivitiesModal}
            onClose={handleActivityClose}
            scroll="body"
            overflow="auto"
          >
            <Col>
              <ActivitiesSelection
                handleSelectedActivities={(activities) => {
                  setSelectedActivities(activities);
                }}
                serviceFeatures={selectedService?.value?.serviceFeatures}
                atJob
              />

              {selectedService?.value?.serviceFeatures?.enableJobNotes && (
                <StyledTextField
                  placeholder="Notes"
                  variant="outlined"
                  color="primary"
                  onChange={handleActivitiesNotes}
                  margintop="12px"
                  defaultValue={orderSelected && orderSelected?.requestNote}
                />
              )}

              <Row justify="flex-end" style={{ marginTop: 8 }}>
                <Button
                  color="primary"
                  onClick={handleActivityClose}
                  style={{ textTransform: 'none' }}
                >
                  Cancel
                </Button>
                <PrimayButton onClick={handleActivities}>Submit</PrimayButton>
              </Row>
            </Col>
          </ADialog>
          <ADialog open={isConfigureJobOpen} maxWidth="500px">
            <JobConfiguration
              handleCancel={handleConfigureJobCancel}
              isOpen={isConfigureJobOpen}
            />
          </ADialog>
          {isPeriodic && (
            <ADialog open={isTimeSelectionOpen} $minWidth="500px">
              <PeriodicJobTimeSelection
                edit={edit}
                hoursData={hoursData}
                minutesData={minutesData}
                periodicSchedule={periodicData}
                weekDaysData={weekDaysData}
                handleSelection={handleSelection}
                handleCancel={handleCancel}
              />
            </ADialog>
          )}
        </>
      )}
    </FormModal>
  );
}

const StyledContainer = styled.div`
  margin-top: 8px;
`;

export const SwitchContainer = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    font-size: 16px;
    font-weight: bold;
  }
  && {
    justify-content: space-between;
  }
`;

const ScheduleTimeContainer = styled(Span)`
  && {
    text-align: left;
  }
`;
