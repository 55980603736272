import React, { useContext } from 'react';

import { Redirect, Route } from 'react-router';

import Vendors from './Vendors';
import Loader from '../../Generics/snowm_loader';
import { ServiceTypesContext } from '../../../contexts/service_types';

export default function () {
  const data = useContext(ServiceTypesContext);

  const { subscribedServices } = data;

  if (!subscribedServices) {
    return <Loader />;
  }

  if (!subscribedServices?.enableOrganizationCrew) {
    return <Redirect to="/home" />;
  }

  return <Route path="/home/vendors" exact component={Vendors} />;
}
