import React, { useContext } from 'react';

import styled from 'styled-components';
import { CardContent } from '@material-ui/core';

import Span from '../../Common/Span';
import { getRelativeDate } from '../../../helpers/date';
import ListTitleItem from '../../Generics/list_title_item';
import { AuthContext } from '../../../contexts/auth_context';
import { LocalizationContext } from '../../../contexts/localization_context';
import color from '../../../utils/color';

const ProviderBasicDetailCard = ({ providerDetail }) => {
  const contextOfAuth = useContext(AuthContext);

  const { company } = contextOfAuth;

  const { strings } = useContext(LocalizationContext);
  const { EMAIL } = strings?.inputLabel;

  const { markerFeatures } = company;
  const { createCrewIdLogin } = markerFeatures;

  const renderLoginStatus = () => {
    if (providerDetail?.loggedIn) {
      return 'Yes';
    }
    return 'No';
  };

  const renderCrewIdDetails = () => {
    if (!createCrewIdLogin) {
      return null;
    }
    return <ListTitleItem title="Crew Id" content={providerDetail?.crewId} />;
  };

  return (
    <StyledCardContent>
      {providerDetail?.disabled && (
        <Span weight="bold" color={color.red}>
          Disabled
        </Span>
      )}
      <ListTitleItem title="Name" content={providerDetail?.name} />
      <ListTitleItem
        title="Type"
        content={providerDetail?.crewType ?? 'Individual'}
      />
      <ListTitleItem title="Phone" content={providerDetail?.phoneNumber} />
      <ListTitleItem title={EMAIL} content={providerDetail?.email} />

      {renderCrewIdDetails()}

      <ListTitleItem title="Logged In" content={renderLoginStatus()} />
      {providerDetail?.loggedIn && (
        <ListTitleItem
          title="Last Log In"
          content={getRelativeDate(providerDetail?.lastSignIn)}
        />
      )}
    </StyledCardContent>
  );
};

export default ProviderBasicDetailCard;

const StyledCardContent = styled(CardContent)`
  &&&& {
    flex: 1;
    display: flex;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
`;
