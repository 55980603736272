import React from 'react';

import { Switch, Route } from 'react-router-dom';

import Activities from './Activities';

export default function() {
  return (
    <Switch>
      <Route path="/home/activities" component={Activities} />
    </Switch>
  );
}
