/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: BeaconM/index.jsx
 *
 *  Description: Index file that contains routes for becaonms
 *
 *  Author: Roshan Gautam (roshan@brainants.com)
 *
 *  Date created: 10-july-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */

import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import BeaconMs from './snowm_beaconms';

export default function() {
  return (
    <Switch>
      <Route path="/home/devices" component={BeaconMs} />
      <Redirect to="/home/devices" />
    </Switch>
  );
}
