/* eslint-disable react/jsx-wrap-multilines */
/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: snowm_add_service_points.jsx
 *
 *  Description: Add Service Points
 *
 *  Author: Roshan Gautam (roshan@brainants.com)
 *
 *  Date created: 8-july-2019
 *
 *
 *********************************************************************************
 */

import React, { useEffect, useState, useContext } from 'react';

import moment from 'moment';
import QRCode from 'qrcode.react';
import styled from 'styled-components';
import MomentUtils from '@date-io/moment';
import { useForm } from 'react-hook-form';
import CloseIcon from 'mdi-react/CloseIcon';
import CameraIcon from 'mdi-react/CameraIcon';
import { RHFInput } from 'react-hook-form-input';
import CheckBoxIcon from 'mdi-react/CheckBoxOutlineIcon';
import CheckBoxOutlineBlankIcon from 'mdi-react/CheckBoxOutlineBlankIcon';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
  Button,
  Card,
  Checkbox,
  CircularProgress,
  DialogActions,
  FormControlLabel,
  IconButton,
  MenuItem,
  Typography,
  Switch,
  Collapse,
  DialogTitle,
  InputAdornment,
  DialogContent,
} from '@material-ui/core';

import Span from '../../Common/Span';
import BeaconItem from './beacon_item';
import color from '../../../utils/color';
import UploadPic from '../../Common/upload_pic';
import { StyledFormControlLabel } from '../styled';
import PrimaryButton from '../../Common/primary_button';
import InputTextField from '../../Common/CustomTextField';
import ListTitleItem from '../../Generics/list_title_item';
import Modal, { useModal } from '../../Generics/snowm_modal';
import { LocationModal } from '../../Generics/location_modal';
import ActivitiesSelection from '../../Common/ActivitiesSelection';
import DeviceIcon from '../../../assets/images/marker_icon_web.png';
import { ServiceTypesContext } from '../../../contexts/service_types';
import { Col, FormContainer, Padding, Row } from '../../../styles/snowm_styled';
import ADialog, {
  LowerCaseButton,
  StyledTextField,
  InvisibleMenuItem,
} from '../../Common/styled';
import {
  uploadImageToStorage,
  addEditNewServicePoint,
  getRealTimeUnAssignedBeacons,
  getPropertiesFromFirestore,
  getServices,
  getBeaconByBeaconId,
  getCompanyDetail,
  getDataByName,
} from '../../../controllers/snowm_firebase';

export default function AddServicePoint({ edit, marker, handleCloseDialog }) {
  const sameMarkerErrorMessage = 'Marker of this name is already assigned.';
  const types = useContext(ServiceTypesContext);
  const subscribedServices = types?.subscribedServices ?? {};

  const { register, handleSubmit, watch, setValue } = useForm();
  const errorMessages = {
    nameError: '',
    deviceError: '',
    locationError: '',
    serviceTypeError: '',
    propertyError: '',
  };

  const initialAssetValues = {
    name: '',
    model: '',
    price: null,
    purchase_date: null,
    sn: '',
    warranty_valid: false,
    validUntil: null,
  };

  const [services, setServices] = useState();
  const [beaconMs, setBeaconMs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [properties, setProperties] = useState();
  const [markerTypes, setMarkerTypes] = useState();
  const [serialNumber, setSerialNumber] = useState();
  const [messages, setMessages] = useState(errorMessages);
  const [serviceFeatures, setServiceFeatures] = useState();
  const [finalCustomData, setFinalCustomData] = useState();
  const [selectedProperty, setSelectedProperty] = useState();
  const [customerDetails, setCustomerDetails] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [customData, setCustomData] = useState(initialAssetValues);
  const [selectedActivities, setSelectedActivities] = useState(null);
  const [openAssetSelection, setOpenAssetSelection] = useState(false);
  const [markerNameCount, setMarkerNameCount] = useState(
    marker.name?.length || 0
  );

  // const autoFocusRef = useRef(null);

  const photoInputRef = React.useRef(null);

  const beaconMModal = useModal();
  const locationModal = useModal();

  function getDefaultServiceType() {
    if (services?.length > 1) return 'none';
    return services ? services[0] : 'none';
  }

  const watchImage = watch('image');
  const watchBeaconId = watch('beaconId', marker?.beaconId);
  const watchService = watch('service', getDefaultServiceType());
  const watchBeaconName = watch('beaconName', marker?.beaconName);
  const watchMarkerType = watch('markerType', marker?.markerType ?? 'Area');
  const watchDeviceType = watch('deviceType', marker?.deviceType ?? 'SENSOR');

  const registerValues = (values) => {
    values.forEach((value) => {
      register({ name: value });
    });
  };

  useEffect(() => {
    registerValues([
      'address',
      'longitude',
      'latitude',
      'key',
      'beaconId',
      'image',
      'serviceType',
      'beaconName',
      'markerType',
      'deviceType',
    ]);
  }, []);

  // useEffect(() => {
  //   if (autoFocusRef.current) {
  //     console.log(autoFocusRef);
  //     autoFocusRef.current.focus();
  //   }
  // }, [autoFocusRef]);

  const autoFocusRef = (input) => {
    if (input) {
      setTimeout(() => {
        input.focus();
      }, 100);
    }
  };
  useEffect(() => {
    setValue('address', marker?.address || '');
    setValue('longitude', marker?.longitude || '');
    setValue('latitude', marker?.latitude || '');
    setValue('key', marker?.key ?? null);
    setValue('beaconId', marker?.beaconId ?? '');
    setValue('beaconName', marker?.beaconName ?? '');
    setValue('markerType', marker?.markerType ?? 'Area');

    setSerialNumber(marker?.serialNumber ?? '');

    setSelectedCategory(marker?.activityType ?? 'none');

    if (marker?.customerDetail) {
      setChecked(true);
      setCustomerDetails({ ...marker?.customerDetail });
    }

    setSelectedActivities(marker?.activities ?? null);

    setCustomData(marker?.customData ?? initialAssetValues);
  }, [marker]);

  useEffect(() => {
    if (!marker.beaconName && marker?.beaconId) {
      getBeaconByBeaconId(marker?.beaconId).then((res) => {
        setValue('beaconName', res?.name ?? null);
        setSerialNumber(res?.serialNumber ?? null);
      });
    }
  }, [marker]);

  useEffect(() => {
    getCompanyDetail().then((res) => {
      const { markerFeatures } = res;

      setMarkerTypes(markerFeatures?.markerTypes);
    });
  }, []);

  useEffect(() => {
    if (marker?.propertyKey) {
      const propertyDetails = properties?.filter(
        (property) => property.id === marker.propertyKey
      );

      setSelectedProperty(propertyDetails ? propertyDetails[0] : null);
    }
  }, [properties, marker]);

  useEffect(() => {
    let isSubscribe = true;
    getServices().then((res) => {
      const servicesFromFirestore = res.map((d) => d.data()) ?? [];
      if (isSubscribe) setServices(servicesFromFirestore);
    });
    return () => {
      isSubscribe = false;
    };
  }, []);

  useEffect(() => {
    const selectedService = services?.filter(
      (service) => service.key === marker?.serviceKey
    );

    const value = selectedService && selectedService[0];
    setValue('service', value ?? 'none');
  }, [services, marker]);

  useEffect(() => {
    setValue('service', watchService);
    setServiceFeatures(watchService.serviceFeatures);
  }, [watchService]);

  function getActivityType() {
    if (!serviceFeatures?.activities || serviceFeatures?.activitiesOnJob) {
      return null;
    }
    if (edit && selectedCategory === 'none') {
      return marker?.activityType;
    }
    if (selectedCategory === 'none') return null;
    return selectedCategory;
  }

  function getPropertyKey() {
    if (serviceFeatures?.properties) return selectedProperty?.id ?? null;
    return null;
  }

  function getActivities() {
    const { activities, activitiesOnJob } = serviceFeatures;
    if (activities && !activitiesOnJob) {
      return selectedActivities ?? [];
    }
    return [];
  }

  function checkForCustomerLogin() {
    if (watchService !== 'none') {
      return (
        !serviceFeatures?.properties && subscribedServices?.endCustomerLogin
      );
    }
    return false;
  }

  function getCustomerDetail() {
    if (checkForCustomerLogin()) return customerDetails ?? null;
    return null;
  }

  function getCustomData() {
    if (watchMarkerType !== 'Asset') {
      return null;
    }
    return finalCustomData ?? null;
  }

  function getServiceType(data) {
    return data.service?.type?.toLowerCase() ?? watchService?.type;
  }

  function getServiceKey(data) {
    return data.service?.key ?? watchService?.key;
  }

  const checkIfPropertyIsEnabled = () => {
    return !!watchService.serviceFeatures?.properties;
  };

  async function onSubmit(formData) {
    const data = { ...formData };
    let writeToFirestore = false;

    setLoading(true);
    setMessages(errorMessages);

    const trimmedName = data.name.trim();

    const markerWithSameName = await getDataByName(
      'servicePoints',
      trimmedName
    );

    if (
      markerWithSameName &&
      (!edit || (edit && markerWithSameName?.key !== marker?.key))
    ) {
      setMessages((prevMessages) => ({
        ...prevMessages,
        nameError: sameMarkerErrorMessage,
      }));
      writeToFirestore = false;
      setLoading(false);
      return;
    }

    if (watchService === 'none') {
      setMessages((prevMessages) => ({
        ...prevMessages,
        serviceTypeError: 'Service Type should be specified.',
      }));
      setLoading(false);
    } else if (
      !!watchService.serviceFeatures?.properties &&
      !edit &&
      !selectedProperty
    ) {
      setMessages((prevData) => ({
        ...prevData,
        propertyError: 'Property is a required field.',
      }));
      setLoading(false);
    } else if (!watchBeaconId) {
      setMessages((prevMessages) => ({
        ...prevMessages,
        deviceError: 'Please specify device.',
      }));
      setLoading(false);
    } else {
      writeToFirestore = true;
    }

    if (writeToFirestore) {
      let profileImageUrl = '';
      if (edit) {
        profileImageUrl = marker.imageUrl;
      }
      if (watchImage) {
        profileImageUrl = await uploadImageToStorage(
          watchImage,
          watchImage.name
        );
      }

      const spData = {
        ...data,
        serviceType: getServiceType(data),
        serviceKey: getServiceKey(data),
        longitude: null,
        latitude: null,
        address: checkIfPropertyIsEnabled()
          ? selectedProperty?.address ?? null
          : null,
        imageUrl: profileImageUrl,
        assigned: false,
        lastServed: null,
        serialNumber,
      };

      setMessages(errorMessages);
      try {
        delete spData.image;
        delete spData.service;

        spData.activities = getActivities();
        spData.activityType = getActivityType();
        spData.propertyKey = getPropertyKey();
        spData.customerDetail = getCustomerDetail();
        spData.customData = getCustomData();

        const nameExists = await addEditNewServicePoint(spData);
        if (nameExists) {
          setMessages((prevMessages) => ({
            ...prevMessages,
            nameError: 'Marker of this name is already assigned.',
          }));
        } else {
          handleCloseDialog();
        }
        // eslint-disable-next-line no-alert

        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    }
  }

  const onLocationChange = (loc, address) => {
    setValue('address', address);
    setValue('longitude', loc.lng);
    setValue('latitude', loc.lat);

    locationModal.close();
  };

  useEffect(() => {
    const unsubscribeRealTimeBeacons = getRealTimeUnAssignedBeacons(
      (beacons) => {
        setBeaconMs(beacons);
      }
    );
    return () => {
      unsubscribeRealTimeBeacons();
    };
  }, []);

  useEffect(() => {
    let isSubscribe = true;
    function _updateProperties(response = []) {
      if (isSubscribe) setProperties(response);
    }

    if (serviceFeatures?.properties) {
      getPropertiesFromFirestore(_updateProperties);
    }

    return () => {
      isSubscribe = false;
    };
  }, [serviceFeatures]);

  function openBrowseWindow() {
    photoInputRef.current.click();
  }

  function handleBeaconSelection(beaconM) {
    setValue('beaconId', beaconM?.uuid);
    setValue('deviceType', beaconM?.type ?? 'SENSOR');
    setValue('beaconName', beaconM?.name);
    setSerialNumber(beaconM?.serialNumber?.toString() ?? '');
    beaconMModal.close();
  }

  const BeaconMModal = () => {
    return (
      <Modal {...beaconMModal.props}>
        <ModalCard>
          <BeaconContainer align="center" justify="center">
            <StyledRow justify="space-between">
              <Span weight="bold" size="16px">
                Add Device
              </Span>
              <IconButton onClick={() => beaconMModal.close()}>
                <CloseIcon />
              </IconButton>
            </StyledRow>
            {beaconMs.length > 0 ? (
              beaconMs.map((beaconM) => (
                <BeaconItem
                  key={beaconM.uuid}
                  beacon={beaconM}
                  onSelectBeacon={() => handleBeaconSelection(beaconM)}
                />
              ))
            ) : (
              <Span> No devices available</Span>
            )}
          </BeaconContainer>
        </ModalCard>
      </Modal>
    );
  };

  function handleSwitchChange() {
    setChecked(!checked);
  }

  function handleEmailChange(event) {
    event.persist();

    setCustomerDetails(() => {
      return {
        ...customerDetails,
        email: event.target.value,
      };
    });
  }

  function handleNameChange(event) {
    event.persist();

    setCustomerDetails(() => {
      return {
        ...customerDetails,
        name: event.target.value,
      };
    });
  }

  function handlePropertiesChange(event) {
    setSelectedProperty(event.target.value);

    setMessages((prevData) => ({
      ...prevData,
      propertyError: '',
    }));
  }

  function handleSelectedActivities(activitiesSelected, categorySelected) {
    setSelectedActivities(activitiesSelected ?? []);
    setSelectedCategory(categorySelected ?? 'none');
  }

  function handleAddAssetBtn() {
    setCustomData({ ...(finalCustomData ?? initialAssetValues) });
    setOpenAssetSelection(true);
  }

  const markerTypeSelection = () => {
    if (
      !markerTypes ||
      (markerTypes?.length === 1 && markerTypes?.includes('Area'))
    ) {
      return null;
    }

    return (
      <>
        <RHFInput
          as={<StyledTextField rightmargin="8px" select />}
          required
          fullWidth
          variant="outlined"
          label="Marker Type"
          name="markerType"
          register={register}
          setValue={setValue}
          defaultValue={watchMarkerType}
        >
          {markerTypes?.map((type) => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </RHFInput>
        {watchMarkerType === 'Asset' && (
          <Col width="100%">
            {finalCustomData && (
              <>
                <ListTitleItem
                  title="Serial Number"
                  content={finalCustomData.sn ?? '-'}
                  size="14px"
                />
                <ListTitleItem
                  title="Model"
                  content={finalCustomData.model ?? '-'}
                  size="14px"
                />
                <ListTitleItem
                  title="Price"
                  content={finalCustomData.price ?? '-'}
                  size="14px"
                />
                <ListTitleItem
                  title="Purchase date"
                  content={moment(finalCustomData.purchase_date).format(
                    'YYYY-MMM-DD'
                  )}
                  size="14px"
                />
                <ListTitleItem
                  title="Warranty valid"
                  content={finalCustomData.warranty_valid ? 'Yes' : 'No'}
                  size="14px"
                />
                {customData.warranty_valid && (
                  <ListTitleItem
                    title="Warranty upto"
                    content={moment(finalCustomData.validUntil).format(
                      'YYYY-MMM-DD'
                    )}
                    size="14px"
                  />
                )}
              </>
            )}

            <Row justify="center">
              <PrimaryButton onClick={handleAddAssetBtn}>
                <Span color={color.white}>
                  {customData?.model ? 'Edit ' : 'Add '}
                  Marker Asset
                </Span>
              </PrimaryButton>
            </Row>
          </Col>
        )}
      </>
    );
  };

  function handleChange(string, event) {
    event.stopPropagation();
    setCustomData({
      ...customData,
      [string]: event.target.value,
    });
  }

  function handleDateChange(string, e) {
    const timestamp = moment(e._d).valueOf();
    setCustomData((prevState) => {
      return {
        ...prevState,
        [string]: timestamp,
      };
    });
  }

  function handleValidChange(e) {
    setCustomData({
      ...customData,
      [e.target.name]: e.target.checked,
    });
  }

  function handleAddData(e) {
    e.preventDefault();
    const {
      validUntil,
      purchase_date: purchaseDate,
      warranty_valid: warrantyValid,
    } = customData;

    if (!purchaseDate || (warrantyValid && !validUntil)) {
      alert('Please specify date.');
      return;
    }
    setOpenAssetSelection(false);
    setFinalCustomData({ ...customData });
  }

  function handleAssetBoxClose() {
    setOpenAssetSelection(false);
  }

  const assetTypeMarkerForm = () => {
    if (watchMarkerType !== 'Asset') {
      return null;
    }

    return (
      <ADialog
        open={openAssetSelection}
        onClose={handleAssetBoxClose}
        padding="16px"
      >
        <DialogTitle>Add Marker Asset</DialogTitle>
        <form onSubmit={handleAddData}>
          <DialogContent>
            <StyledTextField
              fullWidth
              variant="outlined"
              label="Model"
              type="text"
              onChange={(e) => handleChange('model', e)}
              value={customData?.model}
              required
            />

            <StyledTextField
              fullWidth
              variant="outlined"
              label="Serial Number"
              type="text"
              onChange={(e) => handleChange('sn', e)}
              value={customData?.sn}
              required
            />

            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                style={{ margin: '12px 0', width: '100%' }}
                value={
                  customData?.purchase_date
                    ? moment(customData?.purchase_date)
                    : null
                }
                onChange={(momentObject) =>
                  handleDateChange('purchase_date', momentObject)
                }
                label="Purchase Date"
                format="YYYY-MMM-DD"
                required
              />
            </MuiPickersUtilsProvider>

            <StyledTextField
              fullWidth
              variant="outlined"
              label="Price"
              type="number"
              InputProps={{
                startAdornment: <InputAdornment>$</InputAdornment>,
              }}
              onChange={(e) => handleChange('price', e)}
              value={customData?.price}
              required
            />

            <StyledFormControlLabel
              control={
                <Checkbox
                  checked={customData?.warranty_valid ?? null}
                  onChange={handleValidChange}
                  name="warranty_valid"
                  color="primary"
                />
              }
              label="Warranty Upto"
              labelPlacement="start"
            />

            {customData?.warranty_valid && (
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  style={{ margin: '12px 0', width: '100%' }}
                  value={customData?.validUntil}
                  onChange={(momentObject) =>
                    handleDateChange('validUntil', momentObject)
                  }
                  label="Valid Date"
                  format="YYYY-MMM-DD"
                  required
                  disablePast
                  minDate={customData?.purchase_date}
                />
              </MuiPickersUtilsProvider>
            )}
          </DialogContent>

          <Row justify="flex-end">
            <Button color="primary" onClick={handleAssetBoxClose}>
              Cancel
            </Button>
            <PrimaryButton type="submit">Add data</PrimaryButton>
          </Row>
        </form>
      </ADialog>
    );
  };

  return (
    <Padding value={16}>
      <FormContainer>
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <StyledColumn>
            {watchImage || marker.imageUrl ? (
              <UploadPic
                onPress={openBrowseWindow}
                selectedImage={
                  watchImage ? URL.createObjectURL(watchImage) : marker.imageUrl
                }
              />
            ) : (
              <DefaultProfileContainer>
                <PointedIcon
                  size={40}
                  onClick={openBrowseWindow}
                  color="#000"
                />
              </DefaultProfileContainer>
            )}
            <InvisibleInput
              ref={photoInputRef}
              type="file"
              accept="image/x-png,image/gif,image/jpeg"
              onChange={(e) => {
                if (e.target.files.length !== 0) {
                  const file = e.target.files[e.target.files.length - 1];
                  setValue('image', file);
                }
              }}
            />
          </StyledColumn>

          <MarkerNameContainer>
            <StyledTextField
              inputProps={{
                maxLength: 50,
              }}
              onChange={(event) => {
                setMarkerNameCount(event.target.value.length);
              }}
              required
              fullWidth
              variant="outlined"
              label=" Marker Name"
              type="text"
              name="name"
              defaultValue={marker.name}
              inputRef={register}
              autoFocus
            />

            <StyledSpan
              fontcolor={color.boxShadowColor}
              align="right"
              size="16px"
            >
              {markerNameCount}
              /50
            </StyledSpan>
          </MarkerNameContainer>

          {messages.nameError !== '' && <Error>{messages.nameError}</Error>}

          {markerTypeSelection()}

          <StyledTextField
            fullWidth
            variant="outlined"
            label="Remarks"
            type="text"
            name="note"
            defaultValue={marker.note}
            inputRef={register}
          />

          {services?.length > 1 && (
            <RHFInput
              as={<StyledTextField select />}
              register={register}
              setValue={setValue}
              fullWidth
              name="service"
              variant="outlined"
              defaultValue={watchService}
            >
              <InvisibleMenuItem value="none" disabled>
                Service
              </InvisibleMenuItem>

              {services?.map((service) => (
                <MenuItem key={service.key} value={service}>
                  {service.name}
                </MenuItem>
              ))}
            </RHFInput>
          )}
          {watchService === 'none' && messages.serviceTypeError !== '' && (
            <Error>{messages.serviceTypeError}</Error>
          )}

          <ActivitiesSelection
            serviceFeatures={serviceFeatures}
            handleSelectedActivities={handleSelectedActivities}
            marker={marker}
          />

          {serviceFeatures?.properties && (
            <StyledTextField
              required
              select
              defaultValue="none"
              onChange={handlePropertiesChange}
              variant="outlined"
              width="100%"
              value={selectedProperty ?? 'none'}
              disabled={edit}
            >
              <InvisibleMenuItem value="none">Properties</InvisibleMenuItem>
              {properties?.map((property) => (
                <MenuItem key={property.id} value={property}>
                  {property.name}
                </MenuItem>
              ))}
            </StyledTextField>
          )}

          {messages.propertyError && <Error>{messages.propertyError}</Error>}

          <Row>
            <img src={DeviceIcon} alt="Device Icon" height="32px" />
            <OutlinedButton
              color="primary"
              variant="outlined"
              onClick={() => beaconMModal.open()}
            >
              {!watchBeaconId ? (
                'SELECT DEVICE'
              ) : (
                <Col width="100%">
                  <Span color="primary" align="left">
                    Device:
                  </Span>
                  {watchBeaconName && (
                    <Row justify="space-between">
                      <Span size="16px" color="primary">
                        {watchBeaconName}
                      </Span>
                      <Span
                        weight="bold"
                        size="16px"
                        color="primary"
                        align="left"
                      >
                        {serialNumber}
                      </Span>
                    </Row>
                  )}
                </Col>
              )}
            </OutlinedButton>
          </Row>
          {watchDeviceType === 'QR' && (
            <Row justify="center">
              <QRCode value={watchBeaconId} size={150} fgColor="#29abe2" />
            </Row>
          )}

          {!watchBeaconName && messages.deviceError !== '' && (
            <Error>{messages.deviceError}</Error>
          )}

          {watchService &&
            watchService.type?.toLowerCase() !== 'attendance' && (
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    name="captureImage"
                    icon={<CheckBoxOutlineBlankIcon />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    defaultChecked={marker.captureImage ?? true}
                    inputRef={register}
                  />
                }
                label="Allow to capture images"
              />
            )}

          {checkForCustomerLogin() && (
            <>
              <Row justify="space-between">
                <Typography>Enable Customer Login</Typography>
                <Switch
                  color="primary"
                  onChange={handleSwitchChange}
                  checked={checked}
                />
              </Row>
              <StyledCollapse in={checked} unmountOnExit>
                <Col>
                  <InputTextField
                    onChange={handleNameChange}
                    value={customerDetails?.name}
                    label="Customer Name"
                  />
                  <InputTextField
                    onChange={handleEmailChange}
                    label="Customer Email"
                    type="email"
                    value={customerDetails?.email}
                  />
                </Col>
              </StyledCollapse>
            </>
          )}

          <StyledDialogActions>
            <LowerCaseButton onClick={handleCloseDialog} color="primary">
              Cancel
            </LowerCaseButton>

            <PrimaryButton type="submit" disabled={loading}>
              {loading && <AddingProgress size={20} color="inherit" />}
              {edit ? 'Save' : 'ADD'}
            </PrimaryButton>
          </StyledDialogActions>
        </StyledForm>
      </FormContainer>
      <BeaconMModal />
      <LocationModal
        onLocationChange={onLocationChange}
        locationModal={locationModal}
      />

      {assetTypeMarkerForm()}
    </Padding>
  );
}

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ModalCard = styled(Card)`
  &&&& {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: scroll;
    min-height: 30%;
    max-height: 60%;
    width: 40%;
    padding: 12px;
    background-color: ${color.whiteSmoke};
  }
`;

const StyledDialogActions = styled(DialogActions)`
  && {
    margin-top: 16px;
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
`;
const OutlinedButton = styled(Button)`
  && {
    margin-left: 8px;
    width: 100%;
  }
`;

const Error = styled(Span).attrs({ color: color.red })`
  margin: 0 0 1em 1em;
`;

const DefaultProfileContainer = styled.div`
  width: 7em;
  height: 7em;
  display: flex;
  background-color: #c5c5c5;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`;

const PointedIcon = styled(CameraIcon)`
  cursor: pointer;
`;

const StyledColumn = styled(Col)`
  && {
    margin-top: 16px;
    margin-bottom: 16px;
    width: 100%;
    align-items: center;
    height: 13em;
  }
`;

const BeaconContainer = styled(Col)`
  padding: 16px;
`;

const StyledCollapse = styled(Collapse)`
  && {
    width: 100%;
  }
`;

const StyledRow = styled(Row)`
  margin: 8px;
`;

const InvisibleInput = styled.input`
  display: none;
`;

const MarkerNameContainer = styled(Col)`
  width: 100%;
`;

const StyledSpan = styled(Span)`
  && {
    color: ${(props) => props.fontcolor};
  }
`;

const AddingProgress = styled(CircularProgress)`
  margin-right: 8px;
`;
