import React from 'react';

import styled from 'styled-components';
import { Button } from '@material-ui/core';

import color from '../../utils/color';
import { Progress } from '../Generics/snowm_loader';

const PrimaryButton = ({ loading, onClick, children, disabled, ...rest }) => {
  return (
    <PButton
      disabled={loading || disabled}
      onClick={onClick}
      variant="contained"
      {...rest}
    >
      {loading ? (
        <Progress size={15} progressColor={color.white} />
      ) : (
        <>{children}</>
      )}
    </PButton>
  );
};

const PButton = styled(Button)`
  && {
    font-size: 14px;
    padding: 6px 16px;
    border-radius: 8px;
    font-weight: 700;
    text-transform: none;
    color: ${color.white};
    background-image: linear-gradient(to right, #00afee, #006eb9);
  }
`;

export default PrimaryButton;
