import React from 'react';

import styled from 'styled-components';
import { TextField } from '@material-ui/core';

export default function InputTextField(props) {
  const {
    onChange,
    label,
    value,
    variant,
    type,
    disabled,
    error = false,
    helperText = '',
    onClick,
    style,
    ...remainingProps
  } = props;
  return (
    <StyledTextField
      onChange={onChange}
      label={label ?? ''}
      required
      variant={variant ?? 'outlined'}
      value={value ?? ''}
      type={type ?? 'text'}
      disabled={disabled}
      error={error}
      helperText={helperText}
      onClick={onClick}
      style={style}
      {...remainingProps}
    />
  );
}

const StyledTextField = styled(TextField)`
  && {
    margin-top: 8px;
    flex: 2;
    width: 100%;
  }
`;
