import React from 'react';
import styled from 'styled-components';
import CameraIcon from 'mdi-react/CameraIcon';

export default function UploadPic({ selectedImage, onPress }) {
  return (
    <StyledPictureContainer>
      <ProfilePicture
        alt="Upload Picture"
        src={selectedImage}
        onClick={onPress}
      />
      <ProfilePictureWrapper>
        <CameraIcon onClick={onPress} color="#000" />
      </ProfilePictureWrapper>
    </StyledPictureContainer>
  );
}

export const ProfilePicture = styled.img`
  && {
    height: 200px;
    width: 100%;
    object-fit: cover;
  }
`;

const ProfilePictureWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 0px;
  left: 0px;
  pointer-events: none;
  height: 200px;
  width: 100%;
  opacity: 0.4;
  justify-content: center;
  align-items: center;
`;

const StyledPictureContainer = styled.div`
  position: relative;
  width: 100%;
  height: 200px;
  cursor: pointer;
`;
