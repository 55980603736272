/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: App/index.js
 *
 *  Description: Entry point of the Application.
 *
 *  Author: Nabin Kharal (nabin@brainants.com)
 *
 *  Date created: 4-july-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */

import React from 'react';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import { createBrowserHistory } from 'history';
import { MuiThemeProvider } from '@material-ui/core';
import { Redirect, Router, Switch } from 'react-router-dom';

import Dashboard from '../Private';
import Login from '../Public/snowm_login';
import theme from '../../styles/snowm_theme';
import ChooseCompany from '../Public/ChooseCompany';
import ChangePassword from '../Public/ChangePassword';
import { AuthContext } from '../../contexts/auth_context';
import { initFirebase } from '../../controllers/snowm_firebase';
import { LoginRoute, PrivateRoute } from '../../HOC/snowm_authenticator';

const history = createBrowserHistory();

export default function () {
  return (
    <AuthContext.Consumer>
      {({ setAuthenticated, setCompanyKey }) => (
        <App
          setAuthenticated={setAuthenticated}
          setCompanyKey={setCompanyKey}
        />
      )}
    </AuthContext.Consumer>
  );
}
function App({ setAuthenticated, setCompanyKey }) {
  initFirebase(setAuthenticated, setCompanyKey);

  return (
    <MuiThemeProvider theme={theme}>
      <Router history={history}>
        <Switch>
          <LoginRoute path="/login" component={Login} />
          <LoginRoute path="/change-password" component={ChangePassword} />
          <LoginRoute path="/choose-company" component={ChooseCompany} />
          <PrivateRoute path="/home" component={Dashboard} />
          <Redirect to="/home" />
        </Switch>
      </Router>
    </MuiThemeProvider>
  );
}
