import React, { createContext, useEffect, useState } from 'react';

import { getCompanyDetail } from '../controllers/snowm_firebase';

export const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [authenticated, setAuthenticated] = useState(null);

  const [companyKey, setCompanyKey] = useState(null);

  const [company, setCompany] = useState(null);

  useEffect(() => {
    if (companyKey) {
      getCompanyDetail().then((res) => {
        setCompany(res);
      });
    }
  }, [companyKey]);

  return (
    <AuthContext.Provider
      value={{
        authenticated,
        setAuthenticated,
        setCompanyKey,
        company,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
