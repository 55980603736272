import React from 'react';
import { Document, Page, View, Text, StyleSheet } from '@react-pdf/renderer';

import {
  Table,
  TableHeader,
  TableBody,
  TableCell,
  DataTableCell,
} from '@david.kucsai/react-pdf-table';
import color from '../../../utils/color';

const styles = StyleSheet.create({
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bold: { fontFamily: 'Helvetica-Bold', fontWeight: 700 },
  title: {
    fontSize: '15px',
    color: color.snomwBlue,
    margin: '5px 0',
  },
  header: { color: color.detailColor },
  date: { fontSize: '8px', margin: '5px 0' },
  item: { fontSize: '8px', padding: '5px' },
  table: { width: '60%' },
});

export default function PdfReport({ data, details, header }) {
  const { companyName } = details;
  return (
    <Document>
      <Page size="A4">
        <View style={styles.center}>
          <Text style={[styles.center, styles.bold, styles.title]}>
            {`Timesheets for ${companyName}`}
          </Text>
          <View style={{ width: '60%', alignItems: 'center' }}>
            <Text style={{ ...styles.date }}>{header}</Text>
          </View>
          <View style={styles.table}>
            <Table data={data}>
              <TableHeader style={styles.header}>
                <TableCell style={[styles.item, styles.bold, styles.header]}>
                  Name
                </TableCell>
                <TableCell style={[styles.item, styles.bold, styles.header]}>
                  Total Working Hours
                </TableCell>
              </TableHeader>
              <TableBody>
                <DataTableCell style={styles.item} getContent={(r) => r.Name} />
                <DataTableCell
                  style={styles.item}
                  getContent={(r) => r['Total Working Hours']}
                />
              </TableBody>
            </Table>
          </View>
        </View>
      </Page>
    </Document>
  );
}
