import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { Alert } from '@material-ui/lab';
import { Card, Divider, Snackbar, Switch } from '@material-ui/core';

import Span from '../../Common/Span';
import color from '../../../utils/color';
import ADialog from '../../Common/styled';
import AlertBox from '../../Common/alert_box';
import { JobConfiguration } from './JobConfiguration';
import { SwitchContainer } from '../Jobs/snowm_add_job';
import PrimaryButton from '../../Common/primary_button';
import { Col, Row, Title } from '../../../styles/snowm_styled';
import Loader, { Progress } from '../../Generics/snowm_loader';

import {
  cleanOlderJobs,
  getCompanyDetail,
  updateCompanyClosingAndOpening,
} from '../../../controllers/snowm_firebase';

const Settings = () => {
  const [company, setCompany] = useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [isUpdatingCompany, setIsUpdatingCompany] = useState(false);
  const [isCleaningStarted, setIsCleaningStarted] = useState(false);
  const [isConfigureJobOpen, setIsConfigureJobOpen] = useState(false);

  useEffect(() => {
    let isCancel = false;
    getCompanyDetail().then((res) => {
      if (!isCancel) {
        setCompany(res);
      }
    });
    return () => {
      isCancel = true;
    };
  }, []);

  const handleChangeCleanJob = async (event) => {
    setIsUpdatingCompany(true);
    const updatedCompany = {
      ...company,
      markerFeatures: {
        ...company.markerFeatures,
        shouldCleanJobs: event.target.checked,
      },
    };
    setCompany(updatedCompany);
    await updateCompanyClosingAndOpening(updatedCompany);
    setIsUpdatingCompany(false);
  };

  const handleCleanOlderBtn = () => {
    setOpenAlert(true);
  };

  const handleOlderJobsCleaning = async () => {
    setOpenAlert(false);
    setIsCleaningStarted(true);
    await cleanOlderJobs();
  };

  const cancelCleaningOlderJobs = () => {
    setOpenAlert(false);
  };

  const renderLabel = (title, subTitle) => {
    return (
      <Col>
        <Span weight="bold">{title}</Span>
        <Span size="12px" color={color.grey}>
          {subTitle}
        </Span>
      </Col>
    );
  };

  const handleClose = () => {
    setIsCleaningStarted(false);
  };

  const handleConfigureJobButton = () => {
    setIsConfigureJobOpen(true);
  };

  const handleCancelClick = () => {
    setIsConfigureJobOpen(false);
  };

  const description = () => {
    return (
      <Col>
        <Span size="12px" color={color.grey}>
          Once enabled, the system will
        </Span>
        <Span size="12px" color={color.grey}>
          a) automatically mark assigned jobs that were not started as "Not
          Started" (24 hours after their created time), and
        </Span>
        <hr />
        <Span size="12px" color={color.grey}>
          b) automatically mark assigned jobs only partially completed as "Not
          Completed" 12 hours after they were started. In either case, the
          system will remove these jobs from the Crew dashboard, and they will
          not be able to make any further updates at later times.
        </Span>
      </Col>
    );
  };

  if (!company) {
    return <Loader />;
  }
  return (
    <>
      <div style={{ margin: 16 }}>
        <Title>General Settings</Title>
        <Card style={{ width: '50%', padding: 8 }}>
          <Col gap="8px">
            <Row justify="space-between" width="100%">
              <StyledSwitchContainer
                label={renderLabel('Job Cleanup', description())}
                labelPlacement="start"
                control={
                  <Switch
                    color="primary"
                    checked={company?.markerFeatures?.shouldCleanJobs ?? true}
                    onChange={handleChangeCleanJob}
                  />
                }
              />
              {isUpdatingCompany && <Progress size="24px" />}
            </Row>
            <Divider />

            <ButtonContainer justify="space-around" width="100%" gap="4px">
              <div>
                <PrimaryButton onClick={handleCleanOlderBtn}>
                  <Span color={color.white}>Clean Historical Jobs</Span>
                </PrimaryButton>
              </div>
              <Span size="12px" color={color.grey}>
                Will clean all the not started and not completed jobs from Crew
                Dashboard
              </Span>
            </ButtonContainer>

            <Divider />
            <ButtonContainer gap="4px">
              <div>
                <PrimaryButton onClick={handleConfigureJobButton}>
                  <Span color={color.white}>Configure Marker</Span>
                </PrimaryButton>
              </div>
              <Span size="12px" color={color.grey}>
                One time marker configuration is necessary to assign/create
                "No-Touch Job" & if marker is relocated from one property or
                another property.
              </Span>
            </ButtonContainer>
          </Col>
        </Card>

        <Snackbar
          open={isCleaningStarted}
          autoHideDuration={5000}
          onClose={handleClose}
        >
          <Alert severity="info" onClose={handleClose}>
            <Span size="16px">Cleaning historical jobs.</Span>
          </Alert>
        </Snackbar>
        <AlertBox
          open={openAlert}
          subtitle="This action will mark all the not started jobs since last 24 hours to not started status and all the jobs that are started but not completed since last 12 hours to not completed status."
          handleNegativeAction={cancelCleaningOlderJobs}
          handlePositiveAction={handleOlderJobsCleaning}
          negativeText="No"
          positiveText="Yes"
        />
      </div>
      <ADialog open={isConfigureJobOpen} maxWidth="500px">
        <JobConfiguration handleCancel={handleCancelClick} />
      </ADialog>
    </>
  );
};

const StyledSwitchContainer = styled(SwitchContainer)`
  width: 90%;
`;

const ButtonContainer = styled(Col)`
  margin: 8px 0 0 16px;
`;
export default Settings;
