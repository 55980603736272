import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Routes from './snowm_Routes';
import RouteDetail from './snowm_route_detail';

export default function() {
  return (
    <Switch>
      <Route path="/home/routes/add" exact component={() => <div>add</div>} />
      <Route
        path="/home/routes/details/:routeKey"
        exact
        component={props => <RouteDetail viewOnly {...props} />}
      />
      <Route path="/home/routes/" component={Routes} />
      <Redirect to="/home/routes" />
    </Switch>
  );
}
