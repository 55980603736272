import { upperCase } from '../helpers/string';

export const status = {
  started: 'Started',
  completed: 'Completed',
  assigned: 'Assigned',
  not_completed: 'Not Completed',
  not_started: 'Not Started',
};

export const possibleJobStatuses = Object.keys(status).reduce((acc, curr) => {
  const capitilizedStatus = upperCase(curr);
  return { ...acc, [capitilizedStatus]: curr };
}, {});

export default status;

export const getColorForStatus = (job) => {
  switch (job.status) {
    case possibleJobStatuses.ASSIGNED:
      return '#0000ff';

    case possibleJobStatuses.STARTED:
      return '#ffbf00';

    case possibleJobStatuses.COMPLETED:
      return '#003308';

    default:
      return '#ff0000';
  }
};

export const getJobStatus = (actualJob, shift) => {
  const value =
    (actualJob?.servedMarkers?.length ?? 0) /
    (actualJob?.allMarkers?.length ?? 0);

  const percentage = value * 100;

  if (actualJob?.futureAssignmentData && actualJob?.status === 'assigned') {
    return 'Scheduled';
  }

  switch (actualJob.status) {
    case 'assigned':
      return 'Assigned';
    case 'not_started':
      return 'Not Started';
    case 'completed':
      return 'Completed';
    case 'started':
      if ((shift?.repetative ?? false) && actualJob.noTouch) return 'Working';
      return `${percentage.toFixed(2)}% Completed`;
    case 'not_completed':
      return 'Not Completed';
    default:
      return '';
  }
};
