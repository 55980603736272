import React, { useRef, useEffect, useState, useContext } from 'react';

import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { countries } from 'countries-list';
import { RHFInput } from 'react-hook-form-input';
import GoogleMapsIcon from 'mdi-react/GoogleMapsIcon';
import { IconButton, InputAdornment, MenuItem } from '@material-ui/core';

import color from '../../../../utils/color';
import { StyledTextField } from '../../styled';
import FormModal from '../../../Common/form_modal';
import ProfilePic from '../../../Common/profile_pic';
import { useModal } from '../../../Generics/snowm_modal';
import { LocationModal } from '../../../Generics/location_modal';
import { StyledTypography, Row } from '../../../../styles/snowm_styled';
import { LocalizationContext } from '../../../../contexts/localization_context';
import {
  editUserProfile,
  uploadUserProfileToStorage,
} from '../../../../controllers/snowm_firebase';
import Span from '../../../Common/Span';

const UserEditModal = ({ openModal, userProfileData, handleCancelButton }) => {
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [openLocationModal, setOpenLocationModal] = useState(false);
  const [allCountries, setAllCountries] = useState(null);

  const locationModal = useModal();

  const photoRef = useRef();

  const { register, handleSubmit, setValue, errors } = useForm();

  const { strings } = useContext(LocalizationContext);
  const {
    inputLabel: { NAME, EMAIL, ADDRESS, CONTACT_NUMBER, DESIGNATION },
    sidemenu: { USER_PROFILE },
  } = strings;

  const registerValues = (values) => {
    values.forEach((value) => {
      register({ name: value });
    });
  };

  useEffect(() => {
    const countriesKeyValue = Object.entries(countries);
    setAllCountries(countriesKeyValue);
  }, []);

  useEffect(() => {
    registerValues([
      'companyKey',
      'disabled',
      'token',
      'uid',
      'imageUrl',
      'countryCode',
    ]);
  }, []);

  useEffect(() => {
    setValue('uid', userProfileData.uid);
    setValue('companyKey', userProfileData.companyKey);
    setValue('token', userProfileData.token);
    setValue('disabled', userProfileData.disabled);
    setValue('imageUrl', userProfileData.imageUrl);
    setValue('countryCode', userProfileData.countryCode ?? '');
  }, []);

  useEffect(() => {
    if (openLocationModal) locationModal.open();
    else locationModal.close();
  }, [openLocationModal]);

  const handleEditButton = async (data) => {
    let requestedData = { ...data };
    setLoading(true);
    if (image) {
      const url = await uploadUserProfileToStorage(image);
      requestedData = { ...requestedData, imageUrl: url };
    }

    try {
      editUserProfile(requestedData);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      handleCancelButton();
    }
  };

  const handleChangeImage = (e) => {
    e.preventDefault();
    const imageFile = e.target.files[0];
    setImage(imageFile);
  };

  const openFileWindow = () => {
    photoRef.current.click();
  };

  function handleMapIconPress(e) {
    e.stopPropagation();
    setOpenLocationModal(true);
  }

  function onLocationChange(latLng, address) {
    setValue('address', address);
    setOpenLocationModal(false);
  }

  return (
    <>
      <FormModal
        open={openModal}
        edit
        handleNegativeAction={handleCancelButton}
        handlePositiveAction={handleSubmit(handleEditButton)}
        title={USER_PROFILE}
        loading={loading}
        scroll
      >
        <ProfilePic
          onPress={openFileWindow}
          selectedImage={
            image ? URL.createObjectURL(image) : userProfileData.imageUrl
          }
        />
        <input
          ref={photoRef}
          onChange={handleChangeImage}
          type="file"
          id="file"
          accept="image/*"
          style={{ display: 'none' }}
        />
        <StyledTypography weightoffont="bold">{NAME}</StyledTypography>
        <StyledTextField
          defaultValue={userProfileData.name}
          required
          variant="outlined"
          placeholder={NAME}
          inputRef={register({
            pattern: {
              value: /^([a-zA-Z]+|[a-zA-Z]+\s{1}[a-zA-Z]{1,}|[a-zA-Z]+\s{1}[a-zA-Z]{3,}\s{1}[a-zA-Z]{1,})$/,
              message: 'User name should only be letters',
            },
          })}
          error={!!errors.name}
          helperText={errors.name && errors.name.message}
          name="name"
        />
        <StyledTypography weightoffont="bold">{EMAIL}</StyledTypography>
        <StyledTextField
          defaultValue={userProfileData.email}
          variant="outlined"
          required
          type="email"
          inputRef={register}
          name="email"
          disabled
        />
        <StyledTypography weightoffont="bold">{ADDRESS}</StyledTypography>
        <Row>
          <TextField
            required
            defaultValue={userProfileData.address}
            variant="outlined"
            inputRef={register}
            name="address"
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton onClick={handleMapIconPress}>
                    <GoogleMapsIcon color={color.black} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Row>
        <StyledTypography weightoffont="bold">
          {CONTACT_NUMBER}
        </StyledTypography>
        <Row>
          <RHFInput
            as={<StyledTextField select />}
            required
            name="countryCode"
            defaultValue={userProfileData.countryCode}
            register={register}
            setValue={setValue}
            variant="outlined"
            disabled
          >
            {allCountries?.map((countryDetails) => {
              const countryShortForm = countryDetails[0];
              const country = countryDetails[1];
              return (
                <MenuItem key={countryShortForm} value={country.phone}>
                  <Row marginbottom="0">
                    <Span>{country.emoji}</Span>
                    <Span>
                      (+
                      {country.phone})
                    </Span>
                    <Span>{country.name}</Span>
                  </Row>
                </MenuItem>
              );
            })}
          </RHFInput>
        </Row>
        <StyledTypography weightoffont="bold">{DESIGNATION}</StyledTypography>
        <StyledTextField
          required
          defaultValue={userProfileData.designation}
          variant="outlined"
          inputRef={register({
            pattern: {
              value: /^([a-zA-Z]+|[a-zA-Z]+\s{1}[a-zA-Z]{1,}|[a-zA-Z]+\s{1}[a-zA-Z]{3,}\s{1}[a-zA-Z]{1,})$/,
              message: 'Designation should only be letters',
            },
          })}
          error={!!errors.designation}
          helperText={errors.designation && errors.designation.message}
          name="designation"
        />
      </FormModal>
      {openLocationModal && (
        <LocationModal
          locationModal={locationModal}
          onLocationChange={onLocationChange}
        />
      )}
    </>
  );
};

export default UserEditModal;

const TextField = styled(StyledTextField)`
  width: 100%;
`;
