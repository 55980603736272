import React, { useContext, useEffect, useState } from 'react';

import styled from 'styled-components';
import EyeIcon from 'mdi-react/EyeIcon';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Tooltip,
} from '@material-ui/core';

import Span from '../../Common/Span';
import color from '../../../utils/color';
import { StyledTableRow } from '../../Common/styled';
import { Progress } from '../../Generics/snowm_loader';
import { getRelativeDate } from '../../../helpers/date';
import { Col, Row } from '../../../styles/snowm_styled';
import { getBgColorOfTableRow } from '../../../helpers/misc';
import { getPropertyById } from '../../../controllers/snowm_firebase';
import { ServiceTypesContext } from '../../../contexts/service_types';

const markersHeaders = [
  'Name',
  'Activity',
  'Property',
  'Last Served',
  'Actions',
];

function getDate(date) {
  return date ? getRelativeDate(date) : 'Never';
}

const MarkersTable = ({
  markers,
  onEditIconPress,
  onDeletePress,
  onViewPress,
}) => {
  const types = useContext(ServiceTypesContext);
  const subscribedServices = types?.subscribedServices ?? {};

  const { activities, activitiesOnJob } = subscribedServices;

  return (
    <div style={{ margin: 16 }}>
      <TableContainer>
        <Table>
          <TableHead>
            <StyledTableRow bgcolor={color.snomwBlue}>
              {markersHeaders?.map((header) => {
                if (
                  header === 'Activity' &&
                  !(activities && !activitiesOnJob)
                ) {
                  return null;
                }

                return (
                  <TableCell key={header}>
                    <Span weight="bold" color={color.white}>
                      {header}
                    </Span>
                  </TableCell>
                );
              })}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {markers?.map((marker, index) => (
              <MarkerBody
                key={marker.key}
                index={index}
                marker={marker}
                onEditIconPress={onEditIconPress}
                onDeletePress={onDeletePress}
                onViewPress={onViewPress}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default MarkersTable;

const MarkerBody = ({
  marker,
  index,
  onEditIconPress,
  onDeletePress,
  onViewPress,
}) => {
  const types = useContext(ServiceTypesContext);
  const subscribedServices = types?.subscribedServices ?? {};

  const { activities, activitiesOnJob } = subscribedServices;
  const [property, setProperty] = useState();

  useEffect(() => {
    let isSubscribe = true;
    if (marker?.propertyKey) {
      getPropertyById(marker?.propertyKey).then((res) => {
        if (isSubscribe) setProperty(res ?? {});
      });
    } else {
      setProperty({});
    }

    return () => {
      isSubscribe = false;
    };
  }, [marker]);

  const renderProperty = () => {
    if (!property) {
      return <Progress size={15} />;
    }

    if (!property?.name) {
      return '-';
    }

    return (
      <Col>
        <Span>{property.name}</Span>
        <Span>{property.address ?? marker?.address ?? '-'}</Span>
      </Col>
    );
  };
  return (
    <StyledTableRow bgcolor={getBgColorOfTableRow(index)}>
      <TableCell>
        <Col>
          <Span>{marker.name}</Span>
          {marker?.deviceDetail?.battery && (
            <Span weight="bold" size="12px">
              Battery: {marker?.deviceDetail?.battery}%
            </Span>
          )}
          {!marker?.beaconId && (
            <TextContainer>
              <Span color={color.white}>NO DEVICE</Span>
            </TextContainer>
          )}
        </Col>
      </TableCell>
      {activities && !activitiesOnJob && (
        <TableCell>
          <Span>{marker.activityType ?? '-'}</Span>
        </TableCell>
      )}
      <TableCell>{renderProperty()}</TableCell>
      <TableCell>
        <Span>{getDate(marker?.lastServed)}</Span>
      </TableCell>
      <TableCell>
        <Row gap="4px">
          <Tooltip title="View">
            <IconButton onClick={() => onViewPress(marker)} size="small">
              <EyeIcon size="24px" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit">
            <IconButton onClick={() => onEditIconPress(marker)} size="small">
              <EditIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton onClick={() => onDeletePress(marker)} size="small">
              <DeleteIcon size="24px" />
            </IconButton>
          </Tooltip>
        </Row>
      </TableCell>
    </StyledTableRow>
  );
};

const TextContainer = styled.div`
  background-color: ${color.lightRed};
  padding: 4px;
  border-radius: 4px;
`;
