import * as React from 'react';

import styled from 'styled-components';
import { TextField } from '@material-ui/core';

import Span from '../../Common/Span';
import color from '../../../utils/color';
import { CustomSelect } from '../Jobs/custom_select';
import { LowerCaseButton } from '../../Common/styled';
import { Col, Row } from '../../../styles/snowm_styled';
import PrimaryButton from '../../Common/primary_button';
import { ServiceTypesContext } from '../../../contexts/service_types';
import { storeSchedulingBody } from '../../../controllers/snowm_firebase';

export const byCrew = { label: 'By Crew', value: 'byCrew' };
export const byMarker = { label: 'By Marker', value: 'byMarker' };
export const byProperty = { label: 'By Property', value: 'byProperty' };

export const schedules = [
  {
    label: 'Daily',
    value: 'daily',
  },
  {
    label: 'Weekly',
    value: 'weekly',
  },
  {
    label: 'Bi-weekly',
    value: 'biWeekly',
  },
  {
    label: 'Monthly',
    value: 'monthly',
  },
  {
    label: 'Quarterly',
    value: 'quarterly',
  },
];

const emailIdPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// eslint-disable-next-line import/prefer-default-export
export const AddScheduledReport = ({
  crews,
  markers,
  properties,
  markersKeys,
  closeScheduleDialog,
  addNewScheduleReport,
  selectedScheduledReport,
  crewUids,
  propertyKeys,
}) => {
  const initialState = {
    reportName: null,
    reportType: null,
  };
  const initialScheduleReport = selectedScheduledReport ?? initialState;

  const reducer = (state, action) => {
    const { type, payload } = action;
    return { ...state, [type]: payload };
  };

  const [scheduleReportReqBody, dispatch] = React.useReducer(
    reducer,
    initialScheduleReport
  );

  const { subscribedServices } = React.useContext(ServiceTypesContext);

  const [isMulti, setIsMulti] = React.useState(false);
  const [nameError, setNameError] = React.useState('');
  const [emailError, setEmailError] = React.useState('');
  const [reportTypes, setReportTypes] = React.useState([]);
  const [isStoringReport, setIsStoringReport] = React.useState(false);
  const [dropDownByReportType, setDropDownByReportType] = React.useState(null);

  const changeValueByReportType = (typeOfReport, fromReportTypeChange) => {
    switch (typeOfReport) {
      case 'byCrew':
        if (!selectedScheduledReport || fromReportTypeChange) {
          setIsMulti(false);
          dispatch({
            type: 'selectedValue',
            payload: { label: 'All', value: crewUids },
          });
        }
        setDropDownByReportType(crews);
        break;
      case 'byMarker':
        if (!selectedScheduledReport || fromReportTypeChange) {
          setIsMulti(false);

          dispatch({
            type: 'selectedValue',
            payload: { label: 'All', value: markersKeys },
          });
        }
        setDropDownByReportType(markers);
        break;
      case 'byProperty':
        if (!selectedScheduledReport || fromReportTypeChange) {
          setIsMulti(false);
          dispatch({
            type: 'selectedValue',
            payload: { label: 'All', value: propertyKeys },
          });
        }
        setDropDownByReportType(properties);
        break;

      default:
        break;
    }
  };

  React.useEffect(() => {
    if (selectedScheduledReport) {
      setIsMulti(true);
      changeValueByReportType(selectedScheduledReport.reportType.value);
    }
  }, [selectedScheduledReport]);

  React.useEffect(() => {
    let typeOfReports = [byCrew];

    if (subscribedServices.markerBased) {
      typeOfReports.push(byMarker);
    }
    if (subscribedServices?.properties) {
      typeOfReports.push(byProperty);
    }
    setReportTypes(typeOfReports);
  }, [subscribedServices]);

  const handleReportTypeChange = (res) => {
    dispatch({ type: 'reportType', payload: res });

    changeValueByReportType(res.value, true);
  };

  const handleTextFieldChange = (event, type) => {
    setNameError('');
    dispatch({ type, payload: event.target.value });
  };

  const handleValueChange = (res) => {
    if (!isMulti && res.label !== 'All') {
      setIsMulti(true);
      dispatch({ type: 'selectedValue', payload: [res] });
      return;
    }
    if (
      !res ||
      res?.length <= 0 ||
      (isMulti && res?.some((r) => r.label === 'All'))
    ) {
      changeValueByReportType(scheduleReportReqBody?.reportType?.value, true);
      return;
    }
    dispatch({ type: 'selectedValue', payload: res });
  };

  const getSelectedLabel = () => {
    switch (scheduleReportReqBody.reportType.value) {
      case 'byCrew':
        return 'Crews';

      case 'byMarker':
        return 'Markers';

      case 'byProperty':
        return 'Properties';
      default:
        break;
    }
  };

  const renderReportByReportType = () => {
    if (!dropDownByReportType) {
      return null;
    }
    return (
      <Col>
        <Span weight="bold">
          {`Select
          ${getSelectedLabel()}`}
          :
        </Span>

        <CustomSelect
          isMulti={isMulti}
          onChange={handleValueChange}
          options={dropDownByReportType ?? []}
          value={scheduleReportReqBody.selectedValue}
        />
      </Col>
    );
  };

  const handleScheduleChange = (res) => {
    dispatch({ type: 'scheduledFor', payload: res });
  };

  const renderSchedulerFor = () => {
    return (
      <Col>
        <Span weight="bold">Frequency:</Span>

        <CustomSelect
          options={schedules}
          onChange={handleScheduleChange}
          value={scheduleReportReqBody.scheduledFor}
        />
      </Col>
    );
  };

  const rednerEmailIdsField = () => {
    return (
      <Col>
        <Span weight="bold">Emails:</Span>
        <StyledTextField
          variant="outlined"
          placeholder="Enter multiple receiver email ids separated by comma(,)."
          onChange={(event) => handleTextFieldChange(event, 'emailIds')}
          error={!!emailError}
          helperText={emailError}
          size="small"
          value={scheduleReportReqBody?.emailIds}
        />
      </Col>
    );
  };

  const checkIfAnythingEmpty = () => {
    const {
      emailIds,
      reportName,
      reportType,
      scheduledFor,
      selectedValue,
    } = scheduleReportReqBody;

    return (
      !reportName || !reportType || !selectedValue || !scheduledFor || !emailIds
    );
  };

  const getSelectedKeys = (selectedOption) => {
    let selectedKeys;
    if (isMulti) {
      selectedKeys = selectedOption?.map((option) => option.value);
    } else {
      selectedKeys = selectedOption?.value;
    }

    return selectedKeys;
  };

  const handleScheduleButton = async () => {
    setIsStoringReport(true);
    setEmailError('');
    let {
      key,
      emailIds,
      reportName,
      reportType,
      companyKey,
      scheduledFor,
      selectedValue,
    } = scheduleReportReqBody;

    emailIds = emailIds.split(',');

    const doesInvalidEmailExist = emailIds.some((emailId = '') => {
      return !emailId.trim().match(emailIdPattern);
    });

    if (doesInvalidEmailExist) {
      setEmailError('Email is not valid.');
      setIsStoringReport(false);
      return;
    }

    reportType = reportType.value;
    scheduledFor = scheduledFor.value;

    const selectedCrewUids =
      reportType === 'byCrew' ? getSelectedKeys(selectedValue) : null;
    const selectedMarkersKeys =
      reportType === 'byMarker' ? getSelectedKeys(selectedValue) : null;
    const selectedPropertyKeys =
      reportType === 'byProperty' ? getSelectedKeys(selectedValue) : null;

    emailIds = [...new Set(emailIds)];
    const trimmedEmailsIds = emailIds?.map((emailId) => emailId.trim());

    const newlyAddedScheduleReport = await storeSchedulingBody({
      key,
      reportName,
      reportType,
      companyKey,
      scheduledFor,
      selectedCrewUids,
      selectedMarkersKeys,
      selectedPropertyKeys,
      emailIds: trimmedEmailsIds,
    });

    addNewScheduleReport(newlyAddedScheduleReport, key);

    setIsStoringReport(false);
    closeScheduleDialog();
  };

  return (
    <ScheduleContainer gap="8px">
      <Row justify="center">
        <Span weight="bold" size="16px">
          New Scheduled Report
        </Span>
      </Row>
      <Col>
        <Span weight="bold">Name of the report:</Span>
        <StyledTextField
          size="small"
          variant="outlined"
          error={!!nameError}
          helperText={nameError}
          value={scheduleReportReqBody.reportName}
          onChange={(event) => handleTextFieldChange(event, 'reportName')}
        />
      </Col>

      <Col>
        <Span weight="bold">Report By:</Span>
        <CustomSelect
          required
          options={reportTypes}
          onChange={handleReportTypeChange}
          value={scheduleReportReqBody.reportType}
        />
      </Col>

      {renderReportByReportType()}

      {renderSchedulerFor()}

      {rednerEmailIdsField()}

      <Row justify="flex-end" gap="4px">
        <LowerCaseButton onClick={closeScheduleDialog}>
          <Span color="primary">Cancel</Span>
        </LowerCaseButton>
        <PrimaryButton
          loading={isStoringReport}
          onClick={handleScheduleButton}
          disabled={checkIfAnythingEmpty()}
        >
          Schedule
        </PrimaryButton>
      </Row>
      <Row justify="flex-end">
        <Span weight="bold" color={color.red}>
          * All fields are required.
        </Span>
      </Row>
    </ScheduleContainer>
  );
};

const ScheduleContainer = styled(Col)`
  margin: 8px;
`;

const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-inputMarginDense {
    font-size: 14px;
    font-weight: bold;
  }
`;
