import React, { Fragment, useEffect, useState, useContext } from 'react';

import moment from 'moment';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import MomentUtils from '@date-io/moment';
import ClockIcon from 'mdi-react/ClockIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import ClockEndIcon from 'mdi-react/ClockEndIcon';
import ClockStartIcon from 'mdi-react/ClockStartIcon';
import SyncCirlceIcon from 'mdi-react/SyncCircleIcon';
import AccountHardHatIcon from 'mdi-react/AccountHardHatIcon';
import HomeCityOutlineIcon from 'mdi-react/HomeCityOutlineIcon';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import OrderBoolAscendingVariantIcon from 'mdi-react/OrderBoolAscendingVariantIcon';

import {
  Card,
  Grid,
  Divider,
  Checkbox,
  FormControlLabel,
  Button,
  Box,
  IconButton,
} from '@material-ui/core';

import Span from '../../Common/Span';
import { BoldTitle } from './styled';

import color from '../../../utils/color';
import ADialog from '../../Common/styled';
import PrimaryButton from '../../Common/primary_button';
import ListTitleItem from '../../Generics/list_title_item';
import { getDateInDashFormat } from '../../../helpers/date';
import { Col, Row, Title } from '../../../styles/snowm_styled';
import MarkerBasicDetail from '../markers/marker_basic_detail';
import {
  getPropertyById,
  createMarkerLog,
  getProviderByUid,
  getServicePointById,
} from '../../../controllers/snowm_firebase';
import { ServiceTypesContext } from '../../../contexts/service_types';

const MarkerDetailOfAJob = ({
  markerKey,
  markerLog,
  job,
  markerLogDetailPage,
}) => {
  const [marker, setMarker] = useState();
  const [property, setProperty] = useState();
  const [markerStatus, setMarkerStatus] = useState();
  const [crew, setCrew] = useState();
  const [openActivityModal, setOpenActivityModal] = useState(false);
  const [setOfActivitiesIds, setSetOfActivitiesIds] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [markerLogEdit, setMarkerLogEdit] = useState({ working: true });
  const [isSavingMarkerLog, setIsSavingMarkerLog] = useState(false);

  const history = useHistory();
  // const { shift } = useParams();
  // console.log('shift', shift);

  useEffect(() => {
    let isSubscribe = true;
    getServicePointById(markerKey).then((res) => {
      if (isSubscribe) setMarker(res);
    });

    return () => {
      isSubscribe = false;
    };
  }, [markerKey]);

  useEffect(() => {
    let isSubscribe = true;
    if (marker?.propertyKey) {
      getPropertyById(marker?.propertyKey).then((res) => {
        if (isSubscribe) setProperty(res);
      });
    }

    return () => {
      isSubscribe = false;
    };
  }, [marker]);

  useEffect(() => {
    if (markerLog?.aborted) {
      setMarkerStatus('ABORTED');
    } else if (markerLog?.working === false) setMarkerStatus('COMPLETED');
    else if (markerLog?.working === true) setMarkerStatus('WORKING');
  }, [markerLog]);

  useEffect(() => {
    let isSubscribe = true;
    if (markerLog?.providerUid) {
      getProviderByUid(markerLog?.providerUid).then((response) => {
        if (isSubscribe) setCrew(response);
      });
    }
    return () => {
      isSubscribe = false;
    };
  }, [markerLog]);

  useEffect(() => {
    const completedActivityIds = markerLog?.activities?.reduce((acc, cur) => {
      if (cur?.completed) return [...acc, cur?.id];
      return acc;
    }, []);

    const newSetOfActivitiesId = new Set([...(completedActivityIds ?? [])]);

    setSetOfActivitiesIds(newSetOfActivitiesId);
  }, [markerLog]);

  useEffect(() => {
    if (openModal) setIsSavingMarkerLog(false);
  }, [openModal]);

  function isActivityCompleted(activityLog) {
    return !!activityLog?.completed;
  }

  function getNumberOfCompletedActivities() {
    const completedActivities = markerLog?.activities?.filter(
      isActivityCompleted
    );
    return completedActivities?.length ?? 0;
  }

  function getTotalNumberOfActivities() {
    return Math.max(
      getNumberOfCompletedActivities(),
      marker?.activities?.length ?? 0
    );
  }

  function getRatioOfActivities() {
    if (markerLog) {
      return `${getNumberOfCompletedActivities()} / ${getTotalNumberOfActivities()}`;
    }
    return Object.keys(job?.activities[markerKey] ?? {}).length;
  }

  function handleActivitiesClick(e) {
    e.stopPropagation();
    setOpenActivityModal(true);
  }

  function renderRatioOfCompletedActivities() {
    if (
      (markerLog && !markerLog?.activities?.length) ||
      (!markerLog && !Object.keys(job?.activities[markerKey] ?? {}).length)
    ) {
      return null;
    }
    return (
      <ListTitleItem
        onClick={handleActivitiesClick}
        Icon={OrderBoolAscendingVariantIcon}
        content={getRatioOfActivities()}
        title="Activities"
      />
    );
  }

  function getMarkerStatusColor() {
    switch (markerStatus) {
      case 'ABORTED':
        return 'error';
      case 'WORKING':
        return color?.green;
      default:
        return 'black';
    }
  }

  function getDate(date) {
    return date ? getDateInDashFormat(date) : '-';
  }

  function handleMarkerDetailClick(e) {
    e.stopPropagation();
    if (markerLog && !markerLogDetailPage) {
      history.push(
        `/home/jobs/details/${markerLog?.jobKey}/marker/${markerLog?.servicePointId}`
      );
    }
  }

  function checkActivityComplete(activity) {
    return setOfActivitiesIds?.has(activity?.id);
  }

  function handleClose(event) {
    event.stopPropagation();
    setOpenActivityModal(false);
  }

  function getDuration(duration) {
    const minutes = moment.duration(duration).asMinutes();
    return `${Math.floor(minutes)} minutes`;
  }

  const activityLabel = ({ activity }) => {
    return (
      <Col>
        <Span>{activity?.name}</Span>
        {activity.mandatory && <Span color={color.grey}>Mandatory</Span>}
      </Col>
    );
  };

  const activityDetails = ({ activity }) => {
    const checkIfActivityIsCompleted = () => {
      if (markerLog) {
        return checkActivityComplete(activity);
      }
      return !!activity.completed;
    };
    return (
      <StyledFormControlLabel
        labelPlacement="start"
        control={
          <Checkbox color="primary" checked={checkIfActivityIsCompleted()} />
        }
        label={activityLabel({ activity })}
      />
    );
  };

  const listOfActivities = ({ activities }) => {
    const uniqueActivities = activities?.reduce((acc, activity) => {
      return { ...acc, [activity.name]: activity };
    }, {});
    return Object.values(uniqueActivities ?? {}).map((activity) => {
      return (
        <Row key={activity.name} justify="space-between">
          {activityDetails({ activity })}
        </Row>
      );
    });
  };

  const ActivitiesDetail = () => {
    return (
      <ADialog onClose={handleClose} width="300px" open={openActivityModal}>
        <ActivitiesContainer>
          <Row justify="space-between">
            <Title align="center">Activities</Title>
            <CloseIcon cursor="pointer" onClick={handleClose} />
          </Row>
          {markerLog
            ? listOfActivities({ activities: markerLog.activities })
            : job?.activities?.[`${markerKey}`]?.map((activity) => {
                return (
                  <Fragment key={activity.id}>
                    {activityDetails({ activity })}
                  </Fragment>
                );
              })}
        </ActivitiesContainer>
      </ADialog>
    );
  };

  const handleMarkerLog = (data, name) => {
    setMarkerLogEdit({
      ...markerLogEdit,
      [name]: name === 'working' ? !data : data,
    });
  };

  const onClose = () => {
    setOpenModal(false);
  };

  const onHandleEdit = () => {
    setOpenModal(true);
    setMarkerLogEdit(markerLog);
  };

  const addEditMarkerLog = async () => {
    setIsSavingMarkerLog(true);
    let markerLogData;
    let docId = '';
    if (!markerLog) {
      const {
        providerUids,
        key: jobKey,
        companyKey,
        serviceKey,
        repeatId,
      } = job;
      const providerKey = providerUids?.[0];
      const data = {};
      data.working = markerLogEdit.working;
      data.onlyVisit = false;
      data.companyKey = companyKey;
      data.jobKey = jobKey;
      data.routeKey = null;
      data.serviceKey = serviceKey;
      data.propertyKey = marker?.propertyKey;
      data.servicePointId = markerKey;
      data.startDate = markerLogEdit.startDate;
      data.endDate = markerLogEdit.endDate;
      data.providerUid = providerKey;
      docId = `${providerKey}_${jobKey}_${markerKey}_${repeatId ?? ''}`;
      data.key = docId;
      markerLogData = data;
    } else {
      const { key } = markerLogEdit;
      docId = key;
      markerLogData = markerLogEdit;
    }

    try {
      await createMarkerLog(markerLogData, docId);
      setOpenModal(false);
    } catch (err) {
      setIsSavingMarkerLog(false);
      console.error('err', err);
    }
  };

  const OnSiteTime = () => {
    if (markerLog?.onSiteTime) {
      return (
        <ListTitleItem
          Icon={ClockIcon}
          content={getDuration(markerLog?.onSiteTime)}
          title="On-Site Time"
        />
      );
    }
    return null;
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') handleMarkerDetailClick(event);
  };

  const handleCreateButton = () => {
    setMarkerLogEdit({ working: true });
    setOpenModal(true);
  };

  return (
    <>
      <StyledGrid item xs={4}>
        <StyledCard cursor={markerLog ? 'pointer' : 'auto'}>
          <Col
            tabIndex="0"
            role="button"
            onKeyDown={handleKeyDown}
            onClick={handleMarkerDetailClick}
          >
            {!markerLog ? (
              <MarkerBasicDetail marker={marker} property={property} />
            ) : (
              <StyledCol>
                <Row justify="space-between">
                  <BoldTitle>
                    {marker && (marker?.name ?? 'Marker Deleted')}
                  </BoldTitle>
                  {markerStatus && (
                    <Span weight="bold" color={getMarkerStatusColor()}>
                      {markerStatus}
                    </Span>
                  )}
                </Row>
                <Divider />

                <ListTitleItem
                  Icon={AccountHardHatIcon}
                  content={crew?.name}
                  title="Crew"
                />
                <ListTitleItem
                  Icon={HomeCityOutlineIcon}
                  content={property?.name}
                  title="Property"
                />

                <ListTitleItem
                  Icon={HomeCityOutlineIcon}
                  content={property?.address ?? marker?.address ?? '-'}
                  title="Address"
                />
                <ListTitleItem
                  Icon={ClockStartIcon}
                  content={getDate(markerLog?.startDate)}
                  title="Started At"
                />
                <ListTitleItem
                  Icon={ClockEndIcon}
                  content={getDate(markerLog?.endDate)}
                  title="Completed At"
                />

                {renderRatioOfCompletedActivities()}

                <OnSiteTime />

                {(markerLog?.iteration || false) && (
                  <ListTitleItem
                    Icon={SyncCirlceIcon}
                    content={markerLog?.iteration}
                    title="Iteration"
                  />
                )}
              </StyledCol>
            )}
            {Object.keys(job?.activities ?? {}).length ? (
              <PointerText>{renderRatioOfCompletedActivities()}</PointerText>
            ) : null}
          </Col>

          {markerLog && (
            <EditMarkerLog
              setOpenModal={setOpenModal}
              openModal={openModal}
              handleMarkerLog={handleMarkerLog}
              markerLogEdit={markerLogEdit}
              addEditMarkerLog={addEditMarkerLog}
              onClose={onClose}
              markerLog={markerLog}
              onHandleEdit={onHandleEdit}
              isSaving={isSavingMarkerLog}
              handleCreateButton={handleCreateButton}
              job={job}
            />
          )}
        </StyledCard>
      </StyledGrid>
      <ActivitiesDetail />
    </>
  );
};

export default MarkerDetailOfAJob;

const StyledCard = styled(Card)`
  padding: 16px;
  min-height: 200px;
  cursor: ${(props) => props.cursor};
`;

const StyledCol = styled(Col)`
  margin-top: 8px;
`;

const StyledGrid = styled(Grid)`
  min-height: 200px;
  && {
    margin-left: 4px;
  }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  justify-content: space-between;
  width: 100%;
`;

const ActivitiesContainer = styled(Col)`
  padding: 12px;
`;

const PointerText = styled.span`
  cursor: pointer;
`;

const EditMarkerLog = ({
  job,
  onClose,
  isSaving,
  openModal,
  markerLog,
  onHandleEdit,
  markerLogEdit,
  handleMarkerLog,
  addEditMarkerLog,
  handleCreateButton,
}) => {
  const { subscribedServices } = useContext(ServiceTypesContext);
  const { allowMasterEditing } = subscribedServices ?? {};
  const [dateTimeErrorMessage, setDateTimeErrorMessage] = useState('');

  useEffect(() => {
    if (markerLogEdit?.startDate > markerLogEdit?.endDate) {
      setDateTimeErrorMessage(
        'End date/time must be less than start date/time.'
      );
    } else {
      setDateTimeErrorMessage('');
    }
  }, [markerLogEdit?.startDate, markerLogEdit?.endDate]);

  function areDatesEmpty() {
    return !markerLogEdit?.startDate || !markerLogEdit?.endDate;
  }

  function isEndTimeLessThanStartTime() {
    return markerLogEdit?.endDate < markerLogEdit?.startDate;
  }

  function checkForDisablity() {
    return areDatesEmpty() || isEndTimeLessThanStartTime();
  }

  const closeDialog = () => {
    onClose();
    setDateTimeErrorMessage('');
  };

  const renderButton = () => {
    if (!allowMasterEditing) {
      return null;
    }
    if (!markerLog) {
      return (
        <Button
          color="primary"
          variant="contained"
          onClick={handleCreateButton}
        >
          Create
        </Button>
      );
    }

    return (
      <Button onClick={onHandleEdit} variant="contained" color="primary">
        Edit
      </Button>
    );
  };

  return (
    <>
      <Box display="flex" justifyContent="flex-end">
        {renderButton()}
      </Box>
      <ADialog open={openModal} onClose={closeDialog}>
        <form action="" onSubmit={addEditMarkerLog}>
          <ActivitiesContainer gap="5px">
            <Row justify="space-between">
              <Title align="center">
                {markerLogEdit.key ? 'Edit Markerlog' : 'Create Markerlog'}
              </Title>
              <IconButton onClick={closeDialog}>
                <CloseIcon cursor="pointer" />
              </IconButton>
            </Row>
            <Divider />
            <Box mt="10px">
              <Col gap="16px">
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <div
                    style={{
                      gap: 12,
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <DateTimePicker
                      label="Start Date"
                      inputVariant="outlined"
                      value={markerLogEdit?.startDate ?? null}
                      onChange={(d) =>
                        handleMarkerLog(d.valueOf(), 'startDate')
                      }
                      minDate={job?.startedDate ?? ''}
                      disableFuture
                    />
                    <DateTimePicker
                      minDate={markerLogEdit?.startDate}
                      disabled={!markerLogEdit?.startDate}
                      label="End Date"
                      inputVariant="outlined"
                      value={markerLogEdit?.endDate ?? null}
                      onChange={(d) => handleMarkerLog(d.valueOf(), 'endDate')}
                      maxDate={job?.endDate ?? new Date().valueOf()}
                      error={markerLogEdit?.startDate > markerLogEdit?.endDate}
                      disableFuture
                    />
                    {!dateTimeErrorMessage ? null : (
                      <p
                        style={{
                          color: 'red',
                          marginTop: '-12px 0 -10px -6px',
                        }}
                      >
                        {dateTimeErrorMessage}
                      </p>
                    )}
                  </div>
                </MuiPickersUtilsProvider>
              </Col>

              <Box display="flex" gap="10px" justifyContent="space-between">
                <h4>Completed</h4>
                <Checkbox
                  onChange={(e) => handleMarkerLog(e.target.checked, 'working')}
                  checked={!markerLogEdit.working}
                  color="primary"
                />
              </Box>
            </Box>

            <PrimaryButton
              loading={isSaving}
              onClick={addEditMarkerLog}
              disabled={checkForDisablity()}
            >
              Save
            </PrimaryButton>
          </ActivitiesContainer>
        </form>
      </ADialog>
    </>
  );
};
