import React from 'react';

import styled from 'styled-components';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  CardContent,
  Typography,
  Tooltip,
  IconButton,
} from '@material-ui/core';

import color from '../../../utils/color';
import { Row } from '../../../styles/snowm_styled';

const ActivitiesContent = ({ activity, openEditModal, openDeleteModal }) => {
  function handleEditIconPress(e) {
    e.stopPropagation();
    openEditModal(activity);
  }

  function handleDeleteIconPress(e) {
    e.stopPropagation();
    openDeleteModal(activity);
  }

  return (
    <RowCardContent>
      <Row justify="center" marginbottom="0">
        <Typography>{activity.name}</Typography>
        {activity.mandatory && (
          <Tooltip title="mantatory">
            <Typography>*</Typography>
          </Tooltip>
        )}
      </Row>
      <Row justify="center">
        <StyledIconButton onClick={handleEditIconPress}>
          <StyledEditIcon htmlColor={color.lightBlack} fontSize="small" />
        </StyledIconButton>
        <StyledIconButton onClick={handleDeleteIconPress}>
          <StyledDeleteIcon htmlColor={color.lightBlack} fontSize="small" />
        </StyledIconButton>
      </Row>
    </RowCardContent>
  );
};

export default ActivitiesContent;

const RowCardContent = styled(CardContent)`
  && {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 8px;
  }
`;

export const StyledEditIcon = styled(EditIcon)`
  && {
    margin: 0 8px;
    height: 24px;
    width: 24px;
  }
`;

const StyledDeleteIcon = styled(DeleteIcon)`
  height: 24px;
  width: 24px;
`;

export const StyledIconButton = styled(IconButton)`
  && {
    padding: 0px;
  }
`;
