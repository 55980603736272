import React, { useState, useEffect, useContext } from 'react';

import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import { Redirect } from 'react-router';
import DateFnsUtils from '@date-io/moment';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import {
  Grid,
  FormControl,
  Typography,
  CircularProgress,
  Divider,
  Card,
  Checkbox,
} from '@material-ui/core';

import Span from '../../Common/Span';
import color from '../../../utils/color';
import { StyledFormControlLabel } from '../styled';
import { CustomSelect } from '../Jobs/custom_select';
import PrimaryButton from '../../Common/primary_button';
import { Row, Col } from '../../../styles/snowm_styled';
import { ServiceTypesContext } from '../../../contexts/service_types';
import PDFDocumentGenerator from '../../Generics/PDFDocumentGenerator';
import PDFDocumentProvider from '../../Generics/pdf_document_provider';
import {
  getDataForProivderPDF,
  getDataForPDFForServiceRoute,
  getDataForPDF,
} from '../../../controllers/pdf_helper';
import {
  getProvidersOfCompany,
  getAllServiceRoutes,
  getMarkersForService,
  getPropertiesFromFirestore,
  getMarkersByPropertyKey,
} from '../../../controllers/snowm_firebase';

const Reports = () => {
  const [providers, setProviders] = useState([]);
  const [dateOfReport, setDateOfReport] = useState('');

  const [selectedProvider, setSelectedProvider] = useState({ name: '' });
  const [serviceRoutes, setServiceRoutes] = useState([]);
  const [selectedServiceRoute, setSelectedServiceRoute] = useState({
    name: '',
  });
  const [pdfDataRoute, setPdfDataRoute] = useState({});
  const [pdfDataProvider, setPdfDataProvider] = useState({});
  const [pdfDataProperties, setPdfDataProperties] = useState();

  const [startDate, setStartDate] = useState({
    provider: null,
    serviceRoute: null,
    markers: null,
    properties: null,
  });
  const [endDate, setEndDate] = useState({
    provider: null,
    serviceRoute: null,
    markers: null,
    properties: null,
  });

  const [crewLoading, setCrewLoading] = useState(false);
  const [routeLoading, setRouteLoading] = useState(false);
  const [markerLoading, setMarkerLoading] = useState(false);
  const [propertiesLoading, setPropertiesLoading] = useState(false);
  const [pdfDataMarker, setPdfDataMarker] = useState();

  const [markers, setmarkers] = useState();
  const [properties, setProperties] = useState();
  const [selectedMarkers, setSelectedMarkers] = useState();
  const [selectedProperty, setSelectedProperty] = useState();
  const [selectImages, setSelectImages] = useState({
    crew: false,
    markers: false,
    routes: false,
    properties: false,
  });

  const errorsByType = {
    crew: '',
    marker: '',
    route: '',
    property: '',
  };
  const [errors, setErrors] = useState(errorsByType);

  const noDataError = '*No data to be reported for the selection.';

  const data = useContext(ServiceTypesContext);
  const subscribedServices = data?.subscribedServices;

  const dateTime = moment().format('YYYY_MM_DD_hm_A');

  useEffect(() => {
    setDateOfReport(`Marker_Exported_${dateTime}`);
  }, [dateTime]);

  useEffect(() => {
    let isSubscribe = true;
    if (subscribedServices?.markerBased) {
      getMarkersForService('markerbased').then((res) => {
        const labelledMarkers = res?.map((marker) => {
          return {
            label: marker?.name,
            value: marker?.key,
            data: marker,
          };
        });
        if (isSubscribe) {
          setmarkers(labelledMarkers);
        }
      });
    }
    return () => {
      isSubscribe = false;
    };
  }, [subscribedServices]);

  useEffect(() => {
    let isSubscribe = true;
    function _updateProperties(res) {
      const propertiesOptions = res?.map((property) => {
        return {
          label: property?.name,
          value: property,
        };
      });
      if (isSubscribe) {
        setProperties(propertiesOptions);
      }
    }

    if (subscribedServices?.properties) {
      getPropertiesFromFirestore(_updateProperties, true);
    }
    return () => {
      isSubscribe = false;
    };
  }, [subscribedServices]);

  const onProviderSubmit = async () => {
    if (!selectedProvider.name) {
      alert('Please select crew to export pdf.');
      setCrewLoading(false);
      return;
    }

    if (!startDate.provider || !endDate.provider) {
      alert("Dates shouldn't be empty.");
      return;
    }
    setPdfDataProvider({});
    setErrors(errorsByType);
    setCrewLoading(true);
    getDataForProivderPDF(
      selectedProvider,
      startDate.provider,
      endDate.provider,
      selectImages.crew,
      (pdfData) => {
        if (pdfData.servedJobs?.length) {
          // updateInstance(pdfData);
          setPdfDataProvider(pdfData);
        } else {
          setErrors({
            ...errorsByType,
            crew: noDataError,
          });
        }
        setCrewLoading(false);
      }
    );
  };

  const onRouteSubmit = async () => {
    setPdfDataRoute({});
    setErrors(errorsByType);
    setRouteLoading(true);
    if (selectedServiceRoute.name) {
      getDataForPDFForServiceRoute(
        selectedServiceRoute,
        startDate.serviceRoute,
        endDate.serviceRoute,
        selectImages.routes,
        (pdfData) => {
          if (!pdfData.servedJobs?.length) {
            setErrors({ ...errorsByType, route: noDataError });
          } else {
            setPdfDataRoute(pdfData);
          }
          setRouteLoading(false);
        }
      );
    } else {
      alert('Please select route to export pdf.');
      setRouteLoading(false);
    }
  };

  async function onSubmit(e, type) {
    e.preventDefault();

    if (type === 'properties') {
      if (!selectedProperty) {
        alert('Select one property.');
        return;
      }
      if (!startDate.properties || !endDate.properties) {
        alert('Dates should not be empty.');
        return;
      }
      setPropertiesLoading(true);
      setErrors(errorsByType);

      setPdfDataProperties();

      const propertyMarkers = await getMarkersByPropertyKey(
        selectedProperty.id,
        null,
        null,
        true
      );

      const pdfData = await getDataForPDF(
        propertyMarkers,
        startDate.properties,
        endDate.properties,
        selectedProperty,
        selectImages.properties
      );

      if (pdfData.servedJobs?.length) {
        setPdfDataProperties(pdfData);
      } else {
        setErrors({ ...errorsByType, property: noDataError });
      }

      setPropertiesLoading(false);
    } else {
      if (!selectedMarkers) {
        alert('Please select at least one marker.');
        return;
      }

      if (!startDate.markers || !endDate.markers) {
        alert('Marker Dates should not be empty.');
        return;
      }

      setMarkerLoading(true);
      setErrors(errorsByType);

      setPdfDataMarker();

      const pdfData = await getDataForPDF(
        selectedMarkers,
        startDate.markers,
        endDate.markers,
        null,
        selectImages.markers
      );

      if (pdfData.servedJobs?.length) {
        setPdfDataMarker(pdfData);
      } else {
        setErrors({ ...errorsByType, marker: noDataError });
      }
      setMarkerLoading(false);
    }
  }

  const handleProviderChange = (selectedCrew) => {
    setSelectedProvider(selectedCrew?.value);
  };

  const handleRouteChange = (event) => {
    setSelectedServiceRoute(event.target.value);
  };

  const handleStartDateChange = (date, type) => {
    setStartDate({ ...startDate, [type]: date });
  };

  const handleEndDateChange = (date, type) => {
    setEndDate({ ...endDate, [type]: date });
  };
  useEffect(() => {
    let isSubscribe = true;
    getProvidersOfCompany(null, null, true).then((prov) => {
      if (isSubscribe) {
        const crews = prov?.map((crew) => {
          return {
            label: crew?.name,
            value: crew,
          };
        });
        setProviders(crews);
      }
    });
    getAllServiceRoutes().then((servRoutes) => {
      if (isSubscribe) setServiceRoutes(servRoutes);
    });

    return () => {
      isSubscribe = false;
    };
  }, []);

  const renderDatePicker = (type) => {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DateTimePicker
          margin="normal"
          id="start-date"
          label="Start Date"
          hideTabs
          value={startDate[type]}
          onChange={(date) => handleStartDateChange(date, type)}
          disableFuture
          required
          format="yyyy/MM/DD HH:mm"
        />
        <DateTimePicker
          margin="normal"
          id="end-date"
          label="End Date"
          hideTabs
          value={endDate[type]}
          onChange={(date) => handleEndDateChange(date, type)}
          disableFuture
          minDate={startDate[type] ?? new Date('1900-01-01')}
          required
          disabled={!startDate[type]}
          format="yyyy/MM/DD HH:mm"
        />
      </MuiPickersUtilsProvider>
    );
  };

  function handleMarkerSelect(markersSelected) {
    const markersThatAreSelected = markersSelected?.map(
      (marker) => marker?.data
    );

    setSelectedMarkers(markersThatAreSelected);
  }

  function handleValueChange(propertySelected, type) {
    switch (type) {
      case 'properties': {
        setSelectedProperty(propertySelected?.value);
        break;
      }
      default:
    }
  }

  function doRenderRoute() {
    return subscribedServices?.indoor || subscribedServices?.outdoor;
  }

  function handleChange(e) {
    e.stopPropagation();
    setSelectImages({ ...selectImages, [e.target.name]: e.target.checked });
  }

  if (!subscribedServices) {
    return <StyledCircularProgress size={24} />;
  }

  if (!subscribedServices.reports) {
    return <Redirect to="/home" />;
  }
  return (
    <ModalCard>
      <StyledRow>
        <Span weight="bold" size="24px">
          Job Report
        </Span>
      </StyledRow>
      <StyledGrid container spacing={3}>
        <Grid item xs={6}>
          <Card>
            <StyledCol align="center" justify="center">
              <Span color={color.red}>{errors.crew}</Span>
              <ExportTitle>By Crew</ExportTitle>
              <StyledForm onSubmit={onProviderSubmit}>
                <StyledFormControl>
                  <SelectLabel>Select Crew</SelectLabel>

                  <CustomSelect
                    placeholder="Select Crew"
                    options={providers}
                    onChange={handleProviderChange}
                  />

                  {renderDatePicker('provider')}
                  <Row>
                    <StyledFormControlLabel
                      labelPlacement="start"
                      control={
                        <Checkbox
                          checked={selectImages.crew}
                          onChange={handleChange}
                          name="crew"
                          color="primary"
                        />
                      }
                      label="Show Images"
                    />
                  </Row>
                </StyledFormControl>

                <ButtonContainer>
                  <StyledPrimaryButton
                    onClick={onProviderSubmit}
                    disabled={crewLoading}
                  >
                    Generate PDF Report
                  </StyledPrimaryButton>
                  {crewLoading && <StyledCircularProgress size={24} />}
                </ButtonContainer>
              </StyledForm>
              {!isEmpty(pdfDataProvider) ? (
                <StyledPDFDownlink
                  document={<PDFDocumentProvider pdfData={pdfDataProvider} />}
                  fileName={dateOfReport}
                >
                  {({ loading, url }) => {
                    return loading ? (
                      <Span weight="bold">Exporting PDF...</Span>
                    ) : (
                      <DownLoadPdf url={url} dateOfReport={dateOfReport} />
                    );
                  }}
                </StyledPDFDownlink>
              ) : null}
            </StyledCol>
          </Card>
        </Grid>

        {/* {doRenderRoute() && (
          <Grid item xs={6}>
            <Card>
              <StyledCol align="center" justify="center">
                <ExportTitle>By Route</ExportTitle>
                <StyledForm onSubmit={onRouteSubmit}>
                  <StyledFormControl>
                    <InputLabel shrink htmlFor="service-route-list">
                      Select Route
                    </InputLabel>
                    <Select
                      value={selectedServiceRoute}
                      onChange={handleRouteChange}
                    >
                      {serviceRoutes.map((servRoute) => {
                        return (
                          <MenuItem key={servRoute.key} value={servRoute}>
                            {servRoute.name}
                          </MenuItem>
                        );
                      })}
                    </Select>

                    {renderDatePicker('serviceRoute')}

                    <Row>
                      <StyledFormControlLabel
                        labelPlacement="start"
                        control={
                          <Checkbox
                            checked={selectImages.routes}
                            onChange={handleChange}
                            name="routes"
                            color="primary"
                          />
                        }
                        label="Show Images"
                      />
                    </Row>
                  </StyledFormControl>
                  <ButtonContainer>
                    <StyledPrimaryButton
                      onClick={onRouteSubmit}
                      disabled={routeLoading}
                    >
                      Generate PDF Report
                    </StyledPrimaryButton>
                    {routeLoading && <StyledCircularProgress size={24} />}
                  </ButtonContainer>
                </StyledForm>
                {!isEmpty(pdfDataRoute) ? (
                  <StyledPDFDownlink
                    document={<PDFDocument pdfData={pdfDataRoute} />}
                    fileName={dateOfReport}
                  >
                    {({ loading }) =>
                      loading ? (
                        <Typography>Exporting PDF...</Typography>
                      ) : (
                        <Typography>Download PDF Now!</Typography>
                      )
                    }
                  </StyledPDFDownlink>
                ) : null}
              </StyledCol>
            </Card>
          </Grid>
        )} */}

        <Divider />

        {subscribedServices.markerBased && (
          <ReportByType
            title="By Markers"
            load={markerLoading}
            onChange={handleMarkerSelect}
            onSubmit={(e) => onSubmit(e, 'markers')}
            options={markers}
            pdfData={pdfDataMarker}
            renderDate={renderDatePicker}
            selectMulti
            selectTitle="Select Markers"
            selectedValue={selectedMarkers ?? []}
            type="markers"
            selectImage={selectImages.markers}
            handleChange={handleChange}
            noDataError={errors.marker}
          />
        )}

        {subscribedServices?.properties && (
          <ReportByType
            key="properties"
            title="By Properties"
            selectTitle="Select Properties"
            options={properties}
            renderDate={renderDatePicker}
            type="properties"
            onChange={(selectedOption) =>
              handleValueChange(selectedOption, 'properties')
            }
            load={propertiesLoading}
            onSubmit={(e) => onSubmit(e, 'properties')}
            selectedValue={selectedProperty ?? ''}
            pdfData={pdfDataProperties}
            selectImage={selectImages.properties}
            handleChange={handleChange}
            noDataError={errors.property}
          />
        )}
      </StyledGrid>
    </ModalCard>
  );
};

const ReportByType = ({
  title,
  selectTitle,
  options,
  onChange,
  selectMulti,
  renderDate,
  type,
  load,
  onSubmit,
  pdfData,
  selectImage,
  handleChange,
  noDataError,
}) => {
  const dateTime = moment().format('YYYY_MM_DD_hm_A');

  const [dateOfReport, setDateOfReport] = useState();

  useEffect(() => {
    setDateOfReport(`Marker_Exported_${dateTime}`);
  }, [dateTime]);

  return (
    <Grid item xs={6}>
      <Card>
        <StyledCol align="center" justify="center">
          <Span color={color.red}>{noDataError}</Span>
          <ExportTitle>{title}</ExportTitle>
          <StyledForm>
            <StyledFormControl>
              <SelectLabel>{selectTitle}</SelectLabel>

              <CustomSelect
                isMulti={selectMulti ?? false}
                options={options}
                onChange={onChange}
                placeholder={`Select ${type}`}
              />
              {renderDate(type)}

              <Row>
                <StyledFormControlLabel
                  labelPlacement="start"
                  control={
                    <Checkbox
                      checked={selectImage}
                      onChange={handleChange}
                      name={type}
                      color="primary"
                    />
                  }
                  label="Show Images"
                />
              </Row>
            </StyledFormControl>
            <ButtonContainer>
              <StyledPrimaryButton disabled={load} onClick={onSubmit}>
                Generate PDF Report
              </StyledPrimaryButton>
              {load && <StyledCircularProgress size={24} />}
            </ButtonContainer>
          </StyledForm>
          {pdfData ? (
            <StyledPDFDownlink
              document={<PDFDocumentGenerator pdfData={pdfData} />}
              fileName={dateOfReport}
            >
              {({ loading, url }) =>
                loading ? (
                  <Span weight="bold">Exporting PDF...</Span>
                ) : (
                  <DownLoadPdf url={url} dateOfReport={dateOfReport} />
                )
              }
            </StyledPDFDownlink>
          ) : null}
        </StyledCol>
      </Card>
    </Grid>
  );
};

const DownLoadPdf = ({ url, dateOfReport }) => {
  useEffect(() => {
    if (url) {
      let a = document.createElement('a');
      a.href = url;
      a.download = `${dateOfReport}.pdf`;
      a.click();
      a.remove();
    }
  }, [url]);
  return null;
};

const ModalCard = styled.div``;

const ExportTitle = styled(Typography)`
    font-size: 18px;
    padding-left: 16px;
    padding-right: 16px;
  }
  `;

const StyledCircularProgress = styled(CircularProgress)`
  && {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -8px;
    margin-left: -12px;
  }
`;

const ButtonContainer = styled(Col)`
  position: relative;
  justify-content: center;
`;

const StyledForm = styled.form`
  width: 100%;
`;

const StyledPDFDownlink = styled(PDFDownloadLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
`;

const StyledGrid = styled(Grid)`
  padding-left: 16px;
  padding-right: 16px;
  justify-content: flex-start;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  && {
    margin-top: 16px;
  }
`;

const StyledRow = styled(Row)`
  margin: 8px 16px;
`;

const StyledCol = styled(Col)`
  padding: 24px;
`;

const StyledFormControl = styled(FormControl)`
  width: 100%;
`;

const SelectLabel = styled(Span)`
  && {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.54);
    font-size: 1.2857142857142858rem;
    line-height: 1;
    transform: translate(0, 1.5px) scale(0.75);
    transform-origin: top left;
    margin-bottom: 4px;
  }
`;

export default Reports;
