/* eslint-disable react/jsx-wrap-multilines */
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import React, { useEffect, useState, useContext } from 'react';

import { ServiceType } from '../styled';
import Card from '../../Common/custom_card';
import { getServiceInfo } from '../../../controllers/snowm_firebase';
import { Title, StyledTypography, Row } from '../../../styles/snowm_styled';
import { LocalizationContext } from '../../../contexts/localization_context';

export default function RouteItem({ route, onDeletePress, onPress }) {
  const [type, setType] = useState(null);

  const { strings } = useContext(LocalizationContext);

  const { MARKERS } = strings?.sidemenu;
  const { INDOOR } = strings?.serviceType;
  const { DISTANCE, METERS } = strings?.misc;
  const { YES, NO } = strings?.messages;

  useEffect(() => {
    let isSubscribe = true;
    getServiceInfo(route.serviceKey).then(srv => {
      if (isSubscribe) {
        setType(srv.type);
      }
    });
    return () => {
      isSubscribe = false;
    };
  }, [route]);

  return (
    <Card
      small={12}
      medium={4}
      large={3}
      spacing={7}
      onDeletePress={() => onDeletePress(route)}
      onPress={onPress}
    >
      <Title>{route.name || '-'}</Title>

      <ServiceType style={{ margin: '15px auto' }}>{type}</ServiceType>

      <StyledTypography variant="subtitle1">
        {MARKERS}
        :
        <ColoredTypography>{route.servicePointsKeys.length}</ColoredTypography>
      </StyledTypography>
      {type === 'outdoor' ? (
        <StyledTypography variant="subtitle1">
          {DISTANCE}
          :
          <Row>
            <ColoredTypography>{route.distance}</ColoredTypography>
            <ColoredTypography style={{ marginLeft: 5 }}>
              {METERS}
            </ColoredTypography>
          </Row>
        </StyledTypography>
      ) : (
        <StyledTypography>
          {INDOOR}
          :
          <ColoredTypography>{type === 'indoor' ? YES : NO}</ColoredTypography>
        </StyledTypography>
      )}
    </Card>
  );
}

const ColoredTypography = styled(Typography)`
  && {
    color: #29abe2;
  }
`;
