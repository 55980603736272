import React from 'react';

import styled from 'styled-components';

import Span from '../Common/Span';
import color from '../../utils/color';
import { Row } from '../../styles/snowm_styled';

const ListTitleItem = ({ Icon, title, content, onClick, bgColor, size }) => {
  const Content = ({ fontcolor }) => {
    return (
      <StyledContent
        size={size ?? '16px'}
        weight="bold"
        color={fontcolor}
        content={content}
      >
        {content || '-'}
      </StyledContent>
    );
  };
  return (
    <Container onClick={onClick}>
      {Icon ? <Icon /> : null}
      <StyledRow
        marginleft={Icon ? '16px' : '0'}
        marginbottom="0"
        justify="space-between"
        content={content}
      >
        <ClickableText size={size ?? '16px'} content={content}>
          {title}
        </ClickableText>
        {bgColor ? (
          <TypeContainer bgcolor={bgColor}>
            <Content fontcolor={color.white} />
          </TypeContainer>
        ) : (
          <Content />
        )}
      </StyledRow>
    </Container>
  );
};

const TypeContainer = styled.div`
  background-color: ${(props) => props.bgcolor};
  padding: 2px 4px;
  border-radius: 6px;
`;

const Container = styled(Row)`
  padding: 4px 0;
`;

const ClickableText = styled(Span)`
  && {
    cursor: pointer;
    margin-left: 4px;
    text-align: ${(props) => props.content?.length > 25 && 'start'};
    width: ${(props) => props.content?.length > 25 && '100%'};
  }
`;

const StyledRow = styled(Row)`
  margin-left: ${(props) => props.marginleft};
  flex-direction: ${(props) => (props.content?.length > 25 ? 'column' : 'row')};
`;

const StyledContent = styled(Span)`
  && {
    text-align: ${(props) => (props.content?.length > 25 ? 'end' : 'none')};
    width: ${(props) => props.content?.length > 25 && '100%'};
  }
`;

export default ListTitleItem;
