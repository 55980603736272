import * as React from 'react';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import EmailSendOutlineIcon from 'mdi-react/EmailSendOutlineIcon';
import {
  Divider,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';

import Span from '../../Common/Span';
import color from '../../../utils/color';
import AlertBox from '../../Common/alert_box';
import AddButton from '../../Common/add_button';
import { AddScheduledReport } from './AddScheduleReport';
import ADialog, { StyledTableRow } from '../../Common/styled';
import Loader, { Progress } from '../../Generics/snowm_loader';
import { Row, StyledTitle } from '../../../styles/snowm_styled';
import { ServiceTypesContext } from '../../../contexts/service_types';
import {
  getScheduledReportsOfACompany,
  getMarkersForService,
  getPropertiesOfCompany,
  getProvidersOfCompany,
  deleteScheduledReportFromDatabase,
  sendEmail,
} from '../../../controllers/snowm_firebase';

const scheduleReportHeaders = [
  'Report Name',
  'Report Type',
  'Scheduled For',
  'Emails',
  'Actions',
];

const getTypeOfReport = (reportType) => {
  switch (reportType) {
    case 'byCrew':
      return 'Crew';
    case 'byMarker':
      return 'Marker';
    case 'byProperty':
      return 'Property';
    default:
      return '-';
  }
};

const getScheduledFor = (scheduledFor) => {
  switch (scheduledFor) {
    case 'daily':
      return 'Daily';
    case 'weekly':
      return 'Weekly';
    case 'biWeekly':
      return 'Bi-weekly';
    case 'monthly':
      return 'Monthly';
    case 'quarterly':
      return 'Quarterly';
    default:
      return '-';
  }
};

// eslint-disable-next-line import/prefer-default-export
export const ScheduleReport = () => {
  const { subscribedServices } = React.useContext(ServiceTypesContext);

  const [crews, setCrews] = React.useState(null);
  const [markers, setMarkers] = React.useState(null);
  const [crewUids, setCrewUids] = React.useState([]);
  const [markersKeys, setMarkersKeys] = React.useState([]);
  const [properties, setProperties] = React.useState(null);
  const [propertyKeys, setPropertyKeys] = React.useState([]);
  const [scheduledReports, setScheduledReports] = React.useState(null);
  const [isDeletingReport, setIsDeletingReport] = React.useState(false);
  const [openWarningPopUp, setOpenWarningPopUp] = React.useState(false);
  const [isScheduleModalOpen, setIsScheduleModalOpen] = React.useState(false);

  const [selectedScheduledReport, setSelectedScheduledReport] = React.useState(
    null
  );

  React.useEffect(() => {
    getScheduledReportsOfACompany().then((res) => {
      setScheduledReports(res);
    });
  }, []);

  React.useEffect(() => {
    let isSubscribe = true;
    if (subscribedServices.markerBased) {
      getMarkersForService('markerbased').then((res) => {
        const keysOfMarkers = res?.map((marker) => marker.key);
        const labelledMarkers = res?.map((marker) => {
          return {
            label: marker?.name,
            value: marker?.key,
          };
        });
        if (isSubscribe) {
          setMarkersKeys(keysOfMarkers);
          setMarkers([
            { label: 'All', value: keysOfMarkers },
            ...labelledMarkers,
          ]);
        }
      });
    }
    return () => {
      isSubscribe = false;
    };
  }, [subscribedServices]);

  React.useEffect(() => {
    let isSubscribe = true;
    getProvidersOfCompany().then((res) => {
      if (isSubscribe) {
        const uidsOfCrews = res?.map((crew) => crew?.masterUid ?? crew?.uid);
        const crewsWithlabel = res?.map((crew) => {
          return {
            label: crew?.name,
            value: crew?.masterUid ?? crew?.uid,
          };
        });
        setCrewUids(uidsOfCrews);
        setCrews([{ label: 'All', value: uidsOfCrews }, ...crewsWithlabel]);
      }
    });

    return () => {
      isSubscribe = false;
    };
  }, [subscribedServices]);

  React.useEffect(() => {
    let isSubscribe = true;

    if (subscribedServices?.properties) {
      getPropertiesOfCompany().then((res) => {
        const keysOfProperties = res.map((property) => property.id);
        const propertiesOptions = res?.map((property) => {
          return {
            label: property?.name,
            value: property?.id,
          };
        });

        if (isSubscribe) {
          setPropertyKeys(keysOfProperties);
          setProperties([
            { label: 'All', value: propertyKeys },
            ...propertiesOptions,
          ]);
        }
      });
    }
    return () => {
      isSubscribe = false;
    };
  }, [subscribedServices]);

  const openSchedulingModal = () => {
    setSelectedScheduledReport(null);
    setIsScheduleModalOpen(true);
  };

  const closeDialog = () => {
    setIsScheduleModalOpen(false);
  };

  const getPropertyNameById = (propertyKey) => {
    const selectedProperty = properties?.find(
      (property) => property.value === propertyKey
    );
    return selectedProperty?.label;
  };
  const getCrewNameByUid = (crewUid) => {
    const selectedCrew = crews?.find((crew) => crew.value === crewUid);
    return selectedCrew?.label;
  };

  const getMarkerNameByKey = (markerKey) => {
    const selectedMarker = markers?.find(
      (marker) => marker.value === markerKey
    );
    return selectedMarker?.label;
  };

  const getSelectedValue = ({
    selectedMarkersKeys,
    selectedCrewUids,
    selectedPropertyKeys,
    reportType,
  }) => {
    switch (reportType) {
      case 'byCrew': {
        const selectedCrews = selectedCrewUids?.map((crewUid) => ({
          value: crewUid,
          label: getCrewNameByUid(crewUid),
        }));

        return selectedCrews;
      }

      case 'byProperty': {
        const selectedProperties = selectedPropertyKeys?.map((propertyKey) => ({
          value: propertyKey,
          label: getPropertyNameById(propertyKey),
        }));
        return selectedProperties;
      }

      case 'byMarker': {
        const selectedMarkers = selectedMarkersKeys?.map((markerKey) => ({
          value: markerKey,
          label: getMarkerNameByKey(markerKey),
        }));

        return selectedMarkers;
      }

      default:
        break;
    }

    return null;
  };

  const handleEditIcon = (report) => {
    const {
      key,
      userUid,
      emailIds,
      companyKey,
      reportName,
      reportType,
      scheduledFor,
      selectedCrewUids,
      selectedMarkersKeys,
      selectedPropertyKeys,
    } = report;
    setIsScheduleModalOpen(true);

    setSelectedScheduledReport({
      reportName,
      reportType: { value: reportType, label: getTypeOfReport(reportType) },
      selectedValue: getSelectedValue({
        selectedMarkersKeys,
        selectedCrewUids,
        selectedPropertyKeys,
        reportType,
      }),
      scheduledFor: {
        value: scheduledFor,
        label: getScheduledFor(scheduledFor),
      },
      emailIds: emailIds.join(','),
      key,
      companyKey,
      userUid,
    });
  };

  const handleDeleteIcon = (report) => {
    setSelectedScheduledReport(report);
    setOpenWarningPopUp(true);
  };

  const cancelReportDeletion = () => {
    setOpenWarningPopUp(false);
  };

  const deleteTheReport = async () => {
    setIsDeletingReport(true);
    await deleteScheduledReportFromDatabase(selectedScheduledReport.key);

    const remainingScheduledReport = scheduledReports?.filter(
      (report) => report.key !== selectedScheduledReport.key
    );

    setScheduledReports(remainingScheduledReport);
    setOpenWarningPopUp(false);
  };

  const addNewSchedule = (newReport, editedReportKey) => {
    if (editedReportKey) {
      const scheduledReportWithEditReport = scheduledReports?.reduce(
        (acc, report) => {
          if (report.key === editedReportKey) {
            return [...acc, newReport];
          }
          return [...acc, report];
        },
        []
      );
      setScheduledReports(scheduledReportWithEditReport);
    } else {
      setScheduledReports([newReport, ...scheduledReports]);
    }
  };

  if (!scheduledReports) {
    return <Loader />;
  }

  return (
    <div style={{ margin: 16 }}>
      <Row>
        <StyledTitle $marginLeft="0">Scheduled Reports</StyledTitle>
      </Row>
      <Divider />
      <ScheduledReportsTable
        crews={crews}
        handleEditIcon={handleEditIcon}
        handleDeleteIcon={handleDeleteIcon}
        scheduledReports={scheduledReports}
      />
      <AddButton
        title="Create New Scheduled Report"
        onPress={openSchedulingModal}
      />
      <ADialog open={isScheduleModalOpen} maxWidth="400px">
        <AddScheduledReport
          crews={crews}
          markers={markers}
          crewUids={crewUids}
          properties={properties}
          markersKeys={markersKeys}
          propertyKeys={propertyKeys}
          closeScheduleDialog={closeDialog}
          addNewScheduleReport={addNewSchedule}
          selectedScheduledReport={selectedScheduledReport}
        />
      </ADialog>
      <AlertBox
        title="Delete?"
        open={openWarningPopUp}
        handleNegativeAction={cancelReportDeletion}
        handlePositiveAction={deleteTheReport}
        subtitle="Are you sure that you want to delete the selected report?"
        isTakingAction={isDeletingReport}
      />
    </div>
  );
};

const ScheduledReportsTable = ({
  scheduledReports,
  handleDeleteIcon,
  handleEditIcon,
}) => {
  if (scheduledReports?.length === 0) {
    return (
      <Row justify="center">
        <Span>No scheduled reports.</Span>
      </Row>
    );
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <StyledTableRow bgColor={color.snomwBlue}>
            {scheduleReportHeaders.map((header) => (
              <TableCell>
                <Span color={color.white} weight="bold">
                  {header}
                </Span>
              </TableCell>
            ))}
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {scheduledReports?.map((report) => (
            <>
              <ScheduledReportDetail
                report={report}
                handleDeleteIcon={handleDeleteIcon}
                handleEditIcon={handleEditIcon}
              />
            </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const ScheduledReportDetail = ({
  report,
  handleEditIcon,
  handleDeleteIcon,
}) => {
  const [isSendingEmail, setIsSendingEmail] = React.useState(false);

  const { reportName, reportType, scheduledFor, emailIds } = report;

  const sendEmaiWithPdf = async (scheduledReport) => {
    setIsSendingEmail(true);
    try {
      await sendEmail(scheduledReport);
    } catch (error) {
      console.log('Email error.');
      console.log({ error });
    }
    setIsSendingEmail(false);
  };

  const renderWithRespecToSendingEmail = () => {
    return isSendingEmail ? (
      <Progress size={15} />
    ) : (
      <EmailSendOutlineIcon fontSize="small" />
    );
  };

  const actions = [
    {
      title: 'Edit',
      onClick: (param) => handleEditIcon(param),
      icon: <EditIcon fontSize="small" />,
    },
    {
      title: 'Delete',
      onClick: (param) => handleDeleteIcon(param),
      icon: <DeleteIcon fontSize="small" />,
    },
    {
      title: 'Send now',
      onClick: (param) => sendEmaiWithPdf(param),
      icon: renderWithRespecToSendingEmail(),
    },
  ];

  return (
    <TableRow>
      <TableCell>
        <Span>{reportName}</Span>
      </TableCell>
      <TableCell>
        <Span>{getTypeOfReport(reportType)}</Span>
      </TableCell>
      <TableCell>
        <Span>{getScheduledFor(scheduledFor)}</Span>
      </TableCell>
      <TableCell>
        <Span>{emailIds?.join(', ') ?? '-'}</Span>
      </TableCell>
      <TableCell>
        <Row gap="4px">
          {actions.map((action) => (
            <Tooltip title={action.title}>
              <IconButton
                onClick={() => action.onClick(report)}
                size="small"
                disabled={isSendingEmail}
              >
                {action.icon}
              </IconButton>
            </Tooltip>
          ))}
        </Row>
      </TableCell>
    </TableRow>
  );
};
