import React, { useEffect, useState, useRef, useContext } from 'react';

import moment from 'moment';
import styled from 'styled-components';
import CloseIcon from 'mdi-react/CloseIcon';
import JobIcon from 'mdi-react/AccountHardHatIcon';
import BriefCaseIcon from 'mdi-react/BriefcaseIcon';
import BackArrowIcon from 'mdi-react/ChevronRightIcon';
import FilterOutlineIcon from 'mdi-react/FilterOutlineIcon';
import {
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';

import Map from '../Maps/snowm_maps';
import color from '../../../utils/color';
import ADialog from '../../Common/styled';
import Typography from '../../Common/Span';
import Loader from '../../Generics/snowm_loader';
import { Row, Col } from '../../../styles/snowm_styled';
import MarkerIcon from '../../../assets/images/marker_icon_white.png';
import { ServiceTypesContext } from '../../../contexts/service_types';
import { primaryColor, primaryLight } from '../../../styles/snowm_theme';
import { LocalizationContext } from '../../../contexts/localization_context';

import {
  getRealTimeLocationOfAllProviders,
  getCompanyDetail,
  getRealtimeCompanyDetails,
} from '../../../controllers/snowm_firebase';
import {
  Log,
  LoadContainer,
  TitleContainer,
  VerticalScroll,
  MapContainer,
  StyledCard,
  PaperCard,
  IconDiv,
  DetailsContainer,
  JobStat,
  MapStyledCard,
} from './styled';

export default function Home({
  history,
  fetchProviders,
  logs = [],
  getLogs,
  theme,
}) {
  const [markers, setMarkers] = useState();
  const [latLng, setLatLng] = useState({});
  const [properties, setProperties] = useState();
  const [activities, setActivities] = useState();
  const [company, setCompany] = useState();
  const [selectedImage, setSelectedImage] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [logType, setLogType] = useState('All');
  const [filteredLogs, setFilteredLogs] = useState(null);

  const { strings } = useContext(LocalizationContext);

  const serviceFeatures = useContext(ServiceTypesContext);

  const subscribedServices = serviceFeatures?.subscribedServices;

  const {
    home: { NOTIFICATIONS, SEE_MORE_INFO },
    status: { ASSIGNED, COMPLETED, STARTED },
    sidemenu: { JOBS, MARKERS },
  } = strings;

  const logsRef = useRef();

  const logTypes = [
    'All',
    'Service Request',
    'Incident Reported',
    'Job Information',
    'Job Exception (Late or no show)',
    'General Information',
  ];

  function getLogTypeColor(typeLog) {
    switch (typeLog) {
      case 'Service Request':
        return color.snomwBlue;
      case 'Incident Reported':
        return color.red;
      case 'Job Information':
        return color.amber;
      case 'Job Exception (Late or no show)':
        return color.red;
      default:
        return color.snomwBlue;
    }
  }
  useEffect(() => {
    if (subscribedServices?.properties) {
      setProperties(company?.properties);
    }

    if (subscribedServices?.activities || subscribedServices?.activitiesOnJob) {
      setActivities(company?.activities);
    }

    setMarkers(company?.servicePoints);
  }, [company, subscribedServices]);

  useEffect(() => {
    fetchProviders();
    getLogs();
    getRealtimeCompanyDetails(setCompany);
  }, []);

  useEffect(() => {
    if (logType === 'All') {
      setFilteredLogs(logs);
    } else {
      const logsByLogType = logs?.filter((log) => log.topic === logType);
      setFilteredLogs(logsByLogType);
    }
  }, [logType, logs]);

  useEffect(() => {
    let isSubscribe = true;

    function onChange(loc) {
      const latlongOfCrew = Object.entries(loc ?? {}).reduce(
        (accumulator, data) => {
          if (data[1].latitude) {
            return [...accumulator, { uid: data[0], ...data[1] }];
          }
          return accumulator;
        },
        []
      );

      if (isSubscribe) {
        setLatLng([...(latlongOfCrew ?? [])]);
      }
    }

    getRealTimeLocationOfAllProviders(onChange);
    return () => {
      isSubscribe = false;
    };
  }, []);

  function getLogsList() {
    if (filteredLogs.length === 0) {
      return (
        <LoadContainer>
          <Typography> No logs ...</Typography>
        </LoadContainer>
      );
    }

    function handleImageSelected(imageUrl) {
      setOpenDialog(true);
      setSelectedImage(imageUrl);
    }

    function handleLogClick(l) {
      if (l.route) {
        history.push(l.route);
      }
    }

    return filteredLogs.map((l) => {
      return (
        <Log key={l.key} onClick={() => handleLogClick(l)} cursor={!!l.route}>
          <Row justify="space-between">
            <JobStatus weight="bold">{l.title}</JobStatus>
            <Typography color={getLogTypeColor(l.topic)} weight="bold">
              {l.topic ? l.topic : 'General Information'}
            </Typography>
          </Row>
          <Row align="center">
            {l.imageUrl && (
              <StyledImageContainer
                type="button"
                onClick={() => handleImageSelected(l.imageUrl)}
              >
                <Image
                  src={l.imageUrl}
                  alt="Report"
                  height={50}
                  width={50}
                  borderRadius="50%"
                />
              </StyledImageContainer>
            )}
            <Typography size="16px">{l.message}</Typography>
          </Row>
          <Date size="12px" color="primary">
            {moment(l.date).format('MMMM DD, YYYY, h:mm A')}
          </Date>

          <div ref={logsRef} />
        </Log>
      );
    });
  }

  function handleLogTypeChange(event) {
    setLogType(event.target.value);
  }

  function getLogsContainer(t) {
    return (
      <>
        <TitleContainer>
          <StyledTitle size="1.5em" weight="bold">
            {NOTIFICATIONS}
          </StyledTitle>
          <FormControl
            style={{
              width: 200,
            }}
          >
            <InputLabel>
              <Row>
                <FilterOutlineIcon cursor="pointer" size={30} />
                Filter
              </Row>
            </InputLabel>
            <Select value={logType} onChange={handleLogTypeChange}>
              {logTypes.map((typeOfLog) => (
                <MenuItem key={typeOfLog} value={typeOfLog}>
                  <Typography fon={color.black}>{typeOfLog}</Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </TitleContainer>
        <VerticalScroll>{getLogsList(logs, t)}</VerticalScroll>
      </>
    );
  }

  function ContainerPaper({ children, icon: Icon, margin }) {
    return (
      <PaperCard margin={margin}>
        <IconDiv>
          <Icon />
        </IconDiv>
        {children}
      </PaperCard>
    );
  }
  function SeeMoreInfo({ onPress }) {
    return (
      <SeeMoreContainer
        marginbottom="0"
        align="center"
        justify="flex-end"
        onClick={onPress}
      >
        <Typography size="18px" color="primary" weight="bold">
          {SEE_MORE_INFO}
        </Typography>
        <BackArrowIcon color={primaryColor} size={40} />
      </SeeMoreContainer>
    );
  }

  function renderProperties() {
    if (properties) {
      return (
        <Col>
          <Typography size="1em" weight="bold">
            Properties
          </Typography>
          <Span size="1.5em">{properties ?? 0}</Span>
          <SeeMoreInfo onPress={() => history.push('/home/properties')} />
        </Col>
      );
    }
    return null;
  }

  function renderActivitiesDetail() {
    if (activities) {
      return (
        <Col>
          <Typography size="1em" weight="bold">
            Activities
          </Typography>
          <Span size="1.5em">{activities ?? 0}</Span>
          <SeeMoreInfo onPress={() => history.push('/home/activities')} />
        </Col>
      );
    }
    return null;
  }

  function handleCloseDialog() {
    setOpenDialog(false);
  }

  console.log({ company, subscribedServices });

  if (!company || !subscribedServices) {
    return <Loader />;
  }

  return (
    <>
      <StyledCotainer>
        <Row>
          <ContainerPaper margin="35px" icon={() => <JobIcon size={100} />}>
            <DetailsContainer>
              <Typography size="1em" weight="bold">
                {JOBS}
              </Typography>

              <Row marginbottom="0">
                {`${ASSIGNED}:`}
                <Typography>
                  <JobStat fontcolor="#0000ff">
                    {company?.assigned ?? 0}
                  </JobStat>
                </Typography>
              </Row>
              <Divider />

              <Row marginbottom="0">
                {`${STARTED}:`}
                <Typography>
                  <JobStat color="#ffbf00">{company?.started ?? 0}</JobStat>
                </Typography>
              </Row>
              <Divider />

              <Row marginbottom="0">
                Not Started:
                <Typography>
                  <JobStat fontcolor={color.red}>
                    {company?.not_started ?? 0}
                  </JobStat>
                </Typography>
              </Row>

              <Divider />

              <Row marginbottom="0">
                {`${COMPLETED}:`}
                <Typography>
                  <JobStat fontcolor="#003308">
                    {company?.completed ?? 0}
                  </JobStat>
                </Typography>
              </Row>

              <Divider />

              <Row marginbottom="0">
                Not Completed:
                <Typography>
                  <JobStat fontcolor={color.red}>
                    {company?.not_completed ?? 0}
                  </JobStat>
                </Typography>
              </Row>

              <SeeMoreInfo onPress={() => history.push('/home/jobs')} />
            </DetailsContainer>
          </ContainerPaper>
          {activities || properties ? (
            <ContainerPaper
              margin="35px"
              icon={() => <BriefCaseIcon size={100} />}
            >
              <DetailsContainer>
                <ServiceRouteContainer>
                  {renderActivitiesDetail()}
                  {renderProperties()}
                </ServiceRouteContainer>
              </DetailsContainer>
            </ContainerPaper>
          ) : (
            <div />
          )}
          <ContainerPaper
            icon={() => (
              <img src={MarkerIcon} alt="marker" height={100} width={100} />
            )}
          >
            <DetailsContainer>
              <Typography size="1em" weight="bold">
                {MARKERS}
              </Typography>
              <Span size="2.5em">{markers ?? 0}</Span>
              <SeeMoreInfo onPress={() => history.push('/home/markers')} />
            </DetailsContainer>
          </ContainerPaper>
        </Row>
        <MapContainer>
          <StyledLogsCard>{getLogsContainer(theme)}</StyledLogsCard>
          <MapStyledCard width="100%">
            <Map
              dashboard
              locations={latLng}
              center={[45.347767, -75.90983]}
              zoom={9}
            />
          </MapStyledCard>
        </MapContainer>
      </StyledCotainer>
      <ADialog open={openDialog}>
        <ImageModalContainer gap="4px">
          <Row justify="space-between" marginbottom="0">
            <Typography weight="bold" size="16px">
              Reported Site
            </Typography>
            <CloseIcon onClick={handleCloseDialog} cursor="pointer" />
          </Row>
          <Divider />
          <Image
            height={250}
            src={selectedImage}
            alt="Reporting site"
            borderRadius="8px"
          />
        </ImageModalContainer>
      </ADialog>
    </>
  );
}

const StyledCotainer = styled(Col)`
  padding: 35px;
`;

const StyledLogsCard = styled(StyledCard)`
  flex-basis: 80%;
`;

const StyledTitle = styled(Typography)`
  && {
    margin-top: 16px;
  }
`;

const SeeMoreContainer = styled(Row)`
  cursor: pointer;
`;

const Span = styled(Typography)`
  && {
    color: ${primaryLight};
  }
`;

const ServiceRouteContainer = styled.div`
  height: 75%;
`;

const JobStatus = styled(Typography)`
  && {
    margin-right: 8px;
  }
`;

const StyledImageContainer = styled.button`
  border: none;
  cursor: pointer;
`;

const Image = styled.img`
  border-radius: ${(props) => props.borderRadius};
`;

const ImageModalContainer = styled(Col)`
  padding: 8px;
`;

const Date = styled(Typography)`
  && {
    text-align: end;
  }
`;
