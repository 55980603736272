import React, { useContext, useState, useEffect } from 'react';

import styled from 'styled-components';
import { Redirect } from 'react-router';
import MuiAlert from '@material-ui/lab/Alert';
import { Collapse, IconButton, Snackbar, Tooltip } from '@material-ui/core';
import SortBoolAscendingVariantIcon from 'mdi-react/SortBoolAscendingVariantIcon';
import SortBoolDescendingVariantIcon from 'mdi-react/SortBoolDescendingVariantIcon';

import Span from '../../Common/Span';
import AddUpdateShift from './AddShift';
import Header from '../../Common/header';
import color from '../../../utils/color';
import Card from '../../Common/custom_card';
import AlertBox from '../../Common/alert_box';
import AddButton from '../../Common/add_button';
import Loader from '../../Generics/snowm_loader';
import PrimaryButton from '../../Common/primary_button';
import ListTitleItem from '../../Generics/list_title_item';
import { Col, Row, StyledTitle } from '../../../styles/snowm_styled';
import { ServiceTypesContext } from '../../../contexts/service_types';
import {
  getShiftsOfACompany,
  shiftDelete,
  getShiftJobsStatus,
} from '../../../controllers/snowm_firebase';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Shifts = () => {
  const data = useContext(ServiceTypesContext);

  const [shifts, setShifts] = useState(null);
  const [isSearch, setIsSearch] = useState(false);
  const [allShifts, setAllShifts] = useState(null);
  const [sortType, setSortType] = useState('desc');
  const [openSnackBar, setSnackBar] = useState(false);
  const [expandShift, setExpandShift] = useState(null);
  const [openAlertBox, setOpenAlertBox] = useState(false);
  const [selectedShift, setSelectedShift] = useState(null);
  const [openShiftDialog, setOpenShiftDialog] = useState(false);

  const subscribedServices = data?.subscribedServices;

  useEffect(() => {
    let isCancel = false;
    function _updateShifts(res) {
      if (!isCancel) {
        setAllShifts(res);
        setShifts(res);
      }
    }

    getShiftsOfACompany(_updateShifts);

    return () => {
      isCancel = true;
    };
  }, []);

  if (!subscribedServices || !shifts) return <Loader />;

  if (!subscribedServices?.shiftManagement) return <Redirect to="/home" />;

  function handleAddButton() {
    setOpenShiftDialog(true);
  }

  function closeDialog() {
    setSelectedShift(null);
    setOpenShiftDialog(false);
  }

  function closeAlertDialog() {
    setOpenAlertBox(false);
    setSelectedShift(null);
  }

  function closeSnackBar(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBar(false);
  }

  async function deleteShift() {
    try {
      await shiftDelete(selectedShift?.id);
      closeAlertDialog();
    } catch (error) {
      console.error('@@error', error);
    }
  }

  async function handleDeletePress(shift) {
    const activeJobs = await getShiftJobsStatus(shift.id);
    if (activeJobs) {
      setSnackBar(true);
    } else {
      setOpenAlertBox(true);
      setSelectedShift(shift);
    }
  }

  function handleEditPress(shift) {
    setSelectedShift(shift);
    setOpenShiftDialog(true);
  }

  const handleShiftClick = (id) => {
    setExpandShift({ ...expandShift, [id]: !expandShift?.[id] });
  };

  const RenderShifts = () => {
    if (!shifts?.length) {
      return (
        <Row justify="center">
          <Span>No shifts</Span>
        </Row>
      );
    }

    return (
      <ShiftsContainer>
        {shifts?.map((shift) => {
          const {
            id,
            name,
            startTime,
            endTime,
            raiseException,
            exceptionMinutes,
            repetative,
            finishJobAtEnd,
          } = shift;
          return (
            <Card
              minHeight="0"
              key={id}
              onDeletePress={() => handleDeletePress(shift)}
              onEditIconPress={() => handleEditPress(shift)}
            >
              <Col width="100%">
                <ShiftDetails
                  type="button"
                  onClick={() => handleShiftClick(id)}
                >
                  <Span>{name}</Span>
                  <Span color={color.grey}>{`${startTime} - ${endTime}`}</Span>
                </ShiftDetails>
                <Collapse in={expandShift?.[id]}>
                  {raiseException && (
                    <ListTitleItem
                      title="Raise Exception"
                      content={`After ${exceptionMinutes} mins`}
                    />
                  )}
                  <ListTitleItem
                    title="Repeat Jobs"
                    content={repetative ? 'Yes' : 'No'}
                  />

                  <ListTitleItem
                    title="Finish Job at End"
                    content={finishJobAtEnd ? 'Yes' : 'No'}
                  />
                </Collapse>
              </Col>
            </Card>
          );
        })}
      </ShiftsContainer>
    );
  };

  const handleSearchInput = (string) => {
    const res = allShifts?.reduce((s, curr) => {
      setIsSearch(true);
      if (curr.name.toLowerCase().includes(string.toLowerCase())) {
        return [...s, curr];
      }
      return s;
    }, []);
    setIsSearch(false);
    setShifts(res);
    setIsSearch(true);
  };

  function HandleAllSearchButton() {
    setShifts(allShifts);
    setIsSearch(false);
  }

  const sortByShiftsName = (a, b) => {
    const first = a.name.toLowerCase();
    const second = b.name.toLowerCase();
    if (sortType === 'asc') {
      if (first < second) return 1;
      if (second < first) return -1;
      return 0;
    }
    if (sortType === 'desc') {
      if (first > second) return 1;
      if (second > first) return -1;
      return 0;
    }
    return 0;
  };

  function handleSortingChange(sortingType) {
    shifts.sort(sortByShiftsName);
    setSortType(sortingType);
  }

  return (
    <Container>
      <StyledTitle>Shifts</StyledTitle>
      <Header
        heading="Search Shifts"
        subheading="Search by Shifts Name"
        placeholder="Shift name"
        paddingbottom="1em"
        localSearch
        handleSearch={handleSearchInput}
        indexName="Shifts"
        reset={!isSearch}
      >
        <Tooltip title="Sort">
          <IconButton>
            {sortType === 'desc' ? (
              <SortBoolAscendingVariantIcon
                size={30}
                cursor="pointer"
                onClick={() => handleSortingChange('asc')}
              />
            ) : (
              <SortBoolDescendingVariantIcon
                size={30}
                cursor="pointer"
                onClick={() => handleSortingChange('desc')}
              />
            )}
          </IconButton>
        </Tooltip>

        <div />
        <div />
      </Header>
      {isSearch && (
        <Row justify="center">
          <PrimaryButton
            onClick={() => {
              HandleAllSearchButton();
            }}
          >
            Show All
          </PrimaryButton>
        </Row>
      )}
      <RenderShifts />
      <AddButton onPress={handleAddButton} title="Create New Shift" />
      <AddUpdateShift
        open={openShiftDialog}
        handleNegativeAction={closeDialog}
        selectedShift={selectedShift}
      />
      <AlertBox
        open={openAlertBox}
        handlePositiveAction={deleteShift}
        handleNegativeAction={closeAlertDialog}
        title="Delete shift"
        subtitle="Do you want to delete shift?"
      />
      <Snackbar
        open={openSnackBar}
        autoHideDuration={6000}
        onClose={closeSnackBar}
      >
        <Alert onClose={closeSnackBar} severity="error">
          <Span color={color.white}>
            Currently there are active jobs on this schedule !
          </Span>
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Shifts;

const Container = styled.div`
  margin: 12px;
`;

const ShiftsContainer = styled.div`
  display: grid;
  grid-gap: 4px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
`;

const ShiftDetails = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
  background-color: ${color.white};
  cursor: pointer;
`;
