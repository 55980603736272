import React, { useEffect, useState } from 'react';

import IssueLink from '../../Profile/IssueLink';
import PrimaryButton from '../../../Common/primary_button';
import { fetchJobAuditHistory } from '../../../../controllers/snowm_firebase';

function JobAuditButtons({
  onViewClick,
  onEditClick,
  onHistoryClick,
  jobKey,
  historyOfJob,
}) {
  const [jobHistory, setJobHistory] = useState(historyOfJob ?? null);

  useEffect(() => {
    if (historyOfJob) {
      setJobHistory([...historyOfJob]);
    } else {
      setJobHistory(null);
    }
  }, [historyOfJob]);

  const checkJobAuditHistories = async () => {
    const auditLog = await fetchJobAuditHistory(jobKey, 1);
    setJobHistory(auditLog);
  };

  useEffect(() => {
    checkJobAuditHistories();
  }, []);

  const renderHistoryBtn = () => {
    if (jobHistory?.length) {
      return <PrimaryButton onClick={onHistoryClick}>History</PrimaryButton>;
    }
    return null;
  };

  return (
    <>
      <PrimaryButton onClick={onViewClick}>View</PrimaryButton>
      <PrimaryButton onClick={onEditClick}>Edit</PrimaryButton>
      {renderHistoryBtn()}
    </>
  );
}

export default JobAuditButtons;
