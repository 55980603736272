import React from 'react';

import ArrowRightIcon from 'mdi-react/ArrowRightIcon';
import { List, ListItem, ListItemText } from '@material-ui/core';

import Span from '../Common/Span';
import color from '../../utils/color';
import { Col } from '../../styles/snowm_styled';

const CompaniesList = ({ companiesInDetail, handleCompanySelection }) => {
  const companyDetail = ({ company }) => {
    return (
      <Col>
        <Span weight="bold" color={color.snomwBlue}>
          {company.name}
        </Span>
        <Span size="8px">{company.address}</Span>
      </Col>
    );
  };

  return (
    <List>
      {companiesInDetail?.map((company) => {
        if (!company) {
          return null;
        }
        return (
          <ListItem
            button
            onClick={() => handleCompanySelection(company)}
            key={company.key}
          >
            <ListItemText primary={companyDetail({ company })} />
            <ArrowRightIcon />
          </ListItem>
        );
      })}
    </List>
  );
};

export default CompaniesList;
