import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';

import Chart from 'chart.js';
import styled from 'styled-components';
import { useParams } from 'react-router';
import { Card, Divider } from '@material-ui/core';
import InformationIcon from 'mdi-react/InformationIcon';

import Span from '../../Common/Span';
import AssetInformation from './AssetInformation';
import MarkerBasicDetail from './marker_basic_detail';
import PrimayButton from '../../Common/primary_button';
import { ProfilePicture } from '../../Common/upload_pic';
import ListTitleItem from '../../Generics/list_title_item';
import { getDeviceTemperature } from '../../../controllers/api';
import { Title, Col, Row } from '../../../styles/snowm_styled';
import {
  getPropertyById,
  getServiceInfo,
  getServicePointById,
} from '../../../controllers/snowm_firebase';

const MarkerDetails = ({ details, closeDetailsBox, inModal }) => {
  const params = useParams();

  const { markerKey } = params;

  const [property, setProperty] = useState();
  const [activities, setActivities] = useState();
  const [batteryData, setBatteryData] = useState(null);
  const [markerDetails, setMarkerDetails] = useState(null);
  const [service, setService] = useState(null);
  const [ranges, setRanges] = useState({
    detectionRange: '',
    servingRange: '',
  });

  const canvasRef = useRef(null);

  useEffect(() => {
    if (markerDetails?.propertyKey) {
      getPropertyById(markerDetails?.propertyKey).then((res) => {
        setProperty(res);
      });
    }
  }, [markerDetails]);

  useEffect(() => {
    let isCancel = false;
    if (markerKey) {
      getServicePointById(markerKey).then((res) => {
        if (!isCancel) {
          setMarkerDetails(res);
        }
      });
    } else if (details) {
      if (!isCancel) {
        setMarkerDetails(details);
      }
    }

    return () => {
      isCancel = true;
    };
  }, [markerKey]);

  useEffect(() => {
    let isCancel = false;
    if (markerDetails) {
      getServiceInfo(markerDetails.serviceKey).then((res) => {
        if (!isCancel) {
          setService(res);
        }
      });
    }

    return () => {
      isCancel = true;
    };
  }, [markerDetails]);

  useEffect(() => {
    const data = markerDetails?.activities?.reduce((acc, curr) => {
      acc[curr.category] = acc[curr.category] ?? [];
      acc[curr.category] = [...acc[curr.category], curr];
      return acc;
    }, {});

    setActivities(data);
  }, []);

  const convertDateToReadableDate = (date) => {
    return moment(date).format('MMM DD h:mm A');
  };

  useEffect(() => {
    if (markerDetails) {
      getDeviceTemperature({
        deviceId: markerDetails?.beaconId,
        dataSet: 'battery',
        startDate: '',
        endDate: '',
        dateRange: '6 hours',
      })
        .then((res) => {
          if (Array.isArray(res.data)) {
            const newData = res.data?.reduce((acc, eachRes) => {
              return {
                surfaceTemperature: [
                  ...(acc.surfaceTemperature ?? []),
                  eachRes.surface_temperature,
                ],
                airTemperature: [
                  ...(acc.airTemperature ?? []),
                  eachRes.air_temperature,
                ],
                time: [
                  ...(acc.time ?? []),
                  convertDateToReadableDate(eachRes.timestamp),
                ],
              };
            }, {});

            setBatteryData(newData);
          } else {
            setBatteryData([]);
          }
        })
        .catch((error) => {
          console.log('Error', error);
        });
    }
  }, [markerDetails]);

  useEffect(() => {
    if (canvasRef.current) {
      const ctx = canvasRef.current.getContext('2d');

      // eslint-disable-next-line no-new
      new Chart(ctx, {
        type: 'line',
        data: {
          labels: batteryData?.time,
          datasets: [
            {
              label: 'Air Temperature',
              data: batteryData?.airTemperature,
              pointBackgroundColor: 'red',
            },
            {
              label: 'Surface Temperature',
              data: batteryData?.surfaceTemperature,
              pointBackgroundColor: 'blue',
            },
          ],
        },
        options: {},
      });
    }
  }, [batteryData]);

  function handleCloseButton() {
    closeDetailsBox();
  }

  const BeaconDetails = () => {
    return (
      <>
        <ListTitleItem
          Icon={InformationIcon}
          title="Device Name"
          content={markerDetails?.beaconName ?? markerDetails?.beaconId}
        />
        {markerDetails?.serialNumber && (
          <ListTitleItem
            Icon={InformationIcon}
            title="Serial Number"
            content={markerDetails?.serialNumber}
          />
        )}
      </>
    );
  };

  const temperatureGraph = () => {
    if (!service || !service?.serviceFeatures?.temperatureMonitoring) {
      return null;
    }

    return <canvas ref={canvasRef} width="400" height="400" />;
  };

  return (
    <MarkerDetailContainer>
      <MarkerBasicDetail
        ranges={ranges}
        marker={markerDetails}
        property={property}
        inModal={!markerKey && inModal}
        handleCloseIcon={handleCloseButton}
      />

      <Divider />

      <StyledTitle>Device Detail</StyledTitle>
      <Divider />

      <BeaconDetails />

      <Divider />

      {markerDetails?.activities?.length > 0 && (
        <>
          <StyledTitle>Activity Detail</StyledTitle>
          <Divider />
          <Col>
            {Object.keys(activities ?? {})?.map((category) => (
              <React.Fragment key={category}>
                <Row justify="space-between">
                  <Span>Activity Category</Span>
                  <Span weight="700">{category}</Span>
                </Row>
                {Object.values(activities[category]).map((activity) => (
                  <Row justify="space-between" key={activity.id}>
                    <Span>{activity.name}</Span>
                    <Span weight="700">
                      {activity.mandatory ? 'mandatory' : '-'}
                    </Span>
                  </Row>
                ))}
              </React.Fragment>
            ))}
          </Col>
        </>
      )}

      <AssetInformation details={markerDetails} />
      {markerDetails?.totalRepairCost ? (
        <Row justify="space-between">
          <Span size="16px">Total Repair cost till date</Span>
          <Span weight="bold" size="16px">
            ${Math.floor(markerDetails?.totalRepairCost)}
          </Span>
        </Row>
      ) : null}
      {markerDetails?.customerDetail && (
        <>
          <StyledTitle>Company Detail</StyledTitle>
          <Divider />

          <Row justify="space-between">
            <Span>Name</Span>
            <Span weight="700">
              {markerDetails?.customerDetail?.name ?? '-'}
            </Span>
          </Row>
          <Row justify="space-between">
            <Span>Email</Span>
            <Span weight="700">
              {markerDetails?.customerDetail?.email ?? '-'}
            </Span>
          </Row>
        </>
      )}

      {property && (
        <>
          <StyledTitle>Property Detail</StyledTitle>
          <Divider />
          {property.imageUrl && (
            <ProfilePicture alt="Property" src={property?.imageUrl} />
          )}

          <Row justify="space-between">
            <Span>Name</Span>
            <Span weight="700">{property.name}</Span>
          </Row>
          <Row justify="space-between">
            <Span>Address</Span>
            <Span weight="700">{property.address}</Span>
          </Row>
          <Row justify="space-between">
            <Span>Customer Name</Span>
            <Span weight="700">{property?.customer?.name ?? '-'}</Span>
          </Row>
          <Row justify="space-between">
            <Span>Customer Email</Span>
            <Span weight="700">{property?.customer?.email ?? '-'}</Span>
          </Row>
        </>
      )}

      <Divider />
      {inModal && (
        <StyledRow justify="flex-end">
          <PrimayButton onClick={handleCloseButton}>Close</PrimayButton>
        </StyledRow>
      )}

      {temperatureGraph()}
    </MarkerDetailContainer>
  );
};

export default MarkerDetails;

const StyledTitle = styled(Title)`
  && {
    font-size: 20px;
  }
`;

const StyledRow = styled(Row)`
  margin-top: 8px;
`;

const MarkerDetailContainer = styled(Card)`
  padding: 16px;
  margin: 16px;
`;
