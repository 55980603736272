import React, { useEffect, useState, useContext, Fragment } from 'react';

import styled from 'styled-components';
import DeviceIcon from 'mdi-react/BluetoothIcon';
import WorkerIcon from 'mdi-react/AccountHardHatIcon';
import HomeMapMarkerIcon from 'mdi-react/HomeMapMarkerIcon';
import HomeCityOutlineIcon from 'mdi-react/HomeCityOutlineIcon';
import {
  Card,
  Typography,
  Divider,
  Chip,
  MenuItem,
  List,
} from '@material-ui/core';

import IssueLink from './IssueLink';
import Span from '../../Common/Span';
import { Grid } from '../../Common/Grid';
import color from '../../../utils/color';
import Loader from '../../Generics/snowm_loader';
import { Col, Row } from '../../../styles/snowm_styled';
import ListTitleItem from '../../Generics/list_title_item';
import { ServiceTypesContext } from '../../../contexts/service_types';
import { LocalizationContext } from '../../../contexts/localization_context';
import {
  getCompanyDetail,
  getServices,
} from '../../../controllers/snowm_firebase';

export default function CompanyProfile(props) {
  const [services, setServices] = useState();
  const [companyDetail, setCompanyDetail] = useState(null);

  const { strings } = useContext(LocalizationContext);
  const { subscribedServices } = useContext(ServiceTypesContext);

  const { CREW, MARKERS, COMPANY_PROFILE, DEVICES } = strings?.sidemenu;

  useEffect(() => {
    let isSubscribed = false;
    getCompanyDetail().then((response) => {
      if (!isSubscribed) {
        setCompanyDetail(response ?? {});
      }
    });

    return () => {
      isSubscribed = true;
    };
  }, []);

  useEffect(() => {
    let isSubscribe = true;
    getServices().then((response) => {
      const servicesFromFirestore = response.map((d) => d.data()) ?? [];
      if (isSubscribe) setServices(servicesFromFirestore);
    });
    return () => {
      isSubscribe = false;
    };
  }, []);

  if (!companyDetail || !subscribedServices || !services) {
    return <Loader />;
  }

  const handleClick = (page) => {
    props.history.push(`/home/${page}`);
  };

  const baseJiraUrl = 'https://snowm2018.atlassian.net/browse';

  // const changeLogs = [
  //   {
  //     date: 'April 22, 2021',
  //     data: [{ issueName: '- Scheduling report not coming for issue fixed.' }],
  //   },
  //   {
  //     date: 'April 21, 2021',
  //     data: [
  //       { issueName: '- Removed assigned distance from company profile.' },
  //     ],
  //   },
  //   {
  //     date: 'April 13, 2021',
  //     data: [
  //       { issueName: '- Property address added to the markers.' },
  //       {
  //         issueName: '- Show more after job filter issue fixed.',
  //       },
  //     ],
  //   },
  //   {
  //     date: 'April 08, 2021',
  //     data: [{ issueName: '- UI issues changed.' }],
  //   },
  //   {
  //     date: 'April 05, 2021',
  //     data: [
  //       {
  //         issueName:
  //           '- Multiple crews & properties can be selected while scheduling report.',
  //       },
  //     ],
  //   },
  //   {
  //     date: 'April 02, 2021',
  //     data: [{ issueName: '- Added scheduled report.' }],
  //   },
  //   {
  //     date: 'March 24, 2021',
  //     data: [{ issueName: '- Periodic job search issue fixed.' }],
  //   },
  //   {
  //     date: 'March 18, 2021',
  //     data: [
  //       {
  //         issueName: '- Added cleanup start date.',
  //       },
  //     ],
  //   },
  //   {
  //     date: 'March 17, 2021',
  //     data: [{ issueName: '- Added jobs logs.' }],
  //   },
  //   {
  //     date: 'March 09, 2021',
  //     data: [
  //       { issueName: 'Unable to search places from the map issue fixed.' },
  //     ],
  //   },
  //   {
  //     date: 'March 08, 2021',
  //     data: [
  //       {
  //         issueName:
  //           'Address field dissabled issuue fixed & can only be picked from map issue fixed.',
  //       },
  //     ],
  //   },
  //   {
  //     date: 'March 05, 2021',
  //     data: [{ issueName: 'Added crew actions in the table in crew.' }],
  //   },
  //   {
  //     date: 'Feb 23, 2021',
  //     data: [
  //       {
  //         issueName: '- Property can be deselected.',
  //       },
  //       { issueName: '- Timesheets moved to the reports section.' },
  //       { issueName: '- Periodice jobs are selected by default.' },
  //       {
  //         issueName: '- Start time coming for not started job fixed.',
  //       },
  //     ],
  //   },
  //   {
  //     date: 'Feb 22, 2021',
  //     data: [{ issueName: 'Property can be selected.' }],
  //   },
  //   {
  //     date: 'Feb 16, 2021',
  //     data: [{ link: `${baseJiraUrl}/SM10-764`, issueName: '- SM10 - 764' }],
  //   },
  //   {
  //     date: 'Feb 15, 2021',
  //     data: [
  //       {
  //         link: `${baseJiraUrl}/SM10-747`,
  //         issueName: '- SM10 - 747',
  //       },
  //     ],
  //   },
  //   {
  //     date: 'Feb 09, 2021',
  //     data: [
  //       {
  //         link: `${baseJiraUrl}/SM10-757`,
  //         issueName: '- SM10 - 757',
  //       },
  //       {
  //         link: `${baseJiraUrl}/SM10-750`,
  //         issueName: '- SM10 - 750',
  //       },
  //       {
  //         link: `${baseJiraUrl}/SM10-745`,
  //         issueName: '- SM10 - 745',
  //       },
  //     ],
  //   },
  //   {
  //     date: 'Feb 05, 2021',
  //     data: [
  //       { link: `${baseJiraUrl}/SM10-716`, issueName: '- SM10 - 716' },
  //       { link: `${baseJiraUrl}/SM10-657`, issueName: '- SM10 - 657' },
  //     ],
  //   },
  //   {
  //     date: 'Feb 04, 2021',
  //     data: [
  //       { link: `${baseJiraUrl}/SM10-722`, issueName: '- SM10 - 722' },
  //       {
  //         link: `${baseJiraUrl}/SM10-692`,
  //         issueName: '- SM10 - 692',
  //       },
  //       {
  //         link: `${baseJiraUrl}/SM10-704`,
  //         issueName: '- SM10 - 704',
  //       },
  //       { issueName: '- Added device details in graph for marker.' },
  //     ],
  //   },
  // ];

  return (
    <>
      <CompanyWrapper>
        <HeaderWrapper>
          <Row justify="space-between">
            <Typography variant="h6">{COMPANY_PROFILE}</Typography>
          </Row>
          <Span color={color.snomwBlue} weight="bold">
            {companyDetail.name}
          </Span>
          <Span>{companyDetail.address}</Span>
        </HeaderWrapper>
        <StyledDivider />
        <ListTitleItem
          Icon={DeviceIcon}
          title={DEVICES}
          content={companyDetail.beacons ?? 0}
          onClick={() => handleClick('devices')}
        />
        <ListTitleItem
          Icon={WorkerIcon}
          title={CREW}
          content={companyDetail.providers ?? 0}
          onClick={() => handleClick('crews')}
        />
        <ListTitleItem
          Icon={HomeMapMarkerIcon}
          title={MARKERS}
          content={companyDetail.servicePoints ?? 0}
          onClick={() => handleClick('markers')}
        />
        {subscribedServices?.properties && (
          <ListTitleItem
            Icon={HomeCityOutlineIcon}
            title="Properties"
            content={companyDetail?.properties ?? 0}
            onClick={() => handleClick('properties')}
          />
        )}
        <Divider />
        <Typography>Subscribed Services</Typography>
        {services?.map((service) => {
          return <StyledChip key={service.key} label={service.name} />;
        })}
      </CompanyWrapper>
      {/* 
      <Divider /> */}
      {/* <Grid templateColumns="repeat(auto-fill, minmax(700px, 1fr))">
        <CompanyWrapper width="auto">
          <Col>
            <Row>
              <Span>Change Log</Span>
            </Row>
            <Divider />
            <List>
              {changeLogs?.map((changeLog) => {
                const { data } = changeLog;
                return (
                  <Fragment key={changeLog.date}>
                    <Row>
                      <Span weight="bold">{changeLog.date}</Span>
                    </Row>
                    {data?.map((issue, index) => {
                      return (
                        <>
                          <IssueLink link={issue.link} key={issue.link}>
                            <MenuItem
                              style={{ display: 'flex', flexWrap: 'wrap' }}
                            >
                              <Span color={color.snomwBlue}>
                                {issue.issueName}
                              </Span>
                            </MenuItem>
                          </IssueLink>
                          {!data[index + 1] && <Divider />}
                        </>
                      );
                    })}
                  </Fragment>
                );
              })}
            </List>
          </Col>
        </CompanyWrapper>
      </Grid> */}
    </>
  );
}

const CompanyWrapper = styled(Card)`
  margin: 1rem;
  padding: 1rem;
  width: ${(props) => props.width ?? '50%'};
`;
const HeaderWrapper = styled(Col)`
  display: flex;
  align-items: center;
`;

const StyledDivider = styled(Divider)`
  && {
    margin-top: 8px;
  }
`;

const StyledChip = styled(Chip)`
  margin: 4px;
`;
