import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Services from './snowm_services';

export default function() {
  return (
    <Switch>
      <Route path="/home/services/" component={Services} />
      <Redirect to="/home/services" />
    </Switch>
  );
}
