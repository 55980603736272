import React, { useState, useEffect, useContext, Fragment } from 'react';

import styled from 'styled-components';
import { Redirect } from 'react-router';
import PlusIcon from 'mdi-react/PlusIcon';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  Grid,
  Checkbox,
  Typography,
  Divider,
  Collapse,
} from '@material-ui/core';

import color from '../../../utils/color';
import Card from '../../Common/custom_card';
import AlertBox from '../../Common/alert_box';
import AddButton from '../../Common/add_button';
import FormModal from '../../Common/form_modal';
import Loader from '../../Generics/snowm_loader';
import { getLowerCasedWord } from '../../../helpers/misc';
import CustomTextField from '../../Common/CustomTextField';
import { ServiceTypesContext } from '../../../contexts/service_types';
import {
  Row,
  Title,
  StyledTitle as PageTitle,
} from '../../../styles/snowm_styled';
import ActivitiesContent, {
  StyledEditIcon,
  StyledIconButton,
} from './ActivitiesContent';
import {
  addOrEditActivity,
  getActivitiesOfACompany,
  deleteActivityDatabase,
  editCategoryInFirestore,
  deleteActivitiesOfACategory,
} from '../../../controllers/snowm_firebase';
import Header from '../../Common/header';
import PrimaryButton from '../../Common/primary_button';
import Sort from '../../Common/Sort';
import Span from '../../Common/Span';

export default function Activities() {
  const defaultActivity = {
    category: null,
    completed: null,
    mandatory: false,
    name: null,
  };
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [activityDetails, setActivityDetails] = useState(defaultActivity);
  const [activities, setActivities] = useState();
  const [isSearch, setIsSearch] = useState(false);
  const [allActivities, setAllActivities] = useState();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [categoryName, setCategoryName] = useState('');
  const [openCategoryDialog, setOpenCategoryDialog] = useState(false);
  const [categoryExpanded, setCategoryExpanded] = useState({});

  const servicesContext = useContext(ServiceTypesContext);
  const subscribedServices = servicesContext?.subscribedServices;

  function sortByCategory(first, second) {
    const categoryNameA = getLowerCasedWord(first.category);
    const categoryNameB = getLowerCasedWord(second.category);
    if (categoryNameA < categoryNameB) return -1;
    if (categoryNameA > categoryNameB) return 1;
    return 0;
  }

  function sortByActivityName(a, b) {
    const upperA = a.name?.toUpperCase();
    const upperB = b.name?.toUpperCase();
    if (upperA > upperB) return 1;
    if (upperA < upperB) return -1;
    return 0;
  }

  function _updateActivities(data, fromDatabase) {
    if (fromDatabase) {
      setAllActivities(data);
    }

    data.sort(sortByCategory);

    const newData = data?.reduce((acc, cur) => {
      acc[cur.category] = acc[cur.category] || [];
      acc[cur.category].push(cur);
      return acc;
    }, {});

    Object.keys(newData).forEach((eachData) => {
      newData[eachData].sort(sortByActivityName);
    });

    setActivities(newData);
  }

  useEffect(() => {
    let isSubscribe = true;

    if (isSubscribe) {
      getActivitiesOfACompany(_updateActivities);
    }

    return () => {
      isSubscribe = false;
    };
  }, []);

  useEffect(() => {
    if (isSearch && activities) {
      const categories = Object.keys(activities);
      const selectedCategories = categories.reduce((acc, curr) => {
        return { ...acc, [curr]: true };
      }, {});
      setCategoryExpanded(selectedCategories);
    } else {
      setCategoryExpanded({});
    }
  }, [isSearch, activities]);

  function handleActivityAddDialog() {
    setActivityDetails(defaultActivity);
    setOpenDialog(true);
  }

  function closeDialog() {
    setOpenDialog(false);
    setLoading(false);
  }

  async function handleAddButton(event) {
    event.preventDefault();
    setLoading(true);
    try {
      await addOrEditActivity({ ...activityDetails });
      setLoading(false);
      closeDialog();
    } catch (error) {
      console.error('@@error', error);
      setLoading(false);
    }
  }

  function handleCategory(event) {
    event.persist();

    setActivityDetails((prevData) => {
      return {
        ...prevData,
        category: event.target?.value ?? '',
      };
    });
  }

  function handleName(event) {
    event.persist();

    setActivityDetails((prevData) => {
      return { ...prevData, name: event.target.value };
    });
  }

  function handleMandatoryCheckBox(event) {
    event.persist();

    setActivityDetails((prevData) => {
      return {
        ...prevData,
        mandatory: event.target?.checked,
      };
    });
  }

  function openEditModal(activity) {
    setActivityDetails({ ...activity });
    setOpenDialog(true);
  }

  function openDeleteModal(activity) {
    setOpenDeleteDialog(true);
    setActivityDetails({ ...activity });
  }

  function handleAddActivity(e, activity = {}) {
    e.stopPropagation();
    setActivityDetails({ ...activityDetails, category: activity });
    setOpenDialog(true);
  }

  async function handleDeletion() {
    try {
      if (categoryName) {
        await deleteActivitiesOfACategory(categoryName);
      } else {
        await deleteActivityDatabase(activityDetails.id);
      }
      setOpenDeleteDialog(false);
    } catch (error) {
      console.error('@@deleteError', error);
    }
    setCategoryName('');
  }

  function closeAlertBox() {
    setOpenDeleteDialog(false);
    setCategoryName('');
  }

  async function editCategory(event) {
    setLoading(true);
    event.preventDefault();
    try {
      await editCategoryInFirestore(categoryName, activityDetails.category);
      setOpenCategoryDialog(false);
      setLoading(false);
    } catch (error) {
      console.error('@@error', error);
    }
    setCategoryName('');
  }

  function handleCategoryEdit(e, category) {
    e.stopPropagation();
    setCategoryName(category);
    setActivityDetails({ ...defaultActivity, category });
    setOpenCategoryDialog(true);
  }

  function closeEditDialog() {
    setOpenCategoryDialog(false);
    setCategoryName('');
    setActivityDetails(defaultActivity);
  }

  function handleCategoryDelete(e, category) {
    e.stopPropagation();
    setCategoryName(category);
    setOpenDeleteDialog(true);
  }

  function handleCategoryClick(category) {
    const categoryValue = categoryExpanded[category];
    setCategoryExpanded({ ...categoryExpanded, [category]: !categoryValue });
  }

  if (!activities || !subscribedServices) {
    return <Loader />;
  }

  if (!subscribedServices?.activities && !subscribedServices.activitiesOnJob) {
    return <Redirect to="/home" />;
  }
  const handleSearchInput = (searchWord) => {
    setIsSearch(true);
    const res = allActivities?.reduce((s, curr) => {
      const categoriesName = curr.category;
      const activitiesName = curr.name;
      const nameOfCategory = getLowerCasedWord(categoriesName);
      const activityName = getLowerCasedWord(activitiesName);
      const searchString = getLowerCasedWord(searchWord);
      if (
        activityName.includes(searchString) ||
        nameOfCategory.includes(searchString)
      ) {
        return [...s, curr];
      }
      return s;
    }, []);

    _updateActivities(res ?? []);
  };

  const handleChange = (sortedCategory) => {
    const sortedArrayOfCategory = sortedCategory.reduce((acc, category) => {
      return { ...acc, [category]: activities[category] };
    }, {});
    setActivities(sortedArrayOfCategory);
  };

  return (
    <div
      style={{
        margin: 16,
      }}
    >
      <PageTitle>Activities</PageTitle>
      <Header
        heading="Search Activities"
        subheading="Search by Activities Name"
        placeholder="Activity or category name"
        paddingbottom="1em"
        localSearch
        handleSearch={handleSearchInput}
        indexName="Shifts"
        reset={!isSearch}
      >
        <Sort
          array={Object.keys(activities)}
          onChange={(sorted) => handleChange(sorted)}
        />
        <div />
        <div />
      </Header>
      {isSearch && (
        <Row justify="center">
          <PrimaryButton
            onClick={() => {
              _updateActivities(allActivities);
              setIsSearch(false);
            }}
          >
            Show All Activities
          </PrimaryButton>
        </Row>
      )}

      <StyledGrid container spacing={1}>
        {Object.keys(activities).length > 0 ? (
          <>
            {Object.keys(activities)?.map((category) => (
              <StyledGrid
                padding="0px"
                item
                xs={12}
                sm={6}
                md={4}
                xl={3}
                key={category}
                onClick={() => handleCategoryClick(category)}
              >
                <Card minHeight="auto">
                  <StyledRow marginbottom="16px" justify="space-around">
                    <StyledTitle margintop="auto">{category}</StyledTitle>
                    <Row marginbottom="0px" justify="center">
                      <StyledIconButton
                        onClick={(e) => handleAddActivity(e, category)}
                      >
                        <PlusIcon />
                      </StyledIconButton>
                      <StyledIconButton
                        onClick={(e) => handleCategoryEdit(e, category)}
                      >
                        <StyledEditIcon />
                      </StyledIconButton>
                      <StyledIconButton
                        onClick={(e) => handleCategoryDelete(e, category)}
                      >
                        <DeleteIcon />
                      </StyledIconButton>
                    </Row>
                  </StyledRow>
                  <StyledCollapse in={categoryExpanded[category]} unmountOnExit>
                    <FullWidthDivider />
                    {Object.values(activities[category]).map((activity) => {
                      return (
                        <Fragment key={activity.id}>
                          <ActivitiesContent
                            openEditModal={openEditModal}
                            activity={activity}
                            openDeleteModal={openDeleteModal}
                          />
                          <FullWidthDivider
                            bgcolor={color.dividerColor}
                            light
                          />
                        </Fragment>
                      );
                    })}
                  </StyledCollapse>
                </Card>
              </StyledGrid>
            ))}
          </>
        ) : (
          <Row justify="center">
            <Span weight="bold">No activities.</Span>
          </Row>
        )}
        <AddButton
          title="Create Activity Category"
          onPress={handleActivityAddDialog}
        />
      </StyledGrid>

      <FormModal
        edit={activityDetails.id}
        loading={loading}
        open={openDialog}
        handlePositiveAction={handleAddButton}
        handleNegativeAction={closeDialog}
        title={
          activityDetails.id
            ? 'Edit Activity Category'
            : 'Add Activity Category'
        }
      >
        <CategoryTextField
          details={activityDetails}
          handleCategory={handleCategory}
        />

        <CustomTextField
          value={activityDetails.name}
          onChange={handleName}
          label="Activity Name"
        />
        <MandatoryContainer>
          <Typography>Mandatory</Typography>
          <Checkbox
            color="primary"
            checked={activityDetails.mandatory}
            onChange={handleMandatoryCheckBox}
          />
        </MandatoryContainer>
      </FormModal>

      {openCategoryDialog && (
        <FormModal
          edit
          title="Edit Category"
          handlePositiveAction={editCategory}
          handleNegativeAction={closeEditDialog}
          open={openCategoryDialog}
          loading={loading}
        >
          <CategoryTextField
            details={activityDetails}
            handleCategory={handleCategory}
          />
        </FormModal>
      )}
      <AlertBox
        open={openDeleteDialog}
        title="Deleting Activity"
        subtitle="Are you sure you want to delete?"
        handlePositiveAction={handleDeletion}
        handleNegativeAction={closeAlertBox}
      />
    </div>
  );
}

const CategoryTextField = ({ details, handleCategory }) => {
  return (
    <CustomTextField
      value={details?.category}
      onChange={handleCategory}
      label="Activity Category"
      disabled={!!details?.id}
    />
  );
};

const MandatoryContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1em;
`;

const StyledRow = styled(Row)`
  margin: 8px;
`;

const FullWidthDivider = styled(Divider)`
  && {
    width: 100%;
    background-color: ${(props) => props.bgcolor};
  }
`;

const StyledGrid = styled(Grid)`
  && {
    margin: ${(props) => props.margin};
    padding: ${(props) => props.padding};
  }
`;

const StyledTitle = styled(Title)`
  width: 100%;
`;

const StyledCollapse = styled(Collapse)`
  width: 100%;
`;

const MainTitle = styled(Title)`
  && {
    margin-left: 16px;
  }
`;
