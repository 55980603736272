import React, { useContext, useEffect, useState } from 'react';

import styled from 'styled-components';

import Reports from './snowm_reports';
import TabsToggle from '../../Common/TabsToggle';
import Attendance from '../attendance/Attendance';
import { ScheduleReport } from './ScheduleReport';
import { Col } from '../../../styles/snowm_styled';
import { ServiceTypesContext } from '../../../contexts/service_types';

const reports = {
  jobReports: 'Job Report',
  attendanceReports: 'Timesheets',
};

const ReportsTabs = () => {
  const { subscribedServices } = useContext(ServiceTypesContext);

  const { scheduledReports: isScheduledReportsEnabled } = subscribedServices;

  const [tabValue, setTabValue] = useState(0);
  const [reportsTabs, setReportsTabs] = useState({});

  const reportsKeys = Object.keys(reportsTabs);
  const reportLabels = Object.values(reportsTabs);

  useEffect(() => {
    if (isScheduledReportsEnabled) {
      setReportsTabs({ ...reports, scheduleReports: 'Scheduled Report' });
    } else {
      setReportsTabs(reports);
    }
  }, subscribedServices);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const renderJobReports = () => {
    if (reportsKeys[tabValue] !== 'jobReports') {
      return null;
    }

    return <Reports />;
  };

  const renderAttendanceReports = () => {
    if (reportsKeys[tabValue] !== 'attendanceReports') {
      return null;
    }

    return <Attendance />;
  };

  const renderScheduleReports = () => {
    if (reportsKeys[tabValue] !== 'scheduleReports') {
      return null;
    }

    return <ScheduleReport />;
  };

  return (
    <Container gap="8px">
      <TabsToggle
        currentTab={tabValue}
        arrayOfLabels={reportLabels}
        handleChange={handleChange}
      />
      {renderJobReports()}

      {renderAttendanceReports()}

      {renderScheduleReports()}
    </Container>
  );
};

export default ReportsTabs;

const Container = styled(Col)`
  margin: 16px;
`;
