/* eslint-disable no-await-in-loop */
/* eslint-disable react/jsx-wrap-multilines */
import { keyBy } from 'lodash';
import styled from 'styled-components';
import React, { useEffect, useState, useContext } from 'react';
import {
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Typography,
  Chip,
  Divider
} from '@material-ui/core';

import { Row } from '../../../styles/snowm_styled';
import IndoorMap from './Canvas/indoor_map_canvas';
import BeaconListBox from './Canvas/beacon_list_box';
import PrimayButton from '../../Common/primary_button';
import { ServicePointsContext } from '../../../contexts/service_points';
import ServicePointDetailItem from './components/service_point_detail_item';
import { LocalizationContext } from '../../../contexts/localization_context';
import {
  decodeHash,
  encodeOffsetToPolyline
} from '../../../controllers/map_helper';
import {
  fetchAllBeacons,
  getSubLocationInfo,
  addBeacon,
  saveLocation,
  deleteBeacon
} from '../../../controllers/navigine';
import {
  getUnassignedIndoorServicePoints,
  getRouteByKey,
  updateRouteData,
  getBeaconByBeaconId,
  addBeaconIdToFirestore,
  getLocationIdOfTheCompany,
  getMarkerByBeaconId
} from '../../../controllers/snowm_firebase';

export default function({ history, ...props }) {
  return (
    <ServicePointsContext.Consumer>
      {({ markers, fetchServicePoints }) => {
        return (
          <AddRoute
            fetchServicePoints={fetchServicePoints}
            allServicePoints={markers || []}
            history={history}
            {...props}
          />
        );
      }}
    </ServicePointsContext.Consumer>
  );
}

let newData;
function AddRoute({ fetchServicePoints, allServicePoints, routeInfo }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState('none');
  const [polylineHash, setPolylineHash] = React.useState('');
  const [beaconBoxOpen, setBeaconBoxOpen] = useState(false);
  const [openBeaconDetailsBox, setOpenBeaconDetailsBox] = useState(false);
  const [beaconPoint, setBeaconPoint] = useState({});
  // service points for current service
  const [servicePoints, setServicePoints] = useState({});

  const [beaconPoints, setBeaconPoints] = useState([]);
  const [navigineMarkers, setNavigineMarkers] = useState([]);
  const [unAssignedPoints, setUnAssignedPoints] = React.useState([]);
  const [unAssignedServicePoints, setUnAssignedServicePoints] = React.useState(
    []
  );

  // list of all the points to be drawn in polyline
  const [insert, setInsert] = useState(false);

  // for dialog box
  // point displayed in the dialog box
  const [route, setRoute] = useState({});
  const [subLocation, setSubLocation] = useState(null);
  const [selectedMarkerName, setSelectedMarkerName] = useState('');
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedMarker, setSelectedMarker] = useState({});

  const { strings } = useContext(LocalizationContext);

  const { SELECT_MARKER, SELECTED_MARKERS } = strings?.markers;
  const { CANCEL } = strings?.action;

  useEffect(() => {
    fetchServicePoints();
  }, []);

  useEffect(() => {
    setRoute(routeInfo);
  }, [routeInfo]);

  useEffect(() => {
    const markerArray = servicePoints && Object.values(servicePoints);
    const selectedPoint = markerArray?.find(
      marker => marker.beaconId === beaconPoint?.uuid
    );
    setSelectedMarkerName(selectedPoint?.name);
  }, [servicePoints, beaconPoint]);

  useEffect(() => {
    const routeKey = routeInfo.key;
    getRouteByKey(routeKey).then(response =>
      setPolylineHash(response.polyline)
    );
  }, [routeInfo]);

  useEffect(() => {
    const subLocationId = route.navigineLocationId;

    if (subLocationId) {
      getSubLocationInfo(subLocationId).then(response => {
        setSubLocation(response.sublocation);
      });
      fetchAllBeacons(subLocationId).then(response => {
        setNavigineMarkers(response.beacons);
        setBeaconPoints(response.beacons);
      });
      getUnassignedIndoorServicePoints().then(response => {
        setUnAssignedPoints(response);
      });
    }
  }, [route]);

  useEffect(() => {
    const beaconsMap = keyBy(beaconPoints, point => point.uuid);
    const points = unAssignedPoints.filter(sp => !beaconsMap[sp.beaconId]);
    setUnAssignedServicePoints(points);
  }, [beaconPoints, unAssignedPoints]);

  useEffect(() => {
    if (Object.keys(route).length) {
      Object.values(allServicePoints).forEach(sp => {
        // if (sp.subscribedServices.includes(match.params.serviceKey)) {
        let status = null;
        if (route.servicePointsKeys) {
          if (route.servicePointsKeys.includes(sp.key)) {
            status = 1;
          }
          // }
          servicePoints[sp.key] = { ...sp, status };
        }
      });
      setServicePoints({ ...servicePoints });
    }
  }, [allServicePoints, route.servicePointsKeys]);

  function closeDialog() {
    setInsert(false);
    setOpen(false);
  }

  function handleBeaconDialogClose() {
    setOpenBeaconDetailsBox(false);
    setBeaconBoxOpen(false);
  }

  function makeChanges(point, status) {
    if (point) {
      if (!route.servicePointsKeys) {
        route.servicePointsKeys = [];
      } else {
        route.servicePointsKeys = route.servicePointsKeys.filter(
          sp => sp !== point.key
        );
      }
      if (status) {
        route.servicePointsKeys.push(point.key);
      } else {
        const index = route.servicePointsKeys.indexOf(point[0].key);
        route.servicePointsKeys.splice(index, 1);
      }
      // setServicePoints({ ...servicePoints });
    }
    // setRoute(prevRoute =>  {
    //   return {...prevRoute, servicePointsKeys: [...route.servicePointsKeys]} });
  }

  async function handleBeaconDeleteButton() {
    const remainingBeaconPoints = beaconPoints;
    const index = remainingBeaconPoints.indexOf(beaconPoint);
    remainingBeaconPoints.splice(index, 1);

    setBeaconPoints([...remainingBeaconPoints]);

    const servicePoint = Object.values(servicePoints).filter(
      point => point.beaconId === beaconPoint.uuid
    );
    makeChanges(servicePoint, false);
    setOpenBeaconDetailsBox(false);
  }

  function selectServicePoint(servicePoint) {
    setBeaconPoints([
      ...beaconPoints,
      { kx: newData.kx, ky: newData.ky, uuid: servicePoint.beaconId }
    ]);
    if (route.servicePointsKeys) {
      if (route.servicePointsKeys.includes(servicePoint.key)) {
        setInsert(true);
      } else {
        setInsert(false);
      }
    }

    handleBeaconDialogClose();
    makeChanges(servicePoint, true);
  }

  async function saveRoute() {
    const subLocationId = routeInfo.navigineLocationId;
    setLoading(true);
    try {
      await updateRouteData(routeInfo.key, {
        polyline: polylineHash,
        servicePointsKeys: route.servicePointsKeys
      });

      setBeaconPoint({});

      const promise = navigineMarkers.map(async beacon => {
        let beaconInfo = { ...beacon };
        if (!beacon.id) {
          const beaconDetails = await getMarkerByBeaconId(beacon.uuid);
          beaconInfo = { ...beaconInfo, id: beaconDetails.navigineBeaconId };
        }
        return deleteBeacon(beaconInfo.id);
      });

      await Promise.all(promise);

      // eslint-disable-next-line no-restricted-syntax
      for (const point of beaconPoints) {
        const beacon = await getBeaconByBeaconId(point.uuid);

        const beaconDetails = await addBeacon(subLocationId, beacon, point);
        await addBeaconIdToFirestore(point.uuid, beaconDetails.beacon.id);
      }

      const locationId = await getLocationIdOfTheCompany();
      await saveLocation(locationId);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }

    setNavigineMarkers([...beaconPoints]);
  }

  function onMapTapped(pointData, showBeaconDialog) {
    if (mode === 'route') {
      const data = decodeHash(polylineHash);
      data.push(pointData);
      const hash = encodeOffsetToPolyline(data);
      setPolylineHash(hash);
    } else if (mode === 'beacon') {
      if (showBeaconDialog) {
        setBeaconPoint(pointData);
        setOpenBeaconDetailsBox(true);
      } else {
        newData = pointData;

        setBeaconBoxOpen(true);
      }
    } else if (showBeaconDialog) {
      const markerArray = Object.values(servicePoints);

      const clickedMarker = markerArray?.find(
        marker => marker.beaconId === pointData?.uuid
      );

      if (clickedMarker) setSelectedMarker(clickedMarker);
    } else {
      setSelectedMarker({});
    }
  }

  const changeMode = modeData => {
    setSelectedMarker({});
    setMode(modeData);
  };

  const clearRoutes = () => {
    setPolylineHash(null);
  };

  const undoRoutes = () => {
    const data = decodeHash(polylineHash);
    data.pop();
    const hash = encodeOffsetToPolyline(data);
    setPolylineHash(hash);
  };

  const handleEditButton = () => {
    setIsEditMode(!isEditMode);
  };

  function renderActionButtons() {
    if (mode === 'beacon') {
      return (
        <Button variant="outlined" onClick={() => changeMode('none')}>
          Finish
        </Button>
      );
    }

    if (mode === 'route') {
      return (
        <>
          <Button variant="outlined" onClick={() => clearRoutes()}>
            Clear Routes
          </Button>
          <Button variant="outlined" onClick={() => undoRoutes()}>
            Undo Routes
          </Button>
          <Button variant="outlined" onClick={() => changeMode('none')}>
            Finish
          </Button>
        </>
      );
    }
    return (
      <>
        {isEditMode ? (
          <>
            <Button variant="outlined" onClick={() => changeMode('route')}>
              Draw Route
            </Button>
            <Button variant="outlined" onClick={() => changeMode('beacon')}>
              Add/Delete Marker
            </Button>
          </>
        ) : (
          <PrimayButton onClick={handleEditButton}>Edit Route</PrimayButton>
        )}
      </>
    );
  }

  const handleMarkerListClick = markerDetail => {
    setSelectedMarker(markerDetail);
  };

  return (
    <Container>
      <ContentPane>
        <Row>
          <Typography variant="h5">{route ? route.name : ''}</Typography>

          <Chip label={route.serviceType} color="primary" />
        </Row>
        <SelectedItem>
          <Typography variant="subtitle1">{SELECTED_MARKERS}</Typography>
          {route.servicePointsKeys && route.servicePointsKeys.length !== 0 ? (
            route.servicePointsKeys.map(spKey => {
              const servicePointDetail = servicePoints[spKey];
              if (servicePointDetail) {
                return (
                  <ServicePointDetailItem
                    key={spKey}
                    servicePointDetail={servicePointDetail}
                    onClick={() => handleMarkerListClick(servicePointDetail)}
                    highlight={
                      servicePointDetail?.beaconId === selectedMarker?.beaconId
                    }
                  />
                );
              }
              return <div key={spKey} />;
            })
          ) : (
            <Typography variant="caption">No markers Selected</Typography>
          )}
        </SelectedItem>
        {isEditMode && (
          <>
            <Button
              variant="outlined"
              color="primary"
              disabled={mode !== 'none' || loading}
              style={{ marginTop: 10 }}
              onClick={saveRoute}
            >
              {loading ? (
                <CircularProgress style={{ margin: 10 }} size={16} />
              ) : (
                <Typography> Save Route</Typography>
              )}
            </Button>
            <Button
              style={{
                marginTop: 12
              }}
              variant="contained"
              color="primary"
              onClick={handleEditButton}
              disabled={loading || mode !== 'none'}
            >
              <Typography>Cancel Edit</Typography>
            </Button>
          </>
        )}
      </ContentPane>
      <MapContainer>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <div style={{ margin: 16 }}>{renderActionButtons()}</div>
          {subLocation ? (
            <IndoorMap
              hash={polylineHash}
              image={subLocation.files[0].fullUrl}
              imageHeight={subLocation.ph}
              imageWidth={subLocation.pw}
              beaconPoints={beaconPoints}
              onTapHandler={onMapTapped}
              selectedMarker={selectedMarker}
            />
          ) : (
            <p>Loading</p>
          )}
        </div>
      </MapContainer>
      <Dialog
        open={open}
        onClose={closeDialog}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle id="form-dialog-title">Create New Route</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please specify the name and type of route that you want to create
          </DialogContentText>

          <FormControlLabel
            control={
              <Checkbox
                checked={insert}
                onChange={() => setInsert(!insert)}
                value="Include in the route"
                color="primary"
              />
            }
            label="Add to the route"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary">
            {CANCEL}
          </Button>
        </DialogActions>
      </Dialog>
      <BeaconListBox
        open={beaconBoxOpen}
        handleNegativeAction={handleBeaconDialogClose}
      >
        <MarkerBoxTitle bold="bold">{SELECT_MARKER}</MarkerBoxTitle>
        <Divider />
        {unAssignedServicePoints.length > 0 ? (
          unAssignedServicePoints.map(servicePoint => {
            return (
              <Card
                key={servicePoint.key}
                style={{ margin: 8, padding: 8, cursor: 'pointer' }}
                onClick={() => selectServicePoint(servicePoint)}
              >
                <MarkerBoxTitle>{servicePoint.name}</MarkerBoxTitle>
              </Card>
            );
          })
        ) : (
          <MarkerBoxTitle>No available markers</MarkerBoxTitle>
        )}
      </BeaconListBox>
      <BeaconListBox
        handleNegativeAction={handleBeaconDialogClose}
        open={openBeaconDetailsBox}
        handleDeleteAction={handleBeaconDeleteButton}
        loading={loading}
      >
        <MarkerBoxTitle bold="bold">Delete Marker ?</MarkerBoxTitle>
        <MarkerBoxTitle style={{ marginTop: 12 }} variant="h6">
          {selectedMarkerName}
        </MarkerBoxTitle>
      </BeaconListBox>
    </Container>
  );
}

const MapContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: calc(100vh - 64px);
  flex: 3;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: 'space-between';
`;

const ContentPane = styled.div`
  padding: 10px;
  overflow-y: auto;
  min-width: 250px;
  height: calc(100vh - 84px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
`;

const SelectedItem = styled.div`
  /* padding: 10px; */
`;

const MarkerBoxTitle = styled(Typography)`
  && {
    font-weight: ${props => props.bold};
    text-align: center;
  }
`;
