import React, { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router';

import CompaniesList from './CompaniesList';
import UnAuthenticated from './UnAuthenticated';
import { AuthContext } from '../../contexts/auth_context';
import {
  getCompanyDetail,
  storeCompanyKey,
} from '../../controllers/snowm_firebase';

const ChooseCompany = () => {
  const userDetails = useContext(AuthContext);
  const { authenticated, setAuthenticated, setCompanyKey } = userDetails;
  const [companiesInDetail, setCompaniesInDetail] = useState(null);

  const getCompanyDetails = async (claims) => {
    const { roles } = claims;

    const companiesPromises = roles.map((roleDetail) => {
      return getCompanyDetail(roleDetail.companyKey);
    });

    const companies = await Promise.all(companiesPromises);
    setCompaniesInDetail(companies);
  };

  useEffect(() => {
    const { userClaims } = userDetails?.authenticated;
    if (userClaims) {
      getCompanyDetails(userClaims);
    }
  }, []);

  const handleCompanySelection = (company) => {
    localStorage.setItem('selectedCompanyKey', company.key);
    storeCompanyKey(company.key);
    setCompanyKey(company.key);
    setAuthenticated({ ...authenticated, canLogIn: true });
  };

  if (authenticated === false) {
    return <Redirect to="/login" />;
  }

  return (
    <UnAuthenticated>
      <CompaniesList
        companiesInDetail={companiesInDetail}
        handleCompanySelection={handleCompanySelection}
      />
    </UnAuthenticated>
  );
};

export default ChooseCompany;
