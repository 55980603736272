/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: service_points.js
 *
 *  Description: Contex API for Service Points
 *
 *  Author: Roshan Gautam (roshan@brainants.com)
 *
 *  Date created: 8-july-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */

import React, { createContext, useState } from 'react';
import {
  getRealTimeServicePoints,
  getRealTimeUnAssignedServicePoints,
  getServicePointById,
} from '../controllers/snowm_firebase';

export const ServicePointsContext = createContext();

export function ServicePointsProvider({ children }) {
  const [markers, setMarkers] = useState(null);
  const [unAssignedMarkers, setUnAssignedMarkers] = useState(null);

  const [spLoading, setSpLoading] = useState(null);

  function _updateServicePoints(servicePointsData) {
    setMarkers(servicePointsData);
    setUnAssignedMarkers(servicePointsData);
    setSpLoading(false);
  }
  function _updateUnAssignedServicePoints(servicePointsData) {
    setUnAssignedMarkers(servicePointsData);
    setSpLoading(false);
  }

  function fetchServicePoints() {
    setSpLoading(true);
    getRealTimeServicePoints(_updateServicePoints);
  }

  function fetchUnAssignedServicePoints() {
    setSpLoading(true);
    getRealTimeUnAssignedServicePoints(_updateUnAssignedServicePoints);
  }

  const getMarkerByKey = async (markerKey) => {
    let marker = markers?.[markerKey];
    if (marker) {
      return marker;
    }

    marker = await getServicePointById(markerKey);
    return marker;
  };
  return (
    <ServicePointsContext.Provider
      value={{
        markers,
        unAssignedMarkers,
        fetchServicePoints,
        fetchUnAssignedServicePoints,
        spLoading,
        getMarkerByKey,
      }}
    >
      {children}
    </ServicePointsContext.Provider>
  );
}
