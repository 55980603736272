import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { useHistory } from 'react-router';
import { Card, Divider } from '@material-ui/core';

import Span from '../../Common/Span';
import { Grid } from '../../Common/Grid';
import Loader from '../../Generics/snowm_loader';
import ListTitleItem from '../../Generics/list_title_item';
import { Col, Row, Title } from '../../../styles/snowm_styled';
import MarkerBasicDetail from '../markers/marker_basic_detail';

import {
  getMarkersByPropertyKey,
  getPropertyById,
} from '../../../controllers/snowm_firebase';

const PropertyMarkers = ({ match }) => {
  const { key } = match?.params;

  const history = useHistory();

  const { location } = history;

  const { state } = location;

  const [markers, setMarkers] = useState();
  const [property, setProperty] = useState(state?.property);

  useEffect(() => {
    let isSubscribe = true;
    getMarkersByPropertyKey(key).then((res) => {
      if (isSubscribe) setMarkers(res);
    });

    return () => {
      isSubscribe = false;
    };
  }, [match]);

  useEffect(() => {
    let isSubscribe = true;
    if (!property) {
      getPropertyById(key).then((res) => {
        if (isSubscribe) setProperty(res);
      });
    }

    return () => {
      isSubscribe = false;
    };
  }, [match]);

  if (!markers || !property) {
    return <Loader />;
  }

  const propertyMarkersDetails = () => {
    if (markers.length === 0) {
      return (
        <NoMarkerContainer justify="center">
          <Span weight="bold">No markers.</Span>
        </NoMarkerContainer>
      );
    }

    return (
      <>
        <Row>
          <Span weight="bold" size="24px">
            Markers
          </Span>
        </Row>
        <Grid templateColumns="repeat(auto-fill, minmax(400px, 1fr))">
          {markers?.map((marker) => (
            <StyledCard key={marker.key}>
              <MarkerBasicDetail marker={marker} property={property} />
            </StyledCard>
          ))}
        </Grid>
      </>
    );
  };

  return (
    <Col
      gap="8px"
      style={{
        margin: 24,
      }}
    >
      <Row justify="center">
        <Title>{property?.name}</Title>
      </Row>
      <Grid templateColumns="repeat(auto-fill, minmax(400px, 1fr))">
        <StyledCard>
          <ListTitleItem title="Name" content={property.name} />

          {property.imageUrl && (
            <ListTitleItem
              title="Property Name"
              content={
                <a
                  href={property.imageUrl}
                  target="_blank"
                  without
                  rel="noreferrer"
                >
                  <Image src={property.imageUrl} alt="property" height={100} />
                </a>
              }
            />
          )}
          <ListTitleItem title="Address" content={property.address} />
        </StyledCard>
      </Grid>

      <Divider />

      {propertyMarkersDetails()}
    </Col>
  );
};

export default PropertyMarkers;

const StyledCard = styled(Card)`
  padding: 16px;
`;

const NoMarkerContainer = styled(Row)`
  height: 100%;
`;

const Image = styled.img`
  object-fit: contain;
`;
