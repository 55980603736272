import React, { useReducer, useState, useEffect } from 'react';

import {
  DialogTitle,
  TextField,
  CircularProgress,
  Button,
} from '@material-ui/core';

import Span from '../Common/Span';
import color from '../../utils/color';
import { Col, Row } from '../../styles/snowm_styled';
import { StyledOutlineButton, passwordRange } from './UnAuthenticated';
import { getCode, resetPassword } from '../../controllers/snowm_firebase';

const ResetPassword = ({ handleDialog, ...props }) => {
  const initialEmailState = {
    email: '',
    loading: false,
    error: '',
  };

  const initialState = {
    password: null,
    code: null,
    error: null,
    loading: false,
  };

  function emailReducer(state, action) {
    switch (action.type) {
      case 'email':
        return { ...state, email: action.value };
      case 'error':
        return { ...state, error: action.value };
      case 'loading':
        return { ...state, loading: action.value };
      default:
        throw new Error();
    }
  }

  function reducer(state, action) {
    switch (action.type) {
      case 'code':
        return { ...state, code: action.value };

      case 'newPassword':
        return { ...state, password: action.value };
      case 'error':
        return { ...state, error: action.value };
      case 'loading':
        return { ...state, loading: action.value };
      default:
        throw new Error();
    }
  }

  const [emailState, emailDispatch] = useReducer(
    emailReducer,
    initialEmailState
  );

  const [codeAndPassword, dispatch] = useReducer(reducer, initialState);

  const [codeSent, setCodeSent] = useState(false);

  useEffect(() => {
    if (!props?.open) {
      dispatch({ type: 'error', value: '' });
      emailDispatch({ type: 'error', value: '' });
      setCodeSent(false);
    }
  }, [props]);

  async function handleEmailSubmit(event) {
    event.preventDefault();
    emailDispatch({ type: 'loading', value: true });

    try {
      const response = await getCode(emailState.email);
      if (response?.data?.success) {
        setCodeSent(true);
      } else {
        emailDispatch({ type: 'error', value: response?.data?.message });
      }
    } catch (error) {
      emailDispatch({
        type: 'error',
        value: 'Something went wrong.',
      });
    }
    emailDispatch({ type: 'loading', value: false });
  }

  function handleEmailChange(event) {
    const emailAddress = event.target.value.trim();
    emailDispatch({ type: 'email', value: emailAddress });
  }

  async function handleResetPassword(event) {
    event.preventDefault();
    dispatch({
      type: 'loading',
      value: true,
    });
    try {
      await resetPassword(codeAndPassword);
      handleDialog();
    } catch (error) {
      switch (error.code) {
        case 'auth/invalid-action-code':
          dispatch({ type: 'error', value: error.message });
          break;

        default:
          dispatch({
            type: 'error',
            value: 'One time code does not match.',
          });
          break;
      }
      dispatch({ type: 'loading', value: false });
    }
  }

  function handleCode(event) {
    event.preventDefault();
    dispatch({
      type: 'code',
      value: event.target.value,
    });
  }

  function handleNewPassword(event) {
    event.preventDefault();
    dispatch({ type: 'newPassword', value: event.target.value });
  }

  function renderResetPasswordError() {
    if (codeAndPassword.error) {
      return (
        <Row justify="center">
          <Span color="error">{codeAndPassword.error}</Span>
        </Row>
      );
    }
    return null;
  }

  function renderEmailError() {
    if (emailState.error) {
      return (
        <Row justify="center">
          <Span color="error">{emailState.error}</Span>
        </Row>
      );
    }
    return null;
  }

  const cancelResetPassword = () => {
    handleDialog();
  };

  const cancelSendEmail = () => {
    handleDialog();
  };

  if (!codeSent) {
    return (
      <Col justify="center">
        <Row>
          <DialogTitle>Send Email</DialogTitle>
        </Row>
        <form onSubmit={handleEmailSubmit}>
          <Row justify="center">
            <TextField
              label="Email"
              onChange={handleEmailChange}
              required
              type="email"
              name="email"
              autoComplete="email"
            />
          </Row>
          <Row justify="center">
            <Button color="primary" onClick={cancelSendEmail}>
              Cancel
            </Button>
            <StyledOutlineButton
              disabled={emailState.loading}
              type="submit"
              width="auto"
              {...props}
            >
              {emailState.loading ? (
                <CircularProgress size={15} />
              ) : (
                <Span color={color.white}>Send email</Span>
              )}
            </StyledOutlineButton>
          </Row>
          {renderEmailError()}
        </form>
      </Col>
    );
  }

  return (
    <form onSubmit={handleResetPassword} style={{ padding: 8 }}>
      <Row justify="center">
        <DialogTitle>Reset Password</DialogTitle>
      </Row>
      <Row justify="center">
        <Span color="primary">
          A code is sent to the email address that you&apos;ve provided.
        </Span>
      </Row>
      <Row justify="center">
        <TextField
          required
          label="Code"
          onChange={handleCode}
          inputProps={{
            maxLength: 6,
          }}
        />
      </Row>
      <Row justify="center">
        <TextField
          inputProps={passwordRange}
          required
          label="New Password"
          onChange={handleNewPassword}
          type="password"
        />
      </Row>

      <Row justify="center">
        <Button color="primary" onClick={cancelResetPassword}>
          Cancel
        </Button>
        <StyledOutlineButton
          disabled={codeAndPassword.loading}
          type="submit"
          width="auto"
          flexbasis="auto"
          {...props}
        >
          {codeAndPassword.loading ? (
            <CircularProgress size={15} />
          ) : (
            <Span color={color.white}>Reset Password</Span>
          )}
        </StyledOutlineButton>
      </Row>
      {renderResetPasswordError()}
    </form>
  );
};

export default ResetPassword;
