import React, { useContext, useEffect, useState } from 'react';

import EyeIcon from 'mdi-react/EyeIcon';
import EditIcon from '@material-ui/icons/Edit';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Tooltip,
} from '@material-ui/core';

import color from '../../../utils/color';
import CrewsActions from './CrewsActions';
import { Col } from '../../../styles/snowm_styled';
import Span, { BrokenWord } from '../../Common/Span';
import { StyledTableRow } from '../../Common/styled';
import { getRelativeDate } from '../../../helpers/date';
import { AuthContext } from '../../../contexts/auth_context';
import { getBgColorOfTableRow } from '../../../helpers/misc';
import { isCrewWorking } from '../../../controllers/snowm_firebase';

const CrewsTable = ({
  crews,
  onEditIconPress,
  showProviderDetail,
  handleCrewAction,
  anchorElForActions,
  loading,
  vendors,
}) => {
  const contextOfAuth = useContext(AuthContext);

  const { company } = contextOfAuth;

  const { markerFeatures } = company;
  const { createCrewIdLogin } = markerFeatures;

  return (
    <div style={{ margin: 16 }}>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <StyledTableRow bgcolor={color.snomwBlue}>
              <TableCell>
                <Span color={color.white} weight="bold">
                  Name
                </Span>
              </TableCell>

              <TableCell>
                <Span color={color.white} weight="bold">
                  Contact
                </Span>
              </TableCell>

              <TableCell>
                <Span color={color.white} weight="bold">
                  Status
                </Span>
              </TableCell>

              {createCrewIdLogin && (
                <TableCell>
                  <Span color={color.white} weight="bold">
                    Crew Id
                  </Span>
                </TableCell>
              )}
              <TableCell>
                <Span color={color.white} weight="bold">
                  Last served
                </Span>
              </TableCell>

              <TableCell>
                <Span color={color.white} weight="bold">
                  Actions
                </Span>
              </TableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {crews?.map((crew, index) => (
              <CrewBody
                crew={crew}
                createCrewIdLogin={createCrewIdLogin}
                key={crew.masterUid ?? crew.uid}
                index={index}
                onEditIconPress={onEditIconPress}
                showProviderDetail={showProviderDetail}
                handleCrewAction={handleCrewAction}
                anchorElForActions={anchorElForActions}
                loadingWhileAction={loading?.[crew.masterUid ?? crew.uid]}
                vendors={vendors}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default CrewsTable;

const CrewBody = (props) => {
  const {
    crew,
    createCrewIdLogin,
    index,
    onEditIconPress,
    showProviderDetail,
    handleCrewAction,
    anchorElForActions,
    loadingWhileAction,
    loading,
    vendors,
  } = props;

  const [isWorking, setIsWorking] = useState(null);

  useEffect(() => {
    isCrewWorking(crew.uid, (res) => {
      setIsWorking(res);
    });
  }, []);
  const renderCrewIdDetails = () => {
    if (!createCrewIdLogin) {
      return null;
    }
    return (
      <TableCell>
        <Span>{crew?.crewId}</Span>
      </TableCell>
    );
  };

  const lastLoggedInTime = () => {
    if (!crew?.loggedIn) {
      return '-';
    }

    return getRelativeDate(crew?.lastSignIn);
  };

  const renderStatus = () => {
    if (isWorking === null) {
      return '-';
    }

    if (isWorking) {
      return 'Working';
    }
    return 'Not Working';
  };

  return (
    <StyledTableRow bgcolor={getBgColorOfTableRow(index)}>
      <TableCell>
        <Span weight="bold">{crew?.name}</Span>
        <br />
        {crew.disabled && (
          <Span weight="bold" color={color.red} size="10px">
            Disabled
          </Span>
        )}
      </TableCell>

      <TableCell>
        <Col>
          <BrokenWord>{crew?.email}</BrokenWord>
          <Span>{crew?.phoneNumber}</Span>
        </Col>
      </TableCell>
      <TableCell>
        <Span>{renderStatus()}</Span>
      </TableCell>

      {renderCrewIdDetails()}

      <TableCell>
        <Span>{lastLoggedInTime()}</Span>
      </TableCell>
      <TableCell>
        <Tooltip title="View">
          <IconButton onClick={() => showProviderDetail(crew)} size="small">
            <EyeIcon size="24px" />
          </IconButton>
        </Tooltip>

        {!crew.disabled && !vendors && (
          <Tooltip title="Edit">
            <IconButton onClick={() => onEditIconPress(crew)} size="small">
              <EditIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
        <CrewsActions
          handleCrewAction={handleCrewAction}
          anchorElForActions={anchorElForActions}
          loadingWhileAction={loadingWhileAction}
          crew={crew}
          loading={loading}
        />
      </TableCell>
    </StyledTableRow>
  );
};
