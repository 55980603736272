import React, { useEffect, useState } from 'react';

import { MenuItem, TextField } from '@material-ui/core';

import Span from '../../Common/Span';
import { status } from '../../../enums/jobStatus';
import { LowerCaseButton } from '../../Common/styled';
import { Progress } from '../../Generics/snowm_loader';
import { Col, Row } from '../../../styles/snowm_styled';
import PrimaryButton from '../../Common/primary_button';
import {
  getFilteredJobs,
  getPropertiesOfCompany,
  getProvidersOfCompany,
  getRealTimeServicePoints,
  getServices,
} from '../../../controllers/snowm_firebase';

const jobStatus = Object.keys(status);

const JobFilter = ({ handleJobs, handleMenuClose, allJobs, reset }) => {
  const defaultFilterData = {
    serviceKey: '',
    markerKey: '',
    propertyKey: '',
    providerKey: '',
    status: '',
  };

  const [services, setServices] = useState(null);
  const [markers, setMarkers] = useState(null);
  const [properties, setProperties] = useState(null);
  const [filterAttrs, setFilterAttrs] = useState(defaultFilterData);
  const [isFetching, setIsFetching] = useState(false);
  const [lastJob, setLastJob] = useState(null);
  const [crews, setCrews] = useState(null);

  useEffect(() => {
    function updateServicePoints(res) {
      setMarkers(res);
    }
    getServices().then((res) => {
      const response = res.map((d) => d.data());
      setServices(response);
    });

    getRealTimeServicePoints(updateServicePoints);

    getProvidersOfCompany().then((res) => {
      setCrews(res);
    });
    getPropertiesOfCompany().then((res) => {
      setProperties(res);
    });
  }, []);

  useEffect(() => {
    if (reset) {
      setFilterAttrs(defaultFilterData);
    }
  }, [reset]);

  function isAllEmpty() {
    return (
      !filterAttrs.serviceKey &&
      !filterAttrs.propertyKey &&
      !filterAttrs.markerKey &&
      !filterAttrs.status &&
      !filterAttrs.providerKey
    );
  }

  async function handleFilter(event, filters) {
    const data = filters ?? filterAttrs;
    setIsFetching(true);
    const currentStatus = data?.status?.toLowerCase() ?? '';
    const response = await getFilteredJobs({
      ...data,
      status: currentStatus,
      lastData: null,
    });

    if (data.providerKey) {
      response.jobs = response.jobs.filter((res) => {
        return res?.providerUids?.includes(data.providerKey);
      });
    }
    setLastJob(response.lastJob);
    handleJobs(response.jobs, false, filterAttrs);
    setIsFetching(false);
  }

  function handleValueChange(event, key) {
    setFilterAttrs({ ...filterAttrs, [key]: event.target.value });
  }

  const renderProperty = () => {
    if (!properties?.length) {
      return null;
    }

    return (
      <TextField
        variant="outlined"
        select
        label="Select Property"
        onChange={(event) => handleValueChange(event, 'propertyKey')}
        value={filterAttrs.propertyKey}
      >
        {properties.map((property) => (
          <MenuItem key={property.id} value={property.id}>
            {property.name}
          </MenuItem>
        ))}
      </TextField>
    );
  };

  const renderMarkers = () => {
    const markersDetails = Object.values(markers ?? {});

    if (!markersDetails?.length) {
      return null;
    }
    return (
      <TextField
        select
        label="Select Marker"
        variant="outlined"
        onChange={(event) => handleValueChange(event, 'markerKey')}
        value={filterAttrs.markerKey}
      >
        {markersDetails?.map((marker) => (
          <MenuItem key={marker.key} value={marker.key}>
            {marker.name}
          </MenuItem>
        ))}
      </TextField>
    );
  };

  const renderCrew = () => {
    const crewsArray = Object.values(crews ?? {});

    if (!crewsArray?.length) {
      return null;
    }
    return (
      <TextField
        select
        label="Select Crew"
        variant="outlined"
        onChange={(event) => handleValueChange(event, 'providerKey')}
        value={filterAttrs.providerKey}
      >
        {crewsArray?.map((crew) => (
          <MenuItem
            key={crew.masterUid ?? crew.uid}
            value={crew.masterUid ?? crew.uid}
          >
            {crew.name}
          </MenuItem>
        ))}
      </TextField>
    );
  };

  function handleClose() {
    handleMenuClose();
  }
  function clear() {
    const jobsArray = Object.values(allJobs);
    setLastJob(jobsArray[jobsArray.length - 1]);
    handleJobs(jobsArray, true, {});
    setFilterAttrs(defaultFilterData);
  }

  function renderJobStatus() {
    return (
      <TextField
        select
        label="Select Status"
        variant="outlined"
        onChange={(event) => handleValueChange(event, 'status')}
        value={filterAttrs.status}
      >
        {jobStatus?.map((currentStatus) => (
          <MenuItem key={currentStatus} value={currentStatus}>
            {status[currentStatus]}
          </MenuItem>
        ))}
      </TextField>
    );
  }

  if (!services || !markers || !properties || !crews) {
    return (
      <Row justify="center">
        <Progress />
      </Row>
    );
  }

  return (
    <Col gap="12px">
      <Span weight="bold" size="16px">
        Filter jobs
      </Span>
      {services?.length ? (
        <TextField
          select
          label="Select Service"
          variant="outlined"
          onChange={(event) => handleValueChange(event, 'serviceKey')}
          value={filterAttrs.serviceKey}
        >
          {services?.map((service) => {
            return (
              <MenuItem key={service.key} value={service.key}>
                {service.name}
              </MenuItem>
            );
          })}
        </TextField>
      ) : null}
      {renderMarkers()}
      {renderProperty()}
      {renderJobStatus()}
      {renderCrew()}

      <Row justify="space-between" gap="4px">
        <Row>
          <LowerCaseButton onClick={clear}>Clear</LowerCaseButton>
        </Row>
        <LowerCaseButton onClick={handleClose}>Cancel</LowerCaseButton>
        <PrimaryButton
          loading={isFetching}
          onClick={handleFilter}
          disabled={isAllEmpty()}
        >
          Filter
        </PrimaryButton>
      </Row>
    </Col>
  );
};

export default React.memo(JobFilter);
