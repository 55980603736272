import React, { useState, useContext, useEffect } from 'react';

import styled from 'styled-components';
import { Box, InputBase, Button, CircularProgress } from '@material-ui/core';

import Typography from './Span';
import color from '../../utils/color';
import { Row, Col } from '../../styles/snowm_styled';
import getItemsFromAlgolia from '../../controllers/algolia';
import { LocalizationContext } from '../../contexts/localization_context';

export default function Header(props) {
  const { strings } = useContext(LocalizationContext);
  const { SEARCH } = strings?.search;

  const {
    heading,
    subheading,
    placeholder,
    children,
    marginbottom,
    paddingbottom,
    handleSearch,
    indexName,
    localSearch,
    reset,
    hideSearch,
  } = props;

  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const handleValueChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSearchButtonClick = async (event) => {
    event.preventDefault();
    if (!inputValue.length) return;
    setLoading(true);
    if (!localSearch) {
      try {
        const itemsOfACompany = await getItemsFromAlgolia(
          inputValue,
          indexName
        );
        handleSearch(itemsOfACompany);
      } catch (e) {
        console.error(e);
      }
    } else {
      handleSearch(inputValue);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (reset) setInputValue('');
  }, [reset]);

  return (
    <StyledBox paddingbottom={paddingbottom} boxShadow={4} borderRadius="13px">
      <Col>
        <Row marginbottom={marginbottom}>
          <Row gap="8px">
            <StyledHeading weight="bold" size="16px">
              {heading}
            </StyledHeading>
            <StyledHeading
              fontcolor={color.boxShadowColor}
              size="12px"
              weight="bold"
            >
              {subheading}
            </StyledHeading>
          </Row>
          {children?.length > 1 && children[0]}
        </Row>
        <Row justify="space-between">
          {children?.length > 1 ? children[1] : children}
          {children?.length > 2 && children[2]}
          {!hideSearch && (
            <Form onSubmit={handleSearchButtonClick}>
              <InputDiv>
                <StyledInputBase
                  placeholder={placeholder}
                  inputProps={{ 'aria-label': 'search' }}
                  onInput={handleValueChange}
                  value={inputValue}
                />
              </InputDiv>
              <SearchButton
                color="primary"
                variant="contained"
                disabled={!inputValue || loading}
                onClick={handleSearchButtonClick}
              >
                {loading ? (
                  <CircularProgress color="inherit" />
                ) : (
                  <Typography color="inherit">{SEARCH}</Typography>
                )}
              </SearchButton>
            </Form>
          )}
        </Row>
      </Col>
    </StyledBox>
  );
}

const SearchButton = styled(Button)`
  && {
    font-weight: bolder;
    height: 55px;
    max-width: 155px;
    padding: 8px 16px;
    flex-basis: 15%;
  }
`;

const InputDiv = styled.div`
  border: 1px solid grey;
  border-radius: 7px;
  height: 50px;
  flex-grow: 3;
  margin-right: 40px;
`;

const StyledHeading = styled(Typography)`
  && {
    color: ${(props) => props.fontcolor};
  }
`;

const StyledBox = styled(Box)`
  background-color: white;
  margin: 20px 16px 20px;
  padding-bottom: ${(props) => props.paddingbottom};
  padding: 16px;
}}
`;

const StyledInputBase = styled(InputBase)`
  padding: 8px 8px 8px 20px;
  width: 100%;
`;

const Form = styled.form`
  display: flex;
  flex-grow: 3;
`;
