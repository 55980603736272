/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: index.jsx
 *
 *  Description: Defines Routes for Service Points
 *
 *  Author: Roshan Gautam (roshan@brainants.com)
 *
 *  Date created: 9-july-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */

import React from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';

import MarkerDetails from './marker_details';
import ServicePoints from './snowm_service_points';
import AddServicePoint from './snowm_add_service_points';
import { ServicePointsContext } from '../../../contexts/service_points';

export default function() {
  return (
    <Switch>
      <Route path="/home/markers/add" exact component={AddServicePoint} />
      <Route
        path="/home/markers/edit/:uid"
        exact
        component={props => {
          return (
            <ServicePointsContext.Consumer>
              {({ servicePoints, spLoading, fetchServicePoints }) => {
                if (spLoading === null) {
                  fetchServicePoints();
                }
                return (
                  <AddServicePoint
                    edit
                    servicePoints={servicePoints}
                    {...props}
                  />
                );
              }}
            </ServicePointsContext.Consumer>
          );
        }}
      />
      <Route path="/home/markers/:markerKey" exact component={MarkerDetails} />
      <Route path="/home/markers/" component={ServicePoints} />
      <Redirect to="/home/markers" />
    </Switch>
  );
}
