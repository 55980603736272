/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: beacon_list_box.jsx
 *
 *  Description: Beacon List Box contains list of beacons
 *
 *  Author: Roshan Gautam (roshan@brainants.com)
 *
 *  Date created: 13-august-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */
import React from 'react';
import styled from 'styled-components';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent
} from '@material-ui/core';

export default function BeaconListBox({
  open,
  handleNegativeAction,
  negativeText = 'CANCEL',
  handleDeleteAction,
  children
}) {
  return (
    <StyledDialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={handleNegativeAction}
    >
      <DialogContent>{children}</DialogContent>
      <StyledDialogActions>
        {handleDeleteAction && (
          <Button onClick={handleDeleteAction} color="primary">
            Delete
          </Button>
        )}
        <Button onClick={handleNegativeAction} color="primary">
          {negativeText}
        </Button>
      </StyledDialogActions>
    </StyledDialog>
  );
}

const StyledDialogActions = styled(DialogActions)`
  && {
    padding-right: 16px;
  }
`;

const StyledDialog = styled(Dialog)`
  .MuiDialog-paperWidthXs {
    max-height: 400px;
    min-height: 175px;
    border-radius: 12px;
  }
`;
