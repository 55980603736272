import styled from 'styled-components';
import {
  Button,
  Dialog,
  Divider,
  MenuItem,
  TableRow,
  TextField,
} from '@material-ui/core';

const ADialog = styled(Dialog)`
  && {
    .MuiPaper-rounded {
      height: ${(props) => props.height};
      min-height: ${(props) => props.$minHeight}
      max-height: ${(props) => props.$maxHeight}
      justify-content: ${(props) => props.justifycontent};
      width: ${(props) => (props.width ? props.width : '30%')};
      padding: ${(props) => props.padding || '8px'};
      border-radius: 1em;
      overflow: ${(props) => props.overflow || 'inherit'};
      overflow-x:${(props) => props.overflow ?? 'hidden'};
    }

    .MuiDialog-paperWidthSm {
      max-width: ${(props) => props.maxWidth ?? '600px'};
      min-width: ${(props) => props.$minWidth};
      min-height: ${(props) => props.$minHeight};
      max-height: ${(props) => props.$maxHeight};
    }

    .MuiDialog-scrollBody {
      overflow: hidden;
    }
  }
`;

export const StyledDivider = styled(Divider)`
  width: 100%;
  && {
    margin-top: 8px;
  }
`;

export const StyledTextField = styled(TextField)`
  && {
    margin: 8px 0;
    width: ${(props) => props.width};
    margin-right: ${(props) => props.rightmargin};
  }
`;

export const InvisibleMenuItem = styled(MenuItem)`
  && {
    display: none;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: ${(props) => props.gap};
`;

export const Align = styled.div`
  text-align: ${(props) => props.align ?? 'center'};
`;

export default ADialog;

export const StyledTableRow = styled(TableRow)`
  background-color: ${(props) => props.bgcolor};
`;

export const LowerCaseButton = styled(Button)`
  && {
    text-transform: none;
  }
`;
