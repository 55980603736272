import xlsx from 'xlsx';
import fileSaver from 'file-saver';

import color from '../utils/color';
import { getHoursFromMill } from './date';
import { changeStringToNumber } from './string';

export const logo = 'https://i.ibb.co/K9fsvpx/Stratosfy-Marker-Logo-01.png';

export const defaultAvatar =
  'https://iupac.org/wp-content/uploads/2018/05/default-avatar.png';

// eslint-disable-next-line
export const getFilteredItems = (items, selectedServiceKey) => {
  if (!items) {
    return [];
  }
  if (selectedServiceKey === 'all') {
    return items;
  }
  return Object.values(items).filter(
    (item) => (item.serviceKey || item.job?.serviceKey) === selectedServiceKey
  );
};
export const getSubscribedServices = (subscribed) => {
  if (!subscribed) return [];
  const data = [];
  const subscribedTypes = Object.keys(subscribed);
  if (!subscribedTypes) return [];
  subscribedTypes.forEach((type) => {
    if (subscribed[type]) {
      data.push(type);
    }
  });
  return data;
};
export const getSubscribedServiceTypes = (subscribed) => {
  if (!subscribed) {
    return [];
  }
  const data = [];
  const subscribedTypes = Object.keys(subscribed);
  if (!subscribedTypes) return [];
  subscribedTypes.forEach((type) => {
    if (type !== 'reports' && subscribed[type]) {
      data.push(type);
    }
  });
  return data;
};

export const getFilterTypes = (subscribed) => {
  const subscribedTypes = getSubscribedServiceTypes(subscribed);
  if (!subscribedTypes) return [];
  const capsType = subscribedTypes.map((type) => type.toUpperCase());
  const allSubscribedTypes = ['ALL', ...capsType];
  return allSubscribedTypes;
};

export function getFirstCapitilizedWord(word) {
  const firstCharacter = word.charAt(0).toUpperCase();
  const remainingCharacter = word.slice(1);
  return `${firstCharacter}${remainingCharacter}`;
}

/**
 *
 * @param {string} word
 * @returns string
 */

export const getLowerCasedWord = (word) => {
  return word.toLowerCase();
};

const getCountOnly = (string) => {
  if (!string) return 0;
  const trimmedString = string?.trim();
  const stringSplitted = trimmedString?.split(' ');
  return changeStringToNumber(stringSplitted?.[0]);
};

const changeMinutesToHours = (minutes) => {
  const hours = minutes / 60;
  const hoursInNumber = changeStringToNumber(hours.toFixed(2));
  return hoursInNumber;
};

const getMinutes = (arrayOfHourAndMinutes) => {
  const minute = arrayOfHourAndMinutes?.find((value) => {
    const setOfValue = new Set(value);
    return setOfValue.has('m');
  });
  const minutes = getCountOnly(minute);
  const minuteInHours = changeMinutesToHours(minutes);
  return minuteInHours;
};

const getHours = (arrayOfHourAndMinutes) => {
  const hour = arrayOfHourAndMinutes?.find((value) => {
    const setOfValue = new Set(value);
    return setOfValue.has('h');
  });
  return getCountOnly(hour);
};

export const downloadExcel = ({
  details,
  fileName = 'Timesheets',
  selectedCrews,
  selectedDates,
  header,
  pdfDetails,
}) => {
  const { companyName } = pdfDetails;
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const getFixedHours = (time) => {
    let hoursAndMins;
    if (typeof time === 'number') {
      hoursAndMins = getHoursFromMill(time);
    } else {
      hoursAndMins = time;
    }
    const arrayOfHoursAndMinutes = hoursAndMins.split(',');
    const hours = getHours(arrayOfHoursAndMinutes);
    const minutes = getMinutes(arrayOfHoursAndMinutes);
    const totalWorkingHours = hours + minutes;
    return totalWorkingHours;
  };

  const mapSelectedDatesInHeader = selectedDates.map((date) => date);

  const mapCrewsWorkingHours = details.map((a, index) => {
    const crewLabel = selectedCrews[index]?.label ?? 'Total';
    const workLogForSelectedCrewRow = a.map((workingTime) =>
      getFixedHours(workingTime?.total)
    );
    const { length } = workLogForSelectedCrewRow;
    workLogForSelectedCrewRow.splice(length - 1, 0, []);
    return [crewLabel, ...workLogForSelectedCrewRow];
  });

  const { length } = mapCrewsWorkingHours;
  mapCrewsWorkingHours.splice(length - 1, 0, []);

  const ws = xlsx.utils.aoa_to_sheet([
    ['', '', '', '', '', `${companyName}`, '', '', '', ''],
    ['', '', '', '', '', `${header}`, '', '', '', ''],
    [],
    [],
    ['Dates', ...mapSelectedDatesInHeader, '', 'Total'],
    ...mapCrewsWorkingHours,
  ]);

  const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
  const excelBuffer = xlsx.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });
  fileSaver.saveAs(data, fileName + fileExtension);
};

export const getBgColorOfTableRow = (index) => {
  if (index % 2 === 1) {
    return color.whiteSmoke;
  }
  return color.white;
};

/**
 *
 * @param {string} text
 * @param {object} data
 * @returns object
 */

export const filterDataByName = (text, data) => {
  return Object.keys(data).reduce((acc, current) => {
    const first = getLowerCasedWord(data[current].name);
    const second = getLowerCasedWord(text);
    if (first.includes(second)) return { ...acc, [current]: data[current] };
    return acc;
  }, {});
};
