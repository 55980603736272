import React, { createContext, useState } from 'react';

import { getSubscribedServiceTypes, getFilterTypes } from '../helpers/misc';
import { getServices, getCompanyDetail } from '../controllers/snowm_firebase';

export const ServiceTypesContext = createContext();

export function ServiceTypeProvider({ children }) {
  const [filterTypes, setFilterTypes] = useState([]);
  const [subscribedTypes, setSubscribedTypes] = useState([]);
  const [subscribedServices, setSubscribedServices] = useState();
  const [companyServices, setCompanyServices] = useState(null);

  function checkServiceType(services, type) {
    return services?.map((service) => service.type).includes(type);
  }

  const getCompanyDetailAsync = async () => {
    const companyDetail = await getCompanyDetail();
    const markerFeatures = companyDetail?.markerFeatures;

    const response = await getServices();
    const services = response?.map((d) => d.data()) ?? [];
    setCompanyServices(services);

    function isFlagEnabled(flag) {
      const isThereAFlag = services?.some(
        (service) => service.serviceFeatures?.[flag]
      );
      return isThereAFlag;
    }

    const features = {
      invoicing: isFlagEnabled('invoicing'),
      activities: isFlagEnabled('activities'),
      properties: isFlagEnabled('properties'),
      reports: markerFeatures?.reports ?? false,
      workOrders: isFlagEnabled('requestService'),
      indoor: checkServiceType(services, 'indoor'),
      outdoor: checkServiceType(services, 'outdoor'),
      attendance: markerFeatures?.attendance ?? false,
      routeBased: markerFeatures?.routeBased ?? false,
      activitiesOnJob: isFlagEnabled('activitiesOnJob'),
      shiftManagement: isFlagEnabled('shiftManagement'),
      periodicJobs: markerFeatures?.periodicJobs ?? false,
      calendarView: markerFeatures?.calendarView ?? false,
      markerBased: checkServiceType(services, 'markerbased'),
      scheduledReports: markerFeatures?.scheduledReports ?? false,
      endCustomerLogin: markerFeatures?.endCustomerLogin ?? false,
      propertyLevelGeofence: isFlagEnabled('propertyLevelGeofence'),
      allowMasterEditing: markerFeatures?.allowMasterEditing ?? false,
      enableOrganizationCrew: markerFeatures?.enableOrganizationCrew ?? false,
    };

    const subscribed = {
      attendance: features?.attendance,
      indoor: features?.indoor,
      outdoor: features?.outdoor,
      reports: features?.reports,
      markerbased: features?.markerBased,
      routebased: features?.routeBased,
    };

    const filterTypesData = getFilterTypes(subscribed);

    const subscribedTypesData = getSubscribedServiceTypes(subscribed);

    setFilterTypes(filterTypesData);
    setSubscribedServices(features);
    setSubscribedTypes(subscribedTypesData);
  };

  return (
    <ServiceTypesContext.Provider
      value={{
        filterTypes,
        subscribedTypes,
        subscribedServices,
        getCompanyDetailAsync,
        companyServices,
      }}
    >
      {children}
    </ServiceTypesContext.Provider>
  );
}
