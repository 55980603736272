import styled from 'styled-components';
import React, { Fragment, useEffect, useState } from 'react';
import { CircularProgress, Typography } from '@material-ui/core';

import PrimayButton from '../../Common/primary_button';
import { getServicePointById } from '../../../controllers/snowm_firebase';

const AttendanceRouteDetail = ({ routeInfo }) => {
  const { servicePointsKeys } = routeInfo;
  const [servicePoints, setServicePoints] = useState();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const promises = servicePointsKeys.map(servicePointKey => {
      return getServicePointById(servicePointKey);
    });

    Promise.all(promises).then(values => {
      setServicePoints(values);
      setLoading(false);
    });
  }, [servicePointsKeys]);

  return (
    <Container>
      <div style={{ marginBottom: '1em' }}>
        <StyledTitle>Markers</StyledTitle>
        {servicePoints && Object.keys(servicePoints).length > 0 ? (
          <Fragment>
            {servicePoints.map(sp => (
              <StyledMarkerData key={sp.key}>
                <Typography style={{ fontWeight: 600 }}>{sp.name}</Typography>
                <hr style={{ width: '100%', opacity: 0.1 }} />
                <Row>
                  <Typography>Service Type</Typography>
                  <Typography>{sp.serviceType}</Typography>
                </Row>
                <Row>
                  <Typography>Last Served</Typography>
                  <Typography>
                    {sp.lastServed ? sp.lastServed : 'never'}
                  </Typography>
                </Row>
              </StyledMarkerData>
            ))}
          </Fragment>
        ) : (
          <div>
            {loading ? (
              <CircularProgress />
            ) : (
              <Row justifyContent="center">No markers available</Row>
            )}
          </div>
        )}
      </div>
      <PrimayButton>Edit Route</PrimayButton>
    </Container>
  );
};

export default AttendanceRouteDetail;

const Row = styled.span`
  display: flex;
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : 'space-between'};
  margin-bottom: 6px;
`;

const Container = styled.div`
  position: absolute;
  top: 25%;
  left: 45%;
  width: 25%;
`;

const StyledTitle = styled(Typography)`
  && {
    display: flex;
    background-color: white;
    padding: 1em;
    border-radius: 10px;
    box-shadow: 0px 0px 1px #928080;
    font-weight: 600;
  }
`;

const StyledMarkerData = styled.div`
  background-color: white;
  padding: 1em;
  margin-top: 1em;
  border-radius: 10px;
  box-shadow: 0px 0px 1px #928080;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;
