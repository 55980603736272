import React, { useState, useEffect } from 'react';

import { InputLabel } from '@material-ui/core';

import Select from 'react-select';
import styled from 'styled-components';

// eslint-disable-next-line
export const CustomSelect = React.forwardRef((props, ref) => {
  const [value, setValue] = useState('');
  const { required, isDisabled, onChange, ...rest } = props;

  // const selectRef = useRef(null);
  const enableRequired = !isDisabled && !rest.value;

  useEffect(() => {
    if (props?.autoFocus) {
      // selectRef.current.focus();
    }
    setValue(props.value);
  }, [props]);

  const handleOnChange = (selectedOption) => {
    onChange(selectedOption);
    setValue(selectedOption);
  };

  return (
    <>
      {rest?.title && (
        <InputLabel id="demo-simple-select-label">{rest.title}</InputLabel>
      )}
      <Select
        menuPortalTarget={document.body}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        ref={ref}
        onChange={handleOnChange}
        isDisabled={isDisabled}
        {...rest}
      />
      {enableRequired && (
        <StyledHiddenInput
          tabIndex={-1}
          autoComplete="off"
          value={value ?? ''}
          required={required}
          onFocus={() => ref?.current?.focus()}
          onChange={() => {}}
        />
      )}
    </>
  );
});

const StyledHiddenInput = styled.input`
  opacity: 0;
  height: 0;
  position: absolute;
  border-color: ${(value) => (value ? '' : 'red')};
`;
