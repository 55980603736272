import React, { useEffect, useState, useContext, Fragment } from 'react';

import styled from 'styled-components';
import CloseIcon from 'mdi-react/CloseIcon';
import { Typography, Divider, IconButton } from '@material-ui/core';

import Map from '../Maps/snowm_maps';
import Span from '../../Common/Span';
import CrewsActions from './CrewsActions';

import PreviousJobs from './previous_jobs_info';
import { defaultAvatar } from '../../../helpers/misc';
import { Row, Col } from '../../../styles/snowm_styled';
import PrimaryButton from '../../Common/primary_button';
import ListTitleItem from '../../Generics/list_title_item';
import ProviderBasicDetailCard from './provider_basic_detail_card';
import { ServicePContext } from '../../../contexts/service_provider';
import { LocalizationContext } from '../../../contexts/localization_context';
import {
  providerLatestJob,
  getLocationOfProvider,
} from '../../../controllers/snowm_firebase';
import {
  getFormattedDate,
  getStartTimeStampOfTheDay,
  getTimestampOfAWeekBefore,
} from '../../../helpers/date';

export default function (props) {
  return (
    <ServicePContext.Consumer>
      {() => {
        const {
          providerDetail,
          closeProviderDetailCard,
          handleCrewAction,
          isPerformingAction,
        } = props;
        return (
          <ProviderDetail
            providerDetail={providerDetail}
            handleDialogClose={closeProviderDetailCard}
            handleCrewAction={handleCrewAction}
            loading={isPerformingAction}
          />
        );
      }}
    </ServicePContext.Consumer>
  );
}

function ProviderDetail({
  providerDetail,
  handleDialogClose,
  handleCrewAction,
  loading,
}) {
  const { strings } = useContext(LocalizationContext);
  const { ADDRESS } = strings?.inputLabel;
  const { CURRENT_LOCATION } = strings?.location;
  const { CREW_DETAIL } = strings?.detail;

  const providerUid = providerDetail?.masterUid ?? providerDetail?.uid;

  const [jobs, setJobs] = useState(null);
  const [providerLoc, setProviderLoc] = useState(null);
  const [providerInfo, setProviderInfo] = useState(null);
  const [isJobFetching, setIsJobFetching] = useState(false);
  const [currentSelectedDate, setCurrentSelectedDate] = useState();

  useEffect(() => {
    const currentTimeStamp = getStartTimeStampOfTheDay();
    setCurrentSelectedDate(currentTimeStamp);
  }, []);

  useEffect(() => {
    setProviderInfo(providerDetail);

    const onChange = (uid, value) => {
      setProviderLoc({
        [providerUid]: { ...(value ?? {}), uid },
      });
    };

    getLocationOfProvider(providerUid, onChange);

    providerLatestJob(providerUid)
      .then((jobsRes) => {
        setJobs(jobsRes);
      })
      .catch((e) => new Error(e));
  }, [providerDetail]);

  async function getMoreJobs() {
    setIsJobFetching(true);

    const createdDateOfLastJob = jobs?.length
      ? jobs[jobs?.length - 1]?.createdDate
      : null;
    const dateOfWeekBefore = getTimestampOfAWeekBefore(currentSelectedDate);

    setCurrentSelectedDate(dateOfWeekBefore);

    const crews = await providerLatestJob(
      providerUid,
      dateOfWeekBefore,
      createdDateOfLastJob
    );

    setJobs((prevState) => {
      return [...(prevState ?? []), ...crews];
    });
    setIsJobFetching(false);
  }

  function handleImageError(event) {
    event.currentTarget.src = defaultAvatar;
  }

  return (
    <>
      <DetailContainer>
        {providerInfo ? (
          <>
            <Row>
              <Span style={{ fontWeight: '700' }} variant="h6">
                {providerInfo.name}
              </Span>
              <IconButton onClick={handleDialogClose}>
                <CloseIcon />
              </IconButton>
            </Row>
            <Col>
              <Row justify="space-between">
                <DetailTopic>{CREW_DETAIL}</DetailTopic>

                <CrewsActions
                  crew={providerInfo}
                  handleCrewAction={handleCrewAction}
                  loadingWhileAction={loading}
                />
              </Row>
              <Divider style={{ marginBottom: '1em' }} />
              <Row>
                <StyledImage
                  component="img"
                  alt=""
                  height={320}
                  src={providerInfo.imageUrl || defaultAvatar}
                  onError={handleImageError}
                />
                <Col style={{ flex: 1 }}>
                  <ProviderBasicDetailCard providerDetail={providerInfo} />
                  <ListTitleItem
                    title={ADDRESS}
                    content={providerInfo?.address}
                  />
                </Col>
              </Row>
            </Col>
          </>
        ) : (
          <Span>Loading</Span>
        )}
        <Col>
          <DetailTopic>{CURRENT_LOCATION}</DetailTopic>
          <Divider style={{ marginBottom: '1em' }} />
          {!providerLoc?.[providerUid]?.longitude ||
          !providerLoc?.[providerUid]?.latitude ? (
            <Row justify="center">
              <Span weight="bold">No location found.</Span>
            </Row>
          ) : (
            <div style={{ width: '100%', height: '30vh' }}>
              <Map
                locations={providerLoc}
                points={{ [providerUid]: providerLoc }}
              />
            </div>
          )}
        </Col>
        <Typography style={{ marginTop: 13 }}>Previous Jobs</Typography>
        <Divider />
        <Row justify="center" gap="4px">
          <Span weight="bold" size="12px">
            Previous jobs after
          </Span>
          <Span weight="bold" size="12px">
            {getFormattedDate(currentSelectedDate)}
          </Span>
        </Row>
        {jobs?.length > 0 ? (
          <>
            {jobs.map((job, index) => {
              const nextIndex = index + 1;
              return (
                <Fragment key={job.key}>
                  <PreviousJobs job={job} />
                  {jobs[nextIndex] && <Divider />}
                </Fragment>
              );
            })}
          </>
        ) : (
          <Row justify="center">
            <Span weight="bold">No Jobs Assigned for the date.</Span>
          </Row>
        )}
        <PrimaryButton loading={isJobFetching} onClick={getMoreJobs}>
          Show more
        </PrimaryButton>
      </DetailContainer>
    </>
  );
}

const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const DetailTopic = styled(Typography)`
  && {
    opacity: 0.5;
    font-size: 18px;
    font-weight: 700;
  }
`;

const StyledImage = styled.img`
  height: 5em;
  width: 5em;
  border-radius: 100%;
  margin-right: 1em;
  object-fit: cover;
`;
