import React, { useReducer, useState } from 'react';

import {
  TextField,
  CircularProgress,
  withTheme,
  Button,
} from '@material-ui/core';

import Span from '../Common/Span';
import color from '../../utils/color';
import { Col, Row } from '../../styles/snowm_styled';
import { updatePassword } from '../../controllers/snowm_firebase';
import UnAuthenticated, {
  StyledOutlineButton,
  passwordRange,
} from './UnAuthenticated';

const ChangePassword = ({
  fromProfile,
  handleCancelButton,
  setSnackBarOpen,
  ...props
}) => {
  const initialState = {
    newPassword: '',
    repeatedNewPassword: '',
    oldPassword: '',
  };

  function reducer(stateData, action) {
    switch (action.type) {
      case 'newPassword':
        return {
          ...stateData,
          newPassword: action.value,
        };

      case 'repeatedNewPassword':
        return {
          ...stateData,
          repeatedNewPassword: action.value,
        };
      case 'oldPassword':
        return { ...stateData, oldPassword: action.value };
      default:
        throw new Error();
    }
  }

  const [loading, setLoading] = useState(false);
  const [password, dispatch] = useReducer(reducer, initialState);
  const [samePasswordError, setSamePasswordError] = useState(false);
  const [errorMsg, setErrorMsg] = useState();

  function handlePasswordChange(event, type) {
    event.stopPropagation();
    setSamePasswordError(false);
    dispatch({
      type: `${type}`,
      value: event.target.value,
    });
  }

  function alertError(error) {
    switch (error.code) {
      case 'auth/wrong-password':
        alert("You've entered incorrect current password.");
        break;

      default:
        alert('Cannot update the password');
        break;
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setErrorMsg('');
    if (password?.oldPassword === password?.newPassword) {
      setSamePasswordError(true);
      return;
    }
    if (notMatchPassword()) {
      setErrorMsg("Passwords don't match");
      return;
    }
    setLoading(true);
    try {
      await updatePassword(password.newPassword, password, fromProfile);
      if (handleCancelButton) {
        handleCancelButton();
      }
      if (setSnackBarOpen) {
        setSnackBarOpen(true);
      }
      setLoading(false);
    } catch (error) {
      alertError(error);
      setLoading(false);
    }
  }

  function notMatchPassword() {
    return (
      password.repeatedNewPassword !== '' &&
      password.newPassword !== password.repeatedNewPassword
    );
  }

  return (
    <UnAuthenticated fromProfile={fromProfile}>
      <form onSubmit={handleSubmit}>
        <Row justify="center">
          <Span weight="bold">Change Password</Span>
        </Row>
        <Col justify="center">
          <Row width="auto">
            <TextField
              label="Current Password"
              autoComplete="current-password"
              onChange={(e) => handlePasswordChange(e, 'oldPassword')}
              required
              type="password"
            />
          </Row>
          <Row width="auto">
            <TextField
              label="New Password"
              autoComplete="new-password"
              onChange={(e) => handlePasswordChange(e, 'newPassword')}
              required
              type="password"
              inputProps={passwordRange}
            />
          </Row>
          <Row>
            <TextField
              label="Confirm New Password"
              autoComplete="new-password"
              onChange={(e) => handlePasswordChange(e, 'repeatedNewPassword')}
              required
              type="password"
              error={notMatchPassword()}
              inputProps={passwordRange}
              helperText={errorMsg}
            />
          </Row>
          <Row>
            {fromProfile && (
              <Button color="primary" onClick={handleCancelButton}>
                Cancel
              </Button>
            )}

            <StyledOutlineButton
              type="submit"
              variant="contained"
              disabled={loading}
              color="primary"
              flexbasis={!fromProfile && '300px'}
              width={fromProfile && 'auto'}
              {...props}
            >
              {loading ? (
                <CircularProgress size={15} />
              ) : (
                <Span color={color.white}>Change Password</Span>
              )}
            </StyledOutlineButton>
          </Row>
          {samePasswordError && (
            <Row>
              <Span color="error">
                Old Password and new password can't be the same
              </Span>
            </Row>
          )}
        </Col>
      </form>
    </UnAuthenticated>
  );
};

export default withTheme(ChangePassword);
