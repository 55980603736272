/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: alert_box.jsx
 *
 *  Description: Alert Box Component
 *
 *  Author: Roshan Gautam (roshan@brainants.com)
 *
 *  Date created: 22-july-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */
import React from 'react';

import styled from 'styled-components';
import {
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  DialogTitle,
} from '@material-ui/core';

import Span from './Span';
import ADialog from './styled';
import color from '../../utils/color';
import PrimayButton from './primary_button';
import { Row } from '../../styles/snowm_styled';
import { Progress } from '../Generics/snowm_loader';

export default function AlertBox({
  open,
  handleNegativeAction,
  title,
  subtitle,
  handlePositiveAction,
  negativeText = 'Cancel',
  positiveText = 'Delete',
  error,
  isTakingAction,
}) {
  return (
    <ADialog width="510px" open={open} onClose={handleNegativeAction}>
      <DialogContent>
        <StyledDialogTitle>
          <Span weight="bold">{title}</Span>
        </StyledDialogTitle>
        <StyledDialogContentText>
          <Span weight="bold">{subtitle}</Span>
        </StyledDialogContentText>
      </DialogContent>
      <StyledDialogActions>
        <Button
          style={{ fontSize: 16, fontWeight: '700', textTransform: 'none' }}
          onClick={handleNegativeAction}
          color="primary"
        >
          <Span color="primary">{negativeText}</Span>
        </Button>
        <PrimayButton onClick={handlePositiveAction} disabled={isTakingAction}>
          {isTakingAction ? (
            <Progress size={15} progressColor={color.white} />
          ) : (
            <Span color={color.white}>{positiveText}</Span>
          )}
        </PrimayButton>
      </StyledDialogActions>
      {error && (
        <Row justify="center">
          <Span weight="bold" color="error">
            {error}
          </Span>
        </Row>
      )}
    </ADialog>
  );
}

const StyledDialogContentText = styled(DialogContentText)`
  && {
    margin-top: 10px;
    line-height: 1;
    font-size: 1em;
    font-weight: 700;
    opacity: 0.6;
  }
`;

const StyledDialogTitle = styled(DialogTitle)`
  && {
    h2 {
      font-size: 1em;
      font-weight: 700;
    }
    padding-left: 0;
    padding-bottom: 0;
  }
`;

const StyledDialogActions = styled(DialogActions)`
  && {
    padding: 16px;
  }
`;
