import React from 'react';
import { ServicePContext } from '../../../contexts/service_provider';
import Home from './snowm_home';

export default function(normalProps) {
  return (
    <ServicePContext.Consumer>
      {({
        fetchProviders,
        providers,
        providersLoading,
        locations,
        logs,
        getLogs,
        jobs,
        fetchJobs,
        services,
        fetchServices
      }) => {
        return (
          <Home
            fetchProviders={fetchProviders}
            providers={providers}
            providersLoading={providersLoading}
            points={locations}
            logs={logs}
            getLogs={getLogs}
            jobs={jobs}
            fetchJobs={fetchJobs}
            services={services}
            fetchServices={fetchServices}
            {...normalProps}
          />
        );
      }}
    </ServicePContext.Consumer>
  );
}
