import React, { useEffect, useState, useContext } from 'react';

import { isEmpty } from 'lodash';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { countries } from 'countries-list';
import CloseIcon from 'mdi-react/CloseIcon';
import { RHFInput } from 'react-hook-form-input';
import { Alert, ToggleButton } from '@material-ui/lab';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import SortBoolAscendingVariantIcon from 'mdi-react/SortBoolAscendingVariantIcon';
import SortBoolDescendingVariantIcon from 'mdi-react/SortBoolDescendingVariantIcon';
import {
  Typography,
  InputAdornment,
  IconButton,
  CircularProgress,
  MenuItem,
  Divider,
  Tooltip,
  Snackbar,
} from '@material-ui/core';

import Span from '../../Common/Span';
import CrewsTable from './CrewsTable';
import color from '../../../utils/color';
import Header from '../../Common/header';
import ADialog from '../../Common/styled';
import { StyledTextField } from '../styled';
import AlertBox from '../../Common/alert_box';
import AddButton from '../../Common/add_button';
import FormModal from '../../Common/form_modal';
import NotifyBox from '../../Common/notify_box';
import Loader from '../../Generics/snowm_loader';
import ProviderDetail from './snowm_provider_detail';
import { useModal } from '../../Generics/snowm_modal';
import PrimaryButton from '../../Common/primary_button';
import { filterDataByName } from '../../../helpers/misc';
import { AuthContext } from '../../../contexts/auth_context';
import { LocationModal } from '../../Generics/location_modal';
import { ServicePContext } from '../../../contexts/service_provider';
import { LocalizationContext } from '../../../contexts/localization_context';
import {
  Col,
  Row,
  StyledAddressPickerBtn,
  StyledTitle,
} from '../../../styles/snowm_styled';
import {
  deleteProvider,
  createCrew,
  getCompanyDetail,
  getOrganizationalCrew,
  getDataByName,
  sendPasswordResetEmail,
  disableCrewAccount,
  enableCrewAccount,
  resetCrewDeviceId,
} from '../../../controllers/snowm_firebase';

const crewEnabledMsg = 'Crew account enabled.';
const crewDisabledMsg = 'Crew account disabled.';
const crewResetLoggedIn = 'Logged In device reset.';
const archiveDeleteError = 'Cannot take the action. Some error occured.';
const resetEmailSuccessMsg =
  'Reset email link sent to the email address of the crew.';
const resetEmailErrorMsg =
  "Reset email can't be sent to the email address of the crew.";
const filters = ['All', 'Active', 'Disabled'];

export default function (normalProps) {
  return (
    <ServicePContext.Consumer>
      {({ fetchProviders, providers, providersLoading, locations }) => {
        return (
          <Providers
            fetchProviders={fetchProviders}
            providers={providers}
            providersLoading={providersLoading}
            locations={locations}
            {...normalProps}
          />
        );
      }}
    </ServicePContext.Consumer>
  );
}

const countriesDetails = Object.values(countries).reduce((acc, countryCode) => {
  if (countryCode.phone) {
    return [...acc, Number(countryCode.phone.split(',')[0])];
  }
  return acc;
}, []);

const uniqueCountryCode = [...new Set(countriesDetails)];

uniqueCountryCode.sort((a, b) => a - b);

export function Providers({ fetchProviders, providers, vendors, typeOfCrew }) {
  const tabs = {
    loginWithEmail: 'Login With Email',
    loginWithPhone: 'Login With Phone',
  };
  const tabsValues = Object.values(tabs);

  const { register, handleSubmit, errors, setValue, watch } = useForm();

  const locationModal = useModal();
  const notifyBoxModal = useModal();

  const [action, setAction] = useState();
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [crewId, setCrewId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [allCrews, setAllCrews] = useState(null);
  const [openEdit, setOpenEdit] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [sortType, setSortType] = useState('asc');
  const [noOrgCrew, setNoOrgCrew] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [informText, setInformText] = useState('');
  const [anchorEl, setAnchorEl] = useState(undefined);
  const [filteredCrew, setFilteredCrew] = useState({});
  const [companyDetail, setCompanyDetail] = useState();
  const [isSearching, setIsSearching] = useState(false);
  const [emailExists, setEmailExists] = useState(false);
  const [filterValue, setFilterValue] = useState('All');
  const [tabValue, setTabValue] = useState('individual');
  const [addressError, setAddressError] = useState(null);
  const [selectedProvider, setSelectedProvider] = useState();
  const [warningMessage, setWarningMessage] = useState('');
  const [openInformText, setOpenInformText] = useState(false);
  const [allCountries, setAllCountries] = useState(uniqueCountryCode);
  const [openProviderDetail, setOpenProviderDetail] = useState(false);
  const [isActionPerforming, setIsActionPerforming] = useState(false);
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);
  const [crewsName, setCrewsName] = useState({
    names: [],
    nameExist: false,
  });

  const [emailPhoneError, setEmailPhoneError] = useState('');
  const [noEmailAndPhoneNumberError, setNoEmailAndPhoneNumberError] = useState(
    false
  );

  const [emailPhoneTabValue, setEmailPhoneTabValue] = useState(
    'Login With Email'
  );

  const contextOfAuth = useContext(AuthContext);
  const { strings } = useContext(LocalizationContext);

  const { company } = contextOfAuth;

  const { markerFeatures } = company;
  const { createCrewIdLogin } = markerFeatures;

  const watchCountryCode = watch(
    'countryCode',
    selectedProvider?.countryCode ?? '1'
  );

  const watchEmailAddress = watch('email', selectedProvider?.email ?? null);
  const watchAddress = watch('address', selectedProvider?.address ?? null);

  const watchPhoneNumber = watch(
    'phoneNumber',
    selectedProvider?.phoneNumber ?? null
  );

  const { NO_CREWS_ADDED } = strings?.messages;

  const registerValues = (values) => {
    values.forEach((value) => {
      register({ name: value });
    });
  };

  useEffect(() => {
    setAllCountries(uniqueCountryCode);
  }, []);

  useEffect(() => {
    registerValues(['longitude', 'latitude', 'uid', 'disabled']);
  }, []);

  useEffect(() => {
    if (open) {
      setValue('countryCode', '1');
    }
  }, [open]);

  useEffect(() => {
    setValue('longitude', selectedProvider?.longitude ?? 0.0);
    setValue('latitude', selectedProvider?.latitude ?? 0.0);
    setValue('uid', selectedProvider?.uid ?? null);
    setValue('disabled', selectedProvider?.disabled ?? false);
    if (selectedProvider?.crewId) {
      const arrayOfCrewId = selectedProvider?.crewId?.split(
        companyDetail?.companyCode
      );
      if (arrayOfCrewId?.length > 1) {
        const crewName = arrayOfCrewId[1];
        setValue('crewId', crewName);
      }
    }
  }, [selectedProvider, companyDetail]);

  useEffect(() => {
    let isCancel = false;
    if (fetchProviders) {
      fetchProviders();
    }
    if (typeOfCrew) {
      setTabValue(typeOfCrew);
    }
    getCompanyDetail().then((res) => {
      if (!isCancel) {
        setCompanyDetail(res ?? {});
      }
    });
    return () => {
      isCancel = true;
    };
  }, []);

  function sortCrews(firstCrew, secondCrew) {
    const lowerCaseFirstName = firstCrew?.name?.toLowerCase();
    const lowerCaseSecondName = secondCrew?.name?.toLowerCase();
    let integer = 1;
    if (sortType === 'desc') {
      integer = -1;
    }

    if (lowerCaseFirstName > lowerCaseSecondName) {
      return integer;
    }
    if (lowerCaseFirstName < lowerCaseSecondName) {
      return -integer;
    }
    return 0;
  }

  function makeCrewsWithUids(crews) {
    return crews?.reduce((acc, curr) => {
      return { ...acc, [curr.uid]: curr };
    }, {});
  }

  function sortCrewByOrder() {
    if (providers === null) {
      return null;
    }
    const crews = Object.values(providers ?? {});
    const sortedCrews = crews?.sort(sortCrews);
    let filteredCrews = sortedCrews;
    if (filterValue === 'Disabled') {
      filteredCrews = sortedCrews?.filter((crew) => crew.disabled);
    } else if (filterValue === 'Active') {
      filteredCrews = sortedCrews?.filter((crew) => !crew.disabled);
    }
    const crewsAccordingToUid = makeCrewsWithUids(filteredCrews);
    return crewsAccordingToUid;
  }

  useEffect(() => {
    let filteredCrews = {};
    const orderedCrews = sortCrewByOrder();
    setAllCrews(orderedCrews);
    if (isSearch) {
      filteredCrews = filterDataByName(searchText, orderedCrews);
      setFilteredCrew(filteredCrews);
    } else {
      setFilteredCrew(orderedCrews);
    }
  }, [sortType, providers, filterValue]);

  function handleCloseDialog() {
    setCrewsName((prevState) => {
      return {
        ...prevState,
        nameExist: false,
      };
    });
    setOpen(false);
  }

  function getCrewType() {
    if (openEdit && selectedProvider) {
      return selectedProvider?.crewType ?? null;
    }

    if (tabValue === 'individual') {
      return 'Individual';
    }
    return 'Org';
  }

  function getCrewId(idOfCrew) {
    if (openEdit && selectedProvider) {
      return selectedProvider?.crewId ?? null;
    }

    if (!idOfCrew) {
      return null;
    }
    return `${companyDetail?.companyCode}${idOfCrew}`;
  }

  const getCountryCode = (crewFormData) => {
    if (crewFormData?.phoneNumber) {
      return crewFormData?.countryCode;
    }
    return null;
  };

  const getEmailAddress = (email) => {
    if (emailPhoneTabValue === tabs.loginWithEmail) return email;
    return null;
  };

  const getPhoneNumber = (phoneNo) => {
    if (emailPhoneTabValue === tabs.loginWithPhone) return phoneNo;
    return null;
  };

  async function handlePositiveAction(formData) {
    setAddressError('');
    setNoEmailAndPhoneNumberError(false);

    if (!formData?.address) {
      setAddressError('Please select address from map.');
      return;
    }

    if (
      (emailPhoneTabValue === tabs.loginWithEmail && !watchEmailAddress) ||
      (emailPhoneTabValue === tabs.loginWithPhone && !watchPhoneNumber)
    ) {
      setNoEmailAndPhoneNumberError(true);
      return;
    }
    setCrewsName({ ...crewsName, nameExist: false });
    const data = { ...formData };
    setError(false);
    if (!data.name) {
      return;
    }
    const trimmedName = data.name.trim();

    const crewBySameName = await getDataByName('providers', trimmedName);

    if (!selectedProvider && crewBySameName) {
      setCrewsName({ ...crewsName, nameExist: true });
      return;
    }

    if (
      crewBySameName &&
      selectedProvider &&
      selectedProvider?.uid !== crewBySameName?.uid
    ) {
      setCrewsName({ ...crewBySameName, nameExist: true });
      return;
    }

    const crewType = getCrewType();
    const idOfCrew = getCrewId(data?.crewId);

    let requestData = {
      ...data,
      crewType,
      name: trimmedName,
      crewId: idOfCrew,
      countryCode: getCountryCode(data),
      email: getEmailAddress(data.email),
      phoneNumber: getPhoneNumber(data.phoneNumber),
    };
    setLoading(true);

    if (tabValue === 'organization') {
      const masterUid = data.uid;
      const masterCompanyKey = selectedProvider.companyKey;
      const companyKey = companyDetail.key;
      const uid = `${masterUid}_${companyDetail?.key}`;
      requestData = {
        ...requestData,
        token: '',
        ...selectedProvider,
        masterUid,
        uid,
        masterCompanyKey,
        companyKey,
      };
    }
    try {
      const httpsResponse = await createCrew(requestData);

      if (!httpsResponse?.data?.success) {
        setEmailPhoneError(httpsResponse.data.message);
        setLoading(false);
        return;
      }

      if (openEdit || tabValue === 'organization') {
        handleCloseDialog();
        setLoading(false);
        return;
      }
      if (httpsResponse?.data?.code === 'EMAIL_ALREADY_EXISTS') {
        setEmailExists(true);
      } else if (httpsResponse?.data?.success) {
        handleCloseDialog();
      } else {
        setError(true);
      }
    } catch (e) {
      console.error('@@error', e);
    }
    setLoading(false);
  }

  useEffect(() => {
    const names = Object.values(allCrews ?? {}).map((crew) => {
      return crew.name.toLowerCase();
    });
    setCrewsName((prevState) => {
      return {
        ...prevState,
        names,
      };
    });
  }, [allCrews]);

  function addProvider() {
    setLoading(false);
    setEmailExists(false);
    setError(false);
    setTabValue(typeOfCrew ?? 'individual');
    setSelectedProvider();
    setOpenEdit(false);
    setNoOrgCrew(null);
    setOpen(true);
    setNoEmailAndPhoneNumberError(false);
  }

  const editProviderModal = (provider) => {
    setSelectedProvider(provider);
    setOpenEdit(true);
    setOpen(true);
  };

  async function deleteProviderData() {
    try {
      await deleteProvider(selectedProvider);
      setOpenDeleteConfirmDialog(false);
    } catch (e) {
      alert('Cannot delete crew.');
      console.error('@@Error', e);
    }
  }

  function onLocationChange(loc, address) {
    setValue('address', address);
    setValue('longitude', loc.lng);
    setValue('latitude', loc.lat);
    setAddressError('');
    locationModal.close();
  }

  const showProviderDetail = (p) => {
    setSelectedProvider(p);
    setOpenProviderDetail(true);
  };

  const handleProviderDetailClose = () => {
    setOpenProviderDetail(false);
  };

  const handleSearchInput = async (items) => {
    setSearchText(items);
    const filteredCrewList = filterDataByName(items, allCrews);
    setIsSearching(true);
    setFilteredCrew(filteredCrewList);
    setIsSearching(false);
    setIsSearch(true);
  };

  function handleOnFocus(event) {
    event.stopPropagation();
    setEmailExists(false);
  }

  const getCompanyCode = () => {
    if (!companyDetail) {
      return <CircularProgress size={20} />;
    }
    return companyDetail?.companyCode ?? '';
  };

  function handleCrewIdChange(e) {
    const { value } = e.target;
    if (tabValue === 'organization') {
      setCrewId(value);
    }
  }

  const crewIdField = () => {
    return (
      <StyledTextField
        required={!watchEmailAddress}
        variant="outlined"
        placeholder="Crew Id"
        inputProp
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {tabValue !== 'organization' && getCompanyCode()}
            </InputAdornment>
          ),
        }}
        inputRef={register}
        name="crewId"
        error={!!errors.crewId}
        helperText={errors?.crewId?.message}
        onChange={handleCrewIdChange}
        disabled={tabValue === 'organization' && selectedProvider}
      />
    );
  };

  const handleEmailPhoneTabChange = (event, newValue) => {
    setEmailPhoneTabValue(newValue);
  };

  const renderCrewAddForm = () => {
    return (
      <>
        {error && (
          <Typography>
            Something went wrong while creating crew account.
          </Typography>
        )}

        <StyledTextField
          autoFocus
          required
          inputProps={{
            maxLength: 50,
          }}
          label="Crew Name"
          margin="normal"
          name="name"
          variant="outlined"
          inputRef={register({
            pattern: {
              value: /^[^!@#$%^&*()_+~|}<>?:]+$/,
              message: 'Name should contain alphabets or with numbers.',
            },
          })}
          error={!!errors.name}
          helperText={errors?.name?.message}
          defaultValue={selectedProvider?.name}
          disabled={tabValue === 'organization'}
        />

        {crewsName.nameExist && (
          <Typography variant="subtitle1" color="error">
            Name already exists
          </Typography>
        )}

        <ToggleButtonGroup
          value={emailPhoneTabValue}
          onChange={handleEmailPhoneTabChange}
          exclusive
        >
          {tabsValues?.map((label) => (
            <StyledToggleButton
              value={label}
              $isSelected={label === emailPhoneTabValue}
              disabled={label === emailPhoneTabValue}
              key={label}
            >
              {label}
            </StyledToggleButton>
          ))}
        </ToggleButtonGroup>

        {emailPhoneTabValue === tabs.loginWithEmail && (
          <>
            <StyledTextField
              type="email"
              label="Email"
              margin="normal"
              name="email"
              variant="outlined"
              required
              inputRef={register({
                pattern: {
                  value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: 'Invalid email',
                },
              })}
              error={!!errors.email}
              helperText={errors?.email?.message}
              defaultValue={selectedProvider?.email}
              onFocus={handleOnFocus}
              disabled={tabValue === 'organization'}
            />

            {emailExists && (
              <Row>
                <Span color={color.red} weight="bold">
                  Email already exists.
                </Span>
              </Row>
            )}
          </>
        )}

        {emailPhoneTabValue === tabs.loginWithPhone && (
          <StyledRow gap="4px">
            <RHFInput
              as={<StyledTextField select style={{ flexBasis: '25' }} />}
              required
              name="countryCode"
              defaultValue={watchCountryCode}
              register={register}
              setValue={setValue}
              variant="outlined"
              value={watchCountryCode}
            >
              {allCountries?.map((code) => {
                return (
                  <MenuItem key={code} value={`${code}`}>
                    <Row marginbottom="0">
                      <Span>
                        (+
                        {code})
                      </Span>
                    </Row>
                  </MenuItem>
                );
              })}
            </RHFInput>

            <TextField
              inputProps={{
                maxlength: 10,
                pattern: '[0-9]+',
                title: 'Please use number only',
              }}
              required
              label="Phone number"
              type="text"
              margin="normal"
              name="phoneNumber"
              variant="outlined"
              inputRef={register({
                maxLength: {
                  value: 10,
                  message: 'Phone number should not exceed 10 digits.',
                },
                minLength: {
                  value: 10,
                  message: 'Phone number should be minimum 10 digits.',
                },
              })}
              error={errors.phoneNumber && true}
              helperText={errors.phoneNumber && errors.phoneNumber.message}
              defaultValue={selectedProvider?.phoneNumber}
              disabled={tabValue === 'organization'}
            />
          </StyledRow>
        )}

        <StyledAddressPickerBtn onClick={() => locationModal.open()}>
          <AddressPicker
            required
            inputProps={{
              maxLength: 200,
            }}
            label="Address"
            margin="normal"
            variant="outlined"
            name="address"
            inputRef={register}
            value={watchAddress}
            error={addressError}
            helperText={addressError}
            InputLabelProps={{ shrink: true }}
            disabled
            style={{
              pointerEvents: 'none',
            }}
          />
        </StyledAddressPickerBtn>

        {noEmailAndPhoneNumberError && (
          <Span color={color.red}>
            Either email or phone number is required.
          </Span>
        )}
      </>
    );
  };

  async function handleGetCrew(e) {
    e.stopPropagation();
    setNoOrgCrew(null);
    setLoading(true);
    try {
      const organizationalCrew = await getOrganizationalCrew(crewId);
      if (organizationalCrew) {
        setSelectedProvider(organizationalCrew);
      } else {
        setNoOrgCrew('No organizational crew with this id is found.');
      }
    } catch (err) {
      setNoOrgCrew('Organizational crew with this id already exists.');
    }
    setLoading(false);
  }

  const renderOrganization = () => {
    return (
      <>
        {selectedProvider && tabValue === 'organization' ? (
          <>{renderCrewAddForm()}</>
        ) : (
          <StyledCol gap="12px">
            {crewIdField()}
            <PrimaryButton loading={loading} onClick={handleGetCrew}>
              <Row gap="4px" marginbottom="0">
                {loading && <CircularProgress size={15} color="inherit" />}
                <Span color={color.white}>Get Crew</Span>
              </Row>
            </PrimaryButton>
            {noOrgCrew ? <Span color={color.red}>{noOrgCrew}</Span> : null}
          </StyledCol>
        )}
      </>
    );
  };

  function handleSortChange() {
    let newSortType = '';
    if (sortType === 'asc') {
      newSortType = 'desc';
    } else {
      newSortType = 'asc';
    }
    setSortType(newSortType);
  }

  function handleFilterChange(event) {
    setFilterValue(event.target.value);
  }

  const showAllCrews = () => {
    const sortedCrew = sortCrewByOrder();
    setFilteredCrew(sortedCrew);
    setIsSearch(false);
  };

  const crewOrVendor = () => {
    return vendors ? 'vendor' : 'crew';
  };

  const closeAlertDialog = () => {
    setOpenDeleteConfirmDialog(false);
  };

  function handleNegativeAction() {
    setAction(null);
  }

  const handleInformation = (message) => {
    setInformText(message);
    setOpenInformText(true);
  };

  const getMessage = () => {
    if (alert === 'Delete Crew') {
      return 'Crew deleted.';
    }
    return 'Crew archived.';
  };

  const warningText = {
    deleteCrew: 'Are you sure you want to delete',
    archiveCrew: 'Are you sure you want to archive',
    enableAccount: 'Are you sure you want to enable',
    disableAccount: 'Are you sure you want to disable',
    resetPassword: 'Are you sure you want to reset password for',
    resetLoggedIn: 'Are you sure you want to reset the logged in device',
  };

  async function handleConfirmation() {
    const { masterUid, uid } = selectedProvider;
    setLoading(true);
    setIsActionPerforming({ [masterUid ?? uid]: true });
    setAnchorEl(null);
    const alert = action;
    setAction(null);

    if (alert === 'Archive Crew' || alert === 'Delete Crew') {
      const response = await deleteProvider(selectedProvider);
      if (!response?.data?.success) {
        handleInformation(archiveDeleteError);
        return;
      }

      const updatedCrew = { ...selectedProvider };

      if (alert === 'Archive Crew') {
        updatedCrew.archived = true;
      }

      const information = getMessage();
      handleInformation(information);
      setLoading(false);
      handleProviderDetailClose();
      setIsActionPerforming(null);
      return;
    }

    switch (alert) {
      case 'Reset Password':
        try {
          await sendPasswordResetEmail(selectedProvider?.email);
          handleInformation(resetEmailSuccessMsg);
        } catch (e) {
          handleInformation(resetEmailErrorMsg);
        }
        break;

      case 'Disable Account': {
        const response = await disableCrewAccount(selectedProvider);
        if (!response?.data?.success) {
          setInformText('Cannot disable the account.');
        } else if (response?.data?.success) {
          const updatedCrew = { ...selectedProvider, disabled: true };

          setSelectedProvider(updatedCrew);

          handleInformation(crewDisabledMsg);
        }

        break;
      }

      case 'Reset Logged In Device': {
        await resetCrewDeviceId(selectedProvider);
        const updatedCrew = { ...selectedProvider, deviceId: null };
        setSelectedProvider(updatedCrew);
        handleInformation(crewResetLoggedIn);
        break;
      }

      case 'Enable Account': {
        const response = await enableCrewAccount(selectedProvider);
        if (!response?.data?.success) {
          setInformText('Cannot enable crew account.');
        } else {
          const updatedCrew = { ...selectedProvider, disabled: false };
          setSelectedProvider(updatedCrew);
          handleInformation(crewEnabledMsg);
        }
        break;
      }

      default:
        break;
    }
    setIsActionPerforming(null);
    setLoading(false);
    setAnchorEl(undefined);
  }

  const handleCrewAction = (crew, actionForCrew) => {
    setSelectedProvider(crew);
    switch (actionForCrew) {
      case 'Archive Crew':
        setWarningMessage(`${warningText.archiveCrew} ${crew.name}?`);
        break;

      case 'Delete Crew':
        setWarningMessage(`${warningText.deleteCrew} ${crew.name}?`);
        break;

      case 'Reset Password':
        setWarningMessage(`${warningText.resetPassword} ${crew.name}?`);
        break;

      case 'Disable Account':
        setWarningMessage(`${warningText.disableAccount} ${crew.name}?`);
        break;

      case 'Enable Account':
        setWarningMessage(`${warningText.enableAccount} ${crew.name}?`);
        break;

      case 'Reset Logged In Device':
        setWarningMessage(`${warningText.resetLoggedIn} ${crew.name}`);
        break;

      default:
        break;
    }
    setAction(actionForCrew);
  };

  function handleInformationDialog() {
    setOpenInformText(false);
  }

  const handleSnackbarClose = () => {
    setEmailPhoneError('');
  };

  if (!allCrews) {
    return <Loader />;
  }

  if (allCrews) {
    return (
      <StyledCrewsContainer>
        <Row>
          <StyledTitle>{vendors ? 'Vendors' : 'Crew'}</StyledTitle>
        </Row>
        <Header
          heading={`Search ${crewOrVendor()}`}
          subheading={`Search by ${crewOrVendor()} name`}
          placeholder={`${vendors ? 'Vendor' : 'Crew'} name`}
          handleSearch={(items) => handleSearchInput(items)}
          paddingbottom="1em"
          isSearching={isSearching}
          indexName="crews"
          localSearch
          reset={!isSearch}
        >
          <Tooltip title="Sort">
            <IconButton onClick={handleSortChange}>
              {sortType === 'asc' ? (
                <SortBoolAscendingVariantIcon size={30} />
              ) : (
                <SortBoolDescendingVariantIcon size={30} />
              )}
            </IconButton>
          </Tooltip>
          <StyledTextField
            style={{ marginRight: 4 }}
            select
            value={filterValue}
            label={<Span weight="bold">Filter by</Span>}
            onChange={handleFilterChange}
          >
            {filters.map((filteringValue) => (
              <MenuItem key={filteringValue} value={filteringValue}>
                <Span>{filteringValue}</Span>
              </MenuItem>
            ))}
          </StyledTextField>
        </Header>
        {isSearch && (
          <Row justify="center">
            <PrimaryButton onClick={showAllCrews}>
              Show all
              {` ${crewOrVendor()}s`}
            </PrimaryButton>
          </Row>
        )}

        <CrewsTable
          crews={Object.values(filteredCrew)}
          onEditIconPress={(crew) => {
            return tabValue !== 'organization' ? editProviderModal(crew) : null;
          }}
          showProviderDetail={showProviderDetail}
          handleCrewAction={handleCrewAction}
          loading={isActionPerforming}
          vendors={!!vendors}
        />

        <AddButton
          onPress={addProvider}
          title={vendors ? 'Add vendors' : 'Add Crew Member'}
        />
        <FormModal
          title={openEdit ? 'Edit Crew' : 'Add Crew'}
          handlePositiveAction={handleSubmit(handlePositiveAction)}
          handleNegativeAction={handleCloseDialog}
          loading={loading}
          open={open}
          edit={openEdit}
          organizationalCrew={tabValue === 'organization' && !selectedProvider}
        >
          {tabValue === 'individual' ? (
            <>{renderCrewAddForm()}</>
          ) : (
            <>{renderOrganization()}</>
          )}
        </FormModal>
        {open && (
          <LocationModal
            onLocationChange={onLocationChange}
            locationModal={locationModal}
          />
        )}
        <ADialog
          width="35%"
          overflow="scroll"
          padding="1em 1.5em 1.5em"
          open={openProviderDetail}
          onClose={handleProviderDetailClose}
        >
          <ProviderDetail
            closeProviderDetailCard={handleProviderDetailClose}
            providerDetail={selectedProvider}
            handleCrewAction={handleCrewAction}
            crewsActionAnchorEl={anchorEl}
            isPerformingAction={
              isActionPerforming?.[
                selectedProvider?.masterUid ?? selectedProvider?.uid
              ]
            }
          />
        </ADialog>
        <AlertBox
          open={openDeleteConfirmDialog}
          title="Delete crew"
          subtitle="Are you sure that you want to delete this crew ?"
          handlePositiveAction={deleteProviderData}
          handleNegativeAction={closeAlertDialog}
        />
        <ADialog height="auto" open={openInformText}>
          <InformationContainer>
            <Row justify="flex-end">
              <IconButton onClick={handleInformationDialog}>
                <CloseIcon />
              </IconButton>
            </Row>
            <Divider />
            <Row>
              <Span size="16px">{informText}</Span>
            </Row>
            <Row justify="flex-end">
              <PrimaryButton onClick={handleInformationDialog}>
                OK
              </PrimaryButton>
            </Row>
          </InformationContainer>
        </ADialog>
        <AlertBox
          negativeText="No"
          positiveText="Yes"
          open={Boolean(action)}
          title={warningMessage}
          handlePositiveAction={handleConfirmation}
          handleNegativeAction={handleNegativeAction}
        />
        <Snackbar
          open={!!emailPhoneError}
          autoHideDuration={5000}
          onClose={handleSnackbarClose}
        >
          <Alert severity="error">
            <Span>{emailPhoneError}</Span>
          </Alert>
        </Snackbar>

        {isEmpty(allCrews) && (
          <StyledContainer justifycontent="center">
            {!vendors ? NO_CREWS_ADDED : 'No vendors added.'}
          </StyledContainer>
        )}
        <NotifyBox notifyBoxModal={notifyBoxModal} message="Success" />
      </StyledCrewsContainer>
    );
  }
  return <Loader />;
}

const StyledContainer = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifycontent || 'space-between'};
  align-items: center;
`;

const StyledCrewsContainer = styled.div`
  padding: 16px;
`;

const TextField = styled(StyledTextField)`
  && {
    margin: 0;
    flex-basis: ${(props) => props.flexbasis};
    margin-right: ${(props) => props.marginright};
  }
`;

const StyledRow = styled(Row)`
  margin-top: 16px;
`;

const StyledCol = styled(Col)`
  margin-top: 12px;
`;

const InformationContainer = styled(Col)`
  padding: 12px;
`;

const AddressPicker = styled(StyledTextField)`
  && {
    margin: 0;
    width: 100%;
  }
`;

const StyledToggleButton = styled(ToggleButton)`
  &&&& {
    text-transform: capitalize;
    margin: 10px 0;
    padding: 6px 20px;
    background-color: ${(props) => props.$isSelected && color.snomwBlue};
    color: ${(props) => (props.$isSelected ? color.white : color.snomwBlue)};
    width: 100%;
  }
`;
