/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: snowm_beaconms.jsx
 *
 *  Description: Show the list of beaconms
 *
 *  Author: Roshan Gautam (roshan@brainants.com)
 *
 *  Date created: 10-july-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Typography,
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import Styled from 'styled-components';
import { BeaconMsContext } from '../../../contexts/beaconMs';
import Loader from '../../Generics/snowm_loader';
import BeaconMsItem from './BeaconMsItem';

export default function (beaconMsProps) {
  return (
    <BeaconMsContext.Consumer>
      {({ beaconMs, fetchBeaconMs }) => {
        return (
          <BeaconMs
            beaconMs={beaconMs}
            fetchBeaconMs={fetchBeaconMs}
            {...beaconMsProps}
          />
        );
      }}
    </BeaconMsContext.Consumer>
  );
}

function BeaconMs({ fetchBeaconMs, beaconMs, ...props }) {
  useEffect(() => {
    fetchBeaconMs();
  }, []);

  if (beaconMs) {
    return (
      <Container>
        <Typography variant="h4" style={{ paddingLeft: 20, paddingRight: 20 }}>
          Devices
        </Typography>
        <StyledPaper>
          <Table>
            <TableHead>
              <TableRow style={{ backgroundColor: '#C6E5F5' }}>
                <HeaderTableCell align="center">SN</HeaderTableCell>
                <HeaderTableCell align="center">Name</HeaderTableCell>
                <HeaderTableCell align="center">Status</HeaderTableCell>
                <HeaderTableCell align="center">Marker</HeaderTableCell>
                <HeaderTableCell align="center">Type</HeaderTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.values(beaconMs).map((beaconM) => (
                <BeaconMsItem key={beaconM.uuid} beaconM={beaconM} />
              ))}
            </TableBody>
          </Table>
        </StyledPaper>

        {isEmpty(beaconMs) && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            No Providers
          </div>
        )}
      </Container>
    );
  }
  return <Loader />;
}

const HeaderTableCell = Styled(TableCell)`
  && {
    color: black;
    font-size: 18px;
    font-weight: bold;
  }
`;

const Container = Styled.div`
  padding: 20px;
`;
const StyledPaper = Styled(Paper)`
margin: 20px; 
overflow-x: auto; 
border-radius: 12px; 
`;
