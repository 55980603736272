import React, { useState, useContext, useRef, useEffect } from 'react';

import styled from 'styled-components';
import CameraIcon from 'mdi-react/CameraIcon';
import { Redirect, useHistory } from 'react-router';
import SortBoolAscendingVariantIcon from 'mdi-react/SortBoolAscendingVariantIcon';
import SortBoolDescendingVariantIcon from 'mdi-react/SortBoolDescendingVariantIcon';
import {
  Switch,
  Collapse,
  IconButton,
  Tooltip,
  InputAdornment,
} from '@material-ui/core';

import Span from '../../Common/Span';
import Header from '../../Common/header';
import color from '../../../utils/color';
import AlertBox from '../../Common/alert_box';
import AddButton from '../../Common/add_button';
import UploadPic from '../../Common/upload_pic';
import FormModal from '../../Common/form_modal';
import Loader from '../../Generics/snowm_loader';
import { PropertiesTable } from './PropertiesTable';
import { lowerCase } from '../../../helpers/string';
import { useModal } from '../../Generics/snowm_modal';
import PrimaryButton from '../../Common/primary_button';
import InputTextField from '../../Common/CustomTextField';
import { LocationModal } from '../../Generics/location_modal';
import { ServiceTypesContext } from '../../../contexts/service_types';
import {
  Row,
  Col,
  StyledTitle,
  StyledAddressPickerBtn,
} from '../../../styles/snowm_styled';
import {
  archiveProperty,
  getPropertyByName,
  createEditProperty,
  getJobsCountForProperty,
  getPropertiesFromFirestore,
  getMarkersCountForProperty,
} from '../../../controllers/snowm_firebase';

export default function Properties() {
  const defaultPropertyDetails = {
    name: null,
    address: null,
    customer: null,
    imageUrl: null,
    latitude: null,
    longitude: null,
    geoFenceRadius: 200,
  };
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openAlertBox, setOpenAlertBox] = useState(false);
  const [propertyDetails, setPropertyDetails] = useState(
    defaultPropertyDetails
  );
  const [filtered, setFiltered] = useState();
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [jobsCount, setJobsCount] = useState(0);
  const [properties, setProperties] = useState();
  const [sortType, setSortType] = useState('asc');
  const [isSearch, setIsSearch] = useState(false);
  const [warningOne, setWarningOne] = useState(false);
  const [warningTwo, setWarningTwo] = useState(false);
  const [markersCount, setMarkersCount] = useState(0);
  const [addressError, setAddressError] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [sameNameErrMessage, setSameNameErrMessage] = useState('');

  const locationModal = useModal();

  const photoInputRef = useRef(null);

  const serviceContext = useContext(ServiceTypesContext);

  const history = useHistory();

  const subscribedServices = serviceContext?.subscribedServices;

  const sortByPropertyName = (a, b) => {
    const first = lowerCase(a.name);
    const second = lowerCase(b.name);

    let integer = 1;

    if (sortType === 'desc') {
      integer = -1;
    }
    if (first > second) {
      return integer;
    }
    if (first < second) {
      return -integer;
    }
    return 0;
  };

  useEffect(() => {
    const sortedData = properties?.sort(sortByPropertyName);
    setFiltered([...(sortedData ?? [])]);
  }, [sortType, properties]);

  useEffect(() => {
    let isSubscribe = true;
    function _updateProperties(response) {
      if (isSubscribe) {
        setProperties(response);
      }
    }

    getPropertiesFromFirestore(_updateProperties);

    return () => {
      isSubscribe = false;
    };
  }, []);

  if (!properties || !subscribedServices) {
    return <Loader />;
  }

  if (!subscribedServices?.properties) {
    return <Redirect to="/home" />;
  }

  function handleAddPress() {
    setOpenAddDialog(true);
    setAddressError('');
  }

  function handleCancelButton() {
    setSameNameErrMessage('');
    setOpenAddDialog(false);
    setChecked(false);
    setPropertyDetails(defaultPropertyDetails);
  }

  function openWindow() {
    photoInputRef.current.click();
  }

  function handleImageChange(event) {
    event.preventDefault();
    const filesRef = event.target?.files;
    if (filesRef?.length !== 0) {
      const file = filesRef[filesRef.length - 1];
      setPropertyDetails((prevData) => {
        return {
          ...prevData,
          imageFile: file,
        };
      });
    }
  }

  function handlePropertyName(event) {
    event.persist();

    setPropertyDetails((prevData) => {
      return {
        ...prevData,
        name: event.target.value,
      };
    });
  }

  function onLocationChange(latlng, address) {
    setPropertyDetails((prevData) => {
      return {
        ...prevData,
        latitude: latlng.lat,
        longitude: latlng.lng,
        address,
      };
    });
    setAddressError('');

    locationModal.close();
  }

  function handleSwitchChange() {
    setChecked(!checked);
  }

  async function addOrEditProperty(event) {
    event.preventDefault();
    setSameNameErrMessage('');
    setLoading(true);

    if (!propertyDetails.address) {
      setAddressError('Please select address from map.');
      setLoading(false);
      return;
    }

    const propertyWithSameName = await getPropertyByName(propertyDetails?.name);
    if (
      propertyWithSameName &&
      propertyWithSameName?.id !== propertyDetails?.id
    ) {
      setSameNameErrMessage('Property name with same name already exists.');
      setLoading(false);
      return;
    }
    const updatedDetails = { ...propertyDetails };
    if (!checked) {
      updatedDetails.customer = null;
      setPropertyDetails(updatedDetails);
    }
    try {
      await createEditProperty(updatedDetails);
      setLoading(false);
      setOpenAddDialog(false);
      setPropertyDetails(defaultPropertyDetails);
    } catch (error) {
      setLoading(false);
    }
  }

  const handleGetMarkersCount = async (property) => {
    const count = await getMarkersCountForProperty(property.id);
    setMarkersCount(count);
  };

  const handleGetJobsCount = async (property) => {
    const count = await getJobsCountForProperty(property.id);
    setJobsCount(count);
  };

  function handleDeletePress(property) {
    setWarningOne(true);
    setPropertyDetails(property);
    handleGetMarkersCount(property);
    handleGetJobsCount(property);
  }

  function handleAlexBoxCancel() {
    setOpenAlertBox(false);
  }

  async function handleDeleteButton() {
    try {
      await archiveProperty(propertyDetails.id);
      setOpenAlertBox(false);
      setPropertyDetails(defaultPropertyDetails);
    } catch (error) {
      console.error('@@Error', error);
    }
  }

  function handleEditPress(property) {
    setPropertyDetails({
      ...property,
      geoFenceRadius: property.geoFenceRadius ?? 200,
    });
    if (property.customer) setChecked(true);
    setOpenAddDialog(true);
  }

  function handleCustomerNameChange(event) {
    event.persist();

    setPropertyDetails((prevData) => {
      return {
        ...prevData,
        customer: {
          ...prevData.customer,
          name: event.target.value,
        },
      };
    });
  }

  function handleEmail(event) {
    event.persist();

    setPropertyDetails((prevData) => {
      return {
        ...prevData,
        customer: {
          ...prevData.customer,
          email: event.target.value,
        },
      };
    });
  }

  function handleCardPress(property) {
    history.push(`/home/properties/${property.id}`, {
      property,
    });
  }

  const onSearch = (searchText) => {
    const array = properties;
    setIsSearching(true);
    setFiltered(
      array.filter((property) =>
        lowerCase(property.name).includes(lowerCase(searchText))
      )
    );
    if (searchText.length) setIsSearch(true);
    setIsSearching(false);
  };

  function handleSortingChange() {
    if (sortType === 'asc') {
      setSortType('desc');
    } else {
      setSortType('asc');
    }
  }

  const resetDetails = () => {
    setJobsCount(0);
    setMarkersCount(0);
  };

  const resetFilteredData = () => {
    setFiltered(properties);
  };

  const handleClick = (e) => {
    e.stopPropagation();
    locationModal.open();
  };

  const handleGeoFenceChange = (event) => {
    setPropertyDetails({
      ...propertyDetails,
      geoFenceRadius: parseInt(event.target.value, 10),
    });
  };

  const handleGeoFencingEnablingChange = (event) => {
    setPropertyDetails({
      ...propertyDetails,
      enabledGeofencing: event.target.checked,
    });
  };

  return (
    <StyledContainer>
      <StyledTitle>Properties</StyledTitle>
      <Header
        heading="Search Properties"
        subheading="Search by Property Name"
        placeholder="Property Name"
        handleSearch={(search) => onSearch(search)}
        paddingbottom="1em"
        isSearching={isSearching}
        localSearch
        reset={!isSearch}
      >
        <Row justify="flex-end" width="auto" gap="16px">
          <Tooltip title="Sort">
            <IconButton onClick={handleSortingChange}>
              {sortType === 'desc' ? (
                <SortBoolDescendingVariantIcon size={30} cursor="pointer" />
              ) : (
                <SortBoolAscendingVariantIcon size={30} cursor="pointer" />
              )}
            </IconButton>
          </Tooltip>
        </Row>
        <div />
        <div />
      </Header>
      {isSearch && (
        <Row justify="center">
          <PrimaryButton
            onClick={() => {
              setIsSearch(false);
              resetFilteredData();
            }}
          >
            Show All
          </PrimaryButton>
        </Row>
      )}

      <TableContainer>
        <PropertiesTable
          properties={filtered}
          openPropertiesDetail={handleCardPress}
          onEditIconPress={handleEditPress}
          onDeleteIconPress={handleDeletePress}
        />
      </TableContainer>
      <AddButton onPress={handleAddPress} title="Create New Property" />

      <FormModal
        loading={loading}
        title={propertyDetails.id ? 'Edit Property' : 'Add Property'}
        handleNegativeAction={handleCancelButton}
        open={openAddDialog}
        handlePositiveAction={addOrEditProperty}
        edit={propertyDetails.id}
      >
        <ImageContainer>
          {propertyDetails.imageUrl || propertyDetails.imageFile ? (
            <UploadPic
              selectedImage={
                propertyDetails.imageFile
                  ? URL.createObjectURL(propertyDetails.imageFile)
                  : propertyDetails.imageUrl
              }
              onPress={openWindow}
            />
          ) : (
            <ImageIconContainer>
              <CameraIcon onClick={openWindow} />
            </ImageIconContainer>
          )}
        </ImageContainer>
        <InvisibleInput
          type="file"
          ref={photoInputRef}
          accept="image/**"
          onChange={handleImageChange}
        />

        <InputTextField
          autoFocus
          label="Property Name"
          value={propertyDetails.name}
          onChange={handlePropertyName}
        />
        <Span color={color.red} weight="bold">
          {sameNameErrMessage}
        </Span>

        <StyledAddressPickerBtn onClick={handleClick}>
          <InputTextField
            style={{
              pointerEvents: 'none',
              cursor: 'pointer',
              width: '100%',
            }}
            label="Property Address"
            value={propertyDetails.address}
            error={addressError}
            helperText={addressError}
            disabled
          />
        </StyledAddressPickerBtn>

        {subscribedServices?.propertyLevelGeofence && (
          <Row justify="space-between">
            <Span>Enable Job Geofencing</Span>
            <Switch
              color="primary"
              onChange={handleGeoFencingEnablingChange}
              checked={propertyDetails.enabledGeofencing}
            />
          </Row>
        )}

        {propertyDetails?.enabledGeofencing && (
          <>
            <InputTextField
              label="Geofence radius"
              InputProps={{
                endAdornment: <InputAdornment>m</InputAdornment>,
              }}
              type="number"
              // eslint-disable-next-line react/jsx-no-duplicate-props
              inputProps={{ min: '200' }}
              value={propertyDetails.geoFenceRadius}
              onChange={handleGeoFenceChange}
              isRequired
            />
            <Col gap="12px">
              <Span color="grey" size="12px">
                The distance at which crew is mandated to be present while
                serving a marker withing this property, if crew comes out of the
                property during an active job, you will get a notification.
              </Span>
              <Span color="grey" size="12px">
                Please note that, this will also ask crew to provide background
                location permission and is mandatory for this feature.
              </Span>
            </Col>
          </>
        )}

        {subscribedServices.endCustomerLogin && (
          <>
            <Row justify="space-between">
              <Span>Enable Customer Login</Span>
              <Switch
                checked={checked}
                color="primary"
                onChange={handleSwitchChange}
              />
            </Row>

            <Collapse in={checked} unmountOnExit>
              <InputTextField
                label="Customer Name"
                required
                onChange={handleCustomerNameChange}
                value={propertyDetails?.customer?.name}
              />
              <InputTextField
                value={propertyDetails?.customer?.email}
                label="Customer Email"
                required
                onChange={handleEmail}
              />
            </Collapse>
          </>
        )}
      </FormModal>
      {openAddDialog && (
        <LocationModal
          onLocationChange={onLocationChange}
          locationModal={locationModal}
        />
      )}
      <AlertBox
        open={openAlertBox}
        handleNegativeAction={handleAlexBoxCancel}
        title="Delete Property"
        subtitle="Are you sure you want to delete the property?"
        handlePositiveAction={handleDeleteButton}
      />
      <AlertBox
        open={warningOne}
        handleNegativeAction={() => {
          setWarningOne(false);
          resetDetails();
        }}
        title="Warning"
        subtitle={`There are ${markersCount} marker/s within this property and they will be unassigned from this property. Are you sure want to delete this property? `}
        positiveText="Yes"
        negativeText="No"
        handlePositiveAction={() => {
          setWarningOne(false);
          setWarningTwo(true);
        }}
      />
      <AlertBox
        open={warningTwo}
        handleNegativeAction={() => {
          setWarningTwo(false);
          resetDetails();
        }}
        title="Warning"
        subtitle={`You have ${jobsCount} numbers of Jobs assigned to this property and will not have any impact on the Jobs associated  with this property, Do you stil want to continue deletion ? `}
        positiveText="Yes"
        negativeText="No"
        handlePositiveAction={() => {
          setWarningTwo(false);
          setOpenAlertBox(true);
        }}
      />
    </StyledContainer>
  );
}

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ImageIconContainer = styled(ImageContainer)`
  align-items: center;
  background-color: ${color.snomwBlue};
  height: 100px;
  width: 100px;
  border-radius: 50%;
`;

const StyledContainer = styled.div`
  margin: 18px;
`;

const InvisibleInput = styled.input`
  display: none;
`;

const TableContainer = styled.div`
  padding: 16px;
`;
