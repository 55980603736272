import React, { useState, useEffect, useContext } from 'react';

import styled from 'styled-components';
import { Redirect } from 'react-router';
import { MenuItem, TextField } from '@material-ui/core';

import Span from '../../Common/Span';
import JobModal from '../Jobs/snowm_add_job';
import AlertBox from '../../Common/alert_box';
import Loader from '../../Generics/snowm_loader';
import ServiceRequestItem from './ServiceRequestItem';
import { Row, Title } from '../../../styles/snowm_styled';
import { ServiceTypesContext } from '../../../contexts/service_types';
import {
  getServiceInfo,
  getServicePointById,
  getServiceRequestOfACompany,
  updateWorkOrder,
} from '../../../controllers/snowm_firebase';

const ServiceRequests = () => {
  const workOrdersStatuses = [
    'All',
    'Completed',
    'Dispatched',
    'Rejected',
    'Requested',
    'Working',
  ];
  const data = useContext(ServiceTypesContext);
  const subscribedServices = data?.subscribedServices;

  const [serviceRequests, setServiceRequests] = useState();
  const [allServiceRequests, setAllServiceRequests] = useState(null);
  const [openJobDialog, setOpenJobDialog] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [openAlertBox, setOpenAlertBox] = useState(false);
  const [selectedFilterStatus, setSelectedFilterStatus] = useState('All');

  useEffect(() => {
    let isCancel = false;
    getServiceRequestOfACompany().then((res) => {
      if (!isCancel) {
        setAllServiceRequests(res);
        setServiceRequests(res);
      }
    });

    return () => {
      isCancel = true;
    };
  }, []);

  useEffect(() => {
    if (selectedOrder?.markerKey) {
      getServicePointById(selectedOrder.markerKey).then((res) => {
        setSelectedMarker(res);
      });
    }
  }, [selectedOrder]);

  useEffect(() => {
    if (selectedMarker?.serviceKey) {
      getServiceInfo(selectedMarker.serviceKey).then((res) => {
        setSelectedService({ label: res?.name, value: res });
      });
    }
  }, [selectedMarker]);

  if (!serviceRequests || !subscribedServices) {
    return <Loader />;
  }

  if (!subscribedServices?.workOrders) {
    return <Redirect to="/home" />;
  }

  function handleStartButton(order) {
    setSelectedOrder(order);
    setOpenJobDialog(true);
  }

  function handleJobModalClose() {
    setOpenJobDialog(false);
    setSelectedOrder(null);
    setSelectedService(null);
    setSelectedMarker(null);
  }

  async function handleJobCreation(jobRes) {
    const { id, markerKey } = selectedOrder;
    const newData = {
      status: 'DISPATCHED',
      jobKey: jobRes?.key ?? null,
    };
    try {
      await updateWorkOrder(markerKey, id, newData);
      const updatedWorkOrders = serviceRequests?.reduce((acc, curr) => {
        if (curr.id === id) {
          return [...acc, { ...curr, status: 'DISPATCHED' }];
        }
        return [...acc, curr];
      }, []);

      setServiceRequests(updatedWorkOrders);
      handleJobModalClose();
    } catch (error) {
      console.error({ error });
    }
  }

  function closeAlertDialog() {
    setOpenAlertBox(false);
  }

  async function rejectWorkOrder() {
    const { id, markerKey } = selectedOrder;
    try {
      await updateWorkOrder(markerKey, id, 'REJECTED');
      const updatedWorkOrders = serviceRequests?.reduce((acc, curr) => {
        if (curr.id !== id) {
          return [...acc, curr];
        }
        return [...acc, { ...curr, status: 'REJECTED' }];
      }, []);
      setServiceRequests(updatedWorkOrders);
      setOpenAlertBox(false);
    } catch (error) {
      console.error({ error });
    }
  }

  function handleRejectButton(selectedWorkOrder) {
    setSelectedOrder(selectedWorkOrder);
    setOpenAlertBox(true);
  }

  function handleFilterChange(event) {
    setSelectedFilterStatus(event.target.value);
    const selectedFilter = event.target.value.toUpperCase();
    if (selectedFilter === 'ALL') {
      setServiceRequests(allServiceRequests);
    } else {
      const filteredServiceRequests = allServiceRequests?.filter(
        (request) => request.status === selectedFilter
      );
      setServiceRequests(filteredServiceRequests);
    }
  }

  const renderServiceRequests = () => {
    if (!serviceRequests?.length) {
      return (
        <Row justify="center">
          <Span weight="bold">No service requests.</Span>
        </Row>
      );
    }
    return (
      <Grid>
        {serviceRequests?.map((request) => {
          return (
            <ServiceRequestItem
              key={request.id}
              serviceRequest={request}
              inWorkOrdersPage
              handleStartButton={() => handleStartButton(request)}
              handleRejectButton={() => handleRejectButton(request)}
            />
          );
        })}
      </Grid>
    );
  };

  return (
    <>
      <Container>
        <Row justify="space-between">
          <Title>Work Orders</Title>
          <TextField
            select
            label="Filter"
            value={selectedFilterStatus}
            onChange={handleFilterChange}
          >
            {workOrdersStatuses.map((workStatus) => (
              <MenuItem key={workStatus} value={workStatus}>
                {workStatus}
              </MenuItem>
            ))}
          </TextField>
        </Row>
        {renderServiceRequests()}
      </Container>
      <JobModal
        open={openJobDialog}
        orderSelected={selectedOrder}
        serviceOfOrder={selectedService}
        markerSelected={selectedMarker}
        onJobCreation={handleJobCreation}
        onClose={handleJobModalClose}
      />
      <AlertBox
        open={openAlertBox}
        handleNegativeAction={closeAlertDialog}
        subtitle="Are you sure you want to reject this order?"
        title="Reject Order"
        handlePositiveAction={rejectWorkOrder}
        positiveText="Reject"
      />
    </>
  );
};

export default ServiceRequests;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 12px;
`;

const Container = styled.div`
  margin: 12px;
`;
