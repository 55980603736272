/* eslint-disable react/jsx-wrap-multilines */
import styled from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import React, { useEffect, useState, useContext } from 'react';
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  Card,
  CircularProgress,
  InputBase,
  Divider
} from '@material-ui/core';

import ADialog from '../../Common/styled';
import PolylineMap from '../Maps/map_polyline';
import { FloatingButton, ServiceType } from '../styled';
import { getRouteForPoints } from '../../../helpers/polyline';
import { Title, Row, Col } from '../../../styles/snowm_styled';
import getSearchedMarkersFromAlgolia from '../../../controllers/algolia';
import { LocalizationContext } from '../../../contexts/localization_context';
import { getFilteredItems as getFilteredMarkers } from '../../../helpers/misc';
import {
  editRoute,
  getRealTimeUnAssignedServicePoints,
  getServicePointById
} from '../../../controllers/snowm_firebase';

export default function EditRoute({ type, routeInfo }) {
  const [editing, setEditing] = useState(false);
  const [route, setRoute] = useState({});
  const [open, setOpen] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allServicePoints, setAllServicePoints] = useState([]);
  const [servicePoints, setServicePoints] = useState({});
  const [input, setInput] = useState();
  const [servicePointOfARoute, setServicePointOfARoute] = useState([]);

  const { strings } = useContext(LocalizationContext);

  const { SEARCH } = strings?.search;
  const { MARKERS } = strings?.sidemenu;
  const { LAST_SERVED, MARKER_NAME, SELECTED_MARKERS } = strings?.markers;
  const { UPDATE_ROUTE, CANCEL, EDIT_ROUTE } = strings?.action;
  const { NAME, ADDRESS } = strings?.inputLabel;
  const { MINIMUM_MARKERS } = strings?.messages;

  // list of all the points to be drawn in polyline

  // for dialog box
  // point displayed in the dialog box
  const [selectedPoint, setSelectedPoint] = useState(null);
  const [availableMarkers, setAvailableMarkers] = useState(null);

  const [insert, setInsert] = useState(false);
  const [maps, setMaps] = useState(null);

  const [selectedMarker, setSelectedMarker] = useState();
  const [center, setCenter] = useState([47.367347, 8.5500025]);

  useEffect(() => {
    setRoute(routeInfo);
  }, []);

  useEffect(() => {
    if (window.google) {
      if (window.google.maps) {
        setMaps(window.google.maps);
      }
    }
  }, [window.google]);

  useEffect(() => {
    getRealTimeUnAssignedServicePoints(markers => {
      setAllServicePoints(markers);
    });
  }, []);

  async function updatePolyline() {
    if (route.servicePointsKeys && route.servicePointsKeys.length > 1) {
      if (maps) {
        const allPoints = [];

        route.servicePointsKeys.forEach(key => {
          allPoints.push(servicePoints[key]);
        });

        const newRouteData = await getRouteForPoints(allPoints);
        if (newRouteData) {
          setRoute({
            ...route,
            polyline: newRouteData.routePath,
            distance: newRouteData.distance,
            duration: newRouteData.duration
          });
        }
      }
    } else {
      setRoute({ ...route, polyline: null });
    }
  }

  useEffect(() => {
    if (Object.keys(route).length) {
      const promises = route.servicePointsKeys.map(spKey => {
        return getServicePointById(spKey);
      });

      Promise.all(promises).then(values => {
        setServicePointOfARoute(values);
      });
    }
  }, [allServicePoints, route.servicePointsKeys, maps]);

  useEffect(() => {
    const assignedSp = {};
    const unAssignedSp = {};
    Object.values(allServicePoints).forEach(sp => {
      if (sp.serviceType === 'outdoor') {
        unAssignedSp[sp.key] = { ...sp };
      }
    });

    if (editing) {
      servicePointOfARoute.forEach(v => {
        unAssignedSp[v.key] = { ...v, assigned: true };
      });
      setServicePoints(prevState => {
        return {
          ...prevState,
          ...unAssignedSp
        };
      });
    } else {
      servicePointOfARoute.forEach(v => {
        // if (v.assigned === true) {
        assignedSp[v.key] = { ...v };
        // }
      });
      setServicePoints(assignedSp);
    }
  }, [editing, servicePointOfARoute]);

  useEffect(() => {
    if (Object.keys(servicePoints).length) {
      updatePolyline();
    }
  }, [servicePoints]);

  // point: ServicePoint
  function selectPoint(point) {
    if (editing) {
      if (route.servicePointsKeys) {
        if (route.servicePointsKeys.includes(point.key)) {
          setInsert(true);
        } else {
          setInsert(false);
        }
      }
      setSelectedPoint(point);
      setOpen(true);
    } else if (selectedMarker?.key !== point.key) {
      setSelectedMarker({ ...point });
    }
  }

  function closeDialog() {
    setOpen(false);
  }

  // after the dialog box changes are confirmed
  function makeChanges(point = selectedPoint, status = insert) {
    setOpenSearch(false);
    if (point) {
      closeDialog();
      if (!route.servicePointsKeys) {
        route.servicePointsKeys = [];
      } else {
        route.servicePointsKeys = route.servicePointsKeys.filter(
          sp => sp !== point.key
        );
      }
      if (status) {
        const r = {
          ...route,
          servicePointsKeys: [...route.servicePointsKeys, point.key]
        };

        setRoute({ ...r });
        setServicePoints(prevState => {
          return {
            ...prevState,
            [point.key]: { ...point, assigned: true }
          };
        });
      } else {
        setServicePoints(prevState => {
          return {
            ...prevState,
            [point.key]: { ...point, assigned: false }
          };
        });
      }
    }
    // setRoute({ ...route, servicePointsKeys: [...route.servicePointsKeys] });
  }

  function removeFromList(point) {
    makeChanges(point, false);
  }

  function updateRoute() {
    setLoading(true);
    if (route.servicePointsKeys.length <= 1) {
      route.polyline = '';
    }

    editRoute(route)
      .then(() => {
        setLoading(false);
        setEditing(false);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function cancelEdit() {
    setEditing(false);
  }

  function closeSearch() {
    setOpenSearch(false);
  }

  function openSearchDialog() {
    setOpenSearch(true);
  }

  function showAllMarkers() {
    const mk = Object.values(servicePoints).filter(sp => {
      return sp.serviceType !== 'INDOOR' && sp.assigned === false;
    });
    setAvailableMarkers(mk);
  }

  useEffect(() => {
    showAllMarkers();
  }, [servicePoints]);

  function handleValueChange(e) {
    setInput(e.target.value);
  }

  async function handleSearch() {
    const markers = await getSearchedMarkersFromAlgolia(input, 'markers');
    const markersAfterFilter = getFilteredMarkers(markers, route.serviceType);
    setAvailableMarkers(markersAfterFilter);
  }

  const handleMarkerCardClick = markerDetail => {
    if (selectedMarker?.key !== markerDetail.key) {
      setSelectedMarker({ ...markerDetail });
    }
  };

  return (
    <Container>
      <ContentPane>
        <Title>{route ? route.name : ''}</Title>
        <ServiceType>{type}</ServiceType>
        <Typography variant="subtitle1">
          {`Distance: ${route.distance ? route.distance : 0} meters`}
        </Typography>
        <SelectedItem>
          <Row align="center">
            <Typography
              style={{ marginTop: 10, fontWeight: 500 }}
              variant="subtitle1"
            >
              {SELECTED_MARKERS}
            </Typography>
            {editing && <Image onClick={() => openSearchDialog(true)} />}
          </Row>
          {route.servicePointsKeys && route.servicePointsKeys.length !== 0 ? (
            route.servicePointsKeys.map(spKey => {
              const servicePointDetail = servicePoints[spKey];
              if (servicePointDetail) {
                return (
                  <SPCard
                    onClick={() => handleMarkerCardClick(servicePointDetail)}
                    highlight={selectedMarker?.key === servicePointDetail.key}
                    key={spKey}
                  >
                    <div>
                      <div>
                        <Typography variant="subtitle1">{`${NAME}: ${servicePointDetail.name}`}</Typography>
                      </div>
                      <div>
                        <Typography variant="caption">{`${ADDRESS}: ${servicePointDetail.address}`}</Typography>
                      </div>
                    </div>
                    {editing && (
                      <div>
                        <CloseIcon
                          style={{ fontSize: 12, cursor: 'pointer' }}
                          onClick={() => removeFromList(servicePointDetail)}
                        />
                      </div>
                    )}
                  </SPCard>
                );
              }
              return <div key={spKey} />;
            })
          ) : (
            <Typography variant="caption">No markers selected</Typography>
          )}
        </SelectedItem>
        {editing && (
          <div
            style={{
              width: '100%',
              alignItems: 'center'
            }}
          >
            {loading ? (
              <CircularProgress style={{ margin: 10 }} />
            ) : (
              <div>
                {route.servicePointsKeys.length >= 3 ? (
                  <>
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{ marginTop: 10 }}
                      onClick={updateRoute}
                    >
                      {UPDATE_ROUTE}
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{ marginTop: 10 }}
                      onClick={cancelEdit}
                    >
                      {CANCEL}
                    </Button>
                  </>
                ) : (
                  <h6>{MINIMUM_MARKERS}</h6>
                )}
              </div>
            )}
          </div>
        )}
      </ContentPane>
      <ADialog
        open={openSearch}
        onClose={closeSearch}
        fullWidth
        width="80%"
        height="auto"
      >
        <Col>
          <Row justify="space-between" style={{ marginTop: 20 }}>
            <InputDiv>
              <InputBase
                placeholder={MARKER_NAME}
                inputProps={{ 'aria-label': 'search' }}
                style={{ padding: '8px 8px 8px 20px', width: '100%' }}
                onChange={handleValueChange}
              />
            </InputDiv>
            <SearchButton
              color="primary"
              variant="contained"
              disabled={!input}
              onClick={() => handleSearch()}
            >
              {SEARCH}
            </SearchButton>
          </Row>
        </Col>
        <div style={{ padding: 16 }}>
          <Row>
            <Typography
              style={{ color: '#a3a3a3', fontWeight: 'bold' }}
              variant="body1"
            >
              {MARKERS}
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              onClick={showAllMarkers}
              style={{ padding: '0 5px', marginLeft: 10 }}
            >
              Show all
            </Button>
          </Row>
          <Divider style={{ marginTop: 2, color: '#a3a3a3' }} />

          <div>
            <table style={{ width: '100%' }}>
              <tbody>
                {availableMarkers &&
                  availableMarkers.map(m => {
                    return (
                      <tr key={m.key}>
                        <Row
                          justify="space-between"
                          style={{ margin: 15, cursor: 'pointer' }}
                          onClick={() => selectPoint(m)}
                        >
                          <td style={{ flex: 1 }}>{m.name || 'namee'}</td>
                          <td style={{ flex: 1 }}>
                            {LAST_SERVED}
                            :
                            <Typography style={{ color: '#29abe2' }}>
                              {m.lastServed || '---'}
                            </Typography>
                          </td>
                          <td style={{ flex: 1 }}>
                            <ServiceType>{m.serviceType || '---'}</ServiceType>
                          </td>
                        </Row>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </ADialog>
      <MapContainer>
        <PolylineMap
          selectedMarker={selectedMarker}
          center={center}
          points={servicePoints}
          polyline={route.polyline}
          onMarkerTap={selectPoint}
          edit={editing}
        />
      </MapContainer>
      <Dialog
        open={open}
        onClose={closeDialog}
        disableBackdropClick
        disableEscapeKeyDown
      >
        {selectedPoint && (
          <MarkerDetailsBox>
            <div style={{ padding: '1em' }}>
              <StyledMarkerDetails>
                <StyledTopics>Name:</StyledTopics>
                <StyledDetails>{selectedPoint.name}</StyledDetails>
              </StyledMarkerDetails>
              <StyledMarkerDetails>
                <StyledTopics>Address:</StyledTopics>
                <StyledDetails>{selectedPoint.address}</StyledDetails>
              </StyledMarkerDetails>
            </div>
          </MarkerDetailsBox>
        )}
        <DialogTitle style={{ padding: '0 1em' }} id="form-dialog-title">
          {insert ? 'Remove marker from the route' : 'Add marker to the route'}
        </DialogTitle>
        <DialogActions>
          <Button onClick={closeDialog} color="primary">
            Cancel
          </Button>

          <Button
            onClick={() => makeChanges(selectedPoint, !insert)}
            color="primary"
          >
            {insert ? 'Remove' : 'Add'}
          </Button>
        </DialogActions>
      </Dialog>
      {!editing && (
        <FloatingButton>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setEditing(true);
            }}
          >
            {EDIT_ROUTE}
          </Button>
        </FloatingButton>
      )}
    </Container>
  );
}

export const MapContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  height: calc(100vh - 64px);
  flex: 3;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: 'center';
`;

const ContentPane = styled.div`
  padding: 10px;
  overflow-y: auto;
  height: calc(100vh - 84px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1.2;
`;

const SelectedItem = styled.div`
  /* padding: 10px; */
`;

const SPCard = styled(Card)`
  && {
    margin: 8px;
    padding: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    ${props =>
      props.highlight &&
      `
      background-color: grey;
    color: white;`}
  }
`;

const Image = styled(SearchIcon)`
  height: 35px;
  margin-left: 15px;
  cursor: pointer;
  border-radius: 60%;
  && :hover {
    background: grey;
  }
`;

const InputDiv = styled.div`
  border: 1px solid grey;
  border-radius: 7px;
  height: 50px;
  flex-grow: 3;
  margin-left: 1.1em;
`;

const SearchButton = styled(Button)`
  && {
    font-weight: bolder;
    height: 55px;
    max-width: 155px;
    margin-right: 20px;
    margin-left: 45px;
    padding: 3px 3em;
    flex: 1;
  }
`;

const StyledMarkerDetails = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledTopics = styled.span`
  font-weight: bold;
`;

const StyledDetails = styled.span`
  margin-left: 1em;
`;

const MarkerDetailsBox = styled.div`
  margin: 1em;
  background-color: #cbcbcb;
  border-radius: 1em;
  box-shadow: 1px 1px 5px #847979;
`;
