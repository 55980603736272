import * as React from 'react';

import { Alert } from '@material-ui/lab';
import { useHistory } from 'react-router';
import * as countriesList from 'countries-list';
import { Divider, MenuItem, Snackbar, TextField } from '@material-ui/core';

import Span from '../Common/Span';
import color from '../../utils/color';
import { LowerCaseButton } from '../Common/styled';
import { Progress } from '../Generics/snowm_loader';
import { Col, Row } from '../../styles/snowm_styled';
import PrimaryButton from '../Common/primary_button';
import { AuthContext } from '../../contexts/auth_context';
import {
  auth,
  checkIfPhoneNumberValid,
  sendOTPToPhoneNumber,
  signInWithPhoneNumber,
} from '../../controllers/snowm_firebase';

const countriesDetails = Object.values(countriesList.countries).reduce(
  (acc, countryCode) => {
    if (countryCode.phone) {
      return [...acc, Number(countryCode.phone.split(',')[0])];
    }
    return acc;
  },
  []
);

const uniqueCountryCode = [...new Set(countriesDetails)];

uniqueCountryCode.sort((a, b) => a - b);

const PhoneNumber = ({ handleCloseButton }) => {
  const [error, setError] = React.useState(null);
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [countryCode, setCountryCode] = React.useState('+1');
  const [smsCodeError, setSmsCodeError] = React.useState('');
  const [phoneNumberError, setPhoneNumberError] = React.useState('');
  const [countries, setCountries] = React.useState(uniqueCountryCode);
  const [otpSuccessResult, setOtpSuccessResult] = React.useState(null);

  const [
    phoneNumberWithCountryCode,
    setPhoneNumberWithCountryCode,
  ] = React.useState(null);

  const history = useHistory();
  const [fetching, setFetching] = React.useState(false);

  const [smsCode, setSMSCode] = React.useState(null);
  const [isSendingOTPAgain, setIsSendingOTPAgain] = React.useState(false);

  const authContext = React.useContext(AuthContext);

  const { setAuthenticated, setCompanyKey } = authContext;

  const handleOTPSending = async () => {
    try {
      const confimrationResult = await sendOTPToPhoneNumber(
        phoneNumberWithCountryCode
      );
      setOtpSuccessResult(confimrationResult);
    } catch (err) {
      switch (err.code) {
        case 'auth/too-many-requests':
          setError(
            'All requests block due to unusual activity. Please try again later.'
          );
          break;

        default:
          setError('Cannot send OTP.');
          break;
      }
      console.error('optError', err);
      setFetching(false);
    }
  };

  const sendOtpCodeAgain = async () => {
    setIsSendingOTPAgain(true);
    await handleOTPSending();
    setIsSendingOTPAgain(false);
  };

  React.useEffect(() => {
    if (phoneNumberWithCountryCode) {
      checkIfPhoneNumberValid(phoneNumberWithCountryCode).then((res) => {
        const { data } = res;
        const { roles, validPhone } = data ?? {};
        const isAdmin = roles?.some((role) => role.role === 'administrator');
        if (validPhone && isAdmin) {
          handleOTPSending()
            .then(() => {
              setFetching(false);
            })
            .catch();
        } else if (!validPhone) {
          setError(
            `${phoneNumberWithCountryCode} is not registered on our system. Consult your admin.`
          );
          setFetching(false);
        } else if (!isAdmin) {
          setError(
            `${phoneNumberWithCountryCode} is not allowed to login in marker admin app.`
          );
          setFetching(false);
          //   setError();
        }
      });
    }
  }, [phoneNumberWithCountryCode]);

  React.useEffect(() => {
    window.appVerifier = new auth.RecaptchaVerifier('recaptcha-container', {
      size: 'invisible',
    });
  }, []);

  const handleInputChange = (event) => {
    setPhoneNumberError('');
    const number = event.target.value;
    setPhoneNumber(number);
  };

  const handleSendOTPButton = async (event) => {
    event.preventDefault();

    if (phoneNumber.length < 10) {
      setPhoneNumberError('Phone number must be exactly 10 digits.');
      return;
    }

    setFetching(true);
    if (!otpSuccessResult) {
      setPhoneNumberWithCountryCode(`${countryCode}${phoneNumber}`);
    } else {
      if (smsCode.length < 6) {
        setSmsCodeError('SMS code must be exactly 6 digits.');
        setFetching(false);
        return;
      }
      handleSignIn();
    }
  };

  const handleCountryCodeChange = (event) => {
    setCountryCode(event.target.value);
  };

  const handleClose = () => {
    setError(null);
  };

  const handleSignIn = async () => {
    try {
      const res = await signInWithPhoneNumber(otpSuccessResult, smsCode);

      if (res.pathname === '/home') {
        setCompanyKey(res.keyOfCompany);
        setAuthenticated({ userClaims: res.data, canLogIn: true });
        history.push(res.pathname);
        return;
      }
      history.push(res.pathname);
    } catch (e) {
      switch (e.code) {
        case 'auth/invalid-verification-code':
          setError('Invalid verificaiton code.');
          break;

        default:
          break;
      }
      console.error(e);
      setFetching(false);
    }
  };

  const handleCancelButton = () => {
    setPhoneNumber('');
    setSmsCodeError('');
    handleCloseButton();
  };

  const handleSMSCodeChange = (event) => {
    setSmsCodeError('');
    setSMSCode(event.target.value);
  };

  return (
    <>
      <Col gap="8px">
        <Row justify="center">
          <Span weight="bold" size="18px">
            Login With Phone Number
          </Span>
        </Row>
        <Divider />
        <form onSubmit={handleSendOTPButton}>
          <Col
            gap="8px"
            style={{
              marginTop: 6,
            }}
          >
            <Row justify="center" gap="12px">
              <TextField
                style={{
                  flexBasis: '30%',
                }}
                select
                value={countryCode}
                onChange={handleCountryCodeChange}
                variant="outlined"
              >
                {countries.map((code) => {
                  return (
                    <MenuItem value={`+${code}`}>
                      <Span>{`+${code}`}</Span>
                    </MenuItem>
                  );
                })}
              </TextField>
              <TextField
                style={{
                  width: '100%',
                }}
                inputProps={{
                  maxLength: 10,
                  pattern: '[0-9]+',
                  title: 'Phone number must be exactly 10 digits.',
                }}
                variant="outlined"
                onChange={handleInputChange}
                label="Phone Number"
                type="text"
                required
                helperText={phoneNumberError}
                error={!!phoneNumberError}
              />
            </Row>
            {otpSuccessResult && (
              <>
                <TextField
                  inputProps={{
                    maxLength: 6,
                    pattern: '[0-9]+',
                    title: 'OTP code must be 6 digit long.',
                  }}
                  label="Verification code"
                  onChange={handleSMSCodeChange}
                  variant="outlined"
                  required
                  error={!!smsCodeError}
                  helperText={smsCodeError}
                />
              </>
            )}

            <div id="recaptcha-container" />

            <Row justify="flex-end" gap="8px">
              {otpSuccessResult && (
                <LowerCaseButton
                  onClick={sendOtpCodeAgain}
                  style={{ flexBasis: '35%', backgroundColor: color.white }}
                  disabled={fetching}
                >
                  {isSendingOTPAgain ? (
                    <Progress size={20} />
                  ) : (
                    <Span color="primary" weight="bold">
                      Resend Code
                    </Span>
                  )}
                </LowerCaseButton>
              )}
              <Row>
                <LowerCaseButton
                  onClick={handleCancelButton}
                  disabled={fetching}
                >
                  Cancel
                </LowerCaseButton>
                <PrimaryButton loading={fetching} type="submit">
                  Submit
                </PrimaryButton>
              </Row>
            </Row>
          </Col>
        </form>
      </Col>
      <Snackbar open={!!error} autoHideDuration={6000} onClose={handleClose}>
        <Alert severity="error">
          <Span>{error}</Span>
        </Alert>
      </Snackbar>
    </>
  );
};
export default PhoneNumber;
