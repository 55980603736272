const fr = {
  validation: {},
  auth: {
    LOGIN: `S'identifier`,
    LOG_OUT: 'Connectez - Out',
  },
  inputLabel: {
    EMAIL: 'Email',
    PASSWORD: 'Mot de passe',
    NAME: 'prénom',
    PHONE: 'Téléphone',
    ADDRESS: 'Adresse',
    CONTACT_NUMBER: 'Numéro de contact',
    DESIGNATION: 'La désignation',
  },
  sidemenu: {
    HOME: 'Accueil',
    JOBS: 'Emplois',
    CREW: 'Équipage',
    SERVICES: 'Prestations de service',
    ROUTES: 'routes',
    MARKERS: 'Marqueurs',
    REPORTS: 'Rapports',
    COMPANY_PROFILE: 'Profil',
    DEVICES: 'Dispositifs',
    ATTENDANCE: 'Présence',
    USER_PROFILE: `Profil de l'utilisateur`,
  },
  jobType: {
    ASSIGNED_JOBS: "Offres d'emploi Assigned",
    PERIODIC_JOBS: 'Emploi périodique',
  },
  action: {
    CREATE_JOB: 'Créer emploi',
    UPDATE_ROUTE: 'Mise à jour Route',
    EDIT_ROUTE: "Modifier l'itinéraire",
    EDIT: 'modifier',
    CANCEL: 'Annuler',
    CONFIRM: 'Confirmer',
    ADD: 'Ajouter',
  },
  location: {
    CURRENT_LOCATION: 'Localisation actuelle',
  },
  detail: {
    CREW_DETAIL: 'Détail équipage',
    ISSUE_REPORTS: "Rapports d'émission",
  },
  messages: {
    NO_CREWS_ADDED: "Aucun membre d'équipage.",
    MINIMUM_MARKERS:
      "Il n'y a pas assez de marqueurs sur la route à affecter à une équipe. S'il vous plaît ajouter au moins 3 points",
    YES: 'Oui',
    NO: 'Non',
    NO_MESSAGES: 'Pas de messages',
    NOT_AVAILABLE: 'Indisponible',
  },
  search: {
    SEARCH: 'Recherche',
  },
  home: {
    NOTIFICATIONS: 'notifications',
    SEE_MORE_INFO: `Voir plus d'infos`,
  },
  status: {
    ASSIGNED: 'attribué',
    COMPLETED: 'Terminé',
    STARTED: 'commencé',
  },
  markers: {
    LAST_SERVED: 'dernière Servi',
    MARKER_NAME: 'marker Nom',
    SELECTED_MARKERS: 'Marqueurs sélectionnés',
    SELECT_MARKER: 'Sélectionnez Marker',
    MARKER_LOGS: 'journaux marker',
    SERVED_MARKERS: 'Marqueurs servis',
  },
  misc: {
    CHANGE_LANGUAGE: 'Changer de langue',
    LANGUAGE_SETTINGS: 'Paramètres de langue',
    DISTANCE: 'Distance',
    METERS: 'mètres',
    LAST_UPDATED: 'Dernière mise à jour',
    MAP_VIEW: 'Vue de la carte',
    CHANGE_PASSWORD: 'Changer le mot de passe',
  },
  serviceType: {
    INDOOR: 'Intérieur',
  },
  image: {
    AFTER_IMAGE: "après l'image",
    BEFORE_IMAGE: "avant l'image",
  },
  time: {
    COMPLETED_AT: 'terminé à',
  },
};

export default fr;
