import React, { useEffect, useState } from 'react';

import { CustomSelect } from './custom_select';

function List(
  {
    selectedService,
    edit,
    names,
    options,
    setRoutesOrMarkers,
    value,
    isDisabled,
    ...props
  },
  ref
) {
  const [selectedName, setSelectedName] = useState('');

  useEffect(() => {
    if (selectedService.value.type === 'markerbased') {
      setSelectedName('markers');
    } else {
      setSelectedName('routes');
    }
  }, [selectedService]);

  function handleChange(data) {
    let allSelected = [];
    if (data) {
      if (Array.isArray(data)) {
        allSelected = data.map((prov) => prov.value);
      } else {
        allSelected = [data.value];
      }
    }
    setRoutesOrMarkers(allSelected, data);
  }

  return (
    <CustomSelect
      isDisabled={isDisabled}
      defaultValue={[]}
      placeholder={
        edit && names
          ? names?.map((name) => name).join()
          : `Select ${selectedName}`
      }
      isMulti
      name="routes"
      options={options}
      onChange={(data) => handleChange(data)}
      required
      value={value}
      {...props}
      ref={ref}
    />
  );
}

export default React.forwardRef(List);
