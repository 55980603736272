import React from 'react';

import styled from 'styled-components';

const IssueLink = ({ link, children }) => {
  return (
    <Link href={link} target="_blank" rel="noreferrer">
      {children}
    </Link>
  );
};

const Link = styled.a`
  text-decoration: none;
`;

export default IssueLink;
