/* eslint-disable react/jsx-one-expression-per-line */
/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: pdf_document_provider.js
 *
 *  Description: Document that will be exported as pdf according to provider
 *
 *  Author: Roshan Gautam (roshan@brainants.com)
 *
 *  Date created: 18-july-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */

import React, { Fragment } from 'react';

import { Document, Page, Text, View } from '@react-pdf/renderer';
import {
  DataTableCell,
  Table,
  TableBody,
  TableHeader,
} from '@david.kucsai/react-pdf-table';

import { getTimeFromLog } from './getTimeFromLog';
import { selectTypeOfRoutes } from '../Private/reports/selectType';
import { getDayMonthYear, getDayMonthYearTime } from '../../helpers/date';
import {
  JobHeader,
  RowData,
  styles,
  LogData,
  TopPdfHeader,
  RenderImage,
  RenderIssueReports,
} from './pdf_utils';

// Create styles

// Create Document Component

const PDFDocumentProvider = ({ pdfData }) => {
  const {
    id,
    provider,
    servedJobs,
    companyDetail = {},
    generatedBy,
    selectImages,
    startDate,
    endDate,
  } = pdfData;

  const reportTitle = `Report generated for ${
    provider.name
  } from ${getDayMonthYear(startDate)} to ${getDayMonthYear(endDate)}`;

  return (
    <Document>
      {servedJobs?.map((jobsServed, index) => {
        return (
          <Page key={index} wrap size="A4" style={styles.page}>
            <TopPdfHeader
              companyDetail={companyDetail}
              generatedBy={generatedBy}
              id={id}
              reportFor="Crew"
            />
            <View>
              <Text style={styles.valueText}>{reportTitle}</Text>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                height: 50,
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  margin: 8,
                }}
              >
                <RenderImage
                  height="40px"
                  width="40px"
                  imageUrl={provider?.imageUrl}
                />
                <View style={{ marginLeft: 10 }}>
                  <Text>{provider.name}</Text>
                  <Text style={{ fontSize: 10 }}>{provider.email}</Text>
                </View>
              </View>
            </View>
            <View>
              <View style={{ marginTop: 16 }}>
                {jobsServed?.map((servedJob) => {
                  const { job, routeInfo, logs, issueReports } = servedJob;
                  if (!job) {
                    return null;
                  }

                  if (Object.values(job).length === 0) {
                    return null;
                  }
                  return (
                    <View key={job.key}>
                      <JobHeader job={job} crew={provider} />
                      {issueReports?.length > 0 && (
                        <RenderIssueReports
                          issueReports={issueReports}
                          selectImages={selectImages}
                        />
                      )}

                      {/* {activities?.length ? (
                        <RenderActivities activities={activities} />
                      ) : ( */}
                      <>
                        {logs?.map((log) => {
                          return (
                            <LogData
                              key={log?.key ?? log?.log?.key}
                              selectImages={selectImages}
                              log={log}
                            />
                          );
                        })}
                      </>
                      {/* )} */}
                      {routeInfo?.map((routeData) => {
                        const { route, activeRouteLogs, logData } = routeData;
                        const routelogData = [
                          ...activeRouteLogs,
                          ...logData,
                        ].sort((a, b) => getTimeFromLog(a) - getTimeFromLog(b));

                        return (
                          <Fragment key={route.key}>
                            <View style={styles.header}>
                              <View>
                                <RowData
                                  titleText="Route Name: "
                                  valueText={route.name}
                                />
                                <RowData
                                  titleText="Total Markers: "
                                  valueText={route.servicePointsKeys.length}
                                />
                              </View>
                              <View>
                                <RowData
                                  titleText="Started At: "
                                  valueText={getDayMonthYearTime(
                                    route.startedAt
                                  )}
                                />
                                <RowData
                                  titleText="Ended At: "
                                  valueText={getDayMonthYearTime(
                                    route.completedAt
                                  )}
                                />
                              </View>
                            </View>

                            <View style={{ margin: 4 }}>
                              <Table data={routeData}>
                                <TableHeader></TableHeader>
                                <TableBody data={routelogData}>
                                  <DataTableCell
                                    style={{
                                      padding: 4,
                                      fontSize: 10,
                                    }}
                                    getContent={(log) => {
                                      return log.log
                                        ? log.name || 'Deleted Marker'
                                        : selectTypeOfRoutes(log.type);
                                    }}
                                  />
                                  <DataTableCell
                                    style={{ textAlign: 'center', padding: 4 }}
                                    getContent={(log) =>
                                      log?.log?.startDate
                                        ? getDayMonthYearTime(log.log.startDate)
                                        : getDayMonthYearTime(log.time)
                                    }
                                  />
                                </TableBody>
                              </Table>
                            </View>
                          </Fragment>
                        );
                      })}
                    </View>
                  );
                })}
              </View>
            </View>
          </Page>
        );
      })}
    </Document>
  );
};

export default React.memo(PDFDocumentProvider);
