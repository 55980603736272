import { Card, Collapse, Divider, CardContent } from '@material-ui/core';

import styled from 'styled-components';
import RoutesIcon from 'mdi-react/RoutesIcon';
import ServiceIcon from 'mdi-react/BriefcaseIcon';
import ClockEndIcon from 'mdi-react/ClockEndIcon';
import ClockStartIcon from 'mdi-react/ClockStartIcon';
import WorkerIcon from 'mdi-react/AccountHardHatIcon';
import HomeMapMarkerIcon from 'mdi-react/HomeMapMarkerIcon';
import React, { useEffect, useState, useContext } from 'react';

import { Text, BoldTitle } from '../styled';
import { Col } from '../../../../styles/snowm_styled';
import { getRelativeDate } from '../../../../helpers/date';
import ListTitleItem from '../../../Generics/list_title_item';
import { LocalizationContext } from '../../../../contexts/localization_context';
import {
  getRouteName,
  getServiceInfo,
  getServicePointById,
  getProviderByUid,
} from '../../../../controllers/snowm_firebase';

const MarkerLogs = ({ log, invoice }) => {
  const [routeName, setRouteName] = useState('');
  const [serviceName, setServiceName] = useState('');
  const [marker, setMarker] = useState({});
  const [crewName, setCrewName] = useState('');
  const [expanded, setExpanded] = useState(false);

  const { strings } = useContext(LocalizationContext);

  const { AFTER_IMAGE, BEFORE_IMAGE } = strings?.image;
  const { NOT_AVAILABLE } = strings?.messages;
  const { COMPLETED_AT } = strings?.time;

  useEffect(() => {
    let isSubscribe = true;
    if (log.routeKey) {
      getRouteName(log.routeKey).then((name) => {
        if (isSubscribe) setRouteName(name);
      });
    }
    getServiceInfo(log.serviceKey).then((service) => {
      if (isSubscribe) setServiceName(service.name);
    });

    getServicePointById(log.servicePointId).then((m) => {
      if (isSubscribe) setMarker(m);
    });

    getProviderByUid(log.providerUid).then((crew) => {
      if (isSubscribe) setCrewName(crew.name);
    });

    return () => {
      isSubscribe = false;
    };
  }, [log]);

  const handleLogCardClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card
      style={{
        marginBottom: '1em',
        padding: !invoice ? '1em' : 0,
        width: 'auto',
        boxShadow: invoice && 'none',
        overflow: invoice && 'visible',
      }}
    >
      <CardContent style={{ cursor: 'pointer' }} onClick={handleLogCardClick}>
        <ListTitleItem
          Icon={ServiceIcon}
          title="Service"
          content={serviceName}
        />
        {routeName && (
          <ListTitleItem Icon={RoutesIcon} title="Route" content={routeName} />
        )}
        <ListTitleItem
          Icon={HomeMapMarkerIcon}
          title="Marker"
          content={marker.name}
        />
        <ListTitleItem Icon={WorkerIcon} title="Crew" content={crewName} />

        <ListTitleItem
          Icon={ClockStartIcon}
          title="Start At"
          content={getRelativeDate(log?.startDate)}
        />
        <ListTitleItem
          Icon={ClockEndIcon}
          title={COMPLETED_AT}
          content={log?.endDate ? getRelativeDate(log?.endDate) : '-'}
        />
      </CardContent>
      <Collapse in={expanded && !invoice}>
        <Divider />
        <ColContainer>
          <StyledBoldTitle>{BEFORE_IMAGE}</StyledBoldTitle>
          {log?.beforeImage ? (
            <a
              style={{ display: 'flex', justifyContent: 'center' }}
              href={log.beforeImage}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={log.beforeImage}
                alt="before"
                height="150px"
                width="150px"
              />
            </a>
          ) : (
            <StyledText>{NOT_AVAILABLE}</StyledText>
          )}
        </ColContainer>
        <Divider />
        <ColContainer>
          <StyledBoldTitle>{AFTER_IMAGE}</StyledBoldTitle>
          {log.afterImage ? (
            <a
              style={{ display: 'flex', justifyContent: 'center' }}
              href={log.afterImage}
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                src={log.afterImage}
                alt="after"
                height="150px"
                width="150px"
              />
            </a>
          ) : (
            <StyledText>{NOT_AVAILABLE}</StyledText>
          )}
        </ColContainer>
      </Collapse>
    </Card>
  );
};

export default MarkerLogs;

const StyledText = styled(Text)`
  font-style: italic;
  opacity: 0.5;
  text-align: center;
`;

const ColContainer = styled(Col)`
  margin: 10px 0;
`;

const StyledBoldTitle = styled(BoldTitle)`
  && {
    margin-bottom: 4px;
  }
`;
