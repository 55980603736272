import React, { useEffect, useState } from 'react';

import DotsVerticalIcon from 'mdi-react/DotsVerticalIcon';
import { IconButton, Menu, MenuItem } from '@material-ui/core';

import Span from '../../Common/Span';
import { Progress } from '../../Generics/snowm_loader';

const CrewsActions = ({ crew, loadingWhileAction, handleCrewAction }) => {
  const [actions, setActions] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const updateActions = () => {
    let actionsForCrew = [];

    const { disabled, loggedIn, deviceId } = crew ?? {};

    if (!disabled && loggedIn) {
      actionsForCrew = [...actionsForCrew, 'Disable Account'];
    } else if (loggedIn) {
      actionsForCrew = [...actionsForCrew, 'Enable Account'];
    }

    if (!disabled) {
      actionsForCrew = [...actionsForCrew, 'Reset Password'];
    }

    if (loggedIn && disabled) {
      actionsForCrew = [...actionsForCrew, 'Archive Crew'];
    } else if (!loggedIn || disabled) {
      actionsForCrew = [...actionsForCrew, 'Delete Crew'];
    }

    if (deviceId) {
      actionsForCrew = [...actionsForCrew, 'Reset Logged In Device'];
    }

    const newSetOfActions = [...new Set(actionsForCrew)];
    setActions(newSetOfActions);
  };

  useEffect(() => {
    updateActions();
  }, [crew]);

  function handleIconClick(event) {
    setAnchorEl(event.currentTarget);
  }

  const actionsDots = () => {
    if (!actions.length) {
      return null;
    }

    if (!loadingWhileAction) {
      return (
        <IconButton onClick={handleIconClick}>
          <DotsVerticalIcon />
        </IconButton>
      );
    }

    return <Progress size="15px" />;
  };

  function handleMenuClose() {
    setAnchorEl(null);
  }

  async function handleAction(action) {
    setAnchorEl(null);
    handleCrewAction(crew, action);
  }

  return (
    <>
      {actionsDots()}

      {actions.length > 0 && (
        <Menu onClose={handleMenuClose} open={open} anchorEl={anchorEl}>
          {actions?.map((action) => (
            <MenuItem key={action} onClick={() => handleAction(action)}>
              <Span>{action}</Span>
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
};

export default CrewsActions;
