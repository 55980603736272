import React from 'react';

import styled from 'styled-components';
import { Paper } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import color from '../../utils/color';

const TabsToggle = (props) => {
  const { currentTab, arrayOfLabels, handleChange } = props;

  return (
    <StyledPaper>
      <ToggleButtonGroup value={currentTab} onChange={handleChange} exclusive>
        {arrayOfLabels?.map((label, index) => (
          <StyledToggleButton
            value={index}
            $isSelected={index === currentTab}
            disabled={index === currentTab}
            key={label}
          >
            {label}
          </StyledToggleButton>
        ))}
      </ToggleButtonGroup>
    </StyledPaper>
  );
};

export default TabsToggle;

const StyledToggleButton = styled(ToggleButton)`
  &&&& {
    text-transform: capitalize;
    margin: 10px 0;
    padding: 6px 20px;
    background-color: ${(props) => props.$isSelected && color.snomwBlue};
    color: ${(props) => (props.$isSelected ? color.white : color.snomwBlue)};
  }
`;

const StyledPaper = styled(Paper)`
  text-align: center;
  margin: 16px;
`;
