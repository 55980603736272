import React, { useReducer, useState, useEffect, useMemo } from 'react';

import moment from 'moment';
import styled from 'styled-components';
import MomentUtils from '@date-io/moment';
import {
  Switch,
  Divider,
  Checkbox,
  TextField,
  FormControlLabel,
} from '@material-ui/core';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';

import Span from '../../Common/Span';
import color from '../../../utils/color';
import FormModal from '../../Common/form_modal';
import { Col, Row } from '../../../styles/snowm_styled';
import ADialog, { LowerCaseButton } from '../../Common/styled';
import { addOrEditShift } from '../../../controllers/snowm_firebase';

const AddUpdateShift = ({ open, handleNegativeAction, selectedShift }) => {
  const initialShiftData = {
    id: null,
    name: '',
    startTime: null,
    endTime: null,
    exceptionMinutes: null,
    repetative: false,
    finishJobAtEnd: false,
    raiseException: false,
    companyKey: null,
    active: false,
  };

  const weekDays = useMemo(
    () => ({
      Sun: 'Sunday',
      Mon: 'Monday',
      Tue: 'Tuesday',
      Wed: 'Wednesday',
      Thu: 'Thursday',
      Fri: 'Friday',
      Sat: 'Saturday',
    }),
    []
  );

  const daysOfWeek = Object.keys(weekDays);

  const [selectedWeekDays, setSelectedWeekDays] = useState(daysOfWeek);
  const [isWeekDaysSelectionOpen, setIsWeekDaysSelectionOpen] = useState(false);

  const stateVariables = [
    'name',
    'startTime',
    'endTime',
    'exceptionMinutes',
    'repetative',
    'finishJobAtEnd',
    'raiseException',
  ];

  function reducer(state, action) {
    if (stateVariables.includes(action.type)) {
      return { ...state, [action.type]: action.payload };
    }

    switch (action.type) {
      case 'all':
        return { ...action.payload };
      case 'reset':
        return initialShiftData;
      default:
        throw new Error();
    }
  }

  const [shiftData, dispatch] = useReducer(reducer, initialShiftData);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selectedShift) {
      dispatch({ type: 'all', payload: { ...selectedShift } });
      const daysSelected = new Set(selectedShift.weekDays ?? []);
      const selectedDays = daysOfWeek.reduce((acc, day, index) => {
        if (daysSelected.has(index)) return [...acc, day];
        return acc;
      }, []);

      setSelectedWeekDays(selectedDays);
    } else {
      dispatch({ type: 'reset' });
    }
  }, [selectedShift]);

  function handleDataChange(data, type) {
    if (type === 'raiseException' && !data) {
      dispatch({ type: 'exceptionMinutes', payload: null });
    }
    dispatch({ type, payload: data });
  }

  function getMomentObject(time) {
    if (time) {
      return moment(time, 'HH:mm a');
    }
    return null;
  }

  const DatePicker = () => {
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <PickerTimer
          label="Start Time"
          value={getMomentObject(shiftData.startTime)}
          onChange={(date) => handleDataChange(date, 'startTime')}
          required
          inputVariant="outlined"
          minutesStep={15}
        />
        <PickerTimer
          label="End Time"
          value={getMomentObject(shiftData.endTime)}
          onChange={(date) => handleDataChange(date, 'endTime')}
          inputVariant="outlined"
          required
          minutesStep={15}
        />
      </MuiPickersUtilsProvider>
    );
  };

  function getTime(time) {
    if (time._d) {
      return moment(time).format('HH:mm');
    }
    return time;
  }

  async function addDetails(e) {
    e.preventDefault();
    if (!shiftData.startTime || !shiftData.endTime) {
      alert('Times should be specified.');
      return;
    }

    const daysSelected = new Set(selectedWeekDays);

    const arrayOfSelectedWeekDays = daysOfWeek?.reduce(
      (arrayOfIndex, day, index) => {
        if (daysSelected.has(day)) arrayOfIndex.push(index);
        return arrayOfIndex;
      },
      []
    );

    setLoading(true);

    const startTime = getTime(shiftData.startTime);
    const endTime = getTime(shiftData.endTime);

    try {
      await addOrEditShift({
        ...shiftData,
        startTime,
        endTime,
        weekdays: arrayOfSelectedWeekDays,
      });
      dispatch({ type: 'reset' });
      setSelectedWeekDays(daysOfWeek);
    } catch (error) {
      console.error('@@error', error);
    }
    handleNegativeAction();
    setLoading(false);
  }

  function handleMinuteChange(value, type) {
    const numberValue = parseInt(value, 10);
    handleDataChange(numberValue, type);
  }

  const getErrorMessage = () => {
    if (shiftData.exceptionMinutes > 15) {
      return (
        <Span color={color.red} size="12px">
          Exception duration can be maximum of 15 minutes
        </Span>
      );
    }
    return '';
  };

  const exceptionDuration = () => {
    if (!shiftData.raiseException) return null;

    return (
      <TextField
        variant="outlined"
        required
        label="Exception Duration"
        type="number"
        inputProps={{ max: 15, min: 1 }}
        onChange={(e) => handleMinuteChange(e.target.value, 'exceptionMinutes')}
        value={shiftData.exceptionMinutes}
        error={shiftData.exceptionMinutes > 15}
        helperText={getErrorMessage()}
      />
    );
  };

  const finishJobsAtEnd = () => {
    return (
      <CustomSwitch
        checked={shiftData.finishJobAtEnd}
        onChange={handleDataChange}
        color="primary"
        label="Finish jobs at end"
        type="finishJobAtEnd"
      />
    );
  };

  const handleOnFocus = () => {
    setIsWeekDaysSelectionOpen(true);
  };

  const closeWeekDaysSelect = () => {
    setIsWeekDaysSelectionOpen(false);
  };

  const handleWeekDays = (selWeekDays) => {
    setSelectedWeekDays(selWeekDays);
    setIsWeekDaysSelectionOpen(false);
  };

  return (
    <>
      <FormModal
        title={selectedShift ? 'Edit Shift' : 'Add Shift'}
        open={open}
        handleNegativeAction={handleNegativeAction}
        handlePositiveAction={addDetails}
        loading={loading}
        edit={selectedShift}
      >
        <Col gap="8px">
          <TextField
            variant="outlined"
            required
            label="Name"
            onChange={(e) => handleDataChange(e.target.value, 'name')}
            value={shiftData.name}
          />

          <TextField
            variant="outlined"
            label="Weekdays"
            value={selectedWeekDays?.join(', ')}
            onClick={handleOnFocus}
            onChange={handleOnFocus}
            required
          />

          <DatePicker />

          <CustomSwitch
            checked={shiftData.raiseException}
            onChange={handleDataChange}
            color="primary"
            label="Raise Exception if late"
            type="raiseException"
          />

          {exceptionDuration()}
          {finishJobsAtEnd()}
        </Col>
      </FormModal>
      <ADialog open={isWeekDaysSelectionOpen} width="20%">
        <WeekDays
          daysOfWeek={daysOfWeek}
          weekDays={weekDays}
          handleSelectClose={closeWeekDaysSelect}
          handleSave={handleWeekDays}
          daysThatAreSelected={selectedWeekDays}
        />
      </ADialog>
    </>
  );
};

export default AddUpdateShift;

const WeekDays = ({
  daysOfWeek,
  weekDays,
  handleSelectClose,
  handleSave,
  daysThatAreSelected,
}) => {
  const [selectedWeekDays, setSelectedWeekDays] = useState(daysOfWeek);

  useEffect(() => {
    setSelectedWeekDays(daysThatAreSelected);
  }, [daysThatAreSelected]);

  const handleChange = (event, weekDay) => {
    if (event.target.checked) {
      const setOfWeekDays = new Set([...selectedWeekDays, weekDay]);

      const newlySelectedDays = daysOfWeek.filter((day) =>
        setOfWeekDays.has(day)
      );
      setSelectedWeekDays(newlySelectedDays);
    } else {
      const newSelectedWeekDays = selectedWeekDays.filter(
        (selWeekDay) => selWeekDay !== weekDay
      );
      setSelectedWeekDays(newSelectedWeekDays);
    }
  };

  const handleSaveWeekDays = () => {
    handleSave(selectedWeekDays);
  };

  return (
    <Col justify="center" gap="8px">
      <Row>
        <Span weight="bold" size="24px">
          Select
        </Span>
      </Row>
      <Divider />
      <ListOfCheckBoxes>
        {daysOfWeek.map((dayOfWeek) => (
          <FormControlLabel
            key={dayOfWeek}
            control={
              <Checkbox
                checked={selectedWeekDays.includes(dayOfWeek)}
                onChange={(e) => handleChange(e, dayOfWeek)}
                name={weekDays[dayOfWeek]}
                color="primary"
              />
            }
            label={weekDays[dayOfWeek]}
          />
        ))}
      </ListOfCheckBoxes>
      <Divider />
      <Row justify="flex-end" gap="4px">
        <LowerCaseButton onClick={handleSelectClose}>
          <Span color="primary" weight="bold" size="16px">
            Cancel
          </Span>
        </LowerCaseButton>
        <LowerCaseButton onClick={handleSaveWeekDays}>
          <Span color="primary" weight="bold" size="16px">
            Ok
          </Span>
        </LowerCaseButton>
      </Row>
    </Col>
  );
};

const CustomSwitch = ({ checked, onChange, type, label }) => {
  return (
    <StyledFormControlLabel
      control={
        <Switch
          checked={checked}
          onChange={(e) => onChange(e.target.checked, type)}
          color="primary"
        />
      }
      label={label}
      labelPlacement="start"
    />
  );
};

const PickerTimer = styled(TimePicker)`
  && {
    width: 100%;
  }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  && {
    justify-content: space-between;
    margin: 0 8px;
  }
`;

const ListOfCheckBoxes = styled(Col)`
  margin-left: 16px;
`;
