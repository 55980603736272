import React, { useEffect, useState } from 'react';

import { IconButton, Tooltip } from '@material-ui/core';
import SortBoolAscendingVariantIcon from 'mdi-react/SortBoolAscendingVariantIcon';
import SortBoolDescendingVariantIcon from 'mdi-react/SortBoolDescendingVariantIcon';

function Sort({ array, field = 'name', onChange }) {
  const [sortType, setSortType] = useState('asc');

  const sortIcon = () => {
    if (sortType === 'asc') {
      return <SortBoolAscendingVariantIcon size={30} />;
    }
    return <SortBoolDescendingVariantIcon size={30} />;
  };

  const sortObjects = (a, b) => {
    let first;
    let second;

    if (typeof a !== 'string') {
      first = a[field].toLowerCase();
      second = b[field].toLowerCase();
    } else {
      first = a;
      second = b;
    }

    if (first < second) {
      return sortType === 'asc' ? -1 : 1;
    }
    if (second < first) {
      return sortType === 'asc' ? 1 : -1;
    }
    return 0;
  };

  const sortArrayOfObjectsByName = (arr) => {
    arr.sort(sortObjects);
  };

  const handleChange = () => {
    switch (sortType) {
      case 'asc': {
        setSortType('desc');
        break;
      }
      case 'desc': {
        setSortType('asc');
        break;
      }

      default:
        setSortType(sortType);
    }
  };

  useEffect(() => {
    if (array) {
      let arrayToBeSorted = [];
      if (array[0] && typeof array[0] !== 'string') {
        arrayToBeSorted = [...Object.values(array)];
        sortArrayOfObjectsByName(arrayToBeSorted, sortType);

        const sortedArrayOfObjects = arrayToBeSorted.reduce((acc, marker) => {
          const { key } = marker;
          return { ...acc, [key]: marker };
        }, {});
        onChange(sortedArrayOfObjects);
      } else {
        arrayToBeSorted = [...array];
        sortArrayOfObjectsByName(arrayToBeSorted, sortType);
        onChange(arrayToBeSorted);
      }
    }
  }, [sortType]);

  return (
    <Tooltip title="Sort">
      <IconButton
        onClick={() => {
          handleChange();
        }}
      >
        {sortIcon()}
      </IconButton>
    </Tooltip>
  );
}

export default Sort;
