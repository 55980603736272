import React, { Fragment } from 'react';

import { Document, Page, View, Text } from '@react-pdf/renderer';

import {
  Table,
  TableHeader,
  TableCell,
  TableBody,
  DataTableCell,
} from '@david.kucsai/react-pdf-table';

import { getTimeFromLog } from './getTimeFromLog';
import { selectTypeOfRoutes } from '../Private/reports/selectType';
import { getDayMonthYear, getDayMonthYearTime } from '../../helpers/date';
import {
  JobHeader,
  LogData,
  styles,
  RowData,
  TopPdfHeader,
  RenderIssueReports,
  RenderImage,
} from './pdf_utils';

const PDFDocumentGenerator = ({ pdfData }) => {
  const {
    id,
    servedJobs,
    companyDetail = {},
    generatedBy,
    markers,
    startDate,
    endDate,
    property,
    selectImages,
  } = pdfData;
  let name;
  if (property) {
    name = `Property: ${property?.name}`;
  } else {
    const markerNames =
      markers?.map((marker) => marker?.name).join(', ') ?? 'Marker';
    name = `Markers: ${markerNames}`;
  }
  const reportTitle = `Report generated for ${name} from ${getDayMonthYear(
    startDate
  )} to ${getDayMonthYear(endDate)}`;

  return (
    <Document>
      {servedJobs?.map((jobsServed, index) => {
        return (
          <Page key={index} wrap size="A4" style={styles.page}>
            <TopPdfHeader
              companyDetail={companyDetail}
              generatedBy={generatedBy}
              id={id}
              reportFor={property ? 'Property' : 'Markers'}
            />
            <View>
              {selectImages && property?.imageUrl && (
                <View
                  style={{
                    margin: '8px 6px',
                  }}
                >
                  <RenderImage imageUrl={property.imageUrl} />
                </View>
              )}
              <Text style={styles.valueText}>{reportTitle}</Text>
            </View>
            <View>
              {jobsServed?.map((servedJob) => {
                const { job, crew, logs, routeInfo, issueReports } = servedJob;

                return (
                  <Fragment key={job?.key}>
                    <View>
                      <JobHeader job={job} crew={crew} />
                    </View>

                    {issueReports?.length > 0 && (
                      <RenderIssueReports
                        issueReports={issueReports}
                        selectImages={selectImages}
                      />
                    )}

                    {logs?.map((log) => {
                      return (
                        <LogData
                          selectImages={selectImages}
                          key={log?.key}
                          log={log}
                        />
                      );
                    })}

                    {routeInfo?.map((routeData) => {
                      const { route, logData, activeRoutesLogs } = routeData;
                      const markerLogs = [...logData, ...activeRoutesLogs].sort(
                        (a, b) => getTimeFromLog(a) - getTimeFromLog(b)
                      );

                      return (
                        <Fragment key={route.key}>
                          <View
                            style={{
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              margin: 16,
                            }}
                          >
                            <View>
                              <RowData
                                titleText="Route Name: "
                                valueText={route.name}
                              />
                              <RowData
                                titleText="Total Markers: "
                                valueText={
                                  routeData.route.servicePointsKeys.length
                                }
                              />
                            </View>
                            <View>
                              <RowData
                                titleText="Started At: "
                                valueText={getDayMonthYearTime(
                                  routeData.route?.startedAt
                                )}
                              />
                              <RowData
                                titleText="Ended At: "
                                valueText={getDayMonthYearTime(
                                  routeData.route?.completedAt
                                )}
                              />
                            </View>
                          </View>
                          <Table>
                            <TableHeader>
                              <TableCell
                                styles={{ textAlign: 'center', paddingTop: 4 }}
                                weighting={0.2}
                              >
                                Marker
                              </TableCell>
                              <TableCell
                                styles={{ textAlign: 'center' }}
                                weighting={0.3}
                              >
                                Started At
                              </TableCell>
                              <TableCell
                                styles={{ textAlign: 'center' }}
                                weighting={0.3}
                              >
                                Ended At
                              </TableCell>
                              <TableCell
                                styles={{ textAlign: 'center' }}
                                weighting={0.3}
                              >
                                Detected At
                              </TableCell>
                            </TableHeader>

                            <TableBody data={markerLogs}>
                              <DataTableCell
                                styles={{
                                  textAlign: 'center',
                                  paddingVertical: 4,
                                  fontSize: 10,
                                }}
                                weighting={0.2}
                                getContent={(log) => {
                                  if (log.type)
                                    return selectTypeOfRoutes(log.type);
                                  return log.name || 'Deleted Marker';
                                }}
                              />
                              <DataTableCell
                                styles={{ textAlign: 'center' }}
                                weighting={0.3}
                                getContent={(log) => {
                                  if (log?.log?.startDate)
                                    return getDayMonthYearTime(
                                      log.log.startDate
                                    );
                                  return getDayMonthYearTime(log.time);
                                }}
                              />
                              <DataTableCell
                                styles={{ textAlign: 'center' }}
                                weighting={0.3}
                                getContent={(log) => {
                                  return getDayMonthYearTime(log.lastServed);
                                }}
                              />
                              <DataTableCell
                                styles={{ textAlign: 'center' }}
                                weighting={0.3}
                                getContent={(log) => {
                                  return getDayMonthYearTime(log.detectionTime);
                                }}
                              />
                            </TableBody>
                          </Table>
                        </Fragment>
                      );
                    })}
                  </Fragment>
                );
              })}
            </View>
          </Page>
        );
      })}
    </Document>
  );
};

export default React.memo(PDFDocumentGenerator);
