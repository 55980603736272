import * as React from 'react';
import { Button, Divider } from '@material-ui/core';

import Span from '../../Common/Span';
import { CustomSelect } from './custom_select';
import { Row } from '../../../styles/snowm_styled';
import PrimaryButton from '../../Common/primary_button';

export const defaultPeriodicTimeSchedule = {
  hours: null,
  minutes: null,
  weekDays: null,
  hoursData: null,
  minutesData: null,
  weekDaysData: null,
};

// eslint-disable-next-line import/prefer-default-export
export const PeriodicJobTimeSelection = ({
  hoursData,
  minutesData,
  periodicSchedule,
  weekDaysData,
  handleSelection,
  handleCancel,
}) => {
  const [periodicData, setPeriodicData] = React.useState(
    defaultPeriodicTimeSchedule
  );

  React.useEffect(() => {
    setPeriodicData(periodicSchedule);
  }, [periodicSchedule]);

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleSelection(periodicData);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Row justify="center">
        <Span weight="bold" size="24px">
          Schedule
        </Span>
      </Row>
      <div style={{ marginTop: '1em' }}>
        <CustomSelect
          defaultValue={periodicData.jobhoursData}
          value={
            periodicData.jobhoursData?.length ? periodicData.jobhoursData : null
          }
          placeholder="Hours"
          isMulti
          name="hours"
          options={hoursData}
          onChange={(hours) => {
            const data = hours && hours.map((hour) => hour.value);
            setPeriodicData({
              ...periodicData,
              hours: data,
              jobhoursData: hours,
            });
          }}
          required={!periodicData?.hours?.length}
        />
      </div>
      <div style={{ marginTop: 12, marginBottom: 10 }}>
        <CustomSelect
          defaultValue={periodicData.jobminutesData ?? []}
          value={
            periodicData.jobminutesData?.length
              ? periodicData.jobminutesData
              : null
          }
          placeholder="Minutes"
          isMulti
          name="minutes"
          options={minutesData}
          onChange={(minutes) => {
            const data = minutes && minutes.map((minute) => minute.value);
            setPeriodicData({
              ...periodicData,
              minutes: data,
              jobminutesData: minutes,
            });
          }}
          required={!periodicData?.minutes?.length}
        />
      </div>

      <div style={{ marginBottom: '1em' }}>
        <CustomSelect
          defaultValue={periodicData.jobWeekDaysData}
          value={
            periodicData.jobWeekDaysData?.length
              ? periodicData.jobWeekDaysData
              : null
          }
          placeholder="Week days"
          isMulti
          name="weekDays"
          options={weekDaysData}
          onChange={(weekDays) => {
            const data = weekDays && weekDays.map((weekDay) => weekDay.value);
            setPeriodicData({
              ...periodicData,
              weekDays: data,
              jobWeekDaysData: weekDays,
            });
          }}
        />
      </div>

      <Divider />

      <Row justify="flex-end">
        <Button style={{ textTransform: 'none' }} onClick={handleCancel}>
          Cancel
        </Button>
        <PrimaryButton type="submit">Done</PrimaryButton>
      </Row>
    </form>
  );
};
