import React from 'react';

import QRCode from 'qrcode.react';
import styled from 'styled-components';
import CloseIcon from 'mdi-react/CloseIcon';
import CalendarIcon from 'mdi-react/CalendarIcon';
import { Button, Divider, IconButton } from '@material-ui/core';
import HomeMapMarkerIcon from 'mdi-react/HomeMapMarkerIcon';
import HomeCityOutlineIcon from 'mdi-react/HomeCityOutlineIcon';
import OrderBoolAscendingVariantIcon from 'mdi-react/OrderBoolAscendingVariantIcon';

import { getRelativeDate } from '../../../helpers/date';
import ListTitleItem from '../../Generics/list_title_item';
import { Title, Row, Col } from '../../../styles/snowm_styled';

const MarkerBasicDetail = ({ marker, property, inModal, handleCloseIcon }) => {
  function getDate(date) {
    return date ? getRelativeDate(date) : 'Never';
  }

  function renderProperty() {
    if (property) {
      return (
        <>
          <ListTitleItem
            Icon={HomeCityOutlineIcon}
            content={property?.name}
            title="Property"
          />

          <ListTitleItem
            Icon={HomeCityOutlineIcon}
            content={property?.address ?? marker?.address ?? '-'}
            title="Address"
          />
        </>
      );
    }
    return null;
  }

  function renderActivity() {
    if (marker?.activityType) {
      return (
        <ListTitleItem
          Icon={OrderBoolAscendingVariantIcon}
          content={marker?.activityType}
          title="Activity Type"
        />
      );
    }
    return null;
  }

  return (
    <StyledCol justify="space-between" align="center">
      <StyledRow
        justify={marker?.beaconId && !inModal ? 'center' : 'space-between'}
      >
        <Title margintop="0" variant="h6">
          {marker?.name}
        </Title>
        {inModal && (
          <IconButton onClick={handleCloseIcon} aria-label="close">
            <CloseIcon />
          </IconButton>
        )}
      </StyledRow>
      <StyledDivider />

      {renderActivity()}
      {renderProperty()}

      <ListTitleItem
        Icon={CalendarIcon}
        content={getDate(marker?.lastServed)}
        title="Last Served"
      />

      {marker?.deviceType === 'QR' && (
        <QRCode
          style={{ padding: 16 }}
          value={marker?.beaconId}
          size={300}
          fgColor="#29abe2"
        />
      )}
      {marker?.serviceType === 'outdoor' && (
        <ListTitleItem
          Icon={HomeMapMarkerIcon}
          content={marker?.address}
          title="Address"
        />
      )}
    </StyledCol>
  );
};

const StyledCol = styled(Col)`
  cursor: pointer;
  flex: 1;
  width: 100%;
`;

const StyledDivider = styled(Divider)`
  width: 100%;
`;

const StyledRow = styled(Row)`
  margin-top: 8px;
`;

export default MarkerBasicDetail;
