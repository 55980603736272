/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';

import { isEmpty } from 'lodash';
import styled from 'styled-components';
import GoogleMapReact from 'google-map-react';
import CloseIcon from '@material-ui/icons/Close';
import { Card, Button, IconButton } from '@material-ui/core';
import { Combobox, ComboboxInput, ComboboxOption } from '@reach/combobox';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete';

import '@reach/combobox/styles.css';

import Modal from './snowm_modal';
import { Col, Row } from '../../styles/snowm_styled';
import MapMarker from '../Private/Maps/snowm_marker';
import { getAddressFromLongLat } from '../../controllers/map_helper';

// eslint-disable-next-line import/prefer-default-export
export const LocationModal = (props) => {
  const [zoom, setZoom] = useState(4);

  const [mapState, setMapState] = useState({
    mapApi: null,
    mapInstance: null,
    mapApiLoaded: false,
  });

  const [latLng, setLatLng] = useState(null);
  const [address, setAddress] = useState('Unnamed Place');
  const [draggable, setDraggable] = useState(true);

  useEffect(() => {
    if (latLng) {
      setZoom(15);
    }
  }, [latLng]);

  const handleApiLoaded = ({ map, maps }) => {
    setMapState({
      mapApiLoaded: true,
      mapApi: maps,
      mapInstance: map,
    });
  };

  const onMouseDown = () => {
    setDraggable(false);
  };

  const onMouseMove = (childKey, childProps, mouse) => {
    setLatLng(mouse);
    getAddressFromLongLat(mouse.lat, mouse.lng).then((locAddress) => {
      setAddress(locAddress);
    });
  };

  const onMouseUp = () => {
    setDraggable(true);
  };

  const onMapClick = ({ lat, lng }) => {
    getAddressFromLongLat(lat, lng).then((locAddress) => {
      setAddress(locAddress);
    });
    setLatLng({ lat, lng });
  };

  const renderMarker = () => {
    if (!latLng) {
      return null;
    }
    return <MapMarker lat={latLng.lat} lng={latLng.lng} />;
  };

  const handleLocationClose = () => {
    props.locationModal.close();
  };

  const onAddressChange = (addressFromDropDown, latLngFromDropDown) => {
    setAddress(addressFromDropDown);
    setLatLng(latLngFromDropDown);
    const { lat, lng } = latLngFromDropDown;
    mapState.mapInstance.setCenter({
      lat,
      lng,
    });
  };

  return (
    <Modal {...props.locationModal.props}>
      <ModalCard>
        <Col align="center" justify="center" style={{ padding: 16 }}>
          <Row justify="space-between">
            <h4>Add Location</h4>
            <IconButton onClick={handleLocationClose}>
              <CloseIcon />
            </IconButton>
          </Row>

          <MapContainer>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: process.env.REACT_APP_GOOGLE_MAPS_KEY,
                libraries: ['places'],
              }}
              defaultCenter={[]}
              center={latLng ?? { lat: 56.2, lng: -106.3 }}
              defaultZoom={4}
              draggable={draggable}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={handleApiLoaded}
              onChildMouseDown={onMouseDown}
              onChildMouseMove={onMouseMove}
              onChildMouseUp={onMouseUp}
              onClick={onMapClick}
              zoom={zoom}
            >
              {renderMarker()}
            </GoogleMapReact>

            {mapState?.mapApiLoaded && (
              <Search onAddressChange={onAddressChange} />
            )}

            {latLng && (
              <StyledButton
                variant="contained"
                color="primary"
                onClick={() => {
                  props.onLocationChange(latLng, address);
                }}
              >
                Pick
              </StyledButton>
            )}
          </MapContainer>
        </Col>
      </ModalCard>
    </Modal>
  );
};

const Search = ({ onAddressChange }) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({ debounce: 300 });

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSelect = async (val) => {
    setValue(val, false);
    clearSuggestions();

    const result = await getGeocode({ address: val });

    try {
      const latLngRes = await getLatLng(result[0]);

      onAddressChange(val, latLngRes);
    } catch (error) {
      console.error({ error });
    }
  };

  return (
    <StyledPaper>
      <Combobox onSelect={handleSelect} aria-labelledby="demo">
        <ComboboxInput
          value={value}
          onChange={handleInput}
          disabled={!ready}
          placeholder="Enter an address"
          style={{ width: 350, position: 'relative', height: 24 }}
        />

        <div
          style={{
            display: 'flex',
            position: 'absolute',
            flexDirection: 'column',
            backgroundColor: 'white',
            borderRadius: 12,
            width: 350,
          }}
        >
          {status === 'OK' &&
            data.map(({ place_id: placeId, description }) => {
              return (
                <ComboboxOption
                  key={placeId}
                  value={description}
                  style={{ listStyle: 'none', fontSize: 12 }}
                />
              );
            })}
        </div>
      </Combobox>
    </StyledPaper>
  );
};

const MapContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  height: calc(50vh);
  width: 100%;
`;

const StyledPaper = styled.div`
  && {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 16px;
    margin: auto;
    padding: 4px;
    display: flex;
    align-items: center;
    width: 400px;
    z-index: 10;
  }
`;

const StyledButton = styled(Button)`
  && {
    position: absolute;
    bottom: 16px;
    left: 0px;
    right: 0px;
    margin: auto;
    width: 150px;
  }
`;
const ModalCard = styled(Card)`
  &&&& {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: scroll;
    min-height: 50%;
    min-width: 50%;
    max-height: 90%;
  }
`;
