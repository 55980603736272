import React, { useEffect, useState } from 'react';

import Span from '../../Common/Span';
import { DetailsRow } from '../styled';
import { Row } from '../../../styles/snowm_styled';
import { getFormattedDate } from '../../../helpers/date';
import ListTitleItem from '../../Generics/list_title_item';
import { getFirstCapitilizedWord } from '../../../helpers/misc';
import {
  getProviderByUid,
  getServiceInfo,
} from '../../../controllers/snowm_firebase';

const PreviousJobs = ({ job }) => {
  const [service, setService] = useState({});
  const [providers, setProviders] = useState();

  async function getAndSetCrew() {
    const promise = job?.providerUids?.map((crewUid) =>
      getProviderByUid(crewUid)
    );

    const response = await Promise.all(promise ?? []);

    setProviders(response ?? []);
  }

  useEffect(() => {
    getServiceInfo(job.serviceKey).then((response) => {
      setService(response || {});
    });

    getAndSetCrew();
  }, [job]);

  return (
    <>
      <Row justify="space-between" align="flex-start" style={{ marginTop: 15 }}>
        <div style={{ marginTop: 10, width: '100%' }}>
          <Row>
            <Span weight="bold" size="24px">
              {job?.name}
            </Span>
          </Row>
          <ListTitleItem
            title="Crew"
            content={
              providers?.map((prov) => prov.name).join() || 'Crew Deleted'
            }
          />
          <ListTitleItem
            title="Created At"
            content={
              job?.createdDate ? getFormattedDate(job.createdDate) : '--'
            }
          />

          <ListTitleItem title="Service" content={service?.name ?? 'Deleted'} />

          <DetailsRow justify="space-between">
            {job?.serviceType === 'markerbased' ? (
              <ListTitleItem
                title="Markers"
                content={job?.allMarkers?.length ?? 0}
              />
            ) : (
              <ListTitleItem
                title="Routes"
                content={job?.allRoutes?.length ?? 0}
              />
            )}
          </DetailsRow>
          <ListTitleItem
            title="Status"
            content={getFirstCapitilizedWord(job.status)}
          />
        </div>
      </Row>
    </>
  );
};

export default PreviousJobs;
