export function lowerCase(string) {
  return string.toLowerCase();
}
export function upperCase(string) {
  return string.toUpperCase();
}

export const trimTheString = (word) => {
  return word.trim();
};

export const changeStringToNumber = (string) => {
  return Number(string) || 0;
};
