/* eslint-disable import/prefer-default-export */

import React, { useEffect, useRef, useState } from 'react';

import styled from 'styled-components';
import { useParams } from 'react-router';
import { Card, Divider } from '@material-ui/core';

import Span from '../../Common/Span';
import { Col, Row } from '../../../styles/snowm_styled';
import { getJobsLogs } from '../../../controllers/snowm_firebase';

export const JobLogs = () => {
  const { jobKey } = useParams();

  const logsRef = useRef([]);

  const [jobsLogs, setJobsLogs] = useState([]);

  useEffect(() => {
    const updateJobsLogs = (res) => {
      logsRef.current.splice(0, 0, res);
    };

    return getJobsLogs(jobKey, updateJobsLogs);
  }, [jobKey]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setJobsLogs(() => {
        return [...logsRef.current];
      });
    }, 2000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Container style={{ margin: 16 }}>
      <Col>
        <Span size="24px">Job Logs</Span>
        <Divider />
        {jobsLogs?.map((log, index) => (
          <>
            <Row gap="12px">
              <Span key={`${log} ${index}`}>{index + 1}.</Span>
              <Span weight="bold">{log}</Span>
            </Row>
            {jobsLogs[index + 1] && <Divider />}
          </>
        ))}
      </Col>
    </Container>
  );
};

const Container = styled(Card)`
  margin: 16px;
  padding: 16px;
`;
