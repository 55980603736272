/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: job_item.jsx
 *
 *  Description: UI for each job item
 *
 *  Author: Roshan Gautam (roshan@brainants.com)
 *
 *  Date created: 12-july-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */

import React, { useEffect, useState } from 'react';

import cronstrue from 'cronstrue';
import styled from 'styled-components';
import { Tooltip } from '@material-ui/core';

import Span from '../../Common/Span';
import Card from '../../Common/custom_card';
import { getDateForJob } from '../../../helpers/date';
import { Row, Title } from '../../../styles/snowm_styled';
import ListTitleItem from '../../Generics/list_title_item';
import { getFirstCapitilizedWord } from '../../../helpers/misc';
import { getColorForStatus, getJobStatus } from '../../../enums/jobStatus';
import {
  getProviderByUid,
  getServiceInfo,
  getServicePointById,
  getShiftById,
} from '../../../controllers/snowm_firebase';

const JobItem = (props) => {
  const {
    job,
    isPeriodic,
    onEditIconPress,
    onDeleteIconPress,
    onPress,
    services,
    onCheckIconPress,
    lgGrow = 3,
    mdGrow = 4,
    smGrow = 12,
    boxShadow = 10,
  } = props;

  const [shift, setShift] = useState();
  const [crews, setCrews] = useState([]);
  const [markers, setMarkers] = useState();
  const [service, setService] = useState({});
  const [jobDetail, setJobDetail] = useState();

  const getCrews = async (crewIds) => {
    const crewPromise = crewIds?.map((uid) => {
      const response = getProviderByUid(uid);
      return response;
    });

    const result = await Promise.all(crewPromise ?? []);

    return result;
  };

  useEffect(() => {
    let isCancel = false;

    if ((jobDetail?.shift || jobDetail?.shiftKey) && !isPeriodic) {
      if (jobDetail?.shift) {
        setShift(jobDetail?.shift);
      } else {
        getShiftById(jobDetail?.shiftKey).then((res) => {
          if (!isCancel) {
            setShift(res);
          }
        });
      }
    }
    return () => {
      isCancel = true;
    };
  }, [jobDetail]);

  function renderService() {
    if (services?.length > 1) {
      return (
        <ListTitleItem title="Service" content={service.name || 'Deleted'} />
      );
    }
    return null;
  }

  useEffect(() => {
    if (isPeriodic) {
      setJobDetail({ ...job?.job, serviceType: job?.serviceType });
    } else {
      setJobDetail({ ...job });
    }
  }, [job]);

  useEffect(() => {
    let isSubscribe = true;
    if (jobDetail?.serviceKey) {
      getServiceInfo(jobDetail?.serviceKey).then((response) => {
        if (isSubscribe) setService(response);
      });
    }

    getCrews(jobDetail?.providerUids).then((crewsDetails) => {
      if (isSubscribe) setCrews(crewsDetails);
    });
    return () => {
      isSubscribe = false;
    };
  }, [jobDetail]);

  async function fetchMarkers() {
    const promises = jobDetail?.allMarkers?.map((markerKey) => {
      const promise = getServicePointById(markerKey);
      return promise;
    });

    const markersDetails = await Promise.all(promises ?? []);
    setMarkers(markersDetails);
  }

  function isMarkerBased() {
    return (
      jobDetail?.serviceType === 'markerbased' ||
      jobDetail?.allMarkers?.length > 0
    );
  }

  useEffect(() => {
    if (isPeriodic) {
      fetchMarkers();
    }
  }, [jobDetail]);

  function getRoutesOrMarkersContent() {
    if (isMarkerBased() && isPeriodic) {
      return markers?.map((marker) => marker?.name ?? 'Deleted').join(',');
    }
    if (isMarkerBased()) {
      return jobDetail?.allMarkers?.length ?? 0;
    }
    return job?.allRoutes?.length ?? 0;
  }

  function getTitle() {
    if (isMarkerBased()) return 'Markers';
    return 'Routes';
  }

  function renderMarkersOrRoutes() {
    return (
      <ListTitleItem title={getTitle()} content={getRoutesOrMarkersContent()} />
    );
  }

  function renderServedMarkersOrRoutes() {
    if (!job?.servedMarkers?.length) {
      return null;
    }
    return (
      <ListTitleItem
        title="Served Markers"
        content={job?.servedMarkers?.length}
      />
    );
  }

  const NoTouch = () => {
    if (job?.noTouch) return <ListTitleItem title="No Touch" content="Yes" />;
    return null;
  };

  const MonitorTemperature = () => {
    if (!job?.temperatureMonitoring) return null;

    return <ListTitleItem title="Monitor Temperature" content="Yes" />;
  };

  function renderExpectedServiceTime() {
    if (job?.expectedServiceTime != null && job?.expectedServiceTime) {
      return (
        <ListTitleItem
          title="Expected Service Time"
          content={job?.expectedServiceTime}
        />
      );
    }
    return null;
  }

  function renderCompletedBy() {
    if (job?.status !== 'completed') {
      return null;
    }

    const jobCloser = job?.closedBy ?? 'crew';

    return (
      <ListTitleItem
        title="Completed By"
        content={getFirstCapitilizedWord(jobCloser)}
      />
    );
  }

  return (
    <Card
      small={smGrow}
      medium={mdGrow}
      large={lgGrow}
      spacing={7}
      onPress={onPress}
      onEditIconPress={job?.status !== 'completed' ? onEditIconPress : null}
      onDeletePress={onDeleteIconPress}
      status={!isPeriodic ? job?.status : ''}
      onCheckIconPress={onCheckIconPress}
      minHeight="95%"
      boxShadow={boxShadow}
    >
      <Tooltip title={job?.name}>
        <Title $width="100%">{job?.name}</Title>
      </Tooltip>

      {!isPeriodic && (
        <Row justify="center" marginbottom="0" gap="4px">
          <Span>Status:</Span>
          <Span color={getColorForStatus(job)} weight="bold" size="16px">
            {getJobStatus(job, shift)}
          </Span>
        </Row>
      )}

      <DetailsContainer>
        {job?.futureAssignmentData && (
          <ListTitleItem
            title="Scheduled At"
            content={getDateForJob(job?.futureAssignmentData?.date)}
          />
        )}

        <ListTitleItem
          title="Created At"
          content={getDateForJob(job.createdDate)}
        />

        {isPeriodic && job.cronExp && (
          <ListTitleItem
            title="Scheduled For: "
            content={cronstrue.toString(job?.cronExp)}
          />
        )}

        {job?.status !== 'assigned' && !isPeriodic && (
          <ListTitleItem
            title="Started At"
            content={job?.startedDate ? getDateForJob(job?.startedDate) : '-'}
          />
        )}

        {job?.status === 'completed' && job?.endDate && (
          <ListTitleItem
            title="Ended At"
            content={getDateForJob(job?.endDate)}
          />
        )}
        <ListTitleItem
          title="Crew"
          content={crews.map((prov) => prov.name).join() || 'Deleted'}
        />
        {renderExpectedServiceTime()}
        {renderService()}

        <NoTouch />

        <MonitorTemperature />

        {renderMarkersOrRoutes()}
        {renderServedMarkersOrRoutes()}
        {renderCompletedBy()}
      </DetailsContainer>
    </Card>
  );
};

export default JobItem;

const DetailsContainer = styled.div`
  width: 100%;
`;
