import React, { useEffect, useState, Fragment, useContext } from 'react';

import moment from 'moment';
import Select from 'react-select';
import styled from 'styled-components';
import MomentUtils from '@date-io/moment';
import CloseIcon from 'mdi-react/CloseIcon';
import { DateRangePicker } from 'react-dates';
import { PDFViewer } from '@react-pdf/renderer';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import {
  Button,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';

import PdfReport from './PdfReport';
import Span from '../../Common/Span';
import JobItem from '../Jobs/job_item';
import { Grid } from '../../Common/Grid';
import color from '../../../utils/color';
import IssueLink from '../Profile/IssueLink';
import Loader from '../../Generics/snowm_loader';
import { CustomSelect } from '../Jobs/custom_select';
import PrimaryButton from '../../Common/primary_button';
import { getJobDate } from './crewJobs/JobsTableOfACrew';
import JobAuditButtons from './jobHistory/JobAuditButtons';
import { StyledTableCell, UnderlinedText } from './styled';
import { AuthContext } from '../../../contexts/auth_context';
import { Col, Row, Title } from '../../../styles/snowm_styled';
import JobHistoryDetails from './jobHistory/JobHistoryDetails';
import SelectedCrewDetails from './crewJobs/SelectedCrewDetails';
import ADialog, { Align, StyledTableRow } from '../../Common/styled';
import { downloadExcel, getBgColorOfTableRow } from '../../../helpers/misc';
import statusesOfJob, { getColorForStatus } from '../../../enums/jobStatus';

import {
  getDayMonthYear,
  getHoursFromMill,
  getDateForJob,
} from '../../../helpers/date';
import {
  getTimeSheet,
  storeAuditHistory,
  updateSomeJobDetail,
  getProvidersOfCompany,
  getCompanyDetail,
  getPropertiesOfCompany,
} from '../../../controllers/snowm_firebase';

const weeklyOption = { label: 'Weekly', value: 'weekly' };

const dateRangeOptions = [
  weeklyOption,
  {
    label: 'Bi-weekly',
    value: 'biWeekly',
  },
  { label: 'Custom', value: 'custom' },
];

const todayStartAndEndDate = {
  startDate: moment().startOf('day'),
  endDate: moment().endOf('day'),
};

const statuses = [
  { label: 'Completed', value: 'completed' },
  { label: 'Not completed', value: 'not_completed' },
];

const Attendance = () => {
  const authContext = useContext(AuthContext);

  const [jobs, setJobs] = useState(null);
  const [open, setOpen] = useState(false);
  const [crews, setCrews] = useState(null);
  const [errMsg, setErrMsg] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isMulti, setIsMulti] = useState(false);
  const [auditingDate, setAuditingDate] = useState();
  const [pdfCrewLog, setPdfCrewLog] = useState(null);
  const [pdfDetails, setPdfDetails] = useState(null);
  const [properties, setProperties] = useState(null);
  const [startedDate, setStartedDate] = useState(null);
  const [auditableCrew, setAuditableCrew] = useState();
  const [selectedJob, setSelectedJob] = useState(null);
  const [openEditJob, setOpenEditJob] = useState(false);
  const [focusedInput, setFocusedInput] = useState(null);
  const [historyOfJob, setHistoryOfJob] = useState(null);
  const [jobStatus, setJobStatus] = useState(statuses[0]);
  const [isJobEditing, setIsJobEditing] = useState(false);
  const [showJobAudit, setShowJobAudit] = useState(false);
  const [selectedCrews, setSelectedCrews] = useState(null);
  const [auditableJobs, setAuditableJobs] = useState(null);
  const [isGettingJobs, setIsGettingJobs] = useState(false);
  const [isEndDateLess, setIsEndDateLess] = useState(false);
  const [isCrewSelected, setIsCrewSelected] = useState(false);
  const [showPdfPreview, setShowPdfPreview] = useState(false);
  const [isMultiProperty, setIsMultiProperty] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [selectedJobStatus, setSelectedJobStatus] = useState(null);
  const [selectedDuration, setSelectedDuration] = useState(weeklyOption);
  const [openHistoryDetailOfJob, setOpenHistoryDetailJob] = useState(false);

  const [date, setDate] = useState(todayStartAndEndDate);

  const exportPdfDetails = async () => {
    const { authenticated } = authContext;
    const currentLoggedUser = authenticated?.userClaims;
    const { name, company } = currentLoggedUser;

    const companyDetails = await getCompanyDetail(company);
    const companyName = companyDetails?.name;
    setPdfDetails({ name, companyName });
  };

  useEffect(() => {
    exportPdfDetails();
    if (jobs) {
      exportCrewsData();
    }
  }, [jobs, selectedCrews, date]);

  useEffect(() => {
    let isCancel = false;
    getProvidersOfCompany().then((res) => {
      const selectableCrews = res?.map((crew) => {
        return { label: crew.name, value: crew.masterUid ?? crew.uid };
      });

      if (!isCancel) {
        setCrews([{ label: 'All', value: selectedCrews }, ...selectableCrews]);
      }
    });

    return () => {
      isCancel = true;
    };
  }, []);

  useEffect(() => {
    let isCancel = false;
    getPropertiesOfCompany().then((res) => {
      const selectableProperties = res?.map((property) => {
        return { label: property.name, value: property.id };
      });

      if (!isCancel) {
        setProperties([
          { label: 'All', value: selectableProperties },
          ...selectableProperties,
        ]);
      }
    });

    return () => {
      isCancel = true;
    };
  }, []);

  useEffect(() => {
    switch (selectedDuration?.value) {
      case 'weekly':
        {
          const endTimeOfEndDatte = moment().endOf('day');
          const startDayOfStartDate = moment()
            .subtract('1', 'week')
            .endOf('day')
            .add(1, 's');

          setDate({
            startDate: startDayOfStartDate,
            endDate: endTimeOfEndDatte,
          });
        }
        break;

      case 'biWeekly':
        {
          const endTimeOfEndDatte = moment().endOf('day');
          const startDayOfStartDate = moment()
            .subtract('2', 'weeks')
            .endOf('day')
            .add(1, 's');

          setDate({
            startDate: startDayOfStartDate,
            endDate: endTimeOfEndDatte,
          });
        }
        break;

      default:
        setDate({ ...todayStartAndEndDate });
        break;
    }
  }, [selectedDuration]);

  const openPdfPreview = () => {
    setShowPdfPreview(true);
  };

  const closePdfPreview = () => {
    setShowPdfPreview(false);
  };

  const filterJobsByCrewDateAndStatus = (job, crew, status) => {
    const { value: crewUid } = crew;
    const setOfProviderUids = new Set(job.providerUids ?? []);
    if (!status) {
      return (
        setOfProviderUids.has(crewUid) &&
        getDayMonthYear(getJobDate(job)) === getDayMonthYear(auditingDate)
      );
    }

    return (
      setOfProviderUids.has(crewUid) &&
      getDayMonthYear(getJobDate(job)) === getDayMonthYear(auditingDate) &&
      job.status === status
    );
  };

  useEffect(() => {
    if (auditableCrew && auditingDate) {
      const filteredJobs = jobs?.response?.filter((j) =>
        filterJobsByCrewDateAndStatus(j, auditableCrew, selectedJobStatus)
      );

      setAuditableJobs(filteredJobs);
    }
  }, [auditableCrew, auditingDate, selectedJobStatus, jobs]);

  useEffect(() => {
    if (!isMulti) {
      setSelectedCrews({ label: 'All', value: crews });
    }
  }, [crews, isMulti]);

  useEffect(() => {
    if (!isMultiProperty) {
      setSelectedProperty({ label: 'All', value: properties });
    }
  }, [isMultiProperty]);

  const handleFocusChange = (focus) => {
    setFocusedInput(focus);
  };

  const handleDateChange = (dateRange) => {
    let endTimeOfEndDatte = moment().endOf('day');
    const startDayOfStartDate = moment(dateRange.startDate).startOf('day');
    if (dateRange.endDate) {
      endTimeOfEndDatte = moment(dateRange.endDate).endOf('day');
    }

    setDate({ startDate: startDayOfStartDate, endDate: endTimeOfEndDatte });
  };

  const selectAWeekDate = () => {
    const endTimeOfEndDatte = moment();
    const startDayOfStartDate = moment().subtract('1', 'week').startOf('day');

    setDate({ startDate: startDayOfStartDate, endDate: endTimeOfEndDatte });
  };

  const handleCrewChange = (changedCrew) => {
    let isAllSelected = false;

    if (!changedCrew) {
      setIsMulti(false);
      setSelectedCrews({ label: 'All', value: crews });
      return;
    }

    if (isMulti) {
      isAllSelected = changedCrew?.some((crew) => crew.label === 'All');
    } else {
      isAllSelected = changedCrew?.label === 'All';
    }

    if (isAllSelected) {
      setIsMulti(false);
      setSelectedCrews({ label: 'All', value: crews });
      return;
    }

    if (!isMulti && !isAllSelected) {
      setIsMulti(true);
      setSelectedCrews([changedCrew]);
      return;
    }

    setSelectedCrews(changedCrew);
  };

  const getValidCrews = () => {
    let crewsThatAreSelected;

    if (!isMulti) {
      crewsThatAreSelected = selectedCrews?.value;
    } else {
      crewsThatAreSelected = selectedCrews;
    }
    return crewsThatAreSelected;
  };

  const exportCrewsData = () => {
    const validCrews = getValidCrews();
    if (validCrews) {
      const crewsTotalWorkingHours = validCrews.reduce((total, crew) => {
        if (crew.label === 'All') return total;
        const totalHours = totalHoursForACrew(crew.value);
        const crewLog = { Name: crew.label, 'Total Working Hours': totalHours };

        return [...total, crewLog];
      }, []);
      const totalHoursOfCrews = selectedCrewsTotalWorkingHours();
      setPdfCrewLog([
        ...crewsTotalWorkingHours,
        { Name: 'Total', 'Total Working Hours': totalHoursOfCrews },
      ]);
    }
  };

  const exportSelectedDate = () => {
    const startDate = moment(date.startDate).format('MMM Do YYYY');
    const endDate = moment(date.endDate).format('MMM Do YYYY');

    return { startDate, endDate };
  };

  const selectedCrewsTotalWorkingHours = () => {
    const validCrews = getValidCrews();
    const totalOfAll = validCrews.reduce((totalHours, current) => {
      if (current.label === 'All') return totalHours;

      const total = jobs?.response?.reduce((acc, job) => {
        const setOfProviderUids = new Set(job?.providerUids);
        if (!setOfProviderUids.has(current.value) || !job.endDate) {
          return acc;
        }

        const newSum = acc + (job.endDate - getJobDate(job));
        return newSum;
      }, 0);

      const newSum = total + totalHours;
      return newSum;
    }, 0);

    return getHoursFromMill(totalOfAll);
  };

  const getjobs = async () => {
    setIsGettingJobs(true);
    let crewsUids;
    if (!isMulti) {
      crewsUids = null;
    } else {
      crewsUids = selectedCrews?.map((crew) => crew.value);
    }

    let selectedProperties;
    if (!isMultiProperty) {
      selectedProperties = null;
    } else {
      selectedProperties = selectedProperty;
    }
    const propertyKeys = selectedProperties?.reduce((acc, property) => {
      return [...acc, property.value];
    }, []);

    const res = await getTimeSheet(
      crewsUids,
      date.startDate,
      date.endDate,
      propertyKeys
    );

    setJobs(res);
    setIsGettingJobs(false);
  };

  const auditableJobsFor = (crewUid, d) => {
    return jobs?.response?.filter((j) => {
      return (
        j.providerUids.includes(crewUid) &&
        getDayMonthYear(getJobDate(j)) === getDayMonthYear(d) &&
        !j.endDate
      );
    });
  };

  const jobsThatShouldBeAudited = (crew, d) => {
    return jobs?.response?.filter(
      (j) =>
        j.providerUids.includes(crew?.value) &&
        getDayMonthYear(getJobDate(j)) === getDayMonthYear(d)
    );
  };

  const handleAudit = (crew, d, selectedStatus) => {
    const jobsThatAreAuditable = jobsThatShouldBeAudited(crew, d);
    if (!jobsThatAreAuditable?.length) {
      return;
    }
    setAuditableCrew(crew);
    setAuditingDate(d);
    setSelectedJobStatus(selectedStatus ?? null);
    setOpen(true);
  };

  const isModified = (modifiedArrays) => {
    const setOfJobs = new Set(modifiedArrays ?? []);
    if (setOfJobs.has(true)) {
      return '*';
    }
    return null;
  };

  const getTotalHoursForDate = (crewId, d) => {
    const jobTotalTimeAndAcc = jobs.response.reduce(
      (acc, job) => {
        const setOfProviderUids = new Set(job.providerUids);
        if (
          setOfProviderUids.has(crewId) &&
          getDayMonthYear(getJobDate(job)) === getDayMonthYear(d) &&
          job.endDate &&
          getJobDate(job)
        ) {
          const newTotal = acc.total + job.endDate - getJobDate(job);
          return {
            ...acc,
            total: newTotal,
            modified: [...(acc.modified ?? []), job.modified ?? false],
          };
        }
        return acc;
      },
      {
        total: 0,
        modified: [],
      }
    );

    return jobTotalTimeAndAcc;
  };

  const getHourForDateAndCrew = (crew, d) => {
    const { value: crewUid } = crew;

    const jobTotalTimeAndAcc = getTotalHoursForDate(crewUid, d);

    return (
      <StyledTableCell
        key={crew.value}
        color={auditableJobsFor(crewUid, d)?.length ? color.red : null}
        onClick={() => handleAudit(crew, d)}
        cursor="pointer"
      >
        <UnderlinedText>
          {getHoursFromMill(jobTotalTimeAndAcc.total)}
          {isModified(jobTotalTimeAndAcc.modified)}
        </UnderlinedText>
      </StyledTableCell>
    );
  };

  const handleCrewClick = (crew) => {
    const jobsOfSelectedCrew = jobs?.response?.filter((job) => {
      return job.providerUids?.includes(crew.value);
    });

    if (!jobsOfSelectedCrew?.length) {
      return;
    }

    setAuditableCrew(crew);
    setIsCrewSelected(true);
  };

  const renderCrewData = ({ crew }) => {
    return jobs?.arrayOfDates?.map((d) => {
      const selectedDate = getDayMonthYear(d);
      if (crew.label === 'All') {
        return null;
      }
      return (
        <Fragment key={selectedDate}>{getHourForDateAndCrew(crew, d)}</Fragment>
      );
    });
  };

  const renderCrewsWorkHoursForDates = () => {
    const crewsThatsSelected = getValidCrews();
    return crewsThatsSelected?.map((crew, index) => {
      if (crew.label === 'All') {
        return null;
      }

      return (
        <StyledTableRow bgcolor={getBgColorOfTableRow(index)} key={crew.value}>
          <StyledTableCell
            cursor="pointer"
            key={crew.value}
            onClick={() => handleCrewClick(crew)}
          >
            <UnderlinedText weight="bold">{crew.label}</UnderlinedText>
          </StyledTableCell>
          {renderCrewData({ crew })}
          <TableCell>
            <Span>{totalHoursForACrew(crew.value)}</Span>
          </TableCell>
        </StyledTableRow>
      );
    });
  };

  const totalHoursForACrew = (crewUid) => {
    const jobsOfCrew = jobs?.response?.filter((job) => {
      const setOfProviderUids = new Set(job.providerUids ?? []);
      return setOfProviderUids.has(crewUid);
    });

    const totalTime = jobsOfCrew?.reduce((total, curr) => {
      const { endDate: endDateOfJob } = curr;
      if (!endDateOfJob) {
        return total;
      }
      return total + endDateOfJob - getJobDate(curr);
    }, 0);

    return getHoursFromMill(totalTime);
  };

  const totalHoursForADate = (d) => {
    const selectedDate = getDayMonthYear(d);
    const crewsThatAreSelected = getValidCrews();
    const totalHoursOfADate = crewsThatAreSelected?.reduce((total, crew) => {
      const crewUid = crew.value;

      const totalOfAllJobs = jobs?.response?.reduce((totalHr, job) => {
        const createdDate = getDayMonthYear(getJobDate(job));
        const setOfProviderUids = new Set(job.providerUids ?? []);
        if (
          !setOfProviderUids.has(crewUid) ||
          !job.endDate ||
          selectedDate !== createdDate
        ) {
          return totalHr;
        }

        const newTotal = totalHr + (job.endDate - getJobDate(job));
        return newTotal;
      }, 0);

      return total + totalOfAllJobs;
    }, 0);

    return getHoursFromMill(totalHoursOfADate);
  };

  const getSelectedProperties = () => {
    if (!isMultiProperty) {
      return selectedProperty?.value?.filter(
        (property) => property.label !== 'All'
      );
    }
    const propertiesThatAreSelected = selectedProperty?.reduce(
      (acc, property) => {
        if (property) {
          return [...acc, property];
        }
        return acc;
      },
      []
    );

    return propertiesThatAreSelected;
  };

  const getMainHeader = () => {
    const { name } = pdfDetails ?? {};
    const { startDate, endDate: endDateOfJob } = exportSelectedDate();
    const propertiesSelected = getSelectedProperties();
    let subTitle = `Generated by ${name} for date range: ${startDate} to ${endDateOfJob}`;

    if (propertiesSelected?.length) {
      subTitle = `${subTitle} on property ${properties
        ?.map((property) => property.label)
        .join(' ,')}`;
    }

    return subTitle;
  };

  const selectedDatesAndTotalHours = (acc, d) => {
    const selectedDate = getDayMonthYear(d);
    const totalHoursForSelectedDate = totalHoursForADate(d);

    acc.selectedDates.push(selectedDate);
    acc.totalHoursForSelectedDates.push({
      total: totalHoursForSelectedDate,
    });
    return acc;
  };

  const handleExcelExport = () => {
    const selectedCrewsForExcel = getValidCrews();

    const selectedCrewsLog = selectedCrewsForExcel.reduce((total, crew) => {
      if (crew.label === 'All') {
        return total;
      }
      const individualDateLog = jobs?.arrayOfDates?.map((d) => {
        const { value: crewUid } = crew;

        const jobTotalTimeAndAcc = getTotalHoursForDate(crewUid, d);
        return jobTotalTimeAndAcc;
      });

      const totalHours = totalHoursForACrew(crew.value);
      const totalHoursForCrew = { total: totalHours };
      return [...total, [...individualDateLog, totalHoursForCrew]];
    }, []);

    const acc = jobs?.arrayOfDates?.reduce(selectedDatesAndTotalHours, {
      selectedDates: [],
      totalHoursForSelectedDates: [],
    });

    const totalRow = [
      ...acc.totalHoursForSelectedDates,
      { total: selectedCrewsTotalWorkingHours() },
    ];

    let crewsSelected = [];
    if (!isMulti) {
      crewsSelected = selectedCrews?.value?.filter(
        (crew) => crew.label !== 'All'
      );
    } else {
      crewsSelected = [...selectedCrews];
    }

    downloadExcel({
      details: [...selectedCrewsLog, totalRow],
      fileName: 'Timesheets',
      selectedCrews: crewsSelected,
      selectedDates: acc.selectedDates,
      header: getMainHeader(),
      pdfDetails,
    });
  };

  const renderTable = () => {
    if (!jobs || !selectedCrews) {
      return null;
    }

    if (!jobs?.response?.length) {
      return (
        <Row justify="center">
          <Span weight="bold">No jobs.</Span>
        </Row>
      );
    }

    return (
      <>
        <Row justify="flex-end" gap="8px">
          <PrimaryButton onClick={openPdfPreview}>
            Total Hours PDF
          </PrimaryButton>
          <PrimaryButton onClick={handleExcelExport}>
            Export Excel
          </PrimaryButton>
        </Row>
        <TableContainer style={{ maxHeight: '90vh' }}>
          <Table stickyHeader aria-label="sticky table" color={color.snomwBlue}>
            <TableHead>
              <StyledTableRow>
                <ReportHeaderCell>Dates</ReportHeaderCell>
                {jobs.arrayOfDates?.map((d) => {
                  const selectedDate = getDayMonthYear(d);
                  return (
                    <ReportHeaderCell key={selectedDate}>
                      <Span color={color.white}>{getDayMonthYear(d)}</Span>
                    </ReportHeaderCell>
                  );
                })}
                <ReportHeaderCell style={{ minWidth: 100 }}>
                  <Span color={color.white}>Total</Span>
                </ReportHeaderCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {renderCrewsWorkHoursForDates()}

              <StyledTableRow bgcolor={color.green}>
                <TableCell>
                  <Span color={color.white}>Total</Span>
                </TableCell>
                {jobs?.arrayOfDates?.map((d) => {
                  const selectedDate = getDayMonthYear(d);
                  return (
                    <TableCell key={selectedDate}>
                      <Span color={color.white}>{totalHoursForADate(d)}</Span>
                    </TableCell>
                  );
                })}
                <TableCell>
                  <Span color={color.white}>
                    {selectedCrewsTotalWorkingHours()}
                  </Span>
                </TableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Row justify="flex-end">
          <Span weight="bold">
            * - indicates that the record has been modified.
          </Span>
        </Row>
      </>
    );
  };

  const handleJobEdit = (job) => {
    setSelectedJob(job);
    setOpenEditJob(true);
    setStartedDate(job?.startedDate);
    setEndDate(job?.endDate);
  };

  const getStartedDate = () => {
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DateTimePicker
          value={startedDate}
          onChange={setStartedDate}
          minDate={selectedJob?.createdDate}
        />
      </MuiPickersUtilsProvider>
    );
  };

  const getEndDate = () => {
    const minDate = startedDate ?? selectedJob?.createdDate;
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DateTimePicker
          value={endDate}
          onChange={setEndDate}
          minDate={minDate}
        />
      </MuiPickersUtilsProvider>
    );
  };

  const handleStatusChange = (selectedOption) => {
    setJobStatus(selectedOption);
  };

  const saveJobDetails = async () => {
    setErrMsg(null);
    setIsJobEditing(true);

    const jobStartedDate = moment(startedDate).valueOf();
    const jobEndDate = moment(endDate).valueOf();

    const status = jobStatus.value;
    await updateSomeJobDetail(selectedJob, status, jobStartedDate, jobEndDate);

    const oldJobs = [...jobs.response];

    const updatedJob = {
      ...selectedJob,
      status,
      endDate: jobEndDate,
      startedDate: jobStartedDate,
      modified: true,
    };

    const historyContent = await storeAuditHistory(selectedJob, updatedJob);

    setHistoryOfJob({
      ...(historyOfJob ?? {}),
      [selectedJob.key]: historyContent,
    });

    const updatedJobIndex = oldJobs.findIndex(
      (job) => job.key === updatedJob.key
    );

    oldJobs[updatedJobIndex] = updatedJob;

    setJobs({ ...jobs, response: oldJobs });
    setIsJobEditing(false);
    setOpenEditJob(false);
  };

  const handleEditJobCancel = () => {
    setOpenEditJob(false);
    setErrMsg(null);
  };

  const handleCloseIconOfJobDetails = () => {
    setOpen(false);
  };

  const checkTimeStamps = () => {
    setErrMsg(null);
    if (!startedDate || !endDate) {
      setErrMsg('Please fill all the field.');
      setIsJobEditing(false);
      setIsEndDateLess(true);
      return;
    }

    if (startedDate > endDate) {
      setErrMsg('End time should be greater than start time.');
      setIsJobEditing(false);
      setIsEndDateLess(true);
      return;
    }
    setIsEndDateLess(false);
  };

  useEffect(() => {
    checkTimeStamps();
  }, [startedDate, endDate]);

  const renderCrewsOptions = () => {
    return (
      <>
        <Span weight="bold">Select crew:</Span>
        <Select
          options={crews}
          isMulti={isMulti}
          value={selectedCrews}
          isClearable={isMulti}
          onChange={handleCrewChange}
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        />
      </>
    );
  };

  const handlePropertyChange = (selectedOption) => {
    if (!selectedOption) {
      setIsMultiProperty(false);
      setSelectedProperty({ label: 'All', value: properties });
      return;
    }
    let isAllSelected = false;
    if (isMultiProperty) {
      isAllSelected = selectedOption?.some(
        (property) => property.label === 'All'
      );
    } else {
      isAllSelected = selectedOption?.label === 'All';
    }

    if (isAllSelected) {
      setIsMultiProperty(false);
      setSelectedProperty({ label: 'All', value: properties });
      return;
    }
    if (!isMultiProperty && !isAllSelected) {
      setIsMultiProperty(true);
      setSelectedProperty([selectedOption]);
      return;
    }
    setSelectedProperty(selectedOption);
  };

  const renderPropertiesOptions = () => {
    if (!properties.length) {
      return null;
    }
    return (
      <>
        <Span weight="bold">Select property:</Span>
        <Select
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          options={properties}
          isMulti={isMultiProperty}
          onChange={handlePropertyChange}
          value={selectedProperty}
          isClearable={isMultiProperty}
        />
      </>
    );
  };

  const handleJobAuditView = (job) => {
    setShowJobAudit(true);
    setSelectedJob(job);
  };

  const closeCrewDetailModal = () => {
    setIsCrewSelected(false);
  };

  const handleHistoryButton = (job) => {
    setSelectedJob(job);
    setOpenHistoryDetailJob(true);
  };

  const handleJobHistoryClose = () => {
    setOpenHistoryDetailJob(false);
  };

  if (!crews || !properties) {
    return <Loader />;
  }

  const renderTimeStampForJob = (job) => {
    const startDate = job?.startedDate ? getDateForJob(job.startedDate) : '-';
    const endedDate = job?.endDate ? getDateForJob(job.endDate) : '-';
    return (
      <>
        <FlexContainer justifyContent="space-between">
          <Span>{startDate}</Span>
        </FlexContainer>
        <FlexContainer justifyContent="space-between">
          <Span>{endedDate}</Span>
        </FlexContainer>
      </>
    );
  };

  const handleSelectionChange = (res) => {
    setSelectedDuration(res);
  };

  const customDateRange = () => {
    if (selectedDuration?.value === 'custom') {
      return (
        <>
          <Divider />
          <Span weight="bold">Select date range:</Span>
          <StyledDatePicker
            onFocusChange={handleFocusChange}
            onDatesChange={handleDateChange}
            startDateId="startDate"
            endDateId="endDate"
            focusedInput={focusedInput}
            startDate={date.startDate}
            endDate={date.endDate}
            isOutsideRange={(d) => d.isAfter(moment().endOf('day'))}
            readOnly
          />
        </>
      );
    }
    return null;
  };

  return (
    <>
      <Grid style={{ margin: 16 }} templateColumns="430px">
        <Paper>
          <Col style={{ margin: 16 }} gap="4px" width="auto">
            <Span weight="bold" size="24px">
              Timesheets
            </Span>

            <Divider />

            <GridAttendance>
              <Col gap="4px">
                {renderCrewsOptions()}

                {renderPropertiesOptions()}

                <Col>
                  <Row>
                    <Span weight="bold">Select Date Range:</Span>
                  </Row>
                  <CustomSelect
                    options={dateRangeOptions}
                    value={selectedDuration}
                    onChange={handleSelectionChange}
                  />
                </Col>
                {customDateRange()}
              </Col>

              <Divider />

              <PrimaryButton loading={isGettingJobs} onClick={getjobs}>
                Continue
              </PrimaryButton>
            </GridAttendance>
            {jobs && <Divider />}
          </Col>
        </Paper>
      </Grid>
      <Col style={{ margin: 16 }} gap="16px">
        {renderTable()}
      </Col>
      <ADialog open={open} maxWidth="xl" width="100%">
        <Row justify="space-between">
          <Title>Audit</Title>
          <IconButton onClick={handleCloseIconOfJobDetails}>
            <CloseIcon />
          </IconButton>
        </Row>

        <Divider />
        <Row gap="8px" justify="center">
          <Span weight="bold" size="24px">
            {auditableCrew?.label}
          </Span>
          <Span>on</Span>
          <Span size="24px" weight="bold">
            {getDayMonthYear(auditingDate)}
          </Span>
        </Row>
        <Divider />
        <TableContainer>
          <Table>
            <TableHead>
              <StyledTableRow bgcolor={color.snomwBlue}>
                <TableCell>
                  <Span color={color.white} weight="bold">
                    Job Name
                  </Span>
                </TableCell>
                <TableCell>
                  <Span color={color.white} weight="bold">
                    Status
                  </Span>
                </TableCell>
                <TableCell>
                  <Span color={color.white} weight="bold">
                    Start Time
                    <br />
                    End Time
                  </Span>
                </TableCell>
                <TableCell>
                  <Span color={color.white} weight="bold">
                    Duration
                  </Span>
                </TableCell>
                <TableCell>
                  <Span color={color.white} weight="bold">
                    Actions
                  </Span>
                </TableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {auditableJobs?.map((job) => {
                return (
                  <TableRow key={job.key}>
                    <TableCell>
                      <Span weight="bold">{job.name}</Span>
                    </TableCell>
                    <TableCell>
                      <Span color={getColorForStatus(job)} weight="bold">
                        {statusesOfJob[job.status]}
                      </Span>
                    </TableCell>
                    <TableCell>
                      <Span>{renderTimeStampForJob(job)}</Span>
                    </TableCell>
                    <TableCell>
                      {!job.endDate ? (
                        '-'
                      ) : (
                        <Span>
                          {getHoursFromMill(job.endDate - getJobDate(job))}
                        </Span>
                      )}
                    </TableCell>
                    <TableCell>
                      <Row gap="4px">
                        <JobAuditButtons
                          onViewClick={() => handleJobAuditView(job)}
                          onEditClick={() => handleJobEdit(job)}
                          onHistoryClick={() => handleHistoryButton(job)}
                          jobKey={job.key}
                          historyOfJob={
                            historyOfJob?.[job.key]
                              ? [historyOfJob?.[job.key]]
                              : null
                          }
                        />
                      </Row>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </ADialog>
      <ADialog open={openEditJob}>
        <Col gap="8px">
          <Row justify="space-between">
            <Span weight="bold">Edit Job</Span>
            <IconButton onClick={handleEditJobCancel}>
              <CloseIcon />
            </IconButton>
          </Row>

          <Divider />

          <Span weight="bold">Job Status:</Span>
          <CustomSelect
            disabled
            options={statuses}
            value={jobStatus}
            onChange={handleStatusChange}
          />
          <Divider />
          <Span weight="bold">Started Date:</Span>

          {getStartedDate()}

          <Divider />

          <Span weight="bold">End Date:</Span>

          {getEndDate()}

          <Span color="red">{errMsg}</Span>

          <Divider />
          <Row justify="flex-end">
            <Button
              style={{
                textTransform: 'none',
              }}
              onClick={handleEditJobCancel}
            >
              Cancel
            </Button>
            <PrimaryButton
              loading={isJobEditing}
              onClick={saveJobDetails}
              disabled={isEndDateLess}
            >
              Save
            </PrimaryButton>
          </Row>
        </Col>
      </ADialog>
      <ADialog open={isCrewSelected} maxWidth="xl" width="100%">
        <SelectedCrewDetails
          auditableCrew={auditableCrew}
          jobs={jobs}
          closeCrewDetailModal={closeCrewDetailModal}
          handleAudit={handleAudit}
        />
      </ADialog>
      <ADialog open={openHistoryDetailOfJob} maxWidth="md" width="100%">
        <JobHistoryDetails
          job={selectedJob}
          handleCloseClick={handleJobHistoryClose}
        />
      </ADialog>
      <ADialog maxWidth="xl" width="100%" open={showPdfPreview}>
        <StyledPDFViewer>
          <PdfReport
            data={pdfCrewLog}
            details={pdfDetails}
            header={getMainHeader()}
          />
        </StyledPDFViewer>
        <FlexContainer>
          <CapitalizeButton onClick={closePdfPreview}>Close</CapitalizeButton>
        </FlexContainer>
      </ADialog>
      <ADialog open={showJobAudit} padding="0" width="auto" $minWidth="600px">
        <Align align="right">
          <IconButton onClick={() => setShowJobAudit(false)}>
            <CloseIcon />
          </IconButton>
        </Align>
        <Align>
          <Title>Job details</Title>
        </Align>
        <IssueLink link={`/home/jobs/details/${selectedJob?.key}`}>
          <JobItem
            smGrow="auto"
            mdGrow="auto"
            lgGrow="auto"
            boxShadow="none"
            key={selectedJob?.key}
            job={selectedJob}
          />
        </IssueLink>
      </ADialog>
    </>
  );
};

export default Attendance;

const StyledDatePicker = styled(DateRangePicker)`
  && {
    .DateRangePickerInput__withBorder {
      width: 100%;
    }
  }
`;

const GridAttendance = styled.div`
  display: grid;
  grid-template-columns: 400px;
  gap: 12px;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent ?? 'flex-end'};
  aligh-items: ${(props) => props.alignItems ?? 'right'};
`;

const CapitalizeButton = styled(Button)`
  && {
    text-transform: capitalize;
  }
`;

const StyledPDFViewer = styled(PDFViewer)`
  height: ${(props) => props.height ?? '100vh'};
`;

const ReportHeaderCell = styled(TableCell)`
  && {
    background-color: ${(props) => props.$bgColor ?? color.snomwBlue};
    color: ${(props) => props.$color ?? color.white};
  }
`;
